<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { Consultation } from "@model/entities";
import { defaultLocale, Locale } from "@model/constants";
import { ConsultationService } from "@/services";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { printConsultationProof } from "@/utils/print";

@Options({
    components: {},
})
export default class PrintConsultationProofDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    consultationId: Consultation["id"] = null;
    consultation: Consultation = null;
    locale: Locale = defaultLocale;
    remark = "";

    @ListenEvent("print-consultation-proof-dialog:open")
    async handleOpen(consultationId: Consultation["id"], locale: Locale = defaultLocale) {
        this.locale = locale;
        this.remark = "";
        this.consultationId = consultationId;
        this.open = true;
    }

    async handleClickSubmit() {
        if (this.consultationId) {
            try {
                this.loading = true;
                this.consultation = await ConsultationService.oneDeep<Consultation>(this.consultationId);
                await printConsultationProof({ consultation: this.consultation, remark: this.remark, locale: this.locale });
                this.open = false;
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        div 列印到診證明書
    .body
        el-form(label-position="top")
            el-form-item(label="備註")
                el-input(v-model="remark" type="textarea" :autosize="{minRows: 4}")
            Button(type="primary" @click="handleClickSubmit" :loading="loading") 列印
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
