import { axGet } from "./axios";
import { BaseService } from "./base.service";
import { AdminHerbStockConsumeLogQueryDTO } from "@model/dtos";
import { PaginatedResponse } from "@model/models";


class Service extends BaseService {
    async queryConsumeLog(queryParams: AdminHerbStockConsumeLogQueryDTO): Promise<PaginatedResponse<any>> {
        return this.get(`/query/consume-log?${this.parseQuery(queryParams)}`);
    }
}

export const AdminHerbStockService = new Service('/admin/herb-stock');

