<script lang="ts">
import { Emit, Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class SelectGender extends Vue {
    public value: string = "";

    @Prop({ 
        default: 'select',
        validator: (value: string) => {
            return ['select', 'radio'].includes(value);
        },
    })
    type: 'select' | 'radio';

    genderData = [
        {
            label: "男",
            value: "M",
        },
        {
            label: "女",
            value: "F",
        },
    ];

    @Emit("change")
    onChange(value: any) {
        this.value = value;
    }
}
</script>

<template lang="pug">
template(v-if="type === 'radio'")
    el-radio-group(v-model="value" @change="onChange")
        el-radio(v-for="gender in genderData" :label="gender.value") {{  gender.label }}
template(v-else)
    el-select(v-model="value" @change="onChange" placeholder="選擇性別")
        el-option(v-for="gender in genderData" :key="gender.value" :label="gender.label" :value="gender.value")
</template>

<style scoped lang="scss"></style>
