import { eventEmitter } from "@/services/events";
import { useCache } from "@/hooks/useCache";
import axios, { AxiosError, type AxiosInstance, type AxiosPromise, type AxiosRequestConfig, type AxiosResponse } from "axios";
import qs from "qs";
import { axiosLock } from './axios-lock';
import { dynamicEnv } from "@/utils/dynamic-env";
import { LocalStorage } from "@/utils/local-storage";

const ax: AxiosInstance = axios.create({
    baseURL: dynamicEnv('BACKEND_ENDPOINT'),
    timeout: 60 * 1000,
});

const onRequestFulfilled = (config: AxiosRequestConfig) => {
    if (config.method === "post" && config!.headers!["Content-Type"] === "application/x-www-form-urlencoded") {
        config.data = qs.stringify(config.data);
    }

    if (config.method === "get" && config.params) {
        let url = config.url as string;
        url += "?";
        const keys = Object.keys(config.params);
        for (const key of keys) {
            if (config.params[key] !== void 0 && config.params[key] !== null) {
                url += `${key}=${encodeURIComponent(config.params[key])}&`;
            }
        }
        url = url.substring(0, url.length - 1);
        config.params = {};
        config.url = url;
    }
    return config;
};

const parseError = (err: AxiosError) => {
    return err?.response?.data as any;
};

const onRequestRejected = (error: AxiosError) => {
    // console.log("Axios onRequestRejected" + error);
    Promise.reject(error.message);
};

const onResponseFulfilled = (response: AxiosResponse<Recordable>) => {
    // console.log("Axios onResponseFulfilled", response);
    return response;
};

const onResponseRejected = (error: AxiosError) => {
    const err = parseError(error);
    if (err && "code" in err) {
        if (err.code === "ERR_TOKEN_EXPIRED" || err.code === "ERR_INVALID_TOKEN") {
            eventEmitter.emit("auth:fail");
        }
    } else {
        eventEmitter.emit("server:connection-failed");
    }
    return Promise.reject(error.response?.data);
};

ax.interceptors.request.use(onRequestFulfilled, onRequestRejected);
ax.interceptors.response.use(onResponseFulfilled, onResponseRejected);

const request = (option: AxiosConfig, shouldSkipCredentials = false) => {
    const { url, method, params, data, headersType, responseType, mutex } = option;

    let lockToken;
    if (mutex) {
        lockToken = new Date().toISOString();
        axiosLock.lock(lockToken);
    }

    return ax({
        url: url,
        method,
        params,
        data,
        withCredentials: !shouldSkipCredentials,
        responseType: responseType,
        headers: {
            "X-Selected-Clinic": LocalStorage.selectedClinic.get() || undefined,
            "Content-Type": headersType || "application/json",
        },
    })
        .then(x => x.data)
        .finally(() => {
            if (mutex) {
                axiosLock.releaseLock(lockToken);
            }
        });
};

export const axGet = <T = any>(option: AxiosConfig, shouldSkipCredentials = false): Promise<T> => {
    return request({ method: "get", ...option }, shouldSkipCredentials);
};

export const axPost = <T = any>(option: AxiosConfig, shouldSkipCredentials = false): Promise<T> => {
    return request({ method: "post", ...option }, shouldSkipCredentials);
};

export const axDelete = <T = any>(option: AxiosConfig, shouldSkipCredentials = false): Promise<T> => {
    return request({ method: "delete", ...option }, shouldSkipCredentials);
};

export const axPut = <T = any>(option: AxiosConfig, shouldSkipCredentials = false): Promise<T> => {
    return request({ method: "put", ...option }, shouldSkipCredentials);
};

export const axCachedGet = async <T = any>(option: AxiosConfig, cacheOption?: { flush?: boolean, ttl?: number, cacheKeyOverride?: string }): Promise<T> => {
    const {
        flush = false,
        ttl = 300,
        cacheKeyOverride = `AX_${option.url}`,
    } = cacheOption || {};

    const { wsCache } = useCache();
    const cachedValue = wsCache.get(cacheKeyOverride);
    let result = cachedValue;

    if (!result || flush) {
        result = await axGet(option);
        console.log("RES", result);
        wsCache.set(cacheKeyOverride, result, { exp: ttl });
    }
    return result;
};

export const axDeleteCache = (key: string) => {
    const { wsCache } = useCache();
    wsCache.delete(key);
}