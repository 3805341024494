import { defineStore } from 'pinia';
import { store } from '@/stores';
import { notify } from '@/utils/notify';

interface State {
    common: {
        hidePrimaryMenu: boolean;
    },
    progress: {
        isGeneratingReport: boolean;
        beforeUnloadListener?: (e: BeforeUnloadEvent) => void;
    },
}

const defaultState: State = {
    common: {
        hidePrimaryMenu: false,
    },
    progress: {
        isGeneratingReport: false,
        beforeUnloadListener: null,
    },
}

export const useUITemporaryStore = defineStore({
    id: 'ui-temporary',
    persist: {
        enabled: true,
    },
    state: (): State => defaultState,
    actions: {
        startReportGeneration() {
            if (this.progress.isGeneratingReport) {
                notify.error('正在生成報告，請稍候');
                return;
            }
            this.progress.beforeUnloadListener = (event) => {
                event.preventDefault();
                return (event.returnValue = "正在生成報告，確定要離開嗎？");
            }
            window.addEventListener('beforeunload', this.progress.beforeUnloadListener, { capture: true });
            this.progress.isGeneratingReport = true;
        },
        endReportGeneration() {
            window.removeEventListener('beforeunload', this.progress.beforeUnloadListener, { capture: true });
            this.progress.isGeneratingReport = false;
        }
    }
});

export const useUITemporaryStoreWithOut = () => {
    return useUITemporaryStore(store);
};
