import { Organization } from "@model/entities";
import { BaseService } from "./base.service";
import { OrganizationUpdateDto } from "@model/dtos";


class Service extends BaseService {
    async load(): Promise<Organization> {
        return this.get(``);
    }

    async update(body: OrganizationUpdateDto): Promise<Organization> {
        return this.post(``, body);
    }
}

export const OrganizationService = new Service('/organization');