import dayjs, { Dayjs } from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import locale from "dayjs/locale/zh-hk";
import { defaultLocale, Locale } from "@model/constants";
export * from './ms-converter';

dayjs.extend(tz);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.locale(locale);

const toDayjs = (date?: Date | string | null) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong');
}

const toDate = (date?: Date | string | null) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').toDate();
}

const getDateAfter = (val: number, unit: dayjs.ManipulateType) => {
    return toDayjs().add(val, unit).toDate();
}

const formatDate = (date?: Date | string | null) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD');
}

const formatDatetime = (date?: Date | string | null) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD HH:mm:ss');
}

const formatTimeslot = (date?: Date | string | null) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('HH:mm');
}

const formatAge = (date?: Date | string | null) => {
    if (!date || (typeof date === 'string' && date.length < 7)) {
        return "-";
    }
    return dayjs().diff(date, 'year');
}

const formatTimeslotWithStep = (date?: Date | string | null, step: number = 5) => {
    let d = dayjs(date);
    d = d.minute(Math.floor(d.minute() / step) * step);
    return d.tz('Asia/Hong_Kong').format('HH:mm');
}

const formatDateWithSlash = (date?: Date | string | null) => {
    if (date === null) {
        return '-';
    }
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('YYYY/MM/DD');
}

const formatDateWithAMPM = (date?: Date | string | null, locale: Locale = defaultLocale) => {
    const d = dayjs(date);
    if (locale === 'zh') {
        return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD A').replace('AM', '上午').replace('PM', '下午');
    }
    return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD A');
}

const formatTimeslotWithAMPM = (date: string, timeslot: string, locale: Locale = defaultLocale) => {
    // Define am/pm by custom time: 2pm
    const hour = Number(timeslot.split(':')[0]);
    if (locale === 'zh') {
        return `${date} ${hour < 12 ? '上午' : '下午'}`;
    }
    return `${date} ${hour < 12 ? 'AM' : 'PM'}`;
}

const formatDateText = (date?: Date) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('LL (dd)');
}

const isDayBefore = (date1: Date | string | null, date2: Date | string | null, canEqual: boolean = false) => {
    const d1 = toDayjs(date1);
    const d2 = toDayjs(date2);
    if (!d1.isValid()) {
        return false;
    }
    if (!d2.isValid()) {
        return true;
    }
    return canEqual ? d1.diff(d2, 'd') <= 0 : d1.diff(d2, 'd') < 0;
}

const getSickLeaveDays = (from: Date | string | null, to: Date | string | null, offset?: {
    sick_leave_start_from_pm?: boolean,
    sick_leave_end_at_am?: boolean,
}) => {
    const {
        sick_leave_start_from_pm = false,
        sick_leave_end_at_am = false,
    } = offset ?? {};

    let dayDiff = toDayjs(to).diff(toDayjs(from), 'd') + 1;
    if (sick_leave_start_from_pm) {
        dayDiff -= 0.5;
    }
    if (sick_leave_end_at_am) {
        if (dayDiff <= 0) {
            throw Error('病假不能少於半天');
        }
        dayDiff -= 0.5;
    }
    if (dayDiff <= 0) {
        throw Error('病假不能少於半天');
    }
    return dayDiff;
}

const addDurationToTimeslot = (timeslot: string, duration: number) => {
    return dayjs("1970-01-01 " + timeslot)
        .add(duration, "minute")
        .format("HH:mm");
}


export const useDateUtils = () => {
    dayjs.extend(tz);
    dayjs.extend(utc);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(localizedFormat);
    dayjs.locale(locale);

    return {
        dayjs: dayjs,
        toDayjs,
        toDate,
        today: () => dayjs().tz('Asia/Hong_Kong').format('YYYY-MM-DD'),
        yesterday: () => dayjs().subtract(1, 'day').tz('Asia/Hong_Kong').format('YYYY-MM-DD'),
        isDayBefore,
        getDateAfter,
        formatAge,
        formatDate,
        formatDatetime,
        formatDateWithSlash,
        formatDateWithAMPM,
        formatTimeslotWithAMPM,
        formatDateText,
        formatTimeslot,
        formatTimeslotWithStep,
        getSickLeaveDays,
        addDurationToTimeslot,
    }
};


export default {
    dayjs: dayjs,
    toDayjs,
    toDate,
    today: () => dayjs().tz('Asia/Hong_Kong').format('YYYY-MM-DD'),
    yesterday: () => dayjs().subtract(1, 'day').tz('Asia/Hong_Kong').format('YYYY-MM-DD'),
    isDayBefore,
    getDateAfter,
    formatAge,
    formatDate,
    formatDatetime,
    formatDateWithSlash,
    formatDateWithAMPM,
    formatTimeslotWithAMPM,
    formatDateText,
    formatTimeslot,
    formatTimeslotWithStep,
    getSickLeaveDays,
    addDurationToTimeslot,
};
