import { IsString, Matches } from "class-validator";

export class ScheduleTimeRange {
    @IsString()
    @Matches(/[0-2][0-9]:[0-5][0-9]/)
    from: string;

    @IsString()
    @Matches(/[0-2][0-9]:[0-5][0-9]/)
    to: string;
}