import { BaseService } from "./base.service";
import { HerbDictionaryResponse } from "@model/responses";
import { Clinic, Herb, HerbStockCheck } from "@model/entities";
import { HerbStockCheckItemInfoVO } from "@model/vos";
import { PaginatedResponse } from "@model/models";
import { AdminHerbStockCheckCreateDTO } from "@model/dtos";

class Service extends BaseService {
    async oneItems(id: string | number): Promise<HerbStockCheckItemInfoVO[]> {
        return this.get(`/items/${id}`);
    }

    async list(): Promise<HerbStockCheckItemInfoVO[]> {
        return this.get(`/herb`);
    }

    async query(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }

    async queryHerb(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query/herb?${this.parseQuery(queryParams)}`);
    }

    async cancel(id: HerbStockCheck['id']) {
        return this.delete(``, { id });
    }

    async update(id: HerbStockCheck['id'], body: AdminHerbStockCheckCreateDTO): Promise<HerbStockCheck> {
        return this.post(`/${id}`, body);
    }
    async updateTmp(id: HerbStockCheck['id'], body: AdminHerbStockCheckCreateDTO): Promise<HerbStockCheck> {
        return this.post(`/tmp/${id}`, body);
    }

    async validateTmp(id: HerbStockCheck['id'], body: AdminHerbStockCheckCreateDTO): Promise<HerbStockCheck> {
        return this.post(`/validate/tmp/${id}`, body);
    }

    async validate(id: HerbStockCheck['id'], body: AdminHerbStockCheckCreateDTO): Promise<HerbStockCheck> {
        return this.post(`/validate/${id}`, body);
    }

    async createTmp(body: any): Promise<HerbStockCheck> {
        try {
            return await this.put('/tmp', body);
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }
}

export const AdminHerbStockCheckService = new Service('/admin/herb-stock/check');