import { BaseService } from "./base.service";
import { PrescriptionFacadeStateVO } from "@model/vos";
import { AdvancedResponse } from "@model/responses";

class Service extends BaseService {
    async byAppointmentId(apptId): Promise<PrescriptionFacadeStateVO[]> {
        return this.getAdvanced<PrescriptionFacadeStateVO[]>(`/appointment/${apptId}`);
    }
    
    async byAppointmentIdV2(fromApptId, toClinicId): Promise<PrescriptionFacadeStateVO[]> {
        return this.getAdvanced<PrescriptionFacadeStateVO[]>(`/appointment/${fromApptId}/${toClinicId}`);
    }
}

export const HerbTransformService = new Service('/herb-transform');