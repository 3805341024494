<script lang="ts">
import { Options, Model, Prop, Vue } from "vue-property-decorator";
import { PatientReferral } from "@model/models";

@Options({})
export default class PatientReferralSelectGroup extends Vue {
    @Model("modelValue", { type: Object, default: {} })
    value!: PatientReferral;

    @Prop({ default: false, type: Boolean })
    readonly: boolean;
}
</script>

<template lang="pug">
div
    el-checkbox(v-model="value.friend" :disabled="readonly") 朋友
    el-checkbox(v-model="value.leaflet" :disabled="readonly") 傳單
    el-checkbox(v-model="value.ads" :disabled="readonly") 廣告
    el-checkbox(v-model="value.web" :disabled="readonly") 網上
    el-checkbox(v-model="value.email" :disabled="readonly") 電郵
    el-checkbox(v-if="!readonly || value.other" v-model="value.other" :disabled="readonly") 其他：
    el-input(v-if="!readonly || value.other" v-model="value.other_text" :disabled="!value.other || readonly") 
</template>

<style scoped lang="scss">
</style>
