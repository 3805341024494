<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
@Options({
    components: {},
})
export default class PatientHistoryTabMassage extends Vue {
    store = usePatientHistoryDataviewStore();

    get consultation() {
        return this.store.currentConsultation?.consultation;
    }

    get massage() {
        return this.store.currentConsultation?.massage;
    }
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-massage(label="推拿")   
    .tab-container
        el-row 
            el-col
                el-form-item
                    el-input(v-model="massage" type="textarea" :autosize="{minRows: 10}" disabled)

</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";
.tab-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>
