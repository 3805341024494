import dayjs from "dayjs";
import { formatTimeslot12HR, addDuration } from '@model/utils/time/timeslot';

export const formatDate = (date?: Date | string | null) => {
    if (date === null) {
        return '-';
    }
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD');
}

export const formatAge = (date?: Date | string | null) => {
    if (!date || (typeof date === 'string' && date.length < 7)) {
        return "-";
    }
    return dayjs().diff(date, 'year');
}

export const formatElDateRange = (elDateRange: string[2]) => {
    return {
        from: formatDate(elDateRange[0]),
        to: formatDate(elDateRange[1]),
    };
}

export const formatDateText = (date?: Date) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('LL (dd)');
}

export const formatDateTime = (date?: Date) => {
    if (date === null) {
        return '-';
    }
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD HH:mm');
}

export const formatTodayTimeElseDatetime = (date?: Date) => {
    try {
        const d = dayjs(date).tz('Asia/Hong_Kong');
        if (!d.isValid()) {
            return '-';
        }
        const today = dayjs().tz('Asia/Hong_Kong');
        if (d.diff(today, 'd') === 0) {
            return d.tz('Asia/Hong_Kong').format('HH:mm');
        }
        return d.tz('Asia/Hong_Kong').format('YYYY-MM-DD HH:mm');
    } catch (e) {
        console.log(e);
        return '-';
    }
}

export const formatTimeslot = (date?: Date) => {
    const d = dayjs(date);
    return d.tz('Asia/Hong_Kong').format('HH:mm');
}

export const formatTimeslotTo12HR = (timeslot: string) => {
    return formatTimeslot12HR(timeslot);
}

export const formatTimeRange = (fromTimeslot: string, duration: number) => {
    return `${formatTimeslotTo12HR(fromTimeslot)} - ${formatTimeslotTo12HR(addDuration(fromTimeslot, duration))} (${duration} 分鐘)`;
}

export default {
    formatElDateRange,
    formatDate,
    formatAge,
    formatDateText,
    formatDateTime,
    formatTodayTimeElseDatetime,
    formatTimeslotTo12HR,
    formatTimeRange,
}