import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Consultation, Herb, Patient, Payment } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { PatientDataviewState } from '@model/vos';
import { notify } from "@/utils/notify";

interface State {
    patient: PatientDataviewState,
}

const defaultState: State = {
    patient: PatientDataviewState.getDefaultValue(),
}

export const usePatientDataviewStore = defineStore({
    id: 'patient-dataview',
    state: (): State => cloneDeep(defaultState),
    getters: {
        consultCount(): number {
            return this.patient.consultCount;
        },
        consultationSaveParam() {
            return PatientDataviewState.toConsultationPatientParam(this.patient);
        },
        patientAlert() {
            return PatientDataviewState.toPatientAlertDto(this.patient);
        },
        tags(): string[] {
            return this.patient?.tags?.map(x => x.tag) ?? [];
        }
    },
    actions: {
        async init(patient?: Patient, consultation?: Consultation) {
            this.patient = PatientDataviewState.fromEntityOrDefault(patient, consultation);
            eventEmitter.on('appointment:patient-updated', (updatedPatient: Patient, modifiedFields?: (keyof Patient)[]) => {
                if (updatedPatient.id === this.patient.id) {
                    if (!modifiedFields) {
                        this.patient = PatientDataviewState.fromEntityOrDefault(updatedPatient);
                        return;
                    }

                    for (const field of modifiedFields) {
                        this.patient[field] = updatedPatient[field];
                    }
                }
            });
        },
        openPatientVitalDialog() {
            eventEmitter.emit("patient-vital-dialog:open", this.patient.id);
        },
        openLabRecordDialog() {
            notify.notImplemented();
            // eventEmitter.emit("patient-vital-dialog:open", this.patient.id);
        }
    }
});
