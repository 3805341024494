import { Transform } from "class-transformer";
import date from "../date";

interface Options {
    nullable?: boolean;
    default?: number;
}


export const TransformStringOrNull = () => Transform(({ value }) => {
    if (value === undefined || value === null || value === '') {
        return null;
    }
    if (typeof value === 'string' && value === 'null') {
        return null;
    }
    return value;
})

// export const TransformIntegerArray = (options?: Options) => Transform(({ value }) => {
//     return (value as string).split(',').map(x => parseInt(x));
// });

export const TransformInteger = (options?: Options) => Transform(({ value }) => {
    const {
        nullable = false,
        default: defaultValue,
    } = options ?? {};

    if (value === undefined || value === null || value === '') {
        if (defaultValue) {
            return defaultValue;
        }
        if (nullable) {
            return null;
        }
        throw Error('TransformInteger: Value is undefined or null')
    }
    return parseInt(value);
})

export const TransformNumber = (options?: Options) => Transform(({ value }) => {
    const {
        nullable = false,
        default: defaultValue,
    } = options ?? {};
    if (value === undefined || value === null || value === '') {
        if (defaultValue !== null || defaultValue !== undefined) {
            return defaultValue;
        }
        if (nullable) {
            return null;
        }

        throw Error('TransformNumber: Value is undefined or null')
    }
    return Number(value);
})

export const TransformNumberString = (options?: Options & { decimal?: number }) => Transform(({ value }) => {
    const {
        decimal = 2,
        nullable = false,
        default: defaultValue,
    } = options ?? {};
    if (value === undefined || value === null || value === '') {
        if (defaultValue) {
            return defaultValue;
        }
        if (nullable) {
            return null;
        }
        throw Error('TransformNumberString: Value is undefined or null')
    }
    return Number(`${value}`).toFixed(decimal);
})

export const TransformDateRange = (options?: Options) => Transform(({ value }) => {
    const {
        nullable = false,
        default: defaultValue,
    } = options ?? {};

    if (value === undefined || value === null || value === '') {
        if (defaultValue) {
            return defaultValue;
        }
        if (nullable) {
            return null;
        }
        throw Error('TransformDateRange: Value is undefined or null')
    }
    return [date.toDate(value[0]), date.toDate(value[1])];
})

