import { Appointment, Consultation } from "@model/entities";
import { AppointmentNotificationScheduleCommand } from "@model/dtos";
import { BaseService } from "./base.service";

class Service extends BaseService {
    async getScheduledNotifications(body: { limit?: number, page?: number, date }): Promise<{ total: number, items: any[] }> {
        return this.post(`/schedule/query`, body);
    }

    async getAppointmentNotifications(id: Appointment['id']): Promise<any[]> {
        return this.get(`/appointment/${id}`);
    }

    async scheduleAppointmentNotifications(body: AppointmentNotificationScheduleCommand): Promise<any> {
        return this.put(`/schedule/appointment`);
    }

    async cancelByNotificationId(id: string): Promise<any> {
        return this.delete(`/${id}`);
    }
}

export const NotificationService = new Service('/notification');