import type { App, Directive } from "vue";
import { permissionDirectives } from "./modules/permissions";

const directives = {
    ...permissionDirectives,
}

export const setupDirectives = (app: App<Element>) => {
    Object.keys(directives).forEach(key => {
        app.directive(key, (directives as { [key: string]: Directive<any, any> })[key]);
    });
}