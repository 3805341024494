<script lang="ts">
import { Emit, Options, Vue, Watch, Prop } from "vue-property-decorator";
import { useSelectItemStore } from "@/stores/modules/select-item";

@Options({})
export default class SelectPatientTag extends Vue {
    selectItem$ = useSelectItemStore();

    @Prop()
    modelValue: string[];

    items: string[] = [];

    async mounted() {
        this.items = await this.selectItem$.getTags();
    }

    @Emit("update:modelValue")
    onChange(value: string[]) {
        return value;
    }
}
</script>

<template lang="pug">
#select-patient-tag
    el-select(:modelValue="modelValue" @change="onChange" placeholder="標記" filterable allow-create multiple)
        el-option(v-for="item in items" :key="item" :label="item" :value="item")
</template>

<style lang="scss">
#select-patient-tag {
    width: 100%;
    .el-select {
        width: 100%;
    }
}
</style>
