import { Appointment } from "@model/entities";
import { IsNumber } from "class-validator";

export class PaymentPackagePreviewDTO {
    @IsNumber()
    appointment_id: Appointment['id'];

    static fromAppointment(appointment: Appointment): PaymentPackagePreviewDTO {
        return {
            appointment_id: appointment.id,
        };
    }
}
