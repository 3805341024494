import { PrintingService } from "@/services";
import { print, textToBase64Barcode } from ".";
import { Appointment } from "@model/entities";
import { PaymentReceiptPrintParams } from "@model/models";
import { defaultLocale, Locale } from "@model/constants";

export const printPaymentReceiptFromAppointmentId = async (options: { appointmentId: Appointment['id'], locale?: Locale }) => {
    const locale = options.locale ?? defaultLocale;
    const params = await PrintingService.paymentByAppointmentId(options.appointmentId, locale);
    await printPaymentReceipt({ params, locale });
};

export const printPaymentReceipt = async (options: { params: PaymentReceiptPrintParams, locale?: Locale }) => {
    const {
        params,
        locale = defaultLocale,
    } = options;

    await print({
        folder: "payment",
        templateName: `receipt_${locale}`,
        options,
        title: params?.title,
        appointmentId: params.appointment.id,
        paramsGenerator: async ({ params }): Promise<PaymentReceiptPrintParams> => {
            return {
                ...params,
                barcode: textToBase64Barcode(params.payment.id),
            };
        },
    })
};
