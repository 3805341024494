<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { Consultation } from "@model/entities";
import { useConsultationFacadeStore } from "@/stores/modules/data/consultation-facade.store";
import { usePrescriptionFacadeStore } from "@/stores/modules/data/prescription-facade.store";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
import { eventEmitter } from "@/services/events";
import { notify } from "@/utils/notify";
import { HerbTransformService } from "@/services";

@Options({
    components: {},
})
export default class PatientHistoryInjectComponent extends Vue {
    consultationFacade = useConsultationFacadeStore();
    prescriptionFacade = usePrescriptionFacadeStore();
    store = usePatientHistoryDataviewStore();

    formData = {
        diagnosis: false,
        prescription: false,
        acu: false,
        massage: false,
    };

    get shouldShowPage() {
        if (this.$route.fullPath.includes("/consultation/appointment")) {
            return true;
        }
        return false;
    }

    public async inject(consultation: Consultation) {
        if (Object.values(this.formData).every((x) => !x)) {
            notify.error("未選擇套用項目");
            return false;
        }
        if (this.formData.diagnosis) {
            await this.consultationFacade.injectDiagnosis(consultation);
        }
        if (this.formData.prescription) {
            const prescriptions = await HerbTransformService.byAppointmentIdV2(consultation.appointment_id, this.consultationFacade._appointment.clinic_id);
            if (prescriptions.length > 0) {
                await this.prescriptionFacade.injectByVO(prescriptions);
            }
        }
        if (this.formData.acu) {
            await this.consultationFacade.injectAcu(consultation);
        }
        if (this.formData.massage) {
            await this.consultationFacade.injectMassage(consultation);
        }
        return true;
    }

    async handleClickApply() {
        await this.inject(this.store.currentConsultation.consultation);
        eventEmitter.emit("patient-history-dialog:close");
    }
}
</script>

<template lang="pug">
.component#patient-history-inject-component(v-if="shouldShowPage")
    el-row
        fieldset.apply-group
            legend 套用資料
            el-checkbox(v-model="formData.diagnosis") 診斷
            el-checkbox(v-model="formData.prescription") 處方
            el-checkbox(v-model="formData.acu") 針灸
            el-checkbox(v-model="formData.massage") 推拿
    el-row
        Button(type="primary" @click="handleClickApply") 套用資料
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";
.side-bar {
    width: 100%;
}

.history {
    padding: 5px;
    width: 100%;
    border: 1px dotted $primary-color;
    height: 300px;
    overflow-y: scroll;
}

.row-item {
    user-select: none;
    pointer-events: none;
}

.row {
    padding-left: 5px;
    height: 30px;
    @include flex-center();
    justify-content: flex-start;

    &.active {
        background: lighten($primary-color, 50%);
    }

    &:hover {
        color: $primary-color;
        cursor: pointer;
    }
}
</style>
