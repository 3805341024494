import { PatientCreateDTO } from "@model/dtos";
import { PatientCaution, PatientDeliveryInfo, PatientReferral } from "@model/models";

export const getDefaultPatientCreateDto = (): PatientCreateDTO => ({
    name_zh: "",
    name_en: "",
    mobile: "",
    address: "",
    email: "",
    mobile_code: '852',
    mobile2_code: '852',
    dob: null,
    gender: null,
    hkid: "",
    remark: "",
    allergy: "",
    caution: PatientCaution.getDefaultValue(),
    referral: PatientReferral.getDefaultValue(),
    delivery_info: PatientDeliveryInfo.getDefaultValue(),
});