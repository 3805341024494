import { DoctorPrescription } from "@model/entities";
import { DoctorPrescriptionApplyDTO } from "@model/dtos";
import { BaseService } from "./base.service";
import { AdvancedResponse, DoctorPrescriptionApplyResponse } from "@model/responses";

class Service extends BaseService {
    async many(): Promise<DoctorPrescription[]> {
        return this.get(`/many`);
    }
    async manyByDoctorId(doctorId: number): Promise<DoctorPrescription[]> {
        return this.get(`/many/doctor/${doctorId}`);
    }

    async apply(body: DoctorPrescriptionApplyDTO): Promise<AdvancedResponse<DoctorPrescriptionApplyResponse>> {
        return this.post(`/apply`, body);
    }
}

export const DoctorPrescriptionService = new Service('/doctor-prescription');