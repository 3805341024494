<script lang="ts">
import { ListenEvent } from '@/services/events/listen-event.decorator';
import { handleError } from '@/utils/handleError';
import { Consultation, Patient } from '@model/entities';
import { Options, Vue } from 'vue-property-decorator';
import { usePatientHistoryDataviewStore } from '@/stores/modules/dataview';

@Options({
    components: {},
})
export default class PatientHistoryDialog extends Vue {
    store = usePatientHistoryDataviewStore();
    open = false;
    btnLoading = false;

    patientId: Patient['id'] = null;

    @ListenEvent('patient-history-dialog:open')
    async handleOpen(patientId: Patient['id']) {
        try {
            this.store.loading = true;
            this.patientId = patientId;
            if (this.patientId) {
                await this.store.init(this.patientId);
            }
        } catch (e) {
            handleError(e);
        } finally {
            this.store.loading = false;
        }
        this.open = true;
    }

    @ListenEvent('patient-history-dialog:open-with-id')
    async handleOpenWithId(patientId: Patient['id'], consultationId: Consultation['id']) {
        await this.handleOpen(patientId);
        await this.store.loadConsultation(consultationId);
    }

    @ListenEvent('patient-history-dialog:close')
    async listenDialogClose() {
        this.open = false;
    }

    async handleDialogClose() {
        this.store.reset();
    }
}
</script>

<template lang="pug">
el-dialog#patient-history-dialog(v-model="open" append-to-body width="90%" top="1vh" @close="handleDialogClose" destroy-on-close)
    template(#header)
        el-row(v-if="store.patient")
            el-col(:span="8")
                div 病人姓名: {{ store.patient.name_zh }} ({{ store.patient.code }})
            el-col(:span="8")
                div 診次: {{ store.patient.consult_count }}
            el-col(:span="4")
    .body
        el-row(:gutter="20")
            el-col(:span="4")
                PatientHistorySidebar
            el-col(:span="20")
                PatientHistoryDataview
</template>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';
:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
