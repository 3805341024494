import { useUserStore } from "@/stores/modules";
import { BaseService } from "./base.service";
import { ReportQueryDTO } from "@model/dtos";
import { useSelectItemStore } from "@/stores/modules/select-item";
import qs from "qs";

type DateType = 'daily' | 'monthly' | 'none' | 'daterange';
type DateRange = { startDate: string, endDate: string };
class ExcelReport {
    type: DateType;
    date: Date | DateRange = new Date();
    name: string;
    query?: ReportQueryDTO;

    constructor(type: DateType, name: string, date?: Date | DateRange, query?: ReportQueryDTO) {
        this.type = type;
        this.name = name;
        this.date = date;
        this.query = query;
    }

    getParams() {
        switch (this.type) {
            case 'daily':
                if (!this.date) {
                    throw Error("Date is required for daily report");
                }
                if ('startDate' in this.date) {
                    throw Error("Date must be instance of Date");
                }
                return `${this.date.getFullYear()}/${this.date.getMonth() + 1}/${this.date.getDate()}?${this.parseQuery(this.query)}`;
            case 'monthly':
                if (!this.date) {
                    throw Error("Date is required for monthly report");
                }
                if ('startDate' in this.date) {
                    throw Error("Date must be instance of Date");
                }
                return `${this.date.getFullYear()}/${this.date.getMonth() + 1}?${this.parseQuery(this.query)}`;
            case 'daterange':
                const d = this.date as DateRange;
                if (!d.startDate || !d.endDate) {
                    throw Error("Date must be instance of DateRange");
                }
                return `${d.startDate}/${d.endDate}?${this.parseQuery(this.query)}`;
            default:
                return `?${this.parseQuery(this.query)}`;
        }
    }

    async getFilename(ext: 'xlsx' | 'zip' = 'xlsx') {
        let filename = '';
        switch (this.type) {
            case 'daily':
                if ('startDate' in this.date) {
                    throw Error("Date must be instance of Date");
                }
                filename = `${this.name}_${this.date.getFullYear()}_${this.date.getMonth() + 1}_${this.date.getDate()}.${ext}`
                break;
            case 'monthly':
                if ('startDate' in this.date) {
                    throw Error("Date must be instance of Date");
                }
                filename = `${this.name}_${this.date.getFullYear()}_${this.date.getMonth() + 1}.${ext}`;
                break;
            case 'daterange':
                const d = this.date as DateRange;
                if (!d.startDate || !d.endDate) {
                    throw Error("Date must be instance of DateRange");
                }
                filename = `${this.name}_${d.startDate}_${d.endDate}.${ext}`
                break;
            case 'none':
                const date = new Date();
                filename = `${this.name}_${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}.${ext}`
                break;
        }
        const selectItemStore = useSelectItemStore();
        if (this.query.doctor) {
            filename = `${await selectItemStore.findDoctor(this.query.doctor)}_${filename}`;
        }
        if (this.query.clinic) {
            filename = `${await selectItemStore.findClinic(this.query.clinic)}_${filename}`;
        }
        return filename;
    }

    parseQuery(query: any, mustHaveKeys?: string[]) {
        if (!query) {
            return '';
        }
        for (const key of Object.keys(query)) {
            if (query[key] === null || query[key] === '') {
                delete query[key];
            }
        }
        if (mustHaveKeys) {
            for (const k of mustHaveKeys) {
                if (!(k in query)) {
                    throw Error(`Key \`${k}\` does not exist`);
                }
            }
        }
        return qs.stringify(query);
    }
}

class Service extends BaseService {
    async exportAllDailyReport(date: Date, query: ReportQueryDTO): Promise<any> {
        const report = new ExcelReport('daily', `所有每日報表`, date, query);
        const filename = await report.getFilename('zip');
        return this.download(`/export/daily/${report.getParams()}`, filename);
    }

    async dailySummary(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('daily', `每日收入總覽表`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/daily/summary/${report.getParams()}`, filename);
        }
        return this.get(`/daily/summary/${report.getParams()}`);
    }

    async dailyIncomeDetail(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('daily', `每日收入細列表`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/daily/income/${report.getParams()}`, filename);
        }
        return this.get(`/daily/income/${report.getParams()}`);
    }

    async monthlyHealthcareVoucher(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('monthly', `醫療券總覽表`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/monthly/healthcare-voucher/${report.getParams()}`, filename);
        }
        return this.get(`/monthly/healthcare-voucher/${report.getParams()}`);
    }

    async monthlyHerbUsage(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('monthly', `藥物使用成本報告`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/monthly/herb-usage/${report.getParams()}`, filename);
        }
        return this.get(`/monthly/herb-usage/${report.getParams()}`);
    }

    async monthlySummary(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('monthly', `每月收入總覽表`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/monthly/summary/${report.getParams()}`, filename);
        }
        return this.get(`/monthly/summary/${report.getParams()}`);
    }

    async monthlySalary(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('monthly', `每月薪金表`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/monthly/salary/${report.getParams()}`, filename);
        }
        return this.get(`/monthly/salary/${report.getParams()}`);
    }

    async monthlyPackagePurchaseReport(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('monthly', `套票購買記錄`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/monthly/package-purchase/${report.getParams()}`, filename);
        }
        return this.get(`/monthly/package-purchase/${report.getParams()}`);
    }

    async monthlyPackageConsumeReport(date: Date, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('monthly', `套票使用記錄`, date, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/monthly/package-consume/${report.getParams()}`, filename);
        }
        return this.get(`/monthly/package-consume/${report.getParams()}`);
    }

    async herbUsage(startDate: string, endDate: string, query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('daterange', `藥物使用成本報告`, { startDate, endDate }, query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/herb-usage/${report.getParams()}`, filename);
        }
        return this.get(`/herb-usage/${report.getParams()}`);
    }

    async herbStock(query: ReportQueryDTO, shouldExport = false): Promise<any> {
        const report = new ExcelReport('none', `庫存報告`, new Date(), query);
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/herb-stock/${report.getParams()}`, filename);
        }
        return this.get(`/herb-stock/${report.getParams()}`);
    }

    async patients(shouldExport = false): Promise<any> {
        const report = new ExcelReport('none', `病人列表`, new Date(), {});
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/patients/${report.getParams()}`, filename);
        }
        return this.get(`/patients/${report.getParams()}`);
    }

    async patientPackages(shouldExport = false): Promise<any> {
        const report = new ExcelReport('none', `套票列表`, new Date(), {});
        if (shouldExport) {
            const filename = await report.getFilename();
            return this.download(`/export/patient-packages/${report.getParams()}`, filename);
        }
        return this.get(`/patient-packages/${report.getParams()}`);
    }
}

export const ReportService = new Service('/report');

