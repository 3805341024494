<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { MessageDialogOptions } from "./models/MessageDialogOptions";
import { ListenEvent } from "@/services/events/listen-event.decorator";

@Options({
    components: {},
})
export default class GlobalMessageDialog extends Vue {
    private open = false;
    private options?: MessageDialogOptions = {};

    @ListenEvent("message-dialog:open")
    handleMessageDialogOpen(options?: MessageDialogOptions) {
        this.open = true;
        this.options = options ?? {};
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body)
    template(v-slot:header v-if="options.title")
        div {{ options.title }}
    div(v-if="options.message") {{ options.message }}
    div(v-if="options.html" v-html="options.html")
</template>

<style lang="scss" scoped>
@import "@/assets/styles/layout.scss";
</style>
