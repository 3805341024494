import { axGet } from "./axios";
import { BaseService } from "./base.service";
import { PricingItem } from "@model/entities";
import { PaginationQueryDTO } from "@model/dtos";
import { PaginatedResponse } from "@model/responses";

class Service extends BaseService {
    async query(queryParams: PaginationQueryDTO): Promise<PaginatedResponse<PricingItem>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }
}

export const PaymentPricingItemService = new Service('/payment-pricing/item');

