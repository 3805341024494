<script lang="ts">
import { AuditService } from "@/services";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { useUserStore } from "@/stores/modules";
import { Options, Vue } from "vue-property-decorator";

interface Option {
    title?: string;
    size?: "A5" | "A4";
    totalPages?: number;
    shouldPrint?: boolean;
    html: string;
    audit: any;
    onClose?: () => void;
}

@Options({
    components: {},
})
export default class PrintDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    size: "A5" | "A4" = null;
    title = null;
    totalPages = null;
    shouldPrint = null;
    content = null;
    audit = null;
    onClose: () => void | Promise<void> = null;

    async handleOpened() {
        (this.$refs["preview_iframe"] as any).contentWindow.document.body.innerHTML = "";
        (this.$refs["preview_iframe"] as any).contentWindow.document.write(this.content);
    }

    @ListenEvent("print-dialog:open")
    listenDialogOpen(options: Option) {
        this.audit = options.audit;
        this.title = options.title ?? "列印";
        this.size = options.size ?? "A4";
        this.totalPages = options.totalPages ?? 1;
        this.shouldPrint = options.shouldPrint ?? false;
        this.content = options.html;
        this.onClose = options.onClose ?? (() => {});
        this.open = true;
    }

    async handleClickPrint() {
        const win = (this.$refs["preview_iframe"] as any).contentWindow;
        const originalTitle = document.title;
        document.title = this.title;
        await setTimeout(async () => {
            win.focus();
            try {
                if (!win.document.execCommand("print", false, null)) {
                    win.print();
                }
            } catch (e) {
                win.print();
            }
            win.close();
            if (this.audit) {
                AuditService.auditPrint(this.audit);
            }
            document.title = originalTitle;
            this.open = false;
        }, 10);
    }

    handleClickClose() {
        this.open = false;
    }

    async handleClose() {
        await this.onClose();
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" @close="handleClose" class="no-padding" append-to-body fullscreen @opened="handleOpened" destroy-on-close lock-scroll :show-close="false")
    template(#header)
        div 列印
    .inner
        .preview(:class="size")
            iframe(ref="preview_iframe" contenteditable id="preview_iframe")
        .toolbox
            el-row(style="justify-content: center" :gutter="20")
                el-col(:span="4")
                    Button#act-print(type="primary" @click="handleClickPrint") 列印/匯出
                el-col(:span="4")
                    Button#act-quit(type="secondary" @click="handleClickClose") 退出
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.inner {
    height: calc(100vh - 30px);
    display: flex;
    flex-direction: column;
}

.preview {
    height: 100%;
    overflow-y: auto;
    text-align: center;
    max-height: 842px;
    background-color: grey;

    &.A4 {
        max-height: 1200px;
    }
    &.A5 {
        max-height: 842px;
    }
}

iframe {
    aspect-ratio: 1 / 1.414;
    width: auto;
    height: 99%;
    min-width: 585px;
    min-height: 712px;
    z-index: 100;
    background-color: #fff;
    overflow-y: auto;
}

:deep(.el-dialog__header) {
    display: none;
}

:deep(.el-dialog) {
    .el-dialog__body {
        padding: 0 !important;
    }
}
</style>
