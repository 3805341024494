import { DispensePrescriptionUpdateDto, PatientAlertDTO, PrescriptionCreateParam, PrescriptionsCreateDto, PrescriptionsValidationDto } from "@model/dtos";
import { PrescriptionItemParam } from "@model/dtos/prescription/params/prescription-item.param";
import { Clinic, Herb, HerbBrand, Prescription } from "@model/entities";
import { HerbComboData, HerbPricingType, HerbType, PrescriptionPharmacyOption, PrescriptionRedispenseConfig, PrescriptionUsage, PrescriptionUsageType, SelectItem } from "@model/models";
import { PrescriptionIssue } from "@model/models/prescription/prescription-issues";
import { convert2dp } from "@model/utils";

function findHerbBrand(herbBrandItems, brandName: string) {
    const match = herbBrandItems.filter(x => x.label === brandName);
    if (match.length === 0) {
        return null;
    }
    return match[0];
};

export class PrescriptionFacadeStateVO {
    index: number;
    clinic_id: Clinic['id'];
    clinic_name?: string;
    can_redispense: boolean;
    redispense_config: PrescriptionRedispenseConfig;
    usage: PrescriptionUsage;
    remark: string;
    internal_remark: string;
    caution: string;
    is_pharmacy?: boolean;
    pharmacy_option: PrescriptionPharmacyOption;
    issue: PrescriptionIssue;
    items: {
        id: Herb['id'];
        name: string;
        code: string;
        amount: number;
        dispense_method: string;
        ratio: number;
        unit: string;
        type: string;
        brand: {
            value: number;
            label: string;
        },
        dispense_safe_value: number,
        stock_usable: number,
        stock_available: number,
        stock_weight: number,
        stock_in_cost: number,
        pricing_type: HerbPricingType,
        unit_cost: number,
        expensive_day_cost: number,
        expensive_extra_unit_cost: number,
        expensive_thershold: number,
        pieces_unit_cost: number,
        combo_data?: HerbComboData,
    }[];

    static getDefaultValue(clinicId?: Clinic['id']): PrescriptionFacadeStateVO {
        return {
            index: null,
            clinic_id: clinicId,
            issue: null,
            can_redispense: false,
            redispense_config: null,
            usage: {
                is_required: true,
                is_multiple_per_day: true,
                take: 'internal',
                day: 3,
                times: 2,
                pack: 1,
                pack_unit: '包',
                remark: '',
            },
            remark: "",
            internal_remark: "",
            pharmacy_option: PrescriptionPharmacyOption.getDefaultValue(clinicId),
            caution: "",
            items: [

            ],
        }
    }

    static fromPrescriptionItemParams(options: {
        prescription: Prescription,
        clinic: Clinic,
        data: { herb: Herb; item: PrescriptionItemParam; }[]
    }): PrescriptionFacadeStateVO {
        const {
            prescription,
            clinic,
            data,
        } = options;

        return {
            index: prescription.index,
            clinic_id: clinic.id,
            clinic_name: clinic.display_name,
            can_redispense: prescription.can_redispense,
            redispense_config: PrescriptionRedispenseConfig.sanitize(prescription.redispense_config),
            caution: prescription.caution,
            remark: prescription.remark,
            internal_remark: prescription.internal_remark,
            is_pharmacy: clinic.is_pharmacy,
            pharmacy_option: PrescriptionPharmacyOption.sanitize(prescription.pharmacy_option),
            issue: prescription.issue,
            usage: {
                is_required: prescription.usage.is_required,
                ...prescription.usage,
            },
            items: data.map(
                d => ({
                    id: d.item.id,
                    code: d.item.code,
                    name: d.item.name,
                    amount: Number(d.item.amount),
                    dispense_method: d.item.dispense_method,
                    ratio: d.item.ratio,
                    unit: d.item.unit,
                    type: d.item.type,
                    stock_usable: d.herb.stock?.stock ?? 0,
                    stock_available: d.herb.stock?.stock ?? 0,
                    stock_in_cost: d.item.stock_in_cost,
                    stock_weight: d.item.stock_weight,
                    dispense_safe_value: d.item.dispense_safe_value ?? 0,
                    brand: d.item.brand,
                    pricing_type: d.item.pricing_type,
                    unit_cost: d.item.unit_cost,
                    expensive_day_cost: d.item.expensive_day_cost,
                    expensive_extra_unit_cost: d.item.expensive_extra_unit_cost,
                    expensive_thershold: d.item.expensive_thershold,
                    pieces_unit_cost: d.item.pieces_unit_cost,
                    combo_data: d.herb.combo_data,
                })
            )
        }
    }

    static fromEntity(prescription: Prescription, herbBrandItems: SelectItem<HerbBrand['id']>[]): PrescriptionFacadeStateVO {
        return {
            index: prescription.index,
            clinic_id: prescription.clinic_id,
            clinic_name: prescription.clinic?.display_name,
            can_redispense: prescription.can_redispense,
            redispense_config: PrescriptionRedispenseConfig.sanitize(prescription.redispense_config),
            caution: prescription.caution,
            remark: prescription.remark,
            internal_remark: prescription.internal_remark,
            is_pharmacy: prescription.is_pharmacy,
            pharmacy_option: PrescriptionPharmacyOption.sanitize(prescription.pharmacy_option),
            issue: prescription.issue,
            usage: {
                is_required: prescription.usage.is_required,
                ...prescription.usage,
            },
            items: prescription.items.map(
                item => ({
                    id: item.herb_id,
                    code: item.herb_code,
                    name: item.name,
                    amount: Number(item.amount),
                    dispense_method: item.method,
                    ratio: item.ratio,
                    unit: item.unit_name,
                    type: item.type,
                    stock_usable: item.herbStock?.stock ?? 0,
                    stock_available: item.herbStock?.stock ?? 0,
                    stock_in_cost: item.stock_in_cost,
                    stock_weight: item.stock_weight,
                    dispense_safe_value: item.dispense_safe_value ?? 0,
                    brand: findHerbBrand(herbBrandItems, item.brand_name),
                    pricing_type: item.pricing_type,
                    unit_cost: item.unit_cost,
                    expensive_day_cost: item.expensive_day_cost,
                    expensive_extra_unit_cost: item.expensive_extra_unit_cost,
                    expensive_thershold: item.expensive_thershold,
                    pieces_unit_cost: item.pieces_unit_cost,
                    combo_data: item.combo_data,
                })
            )
        }
    }

    static fromEntities(prescriptions: Prescription[], herbBrandItems: SelectItem<HerbBrand['id']>[]): PrescriptionFacadeStateVO[] {
        return prescriptions.sort((a, b) => a.index - b.index).map(pres => PrescriptionFacadeStateVO.fromEntity(pres, herbBrandItems));
    }

    static toDispensePrescriptionUpdateDto(vo: PrescriptionFacadeStateVO): DispensePrescriptionUpdateDto {
        return {
            usage: PrescriptionUsage.sanitize(vo.usage),
            remark: vo.remark,
            caution: vo.caution,
            items: vo.items.map(item => ({
                ...item,
                code: item.code,
                type: item.type as HerbType,
                amount: convert2dp(item.amount),
                pricing_type: item.pricing_type,
            })),
        }
    }

    static toPrescriptionsCreateParam(vo: PrescriptionFacadeStateVO, issue?: PrescriptionIssue): PrescriptionCreateParam {
        const res: PrescriptionCreateParam = {
            clinic_id: vo.clinic_id,
            usage: PrescriptionUsage.sanitize(vo.usage),
            remark: vo.remark,
            internal_remark: vo.internal_remark,
            pharmacy_option: vo.pharmacy_option,
            caution: vo.caution,
            can_redispense: vo.can_redispense,
            redispense_config: PrescriptionRedispenseConfig.sanitize(vo.redispense_config),
            issue,
            items: vo.items.map(item => ({
                ...item,
                code: item.code,
                type: item.type as HerbType,
                amount: convert2dp(item.amount),
                pricing_type: item.pricing_type,
            })),
        };
        return res;
    }

    static toPrescriptionsValidationDto(alert: PatientAlertDTO, vos: PrescriptionFacadeStateVO[]): PrescriptionsValidationDto {
        return {
            alert,
            prescriptions: vos.filter(x => x.items.length > 0).map(x => ({
                usage: x.usage,
                items: x.items.map(y => ({
                    id: y.id,
                    name: y.name,
                    amount: Number(y.amount),
                    pricing_type: y.pricing_type,
                }))
            })),
        }
    }
}