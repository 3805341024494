export default (value: string) => {
    if (!value) {
        return '-';
    }
    switch (value.toLocaleUpperCase()) {
        case 'M':
            return '男';
        case 'F':
            return '女';
        case '':
        default:
            return '-';
    }
    return value;
}