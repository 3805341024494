import { ElNotification, ElMessage, ElDialog, ElMessageBox } from 'element-plus'

export const notify = {
    doNothing: () => { },
    notImplemented: () => {
        ElNotification({
            // title: 'Success',
            message: '功能開發中，敬請期待',
            type: 'info',
        });
    },
    info: (message: string) => {
        ElNotification({
            // title: 'Success',
            message: message,
            type: 'info',
            showClose: false,
        });
    },
    success: (message: string) => {
        ElNotification({
            // title: 'Success',
            message: message,
            type: 'success',
            showClose: false,
        });
    },
    confirm: async (message, title = '提示', confirmButtonText = "確認", cancelButtonText = '取消') => {
        return ElMessageBox.confirm(
            message,
            title,
            {
                confirmButtonText,
                cancelButtonText,
            }
        ).then(() => true)
            .catch(() => false);
    },
    saved: () => {
        notify.success('已儲存');
    },
    deleted: () => {
        notify.success('已刪除');
    },
    forbidden: (reason: string) => {
        notify.error(`沒有${reason}權限`);
    },
    error: (message: string) => {
        return ElMessage({
            message: message,
            type: 'error',
            showClose: false,
        });
    },
}