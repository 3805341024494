import { BaseService } from "./base.service";
import { ErrorCode } from '@model/error';
import { Doctor, ClinicDoctorAssignment, DoctorEsign } from "@model/entities";
import { DoctorConsultationFeeUpdateDto, DoctorEsignCreateDTO, DoctorSalaryConfigUpdateDTO } from "@model/dtos";

class Service extends BaseService {
    handleCreationError(e: ErrorCode) {
        if (e.code === 'ERR_ALREADY_USED') {
            throw Error('帳戶已被其他醫師連結');
        }
        return false;
    }

    getClinicAssignments(id: Doctor['id']): Promise<ClinicDoctorAssignment[]> {
        return this.get(`/clinic-assignment/${id}`);
    }
    
    updateConsultationFee(body: DoctorConsultationFeeUpdateDto): Promise<void> {
        return this.post(`/setting/consultation-fee`, body);
    }

    oneByCurrentUserId(): Promise<Doctor | null> {
        return this.get(`/one-user`);
    }

    async getEsign(doctorId: Doctor['id']): Promise<DoctorEsign> {
        return this.get(`/esign/${doctorId}`);
    }

    async uploadEsign(body: DoctorEsignCreateDTO): Promise<DoctorEsign> {
        return this.postForm(`/esign`, body);
    }

    async deleteEsign(doctorId: Doctor['id']) {
        return this.delete(`/esign/${doctorId}`);
    }

    async getSalaryConfigs(): Promise<any> {
        return this.get(`/salary-configs`);
    }

    async updateSalaryConfig(body: DoctorSalaryConfigUpdateDTO): Promise<any> {
        return this.post(`/salary-config`, body);
    }
}

export const DoctorService = new Service('/doctor');