<script lang="ts">
import { Options, Model, Vue, Prop, Watch } from "vue-property-decorator";
import { PatientCaution, Gender } from "@model/models";

@Options({})
export default class PatientCautionSelectGroup extends Vue {
    @Model("modelValue", { type: Object, default: {} })
    value!: PatientCaution;

    @Prop({ default: false, type: Boolean })
    readonly: boolean;

    @Prop({ default: false, type: Boolean })
    condense: boolean;

    @Prop({ default: 'F' })
    gender: Gender;

    @Watch('value.blood_thinner_warfarin')
    onBloodThinnerChanged(value: boolean) {
        if (value) {
            this.value.blood_thinner = true;
        }
    }
}
</script>

<template lang="pug">
div
    el-checkbox(v-model="value.g6pd" :disabled="readonly") 
        template(v-if="condense") G6PD
        template(v-else) G6PD
    el-checkbox(v-model="value.pregnant" :disabled="readonly || gender === 'M'" alt="123") 
        template(v-if="condense") 懷孕
        template(v-else) 懷孕
    el-checkbox(v-model="value.blood_thinner" :disabled="readonly || value.blood_thinner_warfarin") 
        template(v-if="condense") 薄血藥
        template(v-else) 薄血藥
    //- el-checkbox(v-model="value.blood_thinner_warfarin" :disabled="readonly" v-if="value.blood_thinner")
    //-     template(v-if="condense") 華法林
    //-     template(v-else) 華法林
    el-checkbox(v-model="value.no_age" :disabled="readonly")
        template(v-if="condense") 隱藏年齡
        template(v-else) 隱藏年齡
    el-checkbox(v-if="!condense && (!readonly || value.other)" v-model="value.other" :disabled="readonly") 其他：
    el-input(v-if="!condense && (!readonly || value.other)" v-model="value.other_text" :disabled="!value.other || readonly") 
</template>

<style scoped lang="scss"></style>
