export const arrayPartition = <T>(arr: T[], partitionFunction: (T) => boolean): T[][] => {
    const matched = [];
    const unmatched = [];

    let i = 0;
    let j = arr.length;

    for (; i < j; i++) {
        (partitionFunction(arr[i]) ? matched : unmatched).push(arr[i]);
    }

    return [matched, unmatched];
};

export const concat = (x, y) => {
    return x.concat(y);
}

const setImmediatePromise = async () => {
    return new Promise<void>((resolve) => {
        setImmediate(() => {
            resolve();
        })
    });
};

export const unblockingLoop = async <T>(arr: T[], callbackfn: (arg: T) => void | Promise<void>, limit: number = 50) => {
    for (let i = 0; i < arr.length; i++) {
        await callbackfn(arr[i]);
        if (i % limit === 0) {
            await setImmediatePromise();
        }
    }
}
