export const os = {
    isWindows: () => {
        return navigator.appVersion.indexOf("Win") !== -1;
    },
    isMac: () => {
        return navigator.appVersion.indexOf("Mac") !== -1;
    },
    isUnix: () => {
        return navigator.appVersion.indexOf("X11") !== -1;
    },
    isLinux: () => {
        return navigator.appVersion.indexOf("Linux") !== -1;
    },
    isAndroid: () => {
        return navigator.appVersion.indexOf("Android") !== -1;
    },
    isIos: () => {
        return navigator.appVersion.indexOf("like Mac") !== -1;
    },
    isMobile: () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    },
    isDesktop: () => {
        return !/Mobi|Android/i.test(navigator.userAgent);
    },
    isTablet: () => {
        return /Tablet|iPad/i.test(navigator.userAgent);
    },
    isBrowser: () => {
        return !/Mobi|Android/i.test(navigator.userAgent);
    },
    isChrome: () => {
        return /Chrome/i.test(navigator.userAgent);
    },
    isFirefox: () => {
        return /Firefox/i.test(navigator.userAgent);
    },
    isSafari: () => {
        return /Safari/i.test(navigator.userAgent);
    },
    isOpera: () => {
        return /Opera|OPR/i.test(navigator.userAgent);
    },
    isEdge: () => {
        return /Edg/i.test(navigator.userAgent);
    },
    isIE: () => {
        return /MSIE|Trident/i.test(navigator.userAgent);
    },
}