import { defineStore } from 'pinia';
import { store } from '@/stores';
import { useCache } from '@/hooks/useCache';
import { useUserStore } from './user.store';
import { AppointmentQueryDTO } from '@model/dtos';
import { eventEmitter } from '@/services/events';
import dayjs from 'dayjs';

const { wsCache } = useCache('sessionStorage');

export interface PreferenceState {
    _loading: boolean;
    _appointmentQueryDate: string;
    _appointmentQueryStep: string;
    _appointmentShouldShowAllClinic: boolean;
    _appointmentShouldShowCancelled: boolean;
}

const defaultState: PreferenceState = {
    _loading: false,
    _appointmentQueryDate: dayjs().format("YYYY-MM-DD"),
    _appointmentQueryStep: '00:05',
    _appointmentShouldShowAllClinic: true,
    _appointmentShouldShowCancelled: false,
}

export const usePreferenceStore = defineStore({
    id: 'preference',
    state: (): PreferenceState => defaultState,
    persist: {
        enabled: true,
    },
    getters: {
        loading(): boolean {
            return this._loading;
        },
        appointmentQuery(): AppointmentQueryDTO {
            return {
                date: this._appointmentQueryDate,
                step: this._appointmentQueryStep,
                shouldShowAllClinic: this._appointmentShouldShowAllClinic,
                shouldShowCancelled: this._appointmentShouldShowCancelled,
            };
        },
        appointmentQueryDate(): string {
            return this._appointmentQueryDate;
        },
        appointmentQueryStep(): string {
            return this._appointmentQueryStep ?? '00:05';
        },
        appointmentShouldShowAllClinic(): boolean {
            return this._appointmentShouldShowAllClinic ?? false;
        },
        appointmentShouldShowCancelled(): boolean {
            return this._appointmentShouldShowCancelled ?? false;
        }
    },
    actions: {
        init() {
            const user = useUserStore();
            this._appointmentQueryDate = wsCache.get('appointment-query:date');
            if (!this._appointmentQueryDate) {
                this._appointmentQueryDate = dayjs().format("YYYY-MM-DD");
            }
            this._appointmentQueryStep = wsCache.get(`appointment-query:step:${user.currentClinicId}`) ?? '00:05';
        },
        setLoading(loading: boolean) {
            this._loading = loading;
        },
        setAppointmentQueryDate(date: string) {
            this._appointmentQueryDate = date;
            eventEmitter.emit('appointment-query:updated');
            wsCache.set('appointment-query:date', date);
        },
        setAppointmentQueryStep(step: string) {
            const user = useUserStore();
            this._appointmentQueryStep = step;
            eventEmitter.emit('appointment-query:updated');
            wsCache.set(`appointment-query:step:${user.currentClinicId}`, step);
        },
        setShouldShowAllClinic(shouldShowAllClinic: boolean) {
            const user = useUserStore();
            this._appointmentShouldShowAllClinic = shouldShowAllClinic;
            eventEmitter.emit('appointment-query:updated');
            wsCache.set(`appointment-query:should-show-all-clinic:${user.currentClinicId}`, shouldShowAllClinic);
        },
        setShouldShowCancelled(shouldShowCancelled: boolean) {
            const user = useUserStore();
            this._appointmentShouldShowCancelled = shouldShowCancelled;
            eventEmitter.emit('appointment-query:updated');
            wsCache.set(`appointment-query:should-show-cancelled:${user.currentClinicId}`, shouldShowCancelled);
        },
    }
});

export const usePreferenceStoreWithOut = () => {
    return usePreferenceStore(store);
};
