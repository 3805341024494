<script lang="ts">
import { AppointmentService } from "@/services";
import { eventEmitter } from "@/services/events";
import { handleError } from "@/utils/handleError";
import { notify } from "@/utils/notify";
import { Options, Vue, Prop } from "vue-property-decorator";
import { AppointmentAttachment } from "@model/entities";
import { dynamicEnv } from "@/utils/dynamic-env";

@Options({
    components: {},
})
export default class AppointmentAttachmentTable extends Vue {
    @Prop({ required: true })
    attachments: AppointmentAttachment[];

    @Prop({ default: false, type: Boolean })
    readonly: boolean;

    async navigate(destination: string) {
        window.open(`${dynamicEnv("S3_ENDPOINT")}/${destination}`, "_blank");
    }

    async handleClickUpdate(row: AppointmentAttachment) {
        eventEmitter.emit("appointment-attachment-update-dialog:open", row);
    }

    async handleClickDeleteAttachment(id: AppointmentAttachment["id"]) {
        if (this.readonly) {
            return;
        }
        try {
            if (await this.$confirm("確認刪除？")) {
                await AppointmentService.deleteAttachment(id);
                notify.deleted();
                eventEmitter.emit("appointment-attachment:updated", id);
            }
        } catch (e) {
            handleError(e);
        }
    }
}
</script>

<template lang="pug">
el-table(:data="attachments")
    el-table-column(label="上傳日期" width="150")
        template(#default="scope")
            div {{ $filters.datetime(scope.row.created_at) }}
    el-table-column(label="上傳員工" width="150")
        template(#default="scope")
            div {{ scope.row.createdBy.name_zh }}
    el-table-column(label="類別" width="150")
        template(#default="scope")
            div {{ $filters.appointmentAttachmentType(scope.row.type) }}
    el-table-column(label="備註")
        template(#default="scope")
            span {{ scope.row.description }}
    el-table-column(label="" width="250")
        template(#default="scope")
            .table-button-group
                el-button(type="primary" @click="navigate(scope.row.destination)") 查看
                el-button(type="warning" @click="handleClickUpdate(scope.row)" v-if="!readonly") 修改
                el-button(type="danger" @click="handleClickDeleteAttachment(scope.row.id)" v-if="!readonly") 刪除
AppointmentAttachmentUpdateDialog
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
