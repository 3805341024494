import { Locale } from "@model/constants";
import date from "@model/utils/date";

export class PrescriptionRedispenseConfig {
    expires_at: Date | null;
    limit: number | null;

    static sanitize(config?: Partial<PrescriptionRedispenseConfig>): PrescriptionRedispenseConfig {
        return {
            expires_at: config?.expires_at ?? null,
            limit: config?.limit ? Number(config.limit) : null,
        }
    }

    static getText(prescription?: {
        redispense_config?: Partial<PrescriptionRedispenseConfig>;
        can_redispense?: boolean;
    }, locale: Locale = 'zh'): string {
        let text = [];
        if (prescription) {
            if (prescription.can_redispense) {
                if (prescription.redispense_config?.expires_at) {
                    if (locale === 'zh') {
                        text.push(`${date.formatDate(prescription.redispense_config.expires_at)}前可安排重配`);
                    } else {
                        text.push(`Redispense before ${date.formatDate(prescription.redispense_config.expires_at)}`);
                    }
                }
                if (prescription.redispense_config?.limit) {
                    if (locale === 'zh') {
                        text.push(`最多可重配 ${prescription.redispense_config.limit} 日藥`);
                    } else {
                        text.push(`Maximum ${prescription.redispense_config.limit} times`);
                    }
                }
                if (text.length === 0) {
                    return '可重配';
                }
                return text.join(locale === 'zh' ? "，" : ",");
            }
            return text.join(locale === 'zh' ? "不可重配" : "Not Redispensable");
        }
        return '';
    }
}
