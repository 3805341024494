import { Transform } from "class-transformer";

export const TransformBoolean = (options?: { default?: boolean }) => Transform(({ value }) => {
    const {
        default: defaultValue,
    } = options ?? {};

    if (value === undefined && defaultValue !== undefined) {
        return defaultValue;
    }
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
})
