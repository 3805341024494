import { BaseService } from "./base.service";
import { HerbDictionaryResponse } from "@model/responses";
import { Clinic, Herb, HerbBrand } from "@model/entities";
import { PrescriptionViolation } from "@model/models";

class Service extends BaseService {
    async dictionary(clinicId: Clinic['id']): Promise<HerbDictionaryResponse> {
        return this.get(`/dictionary/clinic/${clinicId}`);
    }

    async dictionaryOfClinic(clinicId: Clinic['id']): Promise<HerbDictionaryResponse> {
        return this.get(`/dictionary/${clinicId}`);
    }
    
    async getHerbsWithNonzeroStockOfClinic(clinicId: Clinic['id']): Promise<any[]> {
        return this.get(`/dictionary/non-zero-stock/${clinicId}`);
    }

    async query(query: { clinic: Clinic['id'], name: string, brand?: HerbBrand['id'] }): Promise<Herb> {
        return this.get(`/query?${this.parseQuery(query, ['clinic', 'name'])}`);
    }

    async getStock(id: Herb['id']): Promise<number> {
        return this.get(`/stock/${id}`);
    }

    async getStockByCodeAndClinic(code: Herb['code'], clinicId: Clinic['id']): Promise<number> {
        return this.get(`/stock?${this.parseQuery({ code, clinicId }, ['code', 'clinicId'])}`);
    }

    async getManyStockByCodeAndClinic(body: {code: Herb['code'], clinicId: Clinic['id']}[]): Promise<{ code: string, clinicId: number }[]> {
        return this.post(`/stock/many`, body);
    }

    async getRules(code: Herb['code']): Promise<PrescriptionViolation[]> {
        return this.get(`/rules/${code}`);
    }

    async many(ids: number[]): Promise<Herb[]> {
        if (ids.length === 0) {
            return [];
        }
        if (ids.length < 20) {
            return this.get(`/many?${this.parseQuery({ ids }, ['ids'])}`);
        }
        return this.post(`/many`, { ids });
    }
}

export const HerbService = new Service('/herb');