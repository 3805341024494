const decimal2 = (value?: number | null) => {
    if (value === undefined || value === null) {
        return '-';
    }
    return value.toFixed(2);
}

const int = (value?: number | null) => {
    if (value === undefined || value === null) {
        return '-';
    }
    return value.toFixed(0);
}

const decimal1 = (value?: number | null) => {
    if (value === undefined || value === null) {
        return '-';
    }
    return value.toFixed(1);
}

const tempUnit = (value?: number | null, unit?: 'C'|'F') => {
    if (value === undefined || value === null) {
        return '-';
    }
    return `${decimal2(value)} °${unit}`;
}


export default {
    int,
    tempUnit,
    decimal1,
    decimal2,
}