import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Clinic, HerbStockCheck } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { notify } from "@/utils/notify";
import { HerbStockCheckItemInfoVO } from '@model/vos';
import { AdminHerbStockCheckService } from "@/services";
import { handleError } from '@/utils/handleError';
import { useSelectItemStore } from '../../select-item';
import { useUserStore } from '../../user.store';

interface State {
    _herbStockCheckId: HerbStockCheck['id'],
    loading: boolean,
    mode: 'readonly' | 'create' | 'update' | 'complete' | 'validate',
    clinicId: Clinic['id'];
    remark: string;
    // todo not the correct type
    items: HerbStockCheckItemInfoVO[];
}

const defaultState: State = {
    _herbStockCheckId: null,
    loading: false,
    mode: 'create',
    clinicId: null,
    remark: '',
    items: [],
}

export const useStockCheckDatagridStore = defineStore({
    id: 'stock-check',
    state: (): State => cloneDeep(defaultState),
    getters: {
        canTmp(): boolean {
            return this.mode === 'create';
        },
        title(): string {
            const selectItem$ = useSelectItemStore();
            const clinicName = selectItem$.findClinicName(this.clinicId);
            const clinic = clinicName ? ` - ${selectItem$.findClinicName(this.clinicId)}` : '';
            switch (this.mode) {
                case "create":
                    return `新增盤點${clinic}`;
                case "update":
                    return `修改盤點${clinic}`;
                case "validate":
                    return `覆核盤點${clinic}`;
                default:
                    return '';
            }
        },
        saveButtonText(): string {
            switch (this.mode) {
                case "create":
                case "update":
                case "complete":
                    return '儲存盤點';
                case "validate":
                    return '確認覆核';
                default:
                    return '';
            }
        }
    },
    actions: {
        async init(options?: { id?, mode?}) {
            const {
                id: herbStockCheckId = null,
                mode = 'readonly',
            } = options ?? {};

            this._herbStockCheckId = herbStockCheckId;
            this.mode = mode;

            if (herbStockCheckId) {
                const res = await AdminHerbStockCheckService.one<HerbStockCheck>(herbStockCheckId);
                const herbStockItems = await AdminHerbStockCheckService.oneItems(herbStockCheckId);
                this.clinicId = res.clinic_id;
                this.remark = res.remark;
                this.items = herbStockItems;
            } else {
                const user$ = useUserStore();
                const herbs = await AdminHerbStockCheckService.list();
                this.clinicId = user$.currentClinicId;
                this.remark = defaultState.remark;
                this.items = herbs;
            }
            eventEmitter.emit('stock-check:init');
        },
        async createTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockCheckService.createTmp(HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-check:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async updateTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockCheckService.updateTmp(this._herbStockCheckId, HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-check:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async validateTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockCheckService.validateTmp(this._herbStockCheckId, HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-check:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async save(options?: { overrideUpdate?: boolean }) {
            try {
                const {
                    overrideUpdate = false,
                } = options ?? {};

                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                if (this.mode === 'create') {
                    const res = await AdminHerbStockCheckService.create<HerbStockCheck>(HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-check:saved', res.id);
                    return res;
                }

                if (this.mode === 'update') {
                    const res = await AdminHerbStockCheckService.update(this._herbStockCheckId, HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-check:saved', res.id);
                    return res;
                }

                if (this.mode === 'validate') {
                    if (overrideUpdate) {
                        const res = await AdminHerbStockCheckService.validateTmp(this._herbStockCheckId, HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                            clinicId: this.clinicId,
                            remark: this.remark,
                        }));
                        eventEmitter.emit('stock-check:saved', res.id);
                        return res;
                    } else {
                        const res = await AdminHerbStockCheckService.validate(this._herbStockCheckId, HerbStockCheckItemInfoVO.toAdminHerbStockCheckCreateDTO(this.items, {
                            clinicId: this.clinicId,
                            remark: this.remark,
                        }));
                        eventEmitter.emit('stock-check:saved', res.id);
                        return res;
                    }
                }
            } catch (e) {
                handleError(e);
            }
        },
        // async cancel(id: HerbStockIn['id']) {
        //     try {
        //         await AdminHerbStockInService.cancel(id);
        //         notify.success('盤點已取消');
        //     } catch (e) {
        //         handleError(e);
        //     }
        // },
    }
});
