<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { eventEmitter } from "@/services/events";
import { AppointmentQueryResponseData } from "@model/responses";
import { useUserStore } from "@/stores/modules";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import ContextMenu from "@imengyu/vue3-context-menu";
import { Appointment, Patient } from "@model/entities";
import { h } from 'vue';
import {
    ProgressCheck,
    Note,
    Library,
    Attachment,
    Prescription,
    Information,
    BookEdit,
} from 'mdue';

@Options({
    components: {
        Note,
        ProgressCheck,
        Library,
        Attachment,
        Prescription,
        BookEdit,
        Information,
    },
})
export default class AppointmentGridContextMenu extends Vue {
    open = false;
    user = useUserStore();

    @ListenEvent("appointment:contextmenu")
    listenAppointmentContextMenu(e, appointment: AppointmentQueryResponseData) {
        const items = [];
        if (this.user.hasPermission("appointment.edit")) {
            items.push({
                label: "預約進度記錄",
                icon: h(ProgressCheck, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickAppointmentAudit(appointment);
                },
            });
        }
        if (this.user.hasPermission("appointment")) {
            items.push({
                label: "處方追蹤",
                icon: h(Prescription, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickAppointmentPrescriptionTracing(appointment);
                },
            });
            items.push({
                label: "附件",
                icon: h(Attachment, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickAppointmentAttachment(appointment);
                },
            });
            items.push({
                label: "顯示預約",
                icon: h(Library, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickShowAppointments(
                        appointment.patientId
                        ?? (appointment as never as Appointment).patient_id
                    );
                },
            });
            items.push({
                label: "預約備注",
                icon: h(Note, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickAppointmentRemark(appointment);
                },
            });
        }
        if (this.user.hasPermission("consultation")) {
            items.push({
                label: "病歷",
                icon: h(BookEdit, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickPatientHistory(
                        appointment.patientId
                        ?? (appointment as never as Appointment).patient_id
                    );
                },
            });
        }
        if (this.user.hasPermission("patient.edit")) {
            items.push({
                label: "修改病人資料",
                icon: h(Information, {
                    style: {
                        marginRight: '8px',
                    },
                }),
                onClick: () => {
                    this.onClickEditPatient(
                        appointment.patientId
                        ?? (appointment as never as Appointment).patient_id
                    );
                },
            });
        }
        if (items.length > 0) {
            ContextMenu.showContextMenu({
                x: e.x,
                y: e.y,
                items,
            });
        }
    }

    onClickAppointmentAudit(appointment: AppointmentQueryResponseData) {
        eventEmitter.emit("appointment-audit-dialog:open", appointment.id);
    }

    onClickAppointmentPrescriptionTracing(appointment: AppointmentQueryResponseData) {
        eventEmitter.emit("appointment-prescription-tracing-dialog:open", appointment.id);
    }

    onClickPatientHistory(patientId: Patient['id']) {
        eventEmitter.emit("patient-history-dialog:open", patientId);
    }

    onClickShowAppointments(patientId: Patient['id']) {
        eventEmitter.emit("patient-appointment-dialog:open", patientId);
    }

    onClickAppointmentRemark(appointment: AppointmentQueryResponseData) {
        eventEmitter.emit("appointment-remark-dialog:open", appointment.id);
    }

    onClickEditPatient(patientId: Patient['id']) {
        eventEmitter.emit("patient-update-dialog:open", patientId);
    }

    onClickAppointmentAttachment(appointment: AppointmentQueryResponseData) {
        eventEmitter.emit("appointment-attachment-dialog:open", appointment.id);
    }
}
</script>

<template lang="pug">
div
</template>

<style lang="scss">
.mx-menu-ghost-host {
    z-index: 9999 !important;
}
</style>
