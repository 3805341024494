import { Labels, SelectItem } from "../common";

export const appointmentAttachmentTypeList = [
    "none",
    "fps",
    "eps",
    "consult",
    "overpaid",
    "underpaid",
    "package",
    "medical",
    "tongue",
    "skin",
] as const;

export type AppointmentAttachmentType = typeof appointmentAttachmentTypeList[number];

export const translateAppointmentAttachmentType = (type: AppointmentAttachmentType) => {
    return appointmentAttachmentTypeMap[type].label;
}

export const appointmentAttachmentTypeMap: Labels<AppointmentAttachmentType, unknown> = {
    none: {
        label: "-",
        color: "#fff",
        meta: {},
        show: false,
    },
    fps: {
        label: "FPS付款紀錄",
        color: "#fff",
        meta: {}
    },
    eps: {
        label: "EPS付款紀錄",
        color: "#fff",
        meta: {}
    },
    consult: {
        label: "診金付款紀錄",
        color: "#fff",
        meta: {}
    },
    overpaid: {
        label: "多付紀錄",
        color: "#fff",
        meta: {}
    },
    underpaid: {
        label: "少付紀錄",
        color: "#fff",
        meta: {}
    },
    package: {
        label: "套票紀錄",
        color: "#fff",
        meta: {}
    },
    medical: {
        label: "醫療報告",
        color: "#fff",
        meta: {}
    },
    tongue: {
        label: "舌像照片",
        color: "#fff",
        meta: {}
    },
    skin: {
        label: "皮膚照片",
        color: "#fff",
        meta: {}
    },
};

export const appointmentAttachmentTypeItems = <SelectItem<AppointmentAttachmentType>[]>Object.keys(appointmentAttachmentTypeMap)
    .filter(key => appointmentAttachmentTypeMap[key].show !== false)
    .map((key) => {
        return {
            value: key,
            label: appointmentAttachmentTypeMap[key].label,
        };
    });
