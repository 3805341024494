<script lang="ts">
import { AppointmentService } from "@/services";
import { eventEmitter } from "@/services/events";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { notify } from "@/utils/notify";
import { Appointment, Consultation } from "@model/entities";
import { appointmentEditReason } from "@model/models";
import { Options, Vue } from "vue-property-decorator";

@Options({
    components: {},
})
export default class AppointmentCancelDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    appiontmentId: Appointment["id"] = null;
    reason = "";

    @ListenEvent("appointment-cancel-dialog:open")
    async handleOpen(appiontmentId: Consultation["id"]) {
        this.appiontmentId = appiontmentId;
        this.reason = "";
        this.open = true;
    }

    get appointmentEditReason() {
        return appointmentEditReason;
    }

    async handleClickSubmit() {
        if (!this.reason) {
            return notify.error("請填寫預約取消原因");
        }
        if (this.appiontmentId) {
            try {
                this.loading = true;
                const res = await AppointmentService.setStatus(this.appiontmentId, 'cancelled', this.reason);
                notify.saved();
                eventEmitter.emit("appointment:status-updated", res);
                notify.info("已取消預約，在重新整理之前，你仍然可以復原已刪除的預約。");
                this.open = false;
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        }
    }
    
    handleClickAddReason(reason) {
        this.reason = reason;
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        div 預約取消原因
    .body
        el-form(label-position="top")
            el-form-item(label="預約取消原因")
                template(v-for="r in appointmentEditReason")
                    el-button(@click="handleClickAddReason(r)") {{ r }}
                el-input(v-model="reason" type="textarea" :autosize="{minRows: 4}")
            Button(type="primary" @click="handleClickSubmit" :loading="loading") 提交
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
