import { UserService } from "@/services";
import { useUserStoreWithOut } from "@/stores/modules";
import { notify } from "@/utils/notify";
import { TinyEmitter } from "tiny-emitter";
import { useRouter } from "vue-router";

export default {
    init: (eventEmitter: TinyEmitter) => {
        eventEmitter.on("auth:fail", async () => {
            await UserService.logout();
            const user = useUserStoreWithOut();
            user.setIsLoggedIn(false);
            notify.error("登入已過期");
            window.location.href = '/';
        });
    },
};
