import { Labels } from "../common";

const _herbStockOrderStatuses = [
    "cancelled",
    "temporary",
    "pending",
    "validated",
    "completed",
] as const;

export type HerbStockOrderStatus = typeof _herbStockOrderStatuses[number];


export const herbStockOrderStatusMap: Labels<HerbStockOrderStatus, never> = {
    cancelled: {
        label: "取消訂單",
        color: "#ff1904",
        show: false,
    },
    temporary: {
        label: "暫存訂單",
        color: "#4fe74a",
    },
    pending: {
        label: "等侯覆核",
        color: "#4fe74a",
        show: false,
    },
    validated: {
        label: "等侯入庫",
        color: "#4fe74a",
        show: false,
    },
    completed: {
        label: "完成訂單",
        color: "#f1f1f1",
    },
};

export const translateHerbStockOrderStatus = (status: HerbStockOrderStatus) => {
    return herbStockOrderStatusMap[status]?.label ?? '';
}