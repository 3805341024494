import type { App } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist';
import { useSelectItemStoreWithOut } from './modules/select-item';
import { useUIStore, useUserStore } from './modules';
import { usePreferenceStore } from './modules/preference';
import { useNavigationStore } from './modules/navigation.store';
import { RouteLocationRaw, RouteRecordNormalized } from 'vue-router';
import { useConsultationDictionaryStore } from './modules/consultation-dictionary.store';
export * from './state';

const store = createPinia();
store.use(piniaPluginPersist);

export const initStore = async () => {
    const userStore = useUserStore();
    await userStore.init();

    const selectItemStore = useSelectItemStoreWithOut();
    await selectItemStore.init();

    const preferenceStore = usePreferenceStore();
    preferenceStore.init();

    const uiStore = useUIStore();
    uiStore.init();

    const consultationDictionaryStore = useConsultationDictionaryStore();
    consultationDictionaryStore.init();
}

export const setupStore = async (app: App<Element>) => {
    await initStore();
    const navigation = useNavigationStore();
    app.config.globalProperties.$navigate = (to: RouteLocationRaw) => navigation.navigateDialog(app.config.globalProperties.$route, to);
    app.use(store);
}

export { store }
