import { IsArray, IsOptional, IsString } from "class-validator";

export class DoctorMeta {
    @IsString()
    @IsOptional()
    qualifications?: string;

    @IsString()
    @IsOptional()
    tcmaName?: string;
}