const envVar = [
    "BACKEND_ENDPOINT",
    "AG_GRID_LICENSE_KEY",
    "S3_ENDPOINT",
    "ROLLBAR_ACCESS_TOKEN",
    "BUILD_VERSION",
] as const;

type EnvKey = typeof envVar[number];

const shouldReturnDefault = (key: EnvKey, defaultString: string) => {
    return `__$${key}__` === defaultString;
}

export function dynamicEnv(key: EnvKey) {
    switch (key) {
        case 'BACKEND_ENDPOINT':
            return shouldReturnDefault(key, "https://api.qa.portal.soschinmed.clinic") ? "http://localhost:4174" : "https://api.qa.portal.soschinmed.clinic";
        case 'AG_GRID_LICENSE_KEY':
            return "ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2";
            // return shouldReturnDefault(key, "ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2") ? "ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2" : "ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2";
        case 'S3_ENDPOINT':
            return shouldReturnDefault(key, "https://soschinmed-dev.s3.ap-east-1.amazonaws.com") ? "https://soschinmed-dev.s3.ap-east-1.amazonaws.com" : "https://soschinmed-dev.s3.ap-east-1.amazonaws.com";
        case 'ROLLBAR_ACCESS_TOKEN':
            return shouldReturnDefault(key, "") ? "d056e513e0d94b17b088a8117247dad7" : "";
        case 'BUILD_VERSION':
            return shouldReturnDefault(key, "__$BUILD_VERSION__") ? "0.0.0" : "__$BUILD_VERSION__";
    }``
}
