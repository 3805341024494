import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Clinic, Herb, HerbStockAdjust } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { notify } from "@/utils/notify";
import { HerbStockAdjustItemInfoVO } from '@model/vos';
import { AdminHerbStockAdjustService } from "@/services";
import { handleError } from '@/utils/handleError';
import { useSelectItemStore } from '../../select-item';
import { useUserStore } from '../../user.store';

interface State {
    _herbStockAdjustId: HerbStockAdjust['id'],
    loading: boolean,

    mode: 'readonly' | 'create' | 'update' | 'validate',
    clinicId: Clinic['id'];
    currentHerbInfo: HerbStockAdjustItemInfoVO,
    remark: string;
    // todo not the correct type
    items: HerbStockAdjustItemInfoVO[];
}

const defaultState: State = {
    _herbStockAdjustId: null,
    loading: false,

    mode: 'create',
    clinicId: null,
    currentHerbInfo: null,
    remark: '',
    items: [],
}

export const useStockAdjustDatagridStore = defineStore({
    id: 'stock-adjust',
    state: (): State => cloneDeep(defaultState),
    getters: {
        canTmp(): boolean {
            return this.mode === 'create';
        },
        title(): string {
            const selectItem$ = useSelectItemStore();
            const clinicName = selectItem$.findClinicName(this.clinicId);
            const clinic = clinicName ? ` - ${selectItem$.findClinicName(this.clinicId)}` : '';
            switch (this.mode) {
                case "create":
                    return `新增調整${clinic}`;
                case "validate":
                    return `覆核調整${clinic}`;
                case "update":
                    return `修改調整${clinic}`;
                default:
                    return '';
            }
        },
        saveButtonText(): string {
            switch (this.mode) {
                case "create":
                case "update":
                    return '儲存調整';
                case "validate":
                    return '確認覆核';
                default:
                    return '';
            }
        }
    },
    actions: {
        async init(options?: { id, mode?}) {
            const {
                id: herbStockAdjustId = null,
                mode = 'readonly',
            } = options ?? {};

            this._herbStockAdjustId = herbStockAdjustId;
            this.mode = mode;

            if (herbStockAdjustId) {
                const res = await AdminHerbStockAdjustService.one<HerbStockAdjust>(herbStockAdjustId);
                this.remark = res.remark;
                this.clinicId = res.clinic_id;
                this.items = HerbStockAdjustItemInfoVO.fromHerbStockAdjust(res, {
                    isValidateMode: mode === 'validate',
                });
            } else {
                const user$ = useUserStore();
                this.currentHerbInfo = null;
                this.clinicId = user$.currentClinicId;
                this.remark = defaultState.remark;
                this.items = cloneDeep(defaultState.items);
            }
            eventEmitter.emit('stock-adjust:init');
        },
        async selectHerb(herbId: Herb['id']) {
            this.currentHerbInfo = await AdminHerbStockAdjustService.herbInfo(herbId);
        },
        async addHerb() {
            try {
                this.loading = true;
                if (this.currentHerbInfo) {
                    if (this.items.filter(x => x.id === this.currentHerbInfo.id).length > 0) {
                        return notify.error(`藥物 <${this.currentHerbInfo.name}> 重覆加入`);
                    }
                    this.currentHerbInfo.delta = 0;
                    this.items.push(this.currentHerbInfo);
                    eventEmitter.emit('stock-adjust:updated');
                }
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        },
        async createTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockAdjustService.createTmp(HerbStockAdjustItemInfoVO.toAdminHerbStockAdjustCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-adjust:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async updateTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockAdjustService.updateTmp(this._herbStockAdjustId, HerbStockAdjustItemInfoVO.toAdminHerbStockAdjustCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-adjust:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async validateTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockAdjustService.validateTmp(this._herbStockAdjustId, HerbStockAdjustItemInfoVO.toAdminHerbStockAdjustCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-adjust:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async save() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }
                if (this.mode === 'create') {
                    const res = await AdminHerbStockAdjustService.create<HerbStockAdjust>(HerbStockAdjustItemInfoVO.toAdminHerbStockAdjustCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-adjust:saved', res.id);
                    return res;
                }

                if (this.mode === 'update') {
                    const res = await AdminHerbStockAdjustService.update(this._herbStockAdjustId, HerbStockAdjustItemInfoVO.toAdminHerbStockAdjustCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-adjust:saved', res.id);
                    return res;
                }

                if (this.mode === 'validate') {
                    const res = await AdminHerbStockAdjustService.validate(this._herbStockAdjustId, HerbStockAdjustItemInfoVO.toAdminHerbStockAdjustCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-adjust:saved', res.id);
                    return res;
                }
            } catch (e) {
                handleError(e);
            }
        },
        async cancel(id: HerbStockAdjust['id']) {
            try {
                await AdminHerbStockAdjustService.cancel(id);
                notify.success('調整已取消');
            } catch (e) {
                handleError(e);
            }
        },
    }
});
