import { PaymentCreateParam } from "@model/dtos";
import { Herb, PackageConsumeRequest, PackagePurchaseRequest, Patient, Payment, PricingItem } from "@model/entities";
import { PaymentDiscountCategory, PaymentHealthcareVoucherData, PaymentMethodType, PaymentPricingCategory, PaymentItemType, PricingDiscountType } from "@model/models";
import { convert2dp } from "@model/utils";

export class ConsultationPaymentFacadeStateVO {
    patientId: Patient['id'];
    items: {
        name: string;
        amount: string;
        prepaid: string;
        type: PaymentItemType;
        category: PaymentPricingCategory;
        formula: string;
        is_settled: boolean;
        package_purchase_request_id?: PackagePurchaseRequest['id'];
        package_consume_request_id?: PackageConsumeRequest['id'];
        reference_herb_id?: Herb['id'];
        pricing_item_id?: PricingItem['id'];
    }[];
    discounts: {
        name: string;
        type: PricingDiscountType;
        category: PaymentDiscountCategory;
        amount: string;
        is_settled: boolean;
        package_consume_request_id?: PackageConsumeRequest['id'];
    }[];
    isPaid: boolean;
    method: PaymentMethodType;
    total: string;
    paidAmount: string;
    returnAmount: string;
    grandTotal: string;
    remark: string;
    healthcareVoucher: PaymentHealthcareVoucherData;

    static getDefaultValue(): ConsultationPaymentFacadeStateVO {
        return {
            patientId: null,
            items: [],
            isPaid: false,
            method: 'unselected',
            discounts: [],
            total: '0.00',
            grandTotal: '0.00',
            remark: '',
            paidAmount: '0.00',
            returnAmount: '0.00',
            healthcareVoucher: null,
        };
    }

    static fromEntity(payment: Payment): ConsultationPaymentFacadeStateVO {
        return {
            patientId: payment.patient_id,
            items: payment.items.map(x => ({
                name: x.name,
                amount: convert2dp(x.amount),
                formula: convert2dp(x.formula),
                prepaid: convert2dp(x.prepaid),
                type: x.type,
                category: x.category,
                is_settled: x.is_settled,
                package_purchase_request_id: x.package_purchase_request_id ?? null,
                package_consume_request_id: x.package_consume_request_id ?? null,
                reference_herb_id: x.reference_herb_id ?? null,
                pricing_item_id: x.pricing_item_id ?? null,
            })).sort((x, y) => x.name.localeCompare(y.name)),
            discounts: payment.discounts.map(x => ({
                name: x.name,
                type: x.type,
                category: x.category,
                amount: convert2dp(x.amount),
                is_settled: x.is_settled,
                package_consume_request_id: x.package_consume_request_id ?? null,
            })).sort((x, y) => x.name.localeCompare(y.name)),
            isPaid: payment.is_paid,
            method: payment.method,
            total: convert2dp(payment.total_amount),
            grandTotal: convert2dp(payment.grand_total),
            paidAmount: convert2dp(payment.paid_amount),
            returnAmount: convert2dp(payment.return_amount),
            remark: payment.remark,
            healthcareVoucher: payment.healthcare_voucher ?? null,
        };
    }

    static fromEntityOrDefault(payment?: Payment): ConsultationPaymentFacadeStateVO {
        return payment ? this.fromEntity(payment) : this.getDefaultValue();
    }

    static toPaymentCreateParam(vo: ConsultationPaymentFacadeStateVO): PaymentCreateParam {
        return {
            ...vo,
            items: vo.items.map(x => ({
                category: x.category ?? 'default',
                type: x.type ?? 'default',
                amount: convert2dp(x.amount),
                formula: convert2dp(x.formula),
                prepaid: convert2dp(x.prepaid),
                grand_total: convert2dp(x.amount),
                is_settled: x.is_settled ?? false,
                package_purchase_request_id: x.package_purchase_request_id ?? null,
                package_consume_request_id: x.package_consume_request_id ?? null,
                reference_herb_id: x.reference_herb_id ?? null,
                pricing_item_id: x.pricing_item_id ?? null,
                name: x.name,
            })),
        };
    }
}