import { BaseService } from "./base.service";
import { HerbDictionaryResponse } from "@model/responses";
import { Clinic, Herb, HerbStockIn } from "@model/entities";
import { StockOrderHerbInfoVO } from "@model/vos";
import { PaginatedResponse } from "@model/models";
import { AdminHerbStockInCreateDTO } from "@model/dtos";
import { idGenerator } from "@model/utils";

class Service extends BaseService {
    async herbInfo(herbId: Herb['id']): Promise<StockOrderHerbInfoVO> {
        return this.get(`/one/herb/${herbId}`);
    }

    async query(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }

    async queryHerb(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query/herb?${this.parseQuery(queryParams)}`);
    }

    async cancel(id: HerbStockIn['id']) {
        return this.delete(``, { id });
    }

    async update(id: HerbStockIn['id'], body: AdminHerbStockInCreateDTO): Promise<HerbStockIn> {
        return this.post(`/tmp/${id}`, body);
    }

    async complete(id: HerbStockIn['id'], body: AdminHerbStockInCreateDTO): Promise<HerbStockIn> {
        return this.post(`/${id}`, body);
    }

    async validate(id: HerbStockIn['id'], body: AdminHerbStockInCreateDTO): Promise<HerbStockIn> {
        return this.post(`/validate/${id}`, body);
    }

    async excel(id: HerbStockIn['id']): Promise<any> {
        return this.download(`/excel/${id}`, `${idGenerator.formatHerbStockInId(id)}.xlsx`);
    }

    async createTmp(body: any): Promise<HerbStockIn> {
        try {
            return await this.put('/tmp', body);
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }
}

export const AdminHerbStockInService = new Service('/admin/herb-stock/in');