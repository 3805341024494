export const herbTypes = [
    "single",
    "combo",
    // 個別
    "other",
    // 飲片
    "pieces",
] as const;

export type HerbType = typeof herbTypes[number];

export const translateHerbTypeAbbr = (herbType: HerbType) => {
    switch (herbType) {
        case "single":
            return '單';
        case "combo":
            return '複';
        case "other":
            return '個';
        case "pieces":
            return '飲';
    }
}

export const translateHerbType = (herbType: HerbType) => {
    switch (herbType) {
        case "single":
            return '單味';
        case "combo":
            return '複方';
        case "other":
            return '個別';
        case "pieces":
            return '飲片';
    }
}

export const checkHerbTypeCompatability = (herbType1: HerbType, herbType2: HerbType) => {
    if(herbType1 === 'single' || herbType1 === 'combo') {
        return herbType2 === 'single' || herbType2 === 'combo';
    }
    return herbType1 === herbType2;
}