
import { CustomErrorResponse } from "./dtos/custom-error.response";
import { CustomErrorType } from "./interfaces/CustomErrorType";
import * as handlers from './handlers';

export type ErrorCode =
    | 'ERR_SYSTEM'
    | 'ERR_NOT_FOUND'
    | 'ERR_FORBIDDEN'
    | 'ERR_INVALID_PARAMS'

    | 'ERR_TOKEN_INVALID'
    | 'ERR_TOKEN_EXPIRED'

    | 'ERR_RATE_LIMIT'

    | 'ERR_INSUFFICIENT_REWARD_POINT'
    | 'ERR_REWARD_POINT_NOT_MATCH'

    | 'ERR_EMAIL_SENT'

    | 'ERR_ALREADY_USED'
    | 'ERR_DUPLICATED_ENTRIES'
    ;

const errorMap = new Map<ErrorCode, CustomErrorType>([
    ['ERR_EMAIL_SENT', handlers.mailSentError],
    ['ERR_SYSTEM', handlers.systemError],   
    ['ERR_NOT_FOUND', handlers.notFoundError],
    ['ERR_INSUFFICIENT_REWARD_POINT', handlers.insufficientRewardPointError],
    ['ERR_REWARD_POINT_NOT_MATCH', handlers.rewardPointNotMatchError],
    ['ERR_INVALID_PARAMS', handlers.invalidParametersError],
    ['ERR_TOKEN_INVALID', handlers.invalidTokenError],
    ['ERR_RATE_LIMIT', handlers.rateLimitError],
    ['ERR_TOKEN_EXPIRED', handlers.expiredTokenError],
    ['ERR_FORBIDDEN', handlers.forbiddenError],
    ['ERR_DUPLICATED_ENTRIES', handlers.duplicatedEntriesError],
    ['ERR_ALREADY_USED', handlers.alreadyUsedError],
]);

export const constructError = (code: ErrorCode, details: CustomErrorResponse['details'] = null): CustomErrorResponse => {
    return {
        ...errorMap.get(code),
        details,
    };
};


export * from './dtos';
export * from './handlers';
export * from './interfaces';
export * from './utils';