<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
@Options({
    components: {},
})
export default class PatientHistoryTabDiagnosis extends Vue {
    store = usePatientHistoryDataviewStore();

    get consultation() {
        return this.store.currentConsultation?.consultation;
    }

    get diagnosis() {
        return this.store.currentConsultation?.diagnosis;
    }
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-diagnosis(label="診症")
    .tab-container
        el-row
            el-col(:span="6")
                el-form-item(label="預約編號" prop="id")
                    .data {{ $filters.appointmentId(consultation.appointment_id) }}
            el-col(:span="6")
                el-form-item(label="診症編號" prop="id")
                    .data {{ $filters.consultationId(consultation.id) }}
            el-col(:span="8")
                el-form-item(label="主診醫師" prop="code")
                    .data {{ consultation.doctor_name }}
        el-row
            el-col(:span="8")
                el-form-item(label="到診診所" prop="code")
                    .data {{ consultation.appointment?.clinic?.display_name }}

        el-row 
            el-col(:span="24")
                el-form-item(label="主訴*" prop="content")
                    el-input(v-model="diagnosis.content" type="textarea" :autosize="{minRows: 3}" disabled)

        el-row
            el-col(:span="12")
                el-form-item(label="脈診" prop="diagnosis_pulse")
                    el-input(v-model="diagnosis.diagnosis_pulse" disabled)

            el-col(:span="12")
                el-form-item(label="舌診" prop="diagnosis_tongue")
                    el-input(v-model="diagnosis.diagnosis_tongue" disabled)

        el-row
            el-col(:span="12")
                el-form-item(label="病名*" prop="diagnosis_ill")
                    el-input(v-model="diagnosis.diagnosis_ill" disabled)

            el-col(:span="12")
                el-form-item(label="證型" prop="diagnosis_ill_type")
                    el-input(v-model="diagnosis.diagnosis_ill_type" disabled)

        el-row
            el-col(:span="24")
                el-form-item(label="治則治法" prop="diagnosis_treatment")
                    el-input(v-model="diagnosis.diagnosis_treatment" disabled)
        el-row
            el-col(:span="24")
                el-form-item(label="註" prop="remark")
                    el-input(v-model="diagnosis.remark" type="textarea" :autosize="{minRows: 1}" disabled)

        el-row(:gutter="5")
            el-col.flex(:span="6")
                el-form-item(label="病假")
                    el-checkbox(v-model="diagnosis.has_sick_leave" disabled)
            el-col.flex(:span="8")
                el-date-picker(
                    type="daterange" 
                    v-model='diagnosis._sickLeaveDateRange' 
                    start-placeholder="開始日期"
                    end-placeholder="完結日期"
                    disabled
                )
            el-col.flex.center(:span="4")
                el-checkbox(v-model="diagnosis.sick_leave_start_from_pm" disabled) 由下午開始
            el-col.flex.center(:span="4")
                el-checkbox(v-model="diagnosis.sick_leave_end_at_am" disabled) 至上午完結
            el-col.flex.center(:span="2")
                .flex.center(style="height: 30px;") 共 {{ store.sickLeaveCount }} 天

</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.tab-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 1200px;
}

.el-form-item {
    margin: 0;
    align-items: center;

    :deep(.el-form-item__label) {
        align-items: center;
        text-align: center;
        width: 120px;
    }
}
</style>
