export class PatientDeliveryInfo {
    name?: string;
    mobile?: string;
    address?: string;

    static getDefaultValue(): PatientDeliveryInfo {
        return {
            name: '',
            mobile: '',
            address: '',
        };
    }

    static sanitize(d: Partial<PatientDeliveryInfo>): PatientDeliveryInfo {
        return {
            ...PatientDeliveryInfo.getDefaultValue(),
            ...d,
        };
    }
}