<script lang="ts">
import { Emit, Options, Vue } from "vue-property-decorator";
import { useSelectItemStore } from "@/stores/modules/select-item";
import { SelectItem } from "@model/models";
import { Clinic, Doctor, User } from "@model/entities";

@Options({})
export default class SelectClinic extends Vue {
    selectItemStore = useSelectItemStore();

    value: string = "";

    items: SelectItem<Clinic["id"]>[] = [];

    async mounted() {
        this.items = await this.selectItemStore.getClinics();
    }

    @Emit("change")
    onChange(value: any) {
        this.value = value;
    }
}
</script>

<template lang="pug">
el-select(v-model="value" @change="onChange" placeholder="選擇診所")
    el-option(v-for="item in items" :key="item.value" :label="item.label" :value="item.value" )
</template>

<style scoped lang="scss">

</style>
