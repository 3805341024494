<script lang="ts">
import { Emit, Options, Vue } from "vue-property-decorator";
import { useSelectItemStore } from "@/stores/modules/select-item";
import { SelectItem } from "@model/models";
import { defaultLocale, Locale } from "@model/constants";

@Options({})
export default class SelectLocale extends Vue {
    selectItemStore = useSelectItemStore();

    value: string = defaultLocale;

    items: SelectItem<Locale>[] = [
        {
            label: '繁體中文',
            value: 'zh'
        },
        {
            label: 'English',
            value: 'en',
        }
    ];

    @Emit("change")
    onChange(value: any) {
        this.value = value;
    }
}
</script>

<template lang="pug">
el-select(v-model="value" @change="onChange" placeholder="語言")
    el-option(v-for="item in items" :key="item.value" :label="item.label" :value="item.value" )
</template>

<style scoped lang="scss">

</style>
