import { Clinic, Doctor } from "@model/entities";
import { BaseService } from "./base.service";
import { SelectItem } from '@model/models';

class Service extends BaseService {
    async fetchClinicSetting<T>(key: string): Promise<T> {
        return this.get<T>(`/clinic/one/${key}`);
    }

    async updateClinicSetting(key: string, body: any): Promise<void> {
        return this.post(`/clinic/one/${key}`, body);
    }
}

export const SettingService = new Service('/setting');