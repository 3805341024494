import { BaseService } from "./base.service";
import { Appointment, PackagePurchaseRequest, Prescription } from "@model/entities";
import { PaymentReceiptPrintParams, PrescriptionPrintParams, DiagnosisReportPrintParams } from "@model/models";
import { Locale } from "@model/constants";


class Service extends BaseService {
    async paymentByAppointmentId(id: Appointment['id'], locale?: Locale): Promise<PaymentReceiptPrintParams> {
        return this.get(`/payment/${id}?${this.parseQuery({ locale })}`);
    }
    
    async paymentByPackagePurchaseRequestId(id: PackagePurchaseRequest['id'], locale?: Locale): Promise<PaymentReceiptPrintParams> {
        return this.get(`/package-purchase/${id}?${this.parseQuery({ locale })}`);
    }

    async diagnosisReportByAppointmentId(id: Appointment['id'], locale?: Locale): Promise<DiagnosisReportPrintParams> {
        return this.get(`/diagnosis-report/${id}?${this.parseQuery({ locale })}`);
    }

    async prescriptionByPrescriptionId(id: Prescription['id'], locale?: Locale): Promise<PrescriptionPrintParams> {
        return this.get(`/prescription/${id}?${this.parseQuery({ locale })}`);
    }

    async prescriptionsByAppointmentId(id: Appointment['id'], locale?: Locale): Promise<PrescriptionPrintParams[]> {
        return this.get(`/prescriptions/${id}?${this.parseQuery({ locale })}`);
    }
}

export const PrintingService = new Service('/printing');

