import { bytesToUuid } from "./bytesToUuid";

export const uuidv4 = (options, buf, offset) => {
    let rng: any;

    var crypto = typeof global !== 'undefined' && (global.crypto || global.msCrypto); // for IE 11
    if (crypto && crypto.getRandomValues) {
        // WHATWG crypto RNG - http://wiki.whatwg.org/wiki/Crypto
        var rnds8 = new Uint8Array(16); // eslint-disable-line no-undef
        rng = function whatwgRNG() {
            crypto.getRandomValues(rnds8);
            return rnds8;
        };
    }

    if (!rng) {
        // Math.random()-based (RNG)
        //
        // If all else fails, use Math.random().  It's fast, but is of unspecified
        // quality.
        var rnds = new Array(16);
        rng = function () {
            for (var i = 0, r; i < 16; i++) {
                if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
                rnds[i] = r >>> ((i & 0x03) << 3) & 0xff;
            }

            return rnds;
        };
    }

    var i = buf && offset || 0;

    if (typeof (options) == 'string') {
        buf = options == 'binary' ? new Array(16) : null;
        options = null;
    }
    options = options || {};

    var _rnds = options.random || (options.rng || rng)();

    // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
    _rnds[6] = (_rnds[6] & 0x0f) | 0x40;
    _rnds[8] = (_rnds[8] & 0x3f) | 0x80;

    // Copy bytes to buffer, if provided
    if (buf) {
        for (var ii = 0; ii < 16; ++ii) {
            buf[i + ii] = _rnds[ii];
        }
    }

    return buf || bytesToUuid(_rnds);
}