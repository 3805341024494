import { useCache } from "@/hooks/useCache";
import { extend } from "lodash";
import { BaseService } from "../base.service";
import { UserLoginDTO, UserCreateDto, UserUpdateDto } from "@model/dtos";
import { axGet, axPost, axPut } from "../axios";
import { User, UserGroup } from "@model/entities";
import { SelectItem, UserProfile } from '@model/models';
import { useUserStore } from "@/stores/modules";


class Service extends BaseService {
    // Cached State
    get isLoggedIn() {
        const { wsCache } = useCache();
        return wsCache.get('isLoggedIn');
    }

    set isLoggedIn(value: boolean) {
        const { wsCache } = useCache();
        wsCache.set('isLoggedIn', value);
    }

    async getProfile() {
        return this.get<UserProfile>(`/profile`);
    }

    async changeClinic(clinicId: number) {
        return axPost<UserProfile>({
            url: `/auth/change-clinic/${clinicId}`
        });
    }
    
    async login(data: UserLoginDTO) {
        const res = await axPost({
            url: '/auth/login',
            data,
        }).then(x => x.data);
    }

    async logout() {
        const res = await axPost({
            url: '/auth/logout',
        }).then(x => x.data);
        this.isLoggedIn = false;
    }

    // TOCHECK
    async refresh() {
        const res = await axPost({
            url: '/auth/refresh',
        }).then(x => x.data);
    }
}

export const UserService = new Service('/user');