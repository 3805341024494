import { axGet } from "./axios";
import { BaseService } from "./base.service";
import { AdminHerbQueryDTO } from "@model/dtos";
import { HerbComboUpdateDTO } from "@model/dtos";
import { AdminHerbListResponse } from "@model/responses";


class Service extends BaseService {
    async query(queryParams: AdminHerbQueryDTO): Promise<AdminHerbListResponse> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }

    async updateCombo(body: HerbComboUpdateDTO) {
        return this.post(`/combo`, body);
    }
}

export const AdminHerbService = new Service('/admin/herb');

