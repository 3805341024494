import { defineStore } from 'pinia';
import { store } from '@/stores';
import { PatientBriefInfo, DeliveryOption, DecoctingOption } from '@model/models';
import { useCache } from '@/hooks/useCache';
import { Appointment, Doctor, Patient } from '@model/entities';
import date from '@model/utils/date';
import { usePreferenceStore } from './preference';
import { useUserStore } from '.';
import { notify } from '@/utils/notify';
import { LocalStorage } from '@/utils/local-storage';

const { wsCache } = useCache('localStorage');

export interface UIState {
    registration: {
        selectedPatient: PatientBriefInfo;
    },
    appointment: {
        selectedPatientId: Patient['id'];
        selectedAppointmentId: Appointment['id'];
        filterDoctorIds: Doctor['id'][];
    },
    consultation: {
        filterStatusArrivedOnly: boolean;
        partitionByDoctorId: boolean;
        canCancel: boolean;
    },
    payment: {
        filterStatusPaymentOnly: boolean;
        canCancel: boolean;
    },
    dispense: {
        filterStatusPendingOnly: boolean;
        filterDeliveryOption?: DeliveryOption;
        filterDecoctingOption: DecoctingOption;
        filterDeliveryTime?: string;
        filterStatus?: string;
        shouldIgnoreDate: boolean;
    },
    report: {
        clinic: number;
        clinicOptional?: number;
        doctor: number;
        daily: string;
    },
    setting: {
        shouldShowInactiveDoctors: boolean;
        shouldShowInactiveUsers: boolean;
    },
}

const defaultState: UIState = {
    registration: {
        selectedPatient: null,
    },
    appointment: {
        selectedPatientId: null,
        selectedAppointmentId: null,
        filterDoctorIds: null,
    },
    consultation: {
        filterStatusArrivedOnly: true,
        partitionByDoctorId: true,
        canCancel: false,
    },
    payment: {
        filterStatusPaymentOnly: true,
        canCancel: false,
    },
    dispense: {
        filterStatusPendingOnly: true,
        filterDecoctingOption: null,
        filterDeliveryOption: undefined,
        filterDeliveryTime: null,
        filterStatus: null,
        shouldIgnoreDate: true,
    },
    report: {
        doctor: null,
        clinic: null,
        daily: date.formatDate(),
    },
    setting: {
        shouldShowInactiveDoctors: false,
        shouldShowInactiveUsers: false,
    },
}

export const useUIStore = defineStore({
    id: 'ui',
    persist: {
        enabled: true,
    },
    state: (): UIState => defaultState,
    actions: {
        init() {
            this.$subscribe(() => {
                LocalStorage.uiState.set(this.$state);
            });

            const uiState = LocalStorage.uiState.get();
            if (uiState) {
                this.$patch(uiState);
            }
            
            const d = wsCache.get('lastInitAt');
            const today = date.today();
            if (d !== today) {
                wsCache.set('lastInitAt', today);
                const pref = usePreferenceStore();
                pref.setAppointmentQueryDate(today);
            }
            const user = useUserStore();
            this.report.clinic = user.currentClinicId;

            // if(user.doctor) {
            //     this.appointment.filterDoctorIds = [user.doctor.id];
            // }
        },
    }
});

export const useUIStoreWithOut = () => {
    return useUIStore(store);
};
