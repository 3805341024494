import dayjs from "dayjs";

export class PrintTitleParams {
    clinicCode: string;
    patientId: number;
    patientName: string;
    appointmentDate: Date | string;
    documentType: 'prescription' | 'receipt' | 'consultation-proof' | 'pregnancy-proof' | 'sick-leave-proof' | 'diagnosis-report';
}

const getDocumentTypeAbbr = (documentType: string) => {
    switch (documentType) {
        case 'prescription':
            return 'p';
        case 'receipt':
            return 'r';
        case 'consultation-proof':
            return 'a';
        case 'pregnancy-proof':
            return 'pc';
        case 'sick-leave-proof':
            return 'sl';
        case 'diagnosis-report':
            return 'mr';
    }
};

export const getPrintTitle = (params: PrintTitleParams) => {
    const documentTypeAbbr = getDocumentTypeAbbr(params.documentType);
    const date = dayjs(params.appointmentDate).format('YYYYMMDD');
    return `${params.clinicCode.toLocaleUpperCase()}_P${params.patientId}_${params.patientName}_${date}_${documentTypeAbbr}`
};
