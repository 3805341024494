<script lang="ts">
import { PatientService } from '@/services';
import { eventEmitter } from '@/services/events';
import { ListenEvent } from '@/services/events/listen-event.decorator';
import { useUserStore } from '@/stores/modules';
import { handleError } from '@/utils/handleError';
import { PatientCreateDTO } from '@model/dtos';
import { getDefaultPatientCreateDto } from '@model/vos';
import { FormInstance } from 'element-plus';
import { Options, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { hkidMask, dobMask } from '@model/utils';
import { mixins } from 'vue-property-decorator';
import { DialogMixin } from '@/mixins/dialog.mixin';
import { cloneDeep } from 'lodash';

@Options({
    directives: {
        mask,
    },
})
export default class PatientCreateDialog extends mixins(Vue, DialogMixin) {
    readonly mx_dialogName = '新增病人';
    user = useUserStore();
    btnLoading = false;
    hkidErrorMessage = null;

    get hkidMask() {
        return hkidMask;
    }

    get dobMask() {
        return dobMask;
    }

    formData: PatientCreateDTO = cloneDeep(getDefaultPatientCreateDto());

    rules = {
        name_zh: [{ required: true, message: '請輸入病人姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '請輸入電話', trigger: 'blur' }],
        gender: [{ required: true, message: '請選擇性別', trigger: 'blur' }],
    };

    get isPharmacy() {
        return this.user.currentClinic.is_pharmacy;
    }

    get openingHour() {
        return this.user.currentClinic.opening_hour;
    }

    @ListenEvent('patient-create-dialog:open')
    handleAppointmentCreateEvent() {
        if (!this.mx_isOpen) {
            this.formData = cloneDeep(getDefaultPatientCreateDto());
            this.mx_open();
        }
    }

    async handleSubmit() {
        try {
            this.btnLoading = true;
            if (this.formData.hkid === '') {
                this.hkidErrorMessage = '請輸入身份證號碼 (如未能提供，請輸入`N`)';
                return;
            }
            const form = this.$refs['form'] as FormInstance;
            const valid = await form.validate();
            if (valid) {
                const res = await PatientService.create({
                    ...this.formData,
                });
                eventEmitter.emit('patient:created', res);
                this.mx_success();
            }
        } catch (e) {
            handleError(e);
        } finally {
            this.btnLoading = false;
        }
    }

    async handleBlurHkid() {
        if (!this.formData.hkid || this.formData.hkid.length === 0) {
            this.hkidErrorMessage = '請輸入身份證號碼 (如未能提供，請輸入`N`)';
        } else if (this.formData.hkid && this.formData.hkid.length >= 7) {
            const res = await PatientService.hasHkidUsed(this.formData.hkid);
            this.hkidErrorMessage = res ? '身份證號碼已存在' : null;
        } else {
            this.hkidErrorMessage = null;
        }
    }
}
</script>

<template lang="pug">
el-dialog(v-model="mx_isOpen" append-to-body width="1200px" top="1vh" @close="mx_onClose")
    template(#header)
        .d 新增病人
    .body
        el-form(ref="form" :model="formData" :rules="rules" label-position="top")
            el-row(:gutter="20")
                el-col(:span="4")
                    el-form-item(label="病人編號" prop="code")
                        el-input(v-model='formData.code' placeholder="自動新增" disabled)
                el-col(:span="8")
                    el-form-item(label="姓名(中)" prop="name_zh")
                        el-input(v-model='formData.name_zh')
                el-col(:span="8")
                    el-form-item(label="姓名(英)" prop="name_en")
                        el-input(v-model='formData.name_en')
                el-col(:span="4")
                    el-form-item(label="偏好語言" prop="name_en")
                        SelectLocale(v-model='formData.locale')
            el-row(:gutter="20")
                el-col(:span="6")
                    el-form-item(label="性別" prop="gender")
                        SelectGender(v-model='formData.gender' type="radio")
                el-col(:span="6")
                    el-form-item(label="出生日期" prop="dob")
                        el-input(v-model='formData.dob' placeholder="YYYY/MM/DD" v-mask="dobMask")
                el-col(:span="4")
                    el-form-item(label="年齡" prop="age")
                        el-input(:value="$filters.age(formData.dob)" disabled)
                el-col(:span="8")
                    el-form-item(label="身份證號碼" prop="hkid" :error="hkidErrorMessage")
                        el-input(v-model='formData.hkid' placeholder="如未能提供，請輸入`N`" v-mask="hkidMask" @blur="handleBlurHkid")
            el-row(:gutter="20")
                el-col(:span="2")
                    el-form-item(label="區號 #1" prop="mobile_code")
                        el-input(v-model='formData.mobile_code')
                el-col(:span="8")
                    el-form-item(label="聯絡電話 #1" prop="mobile")
                        el-input(v-model='formData.mobile')
                el-col(:span="2")
                    el-form-item(label="區號 #2" prop="mobile2_code")
                        el-input(v-model='formData.mobile2_code')
                el-col(:span="8")
                    el-form-item(label="聯絡電話 #2" prop="mobile2")
                        el-input(v-model='formData.mobile2')
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="地址" prop="address")
                        el-input(v-model='formData.address' type="textarea" :autosize="{minRows:1}")
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="電郵地址" prop="email")
                        el-input(v-model='formData.email')
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="藥物敏感" prop="allergy")
                            el-input(v-model="formData.allergy")
            el-row(:gutter="20")
                el-col(:span="12")
                    el-form-item(label="注意事項" prop="caution")
                        PatientCautionSelectGroup(v-model="formData.caution" :gender="formData.gender")
                    el-form-item(label="介紹途徑" prop="referral")
                        PatientReferralSelectGroup(v-model="formData.referral")
                el-col(:span="12" style="border: 1px solid #000")
                    div 送貨資料
                    el-form-item(label="取貨人姓名")
                        el-input(v-model="formData.delivery_info.name")
                    el-form-item(label="取貨人電話")
                        el-input(v-model="formData.delivery_info.mobile")
                    el-form-item(label="送貨地址")
                        el-input(v-model="formData.delivery_info.address" type="textarea" :autosize="{minRows:1}")
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="病人備註" prop="remark")
                        el-input(v-model='formData.remark' type="textarea" :autosize="{minRows:1}")

            el-row
                el-col(:span="24")
                    Button(type="primary" @click="handleSubmit" :loading="btnLoading") 新增病人
</template>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';

.body {
}
</style>
