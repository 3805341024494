<script lang="ts">
import { AppointmentService } from "@/services";
import { eventEmitter } from "@/services/events";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { Appointment } from "@model/entities";
import { AppointmentRemarkUpdateDTO } from "@model/dtos";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { notify } from "@/utils/notify";
import { Options, Vue } from "vue-property-decorator";
import { parseHtml } from "@model/utils";

@Options({
    components: {},
})
export default class AppointmentRemarkDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    appointmentId: Appointment["id"] = null;

    formData: AppointmentRemarkUpdateDTO = {
        id: null,
        remark: "",
    };

    @ListenEvent("appointment-remark-dialog:open")
    async handleOpen(appointmentId: Appointment["id"]) {
        this.appointmentId = appointmentId;
        const appointment: Appointment = await AppointmentService.one(appointmentId);
        this.formData = {
            id: appointmentId,
            remark: parseHtml(appointment.remark),
        };
        this.open = true;
    }

    async handleClickSubmit() {
        if (this.appointmentId) {
            try {
                this.loading = true;
                const res = await AppointmentService.updateRemark(this.formData);
                this.open = false;
                notify.saved();
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        }
    }

    async handleClickAttachment() {
        eventEmitter.emit('appointment-attachment-dialog:open', this.appointmentId);
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        el-row
            el-col(:span="18")
                div 修改預約備註
            el-col(:span="5" style="text-align:right")
                el-button(type="primary" @click="handleClickAttachment") 查看附件
            el-col(:span="1" style="text-align:right")
    .body
        el-form(label-position="top")
            el-form-item(label="備註")
                el-input(v-model="formData.remark" type="textarea" :autosize="{minRows: 4}")
            Button(type="primary" @click="handleClickSubmit" :loading="loading") 儲存
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
