import { Patient } from "@model/entities";
import { PatientCaution } from "@model/models";

export class ConsultationPatientFacadeStateVO {
    med_hist: string;
    caution: PatientCaution
    allergy: string;

    static getDefaultValue(): ConsultationPatientFacadeStateVO {
        return {
            med_hist: '',
            allergy: '',
            caution: PatientCaution.getDefaultValue(),
        };
    }

    static fromEntity(patient: Patient): ConsultationPatientFacadeStateVO {
        return {
            allergy: patient.allergy ?? '',
            med_hist: patient.med_hist ?? '',
            caution: PatientCaution.sanitize(patient.caution),
        };
    }
}