import { ScheduleTimeRange } from "@model/models";
import dayjs from "dayjs";

interface Time {
    hours: number
    minutes: number
}

export const durationToStep = (duration: number): string => {
    const hour = Math.floor(duration / 60);
    const minute = duration % 60;
    return `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
}

export const stepToDuration = (step: string): number => {
    const stepTime = parseTime(step);
    return stepTime.hours * 60 + stepTime.minutes;
}

export const parseDuration = (time: string): null | number => {
    const values = (time || '').split(':');
    if (values.length >= 2) {
        let hours = Number.parseInt(values[0], 10);
        const minutes = Number.parseInt(values[1], 10);
        const timeUpper = time.toUpperCase();
        if (timeUpper.includes('AM') && hours === 12) {
            hours = 0;
        } else if (timeUpper.includes('PM') && hours !== 12) {
            hours += 12;
        }
        return hours * 60 + minutes;
    }

    return null;
}

export const parseTime = (time: string): null | Time => {
    const values = (time || '').split(':');
    if (values.length >= 2) {
        let hours = Number.parseInt(values[0], 10);
        const minutes = Number.parseInt(values[1], 10);
        const timeUpper = time.toUpperCase();
        if (timeUpper.includes('AM') && hours === 12) {
            hours = 0;
        } else if (timeUpper.includes('PM') && hours !== 12) {
            hours += 12;
        }
        return {
            hours,
            minutes,
        };
    }

    return null;
}

export const compareTime = (time1: string, time2: string): number => {
    const value1 = parseTime(time1);
    if (!value1) return -1;
    const value2 = parseTime(time2);
    if (!value2) return -1;
    const minutes1 = value1.minutes + value1.hours * 60;
    const minutes2 = value2.minutes + value2.hours * 60;
    if (minutes1 === minutes2) {
        return 0;
    }
    return minutes1 > minutes2 ? 1 : -1;
}

export const padTime = (time: number | string) => {
    return `${time}`.padStart(2, '0');
}

export const formatTime = (time: Time): string => {
    return `${padTime(time.hours)}:${padTime(time.minutes)}`;
}

export const nextTime = (time: string, step: string): string => {
    const timeValue = parseTime(time);
    if (!timeValue) return '';

    const stepValue = parseTime(step);
    if (!stepValue) return '';

    const next = {
        hours: timeValue.hours,
        minutes: timeValue.minutes,
    };
    next.minutes += stepValue.minutes;
    next.hours += stepValue.hours;
    next.hours += Math.floor(next.minutes / 60);
    next.minutes = next.minutes % 60;
    return formatTime(next);
}

export const prevTime = (time: string, step: string): string => {
    const timeValue = parseTime(time);
    if (!timeValue) return '';

    const stepValue = parseTime(step);
    if (!stepValue) return '';

    const prev = {
        hours: timeValue.hours,
        minutes: timeValue.minutes,
    };
    prev.minutes -= stepValue.minutes;
    if (prev.minutes < 0) {
        prev.minutes += 60;
        prev.hours -= 1;
    }
    prev.hours -= stepValue.hours;
    prev.hours -= Math.floor(prev.minutes / 60);
    if (prev.hours < 0) {
        return null;
    }
    prev.minutes = prev.minutes + 60 % 60;
    return formatTime(prev);
}

export const findContainerTimeslots = (timeslots: { timeslot: string }[], timeslot: string, step: string, duration: number): string[] => {
    let currentTimeslot = timeslot;
    let match = timeslots.filter(x => x.timeslot === timeslot)[0];
    while (!match && currentTimeslot !== null) {
        currentTimeslot = prevTime(currentTimeslot, '00:05');
        match = timeslots.filter(x => x.timeslot === currentTimeslot)[0];
    }

    if (currentTimeslot === null) {
        currentTimeslot = timeslots[0].timeslot;
    }

    const matchingTimeslot = currentTimeslot;
    const res = [currentTimeslot];

    const durationSteps = Math.floor(duration / parseDuration(step));
    for (let i = 1; i < durationSteps; i++) {
        currentTimeslot = nextTime(currentTimeslot, step);
        res.push(currentTimeslot);
    }

    // Timeslot overflow (e.g. {09:55 with step 15} in 09:45 timeslot)
    if (compareTime(
        nextTime(timeslot, durationToStep(duration)),
        nextTime(matchingTimeslot, durationToStep(stepToDuration(step) * (durationSteps || 1)))
    ) > 0) {
        currentTimeslot = nextTime(currentTimeslot, step);
        res.push(currentTimeslot);
    }

    return res;
}

export const withinTimeRanges = (ranges: ScheduleTimeRange[], timeslot: string): boolean => {
    for (const r of ranges) {
        if (compareTime(timeslot, r.from) >= 0 && compareTime(timeslot, r.to) < 0) {
            return true;
        }
    }
    return false;
}

export const findContainerTimeslot = (timeslots: { timeslot: string }[], timeslot: string): string => {
    let currentTimeslot = timeslot;
    let match = timeslots.filter(x => x.timeslot === timeslot)[0];
    while (!match && currentTimeslot !== null) {
        currentTimeslot = prevTime(currentTimeslot, '00:01');
        match = timeslots.filter(x => x.timeslot === currentTimeslot)[0];
    }

    if (currentTimeslot === null) {
        currentTimeslot = timeslots[0].timeslot;
    }

    return currentTimeslot;
}

export const generateTimeslots = (from: string, to: string, step: string = '00:15', skipFrom?: string, skipTo?: string): string[] => {
    const result = [];
    let current = from;
    let currentTime: string;
    while (current && to && compareTime(current, to) < 0) {
        if (skipFrom && skipTo) {
            if (compareTime(current, skipFrom) >= 0 && compareTime(current, skipTo) < 0) {
                current = nextTime(current, step);
                continue;
            }
        }
        result.push(current);
        current = nextTime(current, step);
    }
    return result;
}


export const addDuration = (timeslot: string, duration: number) => {
    return nextTime(timeslot, durationToStep(duration));
}

export const formatTimeslot12HR = (timeslot: string) => {
    const t = parseTime(timeslot);
    const apm = t.hours < 12 ? "AM" : "PM";
    const hour = t.hours === 12 ? t.hours : t.hours % 12;

    return `${`${hour}`.padStart(2, '0')}:${`${t.minutes}`.padStart(2, '0')} ${apm}`;
}