<script lang="ts">
import { mixins, Options, Vue, Watch } from 'vue-property-decorator';
import { FormInstance } from 'element-plus';
import { Appointment, Patient } from '@model/entities';
import { AppointmentCreateDTO, AppointmentUpdateDTO, NotificationRequestParams } from '@model/dtos';
import { getDefaultAppointmentDurationFromType, appointmentEditReason } from '@model/models';
import { useUserStore } from '@/stores/modules';
import { AppointmentService, PatientService } from '@/services';
import { eventEmitter } from '@/services/events';
import { ListenEvent } from '@/services/events/listen-event.decorator';
import { handleError } from '@/utils/handleError';
import { notify } from '@/utils/notify';
import { DialogMixin } from '@/mixins/dialog.mixin';
import { throttle } from 'lodash';
import { uuidv4 } from '@model/utils';
import date from '@model/utils/date';
import { ScheduleService } from '@/services/schedule.service';

@Options({
    components: {},
})
export default class AppointmentDialog extends mixins(Vue, DialogMixin) {
    user = useUserStore();
    btnLoading = false;

    mode: 'create' | 'update' = 'create';
    warnings = [];
    formData: AppointmentCreateDTO | AppointmentUpdateDTO = {
        type: 'old',
        date: null,
        timeslot: null,
        doctor_id: null,
        clinic_id: null,
        patient_id: null,
        duration: 15,
        remark: '',
    };

    notificationFormData: NotificationRequestParams = {
        useNotification: false,
        name: '',
        mobile_code: '',
        mobile: '',
        date: '',
        timeslot: '',
    };

    reason = '';

    isEditRemark = false;
    isNotOnDuty = false;

    rules = {
        type: [{ required: true, message: '請選擇診症類別', trigger: 'blur' }],
        date: [{ required: true, message: '請輸入日期', trigger: 'blur' }],
        timeslot: [{ required: true, message: '請輸入預約時段', trigger: 'blur' }],
        duration: [{ required: true, message: '請輸入診症時間', trigger: 'blur' }],
        doctor_id: [{ required: true, message: '請選擇醫師', trigger: 'blur' }],
        clinic_id: [{ required: true, message: '請選擇診所', trigger: 'blur' }],
    };

    get isPharmacy() {
        return this.user.currentClinic.is_pharmacy;
    }

    get openingHour() {
        // Subtract 1 minute from the opening hour to make sure the timeslot is within the opening hour
        let [hour, minute] = this.user.currentClinic.opening_hour.from.split(':');
        if (minute === '00') {
            hour = ((24 + Number(hour) - 1) % 24).toFixed(0).padStart(2, '0');
            minute = '59';
        } else {
            minute = (Number(minute) - 1).toFixed(0).padStart(2, '0');
        }

        return {
            from: `${hour}:${minute}`,
            to: this.user.currentClinic.opening_hour.to,
        };
    }

    get canSubmit() {
        return Object.keys(this.formData)
            .filter((x) => !['revisit', 'remark'].includes(x))
            .map((x) => this.formData[x])
            .every((x) => !!x);
    }

    get appointmentEditReason() {
        return appointmentEditReason;
    }

    get width() {
        return this.user.hasPermission('other.notification') ? '1000px' : '800px';
    }

    @Watch('formData.date')
    @Watch('formData.doctor_id')
    @Watch('formData.patient_id')
    async onFormDataChanged() {
        if (this.mode === 'create' && this.formData.date && this.formData.doctor_id && this.formData.patient_id) {
            const res = await AppointmentService.preValidate({
                date: date.formatDate(this.formData.date),
                doctor_id: this.formData.doctor_id,
                patient_id: this.formData.patient_id,
            });
            this.warnings = res.state.warns.map((x) => x.message);
        }
    }

    @Watch('formData.patient_id')
    async onPatientIdChanged() {
        if (this.formData.patient_id) {
            await this.preparePatientForNotification(this.formData.patient_id);
        }
    }

    @Watch('notificationFormData.useNotification')
    async onUseNotificationChanged() {
        await this.preparePatientForNotification(this.formData.patient_id);
        await this.prepareDateForNotification();
    }

    async preparePatientForNotification(patientId: Patient['id']) {
        if (this.notificationFormData.useNotification) {
            await PatientService.one(patientId)
                .then((res: Patient) => {
                    this.notificationFormData.name = res.name_zh;
                    this.notificationFormData.mobile_code = res.mobile_code;
                    this.notificationFormData.mobile = res.mobile;
                })
                .catch(handleError);
        }
    }

    @Watch('formData.date')
    async prepareDateForNotification() {
        if (this.notificationFormData.useNotification) {
            this.notificationFormData.date = date.toDayjs(this.formData.date).format('YYYY-MM-DD');
            this.notificationFormData.timeslot = '08:00';
        }
    }

    @ListenEvent('appointment:create')
    async handleAppointmentCreateEvent({ type, timeslot, doctorId, patientId, date, shouldWarnForDisabledTimeslot, duration }) {
        if (!this.user.hasPermission('appointment.create')) {
            notify.forbidden('掛號');
            return;
        }
        if (!this.mx_isOpen) {
            this.mode = 'create';
            this.notificationFormData.useNotification = false;
            this.warnings = [];
            this.formData.request_id = uuidv4();
            this.formData.remark = '';
            this.formData.clinic_id = this.user.currentClinicId;
            this.formData.type = type ?? 'old';
            this.formData.date = date ?? null;
            this.formData.timeslot = timeslot ?? null;
            this.formData.duration = duration ?? getDefaultAppointmentDurationFromType(this.formData.type);
            this.formData.doctor_id = doctorId ? parseInt(doctorId) : null;
            this.formData.patient_id = patientId ? parseInt(patientId) : null;
            this.formData.revisit = this.$route.query.revisit ? parseInt(this.$route.query.revisit) : undefined;
            this.mx_openAndWarnIf(shouldWarnForDisabledTimeslot, '注意，所選時間並非醫師開診時間，是否仍要掛號？');
            this.isNotOnDuty = shouldWarnForDisabledTimeslot;
        }
    }

    @ListenEvent('appointment:update')
    async handleAppointmentUpdateEvent(appointmentId) {
        if (!this.user.hasPermission('appointment.edit')) {
            notify.forbidden('修改掛號');
            return;
        }
        this.isNotOnDuty = false;
        if (!this.mx_isOpen) {
            const appointment: Appointment = await AppointmentService.one(appointmentId);
            this.mode = 'update';
            this.notificationFormData.useNotification = false;
            this.warnings = [];
            this.formData.id = appointment.id;
            this.formData.type = appointment.type;
            this.formData.clinic_id = appointment.clinic_id;
            this.formData.date = appointment.date;
            this.formData.timeslot = appointment.timeslot;
            this.formData.doctor_id = appointment.doctor_id;
            this.formData.patient_id = appointment.patient_id;
            this.formData.duration = appointment.duration;
            this.formData.remark = appointment.remark;
            this.reason = '';
            this.mx_open();
        }
    }

    @ListenEvent('patient:created')
    async onPatientCreated(patient: Patient) {
        this.formData.patient_id = patient.id;
        this.formData.type = 'new';
    }

    onTypeChange() {
        this.formData.duration = getDefaultAppointmentDurationFromType(this.formData.type);
    }

    async handleSubmit() {
        try {
            this.btnLoading = true;
            const form = this.$refs['form'] as FormInstance;
            const valid = await form.validate();

            if (valid) {
                if (this.mode === 'create') {
                    if (this.notificationFormData.useNotification) {
                        this.formData.notification = this.notificationFormData;
                    }
                    const res = await AppointmentService.create(this.formData);
                    eventEmitter.emit('appointment:created', res);
                } else if (this.mode === 'update') {
                    if (!this.reason) {
                        notify.error('請填寫修改原因');
                        return;
                    }
                    const res = await AppointmentService.update({
                        ...this.formData,
                        reason: this.reason,
                    });
                }
                this.mx_success();
            }
        } catch (e) {
            handleError(e);
        } finally {
            this.btnLoading = false;
        }
    }

    handleClickCreatePatient() {
        eventEmitter.emit('patient-create-dialog:open');
    }

    handleClickAddReason(reason) {
        this.reason = reason;
    }

    handleInputPatient() {
        throttle(() => {
            this.formData.patient_id = null;
        }, 500)();
    }

    async onTimeslotUpdate() {
        try {
            this.btnLoading = true;
            const isOnDuty = await ScheduleService.isOnDuty({
                clinicId: this.formData.clinic_id,
                doctorId: this.formData.doctor_id,
                date: this.formData.date,
                timeslot: this.formData.timeslot,
            });
            if (!isOnDuty) {
                this.isNotOnDuty = true;
            } else {
                this.isNotOnDuty = false;
            }
        } catch (e) {
            handleError(e);
        } finally {
            this.btnLoading = false;
        }
    }

    disabledNotificationDate(time: Date) {
        return time.getTime() + 86400000 < Date.now() || time > new Date(this.formData.date);
    }

    get notifactionMaxTime() {
        return date.formatDate(this.formData.date) === date.formatDate(this.notificationFormData.date) ? this.formData.timeslot : null;
    }
}
</script>

<template lang="pug">
el-dialog(v-model="mx_isOpen" append-to-body :width="width" @close="mx_onClose")
    template(#header)
        .d(v-if="mode === 'create'") 掛號
        .d(v-if="mode === 'update'") 修改預約
    .body.flex.flex-row
        .div(style="width: 800px;")
            el-form(ref="form" :model="formData" :rules="rules" label-position="top")
                el-row(:gutter="5")
                    el-col(:span="8")
                        el-form-item(label="診所" prop="clinic_id")
                            SelectClinic(v-model="formData.clinic_id" @change="onTimeslotUpdate")
                    el-col(:span="8")
                        el-form-item(label="醫師" prop="doctor_id")
                            SelectDoctor(v-model="formData.doctor_id" @change="onTimeslotUpdate")
                    el-col.flex.center(:span="8")
                        div.flex(style="flex-direction: column; align-items: flex-start;")
                            div(v-if="isNotOnDuty" style="color: red") **注意：所選時間並非醫師開診時間
                            div(v-for="w in warnings" style="color: red") **注意：{{ w }}
                .divider.thin
                el-row
                    el-col(:span="12")
                        el-form-item(label="* 病人" prop="patient_id")
                            SelectPatient(v-model:patientId="formData.patient_id" @input="handleInputPatient")
                            el-button(@click="handleClickCreatePatient") + 新病人
                    el-col(:span="8")
                        el-form-item(label="診症類別" prop="type")
                            SelectAppointmentType(v-model="formData.type" @change="onTypeChange")
                el-row
                    el-col(:span="8")
                        el-form-item(label="日期" prop="date")
                            el-date-picker(v-model='formData.date' @change="onTimeslotUpdate")
                    el-col(:span="5")
                        el-form-item(label="預約時段" prop="timeslot")
                            el-time-select(v-model='formData.timeslot' :min-time="openingHour.from" start="00:00" end="23:45" step="00:05" @change="onTimeslotUpdate") 
                    el-col(:span="4")
                        el-form-item(label="診症時間" prop="duration")
                            el-input-number(v-model='formData.duration' :min="5" :step="5")  
                    el-col.flex.center(:span="4" :style="{ justifyContent: 'flex-start' }")
                        .unit 分鐘

                el-row(v-if="mode === 'create'")
                    el-col(:span="24")
                        el-form-item(label="預約備注")
                            el-input(v-model="formData.remark" type="textarea" :autosize="{minRows: 4}")

                el-row(v-if="mode === 'update'")
                    el-col(:span="24")
                        el-form-item(label="修改原因")
                            template(v-for="r in appointmentEditReason")
                                el-button(@click="handleClickAddReason(r)") {{ r }}
                            el-input(v-model="reason" type="textarea" :autosize="{minRows: 4}")

            el-row
                el-col(:span="24")
                    Button(type="primary" @click="handleSubmit" :loading="btnLoading" :disabled="!canSubmit") 
                        template(v-if="mode === 'create'") 掛號
                        template(v-else-if="mode === 'update'") 修改

        template(v-if="user.hasPermission('other.notification')")
            .div(style="width: 200px; padding-left: 10px;" v-if="mode === 'create'")
                el-form(ref="form" :model="formData" label-position="top")
                    el-row
                        el-col 
                            el-form-item(label="排程提示")
                                el-switch(v-model="notificationFormData.useNotification") 排程提示
                    template(v-if="notificationFormData.useNotification")
                        el-row
                            el-col 
                                el-form-item(label="病人名稱" prop="name")
                                    el-input(v-model="notificationFormData.name" placeholder="病人名稱")
                        el-row(:guuter="2")
                            el-col(:span="6")
                                el-form-item(label="區號" prop="mobile_code")
                                    el-input(v-model="notificationFormData.mobile_code" placeholder="區號")
                            el-col(:span="18")
                                el-form-item(label="聯絡電話" prop="mobile")
                                    el-input(v-model="notificationFormData.mobile" placeholder="聯絡電話")
                        el-row
                            el-col 
                                el-form-item(label="提示日期" prop="date")
                                    el-date-picker(
                                        v-model="notificationFormData.date" 
                                        :disabled-date="disabledNotificationDate" 
                                        placeholder="提示日期"
                                    )
                        el-row 
                            el-col
                                el-form-item(label="提示時間" prop="timeslot")
                                    el-time-select(v-model='notificationFormData.timeslot' :max-time="notifactionMaxTime" start="00:00" end="23:45" step="00:05") 

</template>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';

.unit {
    line-height: 6.5;
    padding-left: 5px;
}

:deep(.el-autocomplete .el-input__inner) {
    width: 250px;
}
</style>
