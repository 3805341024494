import { matchAndSumObjectArray } from "@model/utils";
import { arrayPartition } from "@model/utils/array";
import { PrescriptionFacadeStateVO } from "./prescriptions-state.vo";

export class PrescriptionSingleDosageListVO {
    data: {
        name: string;
        amount: number;
        unit: string;
    }[];

    static fromItems(items: PrescriptionFacadeStateVO['items']): PrescriptionSingleDosageListVO {
        if (!items) {
            return {
                data: [],
            };
        }

        const filterUnit = (unit: string) => {
            return unit === '克(濃)' ? '克' : unit;
        };

        const [single, combo] = arrayPartition(items, x => x.type !== 'combo');
        const singleArr = single.map(x => ({
            name: x.name,
            amount: x.amount * x.ratio,
            unit: filterUnit(x.unit),
        }));
        const comboArr = combo.map(x => {
            if (!x.combo_data || !x.combo_data.content) {
                return [{
                    name: x.name,
                    amount: x.amount * x.ratio,
                    unit: filterUnit(x.unit),
                }];
            }
            return x.combo_data.content.map(y => {
                return {
                    name: y.name_zh,
                    amount: (y.amount / (x.combo_data.total_amt ?? 1)) * x.amount * x.ratio,
                    unit: filterUnit(y.unit),
                };
            })
        }).reduce((acc, cur) => {
            const res = acc;
            for (const item of cur) {
                const index = res.findIndex(x => x.name === item.name && x.unit === item.unit);
                if (index === -1) {
                    res.push(item);
                } else {
                    res[index].amount += item.amount;
                }
            }
            return res;
        }, []);
        return {
            data: matchAndSumObjectArray(singleArr, comboArr, 'name', 'amount'),
        }
    }
}