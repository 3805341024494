<script lang="ts">
import { AppointmentService } from "@/services";
import { eventEmitter } from "@/services/events";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { Appointment } from "@model/entities";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { Options, Vue } from "vue-property-decorator";
import { AppointmentAttachment } from "@model/entities";

@Options({
    components: {},
})
export default class AppointmentAttachmentDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    appointmentId: Appointment["id"] = null;
    attachments: AppointmentAttachment[] = [];

    @ListenEvent("appointment-attachment-dialog:open")
    async handleOpen(appointmentId: Appointment["id"]) {
        console.log("OP");
        this.appointmentId = appointmentId;
        await this.reload();
        this.open = true;
    }

    @ListenEvent("appointment-attachment:updated")
    async reload() {
        try {
            this.loading = true;
            this.attachments = await AppointmentService.getAttachmentsByAppointmentId(this.appointmentId);
        } catch (e) {
            handleError(e);
        } finally {
            this.loading = false;
        }
    }

    async handleClickCreateAttachment() {
        eventEmitter.emit("appointment-attachment-create-dialog:open", this.appointmentId);
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        el-row
            el-col(:span="18")
                div 預約附件
            el-col(:span="5" style="text-align:right")
                el-button(type="primary" @click="handleClickCreateAttachment") 新增附件
            el-col(:span="1" style="text-align:right")
    .body
        AppointmentAttachmentTable(:attachments="attachments")
AppointmentAttachmentCreateDialog
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
