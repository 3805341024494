import { Clinic, ConsultationTreatment } from "@model/entities";
import { BaseService } from "./base.service";
import { SettlementVO } from "@model/vos";
import { SettlementUpsertDTO } from "@model/dtos";

class Service extends BaseService {
    async oneByClinicIdAndDate(clinicId: Clinic['id'], date: string) {
        return this.get<any>(`/${clinicId}/${date}`);
    }

    async getBeginOfDaySum(clinidId: Clinic['id'], date: string): Promise<any> {
        return this.get(`/prev-eod-sum/${clinidId}/${date}`);
    }

    async getIncomeSum(clinidId: Clinic['id'], date: string): Promise<any> {
        return this.get(`/income-sum/${clinidId}/${date}`);
    }

    async save(body: SettlementUpsertDTO): Promise<any> {
        return this.post(`/save`, body);
    }

    async confirm(body: SettlementUpsertDTO): Promise<any> {
        return this.post(`/confirm`, body);
    }
}

export const SettlementService = new Service('/settlement');