import { TransformBoolean } from "@model/utils/transform/transform-boolean";
import { TransformInteger, TransformNumber } from "@model/utils/transform/transform-number";
import { IsBoolean, IsNumber, IsOptional, IsString, isString, ValidateIf } from "class-validator";

export type PrescriptionUsageType = 'internal' | 'external';

export class PrescriptionUsage {
    @IsBoolean()
    @TransformBoolean()
    is_required: boolean;

    @IsBoolean()
    @IsOptional()
    @TransformBoolean()
    is_multiple_per_day?: boolean;

    @IsString()
    take?: PrescriptionUsageType;

    @IsNumber()
    @TransformInteger()
    @ValidateIf((object, value) => object.is_required)
    day?: number;

    @IsNumber()
    @TransformInteger()
    @ValidateIf((object, value) => object.is_required)
    times?: number;

    @IsNumber()
    @TransformNumber()
    @ValidateIf((object, value) => object.is_multiple_per_day)
    pack?: number;

    @IsString()
    @ValidateIf((object, value) => object.is_multiple_per_day)
    pack_unit?: string;

    @IsString()
    @IsOptional()
    remark?: string;

    static sanitize(usage: PrescriptionUsage): PrescriptionUsage {
        if(usage.day) {
            usage.day = Number(usage.day);
        }
        if(usage.times) {
            usage.times = Number(usage.times);
        }
        if(usage.pack) {
            console.log(usage.pack);
            usage.pack = Number(usage.pack);
        }
        return usage;
    }
}