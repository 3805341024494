import { AuditPrintType } from '@model/models';
import { omit } from 'lodash';
import jsbarcode from 'jsbarcode';
import { Liquid } from 'liquidjs';
import { Appointment } from '@model/entities';
import { ElLoading } from 'element-plus';
import { handleError } from '../handleError';
import { eventEmitter } from '@/services/events';
import { dynamicEnv } from '../dynamic-env';

export const textToBase64Barcode = (text) => {
    const canvas = document.createElement("canvas");
    jsbarcode(canvas, text, { format: "CODE39" });
    return canvas.toDataURL("image/png");
}

export const renderTemplate = async <T>(folder: string, templateName: string, params: T) => {
    const template = await fetch(`/html-template/${folder}/${templateName}.liquid?${dynamicEnv("BUILD_VERSION")}`).then(x => x.text());
    const engine = new Liquid();
    const html = await engine.parseAndRender(template, {
        __s3Url: dynamicEnv('S3_ENDPOINT'),
        ...params,
    });
    return html;
}

export const print = async <T, U>(options: {
    size?: 'A4' | 'A5',
    folder: string,
    templateName: string | AuditPrintType,
    appointmentId?: Appointment['id'],
    options: T,
    paramsGenerator: (params: T) => Promise<U>,
    isPreview?: boolean,
    shouldShowDialog?: boolean,
    title?: string,
    onClose?: () => void | Promise<void>;
}): Promise<{ params: any, html: string }> => {
    const loading = ElLoading.service({ fullscreen: true });
    try {
        const {
            size = 'A5',
            isPreview = false,
            shouldShowDialog = true,
            templateName,
            title,
            onClose = (() => { }),
        } = options ?? {};

        const params = await options.paramsGenerator(options.options);
        const html = await renderTemplate<U>(options.folder, options.templateName, params);

        if (shouldShowDialog) {
            eventEmitter.emit('print-dialog:open', {
                size,
                shouldPrint: !isPreview,
                html,
                title: title ?? templateName,
                audit: {
                    appointment_id: options.appointmentId,
                    type: templateName,
                    params: omit(params as any, 'barcode'),
                },
                onClose,
            });
        }

        return { params, html };
    } catch (e) {
        handleError(e);
    } finally {
        loading.close();
    }
}



export const printAppend = async <T, U>(options: {
    size?: 'A4' | 'A5',
    folder: string,
    templateName: string | AuditPrintType,
    appointmentId?: Appointment['id'],
    options: T[],
    paramsGenerator: (params: T) => Promise<U>,
    isPreview?: boolean,
    title?: string,
    shouldShowDialog?: boolean,
    onClose?: () => void | Promise<void>;
}): Promise<{ params: any, html: string }> => {
    const loading = ElLoading.service({ fullscreen: true });
    try {
        const {
            size = 'A5',
            isPreview = false,
            shouldShowDialog = true,
            templateName,
            title,
            onClose = (() => { }),
        } = options ?? {};

        let html = '';
        let paramsList = [];
        for (const opt of options.options) {
            const params = await options.paramsGenerator(opt);
            html += await renderTemplate<U>(options.folder, options.templateName, params);
            paramsList.push(params);
        }

        if (shouldShowDialog) {
            eventEmitter.emit('print-dialog:open', {
                size,
                shouldPrint: !isPreview,
                html,
                title: title ?? templateName,
                audit: {
                    appointment_id: options.appointmentId,
                    type: templateName,
                    params: paramsList.map(x => omit(x as any, 'barcode')),
                },
                onClose,
            });
        }

        return { params: paramsList, html };
    } catch (e) {
        handleError(e);
    } finally {
        loading.close();
    }
}

export * from './consultation-proof.print';
export * from './sick-leave-proof.print';
export * from './pregnancy-proof.print';
export * from './prescription.print';
export * from './prescription-facade.print';
export * from './payment-receipt.print';
export * from './diagnosis-report.print';
export * from './stock/herb-stock-order.print';
export * from './stock/herb-stock-in.print';
export * from './stock/herb-stock-check.print';
export * from './stock/herb-stock-check-report.print';
export * from './stock/herb-stock-adjust.print';
export * from './dispense/dispense-label.print';