<script lang="ts">
import { Emit, Options, Vue } from 'vue-property-decorator';
import { AppointmentAttachmentType, SelectItem, appointmentAttachmentTypeList, translateAppointmentAttachmentType } from '@model/models';

@Options({})
export default class SelectAppointmentAttachmentType extends Vue {
    public value: string = '';

    types: SelectItem<AppointmentAttachmentType>[] = appointmentAttachmentTypeList.map((x: AppointmentAttachmentType) => ({
        label: translateAppointmentAttachmentType(x),
        value: x,
    }));

    @Emit('change')
    onChange(value: any) {
        this.value = value;
    }
}
</script>

<template lang="pug">
el-select(v-model="value", @change="onChange", placeholder="選擇類別")
  el-option(v-for="type in types" :key="type.value" :label="type.label" :value="type.value")
</template>

<style scoped lang="scss"></style>
