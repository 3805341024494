import { Labels } from "../common";

const _herbStockInStatuses = [
    "cancelled",
    "temporary",
    "pending",
    "completed",
    "validated",
] as const;

export type HerbStockInStatus = typeof _herbStockInStatuses[number];


export const herbStockInStatusMap: Labels<HerbStockInStatus, never> = {
    cancelled: {
        label: "取消入庫",
        color: "#ff1904",
        show: false,
    },
    temporary: {
        label: "暫存入庫",
        color: "#4fe74a",
    },
    pending: {
        label: "等侯入庫",
        color: "#4fe74a",
        show: false,
    },
    completed: {
        label: "等侯覆核",
        color: "#f1f1f1",
    },
    validated: {
        label: "已完成",
        color: "#4fe74a",
        show: false,
    },
};

export const translateHerbStockInStatus = (status: HerbStockInStatus) => {
    return herbStockInStatusMap[status]?.label ?? '';
}