import { AdminHerbStockInCreateDTO } from "@model/dtos";
import { Clinic, Herb, HerbBrand, HerbStockIn } from "@model/entities";
import { HerbType } from "@model/models";
import { convert2dp, NotNull } from "@model/utils";

export class HerbStockInItemInfoVO {
    id: Herb['id'];
    code: string;
    name: string;
    brand: string;
    type: HerbType;
    stockInCost: string;
    stockUnit: string;
    amount?: number;
    orderAmount?: number;
    validatedAmount?: number;

    static fromEntity(herb: Herb): HerbStockInItemInfoVO {
        return {
            id: herb.id,
            code: herb.code,
            name: herb.name_zh,
            brand: herb.brand.name_zh,
            type: herb.type,
            stockInCost: convert2dp(herb.stock_in_cost),
            stockUnit: herb.stock_unit.name_zh,
        }
    }

    // TODO not the correct type
    static fromHerbStockIn(order: HerbStockIn, options?: { 
        isValidateMode: boolean,
    }): Partial<HerbStockInItemInfoVO>[] {
        if (!order?.items) {
            return [];
        }

        const {
            isValidateMode = false,
        } = options ?? {};
        
        return order.items.sort((x, y) => x.herb_id - y.herb_id).map(x => ({
            id: x.herb_id,
            code: x.herb_code,
            name: x.herb_name,
            brand: x.brand_name,
            stockUnit: x.unit,
            stockInCost: convert2dp(x.unit_cost),
            totalCost: convert2dp(x.total_cost),
            amount: x.amount,
            orderAmount: x.order_amount,
            validatedAmount: NotNull(x.validated_amount) ? x.validated_amount : (isValidateMode ? x.amount : null),
        }));
    }

    static toAdminHerbStockInCreateDTO(items: Partial<HerbStockInItemInfoVO>[], others: {
        clinicId: Clinic['id'];
        remark: string;
    }): AdminHerbStockInCreateDTO {
        if (!items.every(x => !isNaN(Number(x.amount)))) {
            throw Error('入庫數量有誤');
        }

        return {
            clinic_id: others.clinicId,
            remark: others.remark,
            items: items.map(x => {
                const total_cost = convert2dp(Number(x.amount) * Number(x.stockInCost));
                return {
                    herb_id: x.id,
                    herb_code: x.code,
                    herb_name: x.name,
                    brand_name: x.brand,
                    amount: convert2dp(x.amount),
                    order_amount: NotNull(x.orderAmount) ? convert2dp(x.orderAmount) : null,
                    validated_amount: NotNull(x.validatedAmount) ? convert2dp(x.validatedAmount) : null,
                    unit_cost: x.stockInCost,
                    unit: x.stockUnit,
                    total_cost,
                };
            })
        }
    }
}