import { BaseService } from "./base.service";
import { HerbDictionaryResponse } from "@model/responses";
import { Clinic, Herb, HerbStockOrder } from "@model/entities";
import { StockOrderHerbInfoVO } from "@model/vos";
import { PaginatedResponse } from "@model/models";
import { AdminHerbStockOrderDTO } from "@model/dtos";
import { idGenerator } from "@model/utils";

class Service extends BaseService {
    async herbInfo(herbId: Herb['id']): Promise<StockOrderHerbInfoVO> {
        return this.get(`/one/herb/${herbId}`);
    }

    async query(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }

    async queryHerb(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query/herb?${this.parseQuery(queryParams)}`);
    }

    async cancel(id: HerbStockOrder['id']) {
        return this.delete(``, { id });
    }

    async update(id: HerbStockOrder['id'], body: AdminHerbStockOrderDTO): Promise<HerbStockOrder> {
        return this.post(`/${id}`, body);
    }

    async validate(id: HerbStockOrder['id'], body: AdminHerbStockOrderDTO): Promise<HerbStockOrder> {
        return this.post(`/validate/${id}`, body);
    }

    async excel(id: HerbStockOrder['id']): Promise<any> {
        return this.download(`/excel/${id}`, `${idGenerator.formatHerbStockOrderId(id)}.xlsx`);
    }

    async createTmp(body: any): Promise<HerbStockOrder> {
        try {
            return await this.put('/tmp', body);
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }
}

export const AdminHerbStockOrderService = new Service('/admin/herb-stock/order');