<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { defaultLocale, Locale } from "@model/constants";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";

@Options({
    components: {},
})
export default class PatientHistoryTabAttachment extends Vue {
    store = usePatientHistoryDataviewStore();
    locale: Locale = defaultLocale;

    mounted(){
        // console.log(this.store.attachments);
    }
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-attachment(label="附件")
    .tab-container(v-loading="store.loading")
        AppointmentAttachmentTable(:attachments="store.attachments" readonly)
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
