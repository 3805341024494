<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { Appointment, Patient } from '@model/entities';
import { useUserStore } from '@/stores/modules';
import { AppointmentService, PatientService } from '@/services';
import { ListenEvent } from '@/services/events/listen-event.decorator';
import { eventEmitter } from '@/services/events';

@Options({
    directives: {
        mask,
    },
})
export default class PatientAppointmentDialog extends Vue {
    user = useUserStore();
    open = false;
    btnLoading = false;

    patientId: Patient['id'] = null;
    patient: Patient = null;
    appointments: Appointment[];
    hash: number = null;

    @ListenEvent('patient-appointment-dialog:open')
    async handleAppointmentCreateEvent(id: Patient['id']) {
        if (!this.open) {
            this.patientId = id;
            this.open = true;
            await this.refresh();
        }
    }

    @ListenEvent('appointment:status-updated')
    @ListenEvent('appointment:updated')
    async refresh() {
        if (this.open) {
            this.appointments = await AppointmentService.fetchPatient(this.patientId);
            this.patient = await PatientService.one(this.patientId);
            this.hash = Math.random();
        }
    }

    async handleClickEdit(appointment: Appointment) {
        eventEmitter.emit('appointment:update', appointment.id);
    }

    async handleClickSetConfirmed(appointment: Appointment) {
        const res = await AppointmentService.setStatus(appointment.id, 'confirmed');
        eventEmitter.emit('appointment:status-updated', res);
    }

    async handleClickSetArrived(appointment: Appointment) {
        const res = await AppointmentService.setStatus(appointment.id, 'arrived');
        eventEmitter.emit('appointment:status-updated', res);
    }

    async handleClickCancelConfirmed(appointment: Appointment) {
        const res = await AppointmentService.setStatus(appointment.id, 'pending');
        eventEmitter.emit('appointment:status-updated', res);
    }

    async handleClickCancelArrived(appointment: Appointment) {
        const res = await AppointmentService.setStatus(appointment.id, 'pending');
        eventEmitter.emit('appointment:status-updated', res);
    }

    async handleClickCancel(appointment: Appointment) {
        eventEmitter.emit('appointment-cancel-dialog:open', appointment.id);
    }

    async handleContextMenu(appointment: Appointment, colInfo: any, e: PointerEvent) {
        e.preventDefault();
        eventEmitter.emit('appointment:contextmenu', e, appointment);
    }

    async handleClickUpdateReason(appointment: Appointment) {
        eventEmitter.emit('appointment-update-reason-dialog:open', appointment.id);
    }

    async handleClickCheckAudit(appointment: Appointment) {
        eventEmitter.emit('appointment-audit-dialog:open', appointment.id);
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%")
    template(#header)
        .d 預約紀錄
    .body
        div 
            span.mini 姓名：
            span {{ patient?.name_zh }}

        div(style="margin-bottom: 15px")
            span.mini 病人編號：
            span {{ patient?.code }}

        el-table(:data="appointments" :key="hash" @row-contextmenu="handleContextMenu")
            el-table-column(label="狀態" width="70")
                template(#default="scope")
                    .status {{ $filters.appointmentStatus(scope.row.status) }}
            el-table-column(label="類型" width="70")
                template(#default="scope")
                    .doctor-name {{ $filters.appointmentType(scope.row.type) }}
            el-table-column(label="診所" width="100")
                template(#default="scope")
                    .doctor-name {{ scope.row.clinic?.display_name }}
            el-table-column(label="醫師" width="100")
                template(#default="scope")
                    .doctor-name {{ scope.row.doctor?.display_name }}
            el-table-column(label="日期" width="100")
                template(#default="scope")
                    .doctor-name {{ $filters.date(scope.row.date) }}
            el-table-column(label="時間" width="300")
                template(#default="scope")
                    .doctor-name {{ $filters.timeRange(scope.row.timeslot, scope.row.duration) }}
            el-table-column(label="" width="300")
                template(#default="scope")
                    .table-button-group
                        template(v-if="scope.row.status === 'pending'")
                            el-button(v-if="user.hasPermission('appointment.edit')" type="primary" @click="handleClickSetConfirmed(scope.row)") 確認預約
                            el-button(v-if="user.hasPermission('appointment.edit')" type="primary" @click="handleClickSetArrived(scope.row)") 到診
                            el-button(v-if="user.hasPermission('appointment.edit')" @click="handleClickEdit(scope.row)") 修改
                            el-button(v-if="user.hasPermission('appointment.edit')" type="danger" @click="handleClickCancel(scope.row)") 刪除
                        template(v-else-if="scope.row.status === 'confirmed'")
                            el-button(v-if="user.hasPermission('appointment.edit')"  type="primary" @click="handleClickCancelConfirmed(scope.row)") 取消確認
                        template(v-else-if="scope.row.status === 'arrived'")
                            el-button(v-if="user.hasPermission('appointment.edit')"  type="primary" @click="handleClickCancelArrived(scope.row)") 取消到診
                        template(v-else-if="scope.row.status === 'cancelled'")
                            el-button(v-if="user.hasPermission('appointment.edit')"  type="primary" @click="handleClickUpdateReason(scope.row)") 補充原因
AppointmentGridContextMenu
</template>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';

.body {}
</style>
