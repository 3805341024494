import { defineStore } from 'pinia';
import { store } from '..';
import { RouteLocationRaw, RouteLocationNormalizedLoaded } from 'vue-router';
import router from '@/router';
import { ElLoading } from 'element-plus';
import { handleError } from '@/utils/handleError';

interface NavigationState {
    _name: string,
    _breadcrumb: string[];
    _primaryTabHistory: RouteLocationNormalizedLoaded;
}

const defaultState: NavigationState = {
    _name: null,
    _breadcrumb: null,
    _primaryTabHistory: null,
}

const sessionSubWindowCacheKey = 'soschinmed-nbf:sub-window';

export const useNavigationStore = defineStore({
    id: 'navigation',
    state: (): NavigationState => defaultState,
    persist: {
        enabled: true,
    },
    getters: {
        isSubWindow(): boolean {
            return sessionStorage.getItem(sessionSubWindowCacheKey) === '1';
        },
        breadcrumb(): string[] {
            return this._breadcrumb;
        },
    },
    actions: {
        goBack() {
            if (!this._primaryTabHistory) {
                return router.push({
                    name: "registration.index",
                });
            }
            router.push({
                name: this._primaryTabHistory.name,
                params: this._primaryTabHistory.params,
                query: this._primaryTabHistory.query,
            });
            this._primaryTabHistory = null;
        },
        navigateDialog(route: RouteLocationNormalizedLoaded, location: RouteLocationRaw) {
            this._primaryTabHistory = route;
            router.push(location);
        },
        setNavigation(name: string) {
            this._name = name;
            if (name) {
                this._breadcrumb = name.split('.');
            }
        },
        isActiveMenuTab(name: string) {
            const requireLen = this._breadcrumb.includes('setting') ? 3 : 2;
            return name.split('.').filter(x => this._breadcrumb.includes(x)).length >= requireLen;
        },
        openSubWindow(href: string) {
            const subWindow = window.open(href, '_blank', 'height=767,width=1200');
            subWindow.sessionStorage.setItem(sessionSubWindowCacheKey, "1");    
            return subWindow;
        },
        async withFullscreenLoading(callback: () => void | Promise<void>) {
            const loading = ElLoading.service({ fullscreen: true });
            try {
                await callback();
            } catch (e) {
                handleError(e);
            } finally {
                loading.close();
            }
        },
    }
})

export const useNavigationStoreWithOut = () => {
    return useNavigationStore(store)
}
