import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Appointment, PaymentItem, Prescription } from '@model/entities';
import { PatientAlertDTO } from '@model/dtos';
import { eventEmitter } from '@/services/events';
import { DispenseService, PaymentService, PrescriptionService } from '@/services';
import { PrescriptionFacadeStateVO } from '@model/vos';
import { useUserStore } from '@/stores/modules';
import { PrescriptionSingleDosageListVO } from '@model/vos';
import { previewPrescriptionFacade, printPrescriptionById } from '@/utils/print';
import { useSelectItemStore } from '@/stores/modules/select-item';
import { ElLoading } from 'element-plus';
import { handleError } from '@/utils/handleError';
import { PrescriptionUsage } from '@model/models';
import { defaultLocale } from '@model/constants';

export interface DispensePrescriptionDataviewState {
    id: Prescription['id'];
    prescription: PrescriptionFacadeStateVO;
    _appointmentId: Appointment['id'];
    _patientAlert: PatientAlertDTO;
    _prescription: Prescription;
}

const defaultState: DispensePrescriptionDataviewState = {
    id: null,
    prescription: PrescriptionFacadeStateVO.getDefaultValue(),
    _appointmentId: null,
    _patientAlert: null,
    _prescription: null,
}

export const useDispensePrescriptionDataviewStore = defineStore({
    id: 'dispense-prescription-dataview',
    state: (): DispensePrescriptionDataviewState => cloneDeep(defaultState),
    getters: {
        currentPrescriptionDosage(): any {
            return {
                herb: this.prescription.items.filter(x => x.type === 'single' || x.type === 'combo').map(x => Number(x.amount)).reduce((x, y) => x + y, 0).toFixed(2),
                pieces: this.prescription.items.filter(x => x.type === 'pieces').map(x => Number(x.amount)).reduce((x, y) => x + y, 0).toFixed(2),
                itemCount: this.prescription.items.length,
            };
        },
        currentPrescriptionDosageList(): PrescriptionSingleDosageListVO {
            return PrescriptionSingleDosageListVO.fromItems(this.prescription.items);
        },
        isPharmacy(): boolean {
            return this.prescription.is_pharmacy;
        },
        appointmentId(): Appointment['id'] {
            return this._appointmentId;
        },
    },
    actions: {
        async init(id: Prescription['id']) {
            this.$reset();
            const user = useUserStore();
            this.id = id;
            this._prescription = await PrescriptionService.one<Prescription>(id);
            this._patientAlert = {
                allergy: this._prescription.consultation.appointment.patient.allergy,
                caution: this._prescription.consultation.appointment.patient.caution,
            };
            this._appointmentId = this._prescription.consultation.appointment.id;
            const selectItemStore = useSelectItemStore();
            const herbBrandItems = await selectItemStore.getHerbBrands();
            this.prescription = PrescriptionFacadeStateVO.fromEntity(this._prescription, herbBrandItems);
            eventEmitter.emit("prescription:init");
        },
        async validate() {
            const zeroOrNegativeAmount = this.prescription.items.filter(x => x.amount <= 0 || isNaN(x.amount)).reduce((x, y) => x.concat(y), []);
            if (zeroOrNegativeAmount.length > 0) {
                throw Error(`藥物 <${zeroOrNegativeAmount.map(x => x.name).join(', ')}> 的用量不正確`);
            }
            const res = await PrescriptionService.validate({
                alert: this._patientAlert,
                prescriptions: [this.prescription].map(x => ({
                    usage: PrescriptionUsage.sanitize(x.usage),
                    items: x.items.map(y => ({
                        id: y.id,
                        name: y.name,
                        pricing_type: y.pricing_type,
                        amount: Number(y.amount),
                    }))
                }))
            });
            eventEmitter.emit('consultation-prescription:validated', res);
            return res;
        },
        async save(callback) {
            const res = await DispenseService.updatePrescription(this.id, PrescriptionFacadeStateVO.toDispensePrescriptionUpdateDto(this.prescription));
            await printPrescriptionById({
                id: this.id,
                locale: defaultLocale,
                onClose: callback
            });
        },
        async previewPrescriptionPrice(): Promise<PaymentItem[]> {
            const payments = await PaymentService.previewPrescriptions({
                clinic_id: this._prescription.consultation.appointment.clinic_id,
                prescriptions: [this.prescription]
            });
            return payments;
        },
        async previewPrescription() {
            const loading = ElLoading.service({ fullscreen: true });
            try {
                const prescription = await PrescriptionService.one<Prescription>(this.id);
                await previewPrescriptionFacade({
                    appointmentId: prescription.appointment_id,
                    consultationId: prescription.consultation_id,
                    prescription: this.prescription,
                    patient: prescription.consultation.appointment.patient,
                    prescriptionIndex: prescription.index,
                    diagnosis: {
                        diagnosis_ill: prescription.consultation.diagnosis_ill
                    },
                });
            } catch (e) {
                handleError(e);
            } finally {
                loading.close();
            }
        },
    }
});
