import { Labels, SelectItem } from "../common";

export const decoctingOptions = [
    "YES",
    "NO",
] as const;

export type DecoctingOption = typeof decoctingOptions[number];

export const decoctingOptionsMap: Labels<DecoctingOption, never> = {
    NO: {
        label: "免煎",
        color: '#fff',
        show: false,
    },
    YES: {
        label: "代煎",
        color: '#fff',
        show: false,
    },
}

export const translateDecoctingOption = (opt: DecoctingOption) => {
    return decoctingOptionsMap[opt]?.label ?? null;
}

export const decoctingOptionItems: SelectItem<DecoctingOption>[] = decoctingOptions.map(x => ({
    label: translateDecoctingOption(x),
    value: x,
}));