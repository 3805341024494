import { App } from "vue"
import dateFormatter from "./date.filter";
import genderFormatter from "./gender.filter";
import hkidFormatter from "./hkid.filter";
import clinicFormatter from "./clinic.filter";
import appointmentStatusFormatter from "./appointment-status.filter";
import appointmentTypeFormatter from "./appointment-type.filter";
import numbersFilter from "./numbers.filter";
import idFilter from "./id.filter";
import { herbTypeAbbrFormatter } from "./herb-type.filter";
import {
    PrescriptionViolation,
    translatePrescriptionViolation,
    translatePrescriptionStatus,
    translateHerbStockOrderStatus,
    translateHerbStockCheckStatus,
    translateHerbStockInStatus,
    translateAuditAppointmentAction,
    translateHerbStockAdjustStatus,
    translateDecoctingOption,
    translatePaymentMethodTypeAbbr,
    translatePaymentPricingDiscountType,
    translateAppointmentAttachmentType,
    translatePaymentPricingOfferType,
    translatePaymentPricingOfferTypeValue,
    translatePatientPackageItemStatus,
} from "@model/models";
import {
    getUsageTextPrefix,
    idGenerator,
    translate,
} from "@model/utils";

export const initFilters = (app: App<Element>) => {
    app.config.globalProperties.$t = translate;

    app.config.globalProperties.$filters = {
        date: dateFormatter.formatDate,
        time12hr: dateFormatter.formatTimeslotTo12HR,
        timeRange: dateFormatter.formatTimeRange,
        datetime: dateFormatter.formatDateTime,
        todayTime: dateFormatter.formatTodayTimeElseDatetime,
        dateText: dateFormatter.formatDateText,
        hkid: hkidFormatter,
        gender: genderFormatter,
        age: dateFormatter.formatAge,
        clinic: clinicFormatter.translateClinic,
        clinics: clinicFormatter.formatClinics,
        appointmentStatus: appointmentStatusFormatter,
        appointmentAttachmentType: translateAppointmentAttachmentType,
        prescriptionStatus: translatePrescriptionStatus,
        pricingDiscountType: translatePaymentPricingDiscountType,
        pricingOfferType: translatePaymentPricingOfferType,
        pricingOfferTypeValue: translatePaymentPricingOfferTypeValue,
        appointmentType: appointmentTypeFormatter,
        decoctingOption: translateDecoctingOption,
        auditAppointmentAction: translateAuditAppointmentAction,
        herbType: herbTypeAbbrFormatter,
        herbStockOrderStatus: translateHerbStockOrderStatus,
        herbStockInStatus: translateHerbStockInStatus,
        herbStockCheckStatus: translateHerbStockCheckStatus,
        herbStockAdjustStatus: translateHerbStockAdjustStatus,
        patientPackageItemStatus: translatePatientPackageItemStatus,
        paymentMethod: translatePaymentMethodTypeAbbr,
        id: idFilter,
        ...numbersFilter,
        // Id
        appointmentId: idGenerator.formatAppointmentId,
        consultationId: idGenerator.formatConsultationId,
        prescriptionId: idGenerator.formatPrescriptionIdV2,
        prescriptionViolation: (value: PrescriptionViolation) => {
            return translatePrescriptionViolation(value);
        },

        usagePrefix: getUsageTextPrefix,
    }
}