<script lang="ts">
import { eventEmitter } from "@/services/events";
import { Options, Vue, Prop } from "vue-property-decorator";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
import { PrescriptionRedispenseConfig, translatePrescriptionViolation } from "@model/models";
import date from "@model/utils/date";
import { PaymentService } from "@/services";

@Options({
    components: {},
})
export default class PatientHistoryTabPrescription extends Vue {
    store = usePatientHistoryDataviewStore();

    @Prop({ default: false })
    redispense: boolean;

    get consultation() {
        return this.store.currentConsultation?.consultation;
    }

    get prescriptions() {
        return this.store.currentConsultation?.prescriptions;
    }

    get currentPrescription() {
        return this.store.currentPrescription ?? null;
    }

    get shouldEnableBreakdown() {
        return this.store.currentPrescription?.is_pharmacy;
    }

    getText(prescription?: {
        redispense_config?: Partial<PrescriptionRedispenseConfig>;
        can_redispense?: boolean;
    }): string {
        let text = [];
        if (prescription) {
            if (prescription.can_redispense) {
                if (prescription.redispense_config?.expires_at) {
                    text.push(`可重配至 ${date.formatDate(prescription.redispense_config.expires_at)}`);
                }
                if (prescription.redispense_config?.limit) {
                    text.push(`最多可重配 ${prescription.redispense_config.limit} 次`);
                }
                if (text.length === 0) {
                    return '可重配';
                }
                return text.join("，");
            }
            return "不可重配";
        }
        return '';
    }

    get canRedispense() {
        const canRedispense = this.store.currentPrescription?.can_redispense ?? false;
        if (canRedispense) {
            // TODO: redispense count
            return this.store.currentPrescription.redispense_config.expires_at >= new Date();
        }
        return false;
    }

    get currentPrescriptionIssueReason() {
        return this.currentPrescription?.issue?.reason ?? "";
    }

    get currentPrescriptionIssues() {
        if (this.currentPrescription?.issue) {
            return [...new Set(this.currentPrescription?.issue.items.map((x) => translatePrescriptionViolation(x.violation)))].join(", ");
        }
        return "";
    }

    handlePaginationIndexChange(e) {
        this.store.prescriptionIndex = e;
    }

    handleClickRedispense() {
        eventEmitter.emit("patient-redispense-detail-dialog:open", this.store.currentPrescription, this.consultation.id, this.store.prescriptionIndex);
    }

    async handleClickBreakdown() {
        const val = await PaymentService.breakdownPrescription({
            clinic_id: this.currentPrescription.clinic_id,
            prescriptions: [
                {
                    usage: this.currentPrescription.usage,
                    items: this.currentPrescription.items
                }
            ],
        });
        eventEmitter.emit('payment-breakdown-view-dialog:open', val);
    }
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-prescription(label="處方")
    .tab-container(v-if="currentPrescription")
        el-row
            el-col(:span="8")
                el-form-item(label="診症編號" prop="id")
                    .data {{ $filters.consultationId(consultation.id) }}

            el-col(:span="8")
                el-form-item(label="主診醫師" prop="code")
                    .data {{ consultation.doctor_name }}
        el-row
            el-col(:span="8")
                el-form-item(label="到診診所" prop="code")
                    .data {{ consultation.appointment?.clinic?.display_name }}

        el-row.prescription-area
            el-col
                el-row
                    el-col(:span="8")
                        el-pagination(:current-page="store.prescriptionIndex" layout="prev, pager, next" small :page-size="1" :total="prescriptions.length" @current-change="handlePaginationIndexChange")
                    el-col(:span="8")
                        div(v-if="currentPrescriptionIssueReason") 加簽 - 處方問題: {{ currentPrescriptionIssues }} 
                    el-col(:span="8")
                        div(v-if="currentPrescriptionIssueReason") 處方原因: {{ currentPrescriptionIssueReason }}
            el-col
                el-row
                    el-col(:span="8")
                        el-form-item(label="處方診所" prop="code")
                            .data {{ currentPrescription?.clinic_name }}
                    el-col(:span="8" v-if="shouldEnableBreakdown")
                        el-button(type="primary" @click="handleClickBreakdown" :disabled="!shouldEnableBreakdown") 拆解收費

            el-col
                el-row
                    el-col
                        el-table(:data="currentPrescription.items" :height="300")
                            el-table-column(label="名稱" width="200")
                                template(#default="scope")
                                    div {{ scope.row.name }}
                            el-table-column(label="品牌" width="75")
                                template(#default="scope")
                                    div {{ scope.row.brand?.label }}
                            el-table-column(label="數量" width="75")
                                template(#default="scope")
                                    div {{ $filters.decimal1(scope.row.amount) }}
                            el-table-column(label="單位" width="75")
                                template(#default="scope")
                                    div {{ scope.row.unit }}
                            el-table-column(label="種類" width="75")
                                template(#default="scope")
                                    div {{ $filters.herbType(scope.row.type) }}
                            el-table-column(label="倍" width="75")
                                template(#default="scope")
                                    div {{ scope.row.ratio }}
                            el-table-column(label="煎法" width="100")
                                template(#default="scope")
                                    div {{ scope.row.dispense_method }}
            el-col
                el-row(:gutter="5")
                    el-col(:span="2")
                    el-col(:span="1")
                        el-checkbox(v-model="currentPrescription.usage.is_required" disabled)
                    el-col(:span="5")
                        el-radio(v-model="currentPrescription.usage.take" label="internal" disabled) 內服
                        el-radio(v-model="currentPrescription.usage.take" label="external" disabled) 外用
                    el-col(:span="2")
                        el-form-item
                            el-input(v-model="currentPrescription.usage.day" :min="0" disabled type="number")
                    el-col.flex.center(:span="2")
                        .mb5 天, 每天 
                    el-col(:span="2")
                        el-form-item
                            el-input(v-model="currentPrescription.usage.times" :min="0" disabled type="number")
                    el-col.flex.center(:span="1")
                        .mb5 次
                    el-col.flex.center(:span="1")
                    el-col(:span="1")
                        el-checkbox(v-model="currentPrescription.usage.is_multiple_per_day" disabled)
                    el-col.flex.center(:span="1")
                        .mb5 每次
                    el-col(:span="2")
                        el-form-item
                            el-input(type="number" v-model="currentPrescription.usage.pack" :min="0" disabled)
                    el-col(:span="3")
                        el-select(v-model="currentPrescription.usage.pack_unit" disabled)
                            el-option(key="包" label="包" value="包")
                            el-option(key="碗" label="碗" value="碗")
                            el-option(key="劑" label="劑" value="劑")

                el-form-item(label="用法")
                    el-input(v-model="currentPrescription.usage.remark" name="usage" disabled)
                        template(#prepend) 
                            template(v-if="currentPrescription") (處方 {{ (currentPrescription.index + 1) }}) 
                            | {{ $filters.usagePrefix(currentPrescription.usage) }}

                el-form-item(label="禁忌")
                    el-input(v-model="currentPrescription.caution" name="caution" disabled)

                el-form-item(label="備註")
                    el-input(v-model="currentPrescription.remark" type="textarea" name="remark" disabled)

                el-form-item(label="重配")
                    span {{ getText(currentPrescription) }}

                Button(v-if="redispense" type="primary" @click="handleClickRedispense") {{ canRedispense ? '重配' : '重配(需咨詢醫師)' }}

    .tab-container(v-else)
        div 沒有處方
PaymentBreakdownViewDialog
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.tab-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: relative;
    max-width: 1200px;
}

.prescription-area {
    border-top: 1px rgb(145, 145, 145) solid;
    padding-top: 5px;
}

.el-form-item {
    margin: 0;
    align-items: center;

    :deep(.el-form-item__label) {
        align-items: center;
        text-align: center;
        width: 120px;
    }
}
</style>
