import { defaultLocale, Locale } from "@model/constants";

export const _paymentMethodType = [
    "unselected",
    "cash",
    "eps",
    "bank",
] as const;

export type PaymentMethodType = typeof _paymentMethodType[number];

const map: Record<PaymentMethodType, { label: string, meta: { translate: Record<Locale, string> } }> = {
    unselected: {
        label: '請選擇',
        meta: {
            translate: {
                zh: '請選擇',
                en: 'Please select',
            }
        }
    },
    cash: {
        label: '現金',
        meta: {
            translate: {
                zh: '現金',
                en: 'Cash',
            }
        }
    },
    eps: {
        label: 'EPS',
        meta: {
            translate: {
                zh: 'EPS',
                en: 'EPS',
            }
        }
    },
    bank: {
        label: '銀行轉帳/轉數快',
        meta: {
            translate: {
                zh: '銀行轉帳/轉數快',
                en: 'Bank Transfer',
            }
        }
    },
}

export const translatePaymentMethodType = (type: PaymentMethodType, locale: Locale = defaultLocale) => {
    return map[type].meta.translate[locale];
}

export const translatePaymentMethodTypeAbbr = (type: PaymentMethodType) => {
    if (type === 'unselected' || !type) {
        return '-';
    }
    return map[type].label.substring(0, 2);
}