import { BaseService } from "./base.service";
import { Herb, HerbStockAdjust } from "@model/entities";
import { StockOrderHerbInfoVO } from "@model/vos";
import { PaginatedResponse } from "@model/models";
import { AdminHerbStockAdjustCreateDTO } from "@model/dtos";

class Service extends BaseService {
    async herbInfo(herbId: Herb['id']): Promise<StockOrderHerbInfoVO> {
        return this.get(`/one/herb/${herbId}`);
    }

    async query(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }

    async queryHerb(queryParams): Promise<PaginatedResponse<any>> {
        return this.get(`/query/herb?${this.parseQuery(queryParams)}`);
    }

    async cancel(id: HerbStockAdjust['id']) {
        return this.delete(``, { id });
    }

    async update(id: HerbStockAdjust['id'], body: AdminHerbStockAdjustCreateDTO): Promise<HerbStockAdjust> {
        return this.post(`/${id}`, body);
    }

    async updateTmp(id: HerbStockAdjust['id'], body: AdminHerbStockAdjustCreateDTO): Promise<HerbStockAdjust> {
        return this.post(`/tmp/${id}`, body);
    }

    async validate(id: HerbStockAdjust['id'], body: AdminHerbStockAdjustCreateDTO): Promise<HerbStockAdjust> {
        return this.post(`/validate/${id}`, body);
    }

    async validateTmp(id: HerbStockAdjust['id'], body: AdminHerbStockAdjustCreateDTO): Promise<HerbStockAdjust> {
        return this.post(`/validate/tmp/${id}`, body);
    }

    async createTmp(body: any): Promise<HerbStockAdjust> {
        try {
            return await this.put('/tmp', body);
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }
}

export const AdminHerbStockAdjustService = new Service('/admin/herb-stock/adjust');