import { Labels } from "../common";

const _herbStockAdjustStatuses = [
    "cancelled",
    "temporary",
    "pending",
    "completed",
] as const;

export type HerbStockAdjustStatus = typeof _herbStockAdjustStatuses[number];


export const herbStockAdjustStatusMap: Labels<HerbStockAdjustStatus, never> = {
    cancelled: {
        label: "取消調整",
        color: "#ff1904",
        show: false,
    },
    temporary: {
        label: "暫存調整",
        color: "#4fe74a",
    },
    pending: {
        label: "等侯覆核",
        color: "#4fe74a",
        show: false,
    },
    completed: {
        label: "完成調整",
        color: "#f1f1f1",
    },
};

export const translateHerbStockAdjustStatus = (status: HerbStockAdjustStatus) => {
    return herbStockAdjustStatusMap[status]?.label ?? '';
}