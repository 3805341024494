class APILockService {
    locked: boolean;
    lockToken: null;
    resolveWaitPromise: () => void;

    constructor() {
        this.locked = false
        this.lockToken = null;
    }

    lock = (lockToken) => {
        if (this.locked) {
            throw new Error('用戶發送過多請求，請耐心等候並稍後再試');
        }
        this.lockToken = lockToken;
        this.locked = true;
    }

    isLocked = (config) => {
        if (config.lockToken === this.lockToken) { // We do not want to block the API request which placed the lock in first place
            return false;
        }
        return this.locked;
    }

    releaseLock = (lockToken) => {
        if (lockToken === this.lockToken) {
            this.locked = false;
            if (typeof this.resolveWaitPromise === 'function') {
                this.resolveWaitPromise();
            }
        }
    }

    waitTillUnlocked = () => {
        return new Promise<void>((resolve, reject) => {
            this.resolveWaitPromise = () => {
                resolve();
            }
            setTimeout(() => {
                this.locked = false;
                resolve();
            }, 300000);// timeout after 5 mins
        });
    }
}

export const axiosLock = new APILockService();