import { eventEmitter, EventType, setupEventEmitter } from '@/services/events';
import { createDecorator } from 'vue-class-component';
/**
 * Decorator for events
 * @param path the path or the expression to observe
 * @param watchOptions
 */
export function ListenEvent(path: EventType) {
    return createDecorator((componentOptions, propertyKey) => {
        const originalMethod = componentOptions.methods[propertyKey];
        const orginalMounted = componentOptions.mounted ?? (() => { });
        const orginalBeforeUnmount = componentOptions.beforeUnmount ?? (() => { });

        // const method = (...args) => originalMethod.apply(options, args)

        let handler;
        componentOptions.mounted = function wrapper() {
            if (!this) {
                return;
            }
            handler = (...args) => {
                originalMethod.apply(this, args)
            };
            orginalMounted.apply(this);
            if (!eventEmitter.on) {
                setupEventEmitter();
            }
            eventEmitter.on(path, handler);
        }

        componentOptions.beforeUnmount = function wrapper() {
            orginalBeforeUnmount.apply(this);
            if (eventEmitter.off) {
                eventEmitter.off(path, handler);
            }
        }
    });
}
