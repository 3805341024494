import WebStorageCache from 'web-storage-cache';

type CacheType = 'sessionStorage' | 'localStorage';

export const useCache = (type: CacheType = 'localStorage') => {
    const wsCache: WebStorageCache = new WebStorageCache({
        storage: type
    });

    const getCacheOrDefault = (key, defaultValue) => {
        const v = wsCache.get(key);
        if (v) {
            return v;
        }
        return defaultValue;
    }

    const getCacheOrCallApi = async (key, callback, { flush = false, expireInSecond = 3600 * 24 }) => {
        const v = wsCache.get(key);
        if (!flush && v) {
            return v;
        }
        const res = await callback();
        wsCache.set(key, res, {
            exp: expireInSecond,
        });
        return res;
    }

    return {
        wsCache,
        getCacheOrDefault,
        getCacheOrCallApi,
    };
}