import { defineStore } from 'pinia';
import { store } from '@/stores';
import { HerbType, SelectItem, herbTypes, translateHerbType, herbPricingTypes, HerbPricingType, translateHerbPricingType, PaymentMethodType } from '@model/models';
import { Clinic, Doctor, HerbBrand, HerbFunction, HerbUnit, User, UserGroup } from "@model/entities";
import { ClinicService, DoctorService, UserGroupService, UserService, HerbBrandService, HerbFunctionService, HerbUnitService, PaymentService, PatientService } from '@/services';
import { eventEmitter } from '@/services/events';
import { useUserStore } from './user.store';
import { useCache } from '@/hooks/useCache';

export interface SelectItemState {
    _userGroups: SelectItem<UserGroup['id']>[];
    _clinics: SelectItem<Clinic['id']>[];
    _doctors: SelectItem<Doctor['id']>[];
    _users: SelectItem<User['id']>[];
    _herbBrands: SelectItem<HerbBrand['id']>[];
    _herbUnits: SelectItem<HerbUnit['id']>[];
    _herbFunctions: SelectItem<HerbFunction['id']>[];
    _doctorsOfCurrentClinic: Doctor[];
    _paymentMethods: SelectItem<PaymentMethodType>[];
    _tags: string[];
}

const defaultState: SelectItemState = {
    _userGroups: null,
    _paymentMethods: null,
    _clinics: null,
    _doctors: null,
    _users: null,
    _herbBrands: null,
    _herbUnits: null,
    _herbFunctions: null,
    _doctorsOfCurrentClinic: null,
    _tags: null,
}

export const useSelectItemStore = defineStore({
    id: 'select-item',
    state: (): SelectItemState => defaultState,
    persist: {
        enabled: true,
    },
    getters: {
        clinics(): SelectItem<Clinic['id']>[] {
            return this._clinics ?? [];
        },
        userGroups(): SelectItem<UserGroup['id']>[] {
            return this._userGroups;
        },
        herbTypes(): SelectItem<HerbType>[] {
            return herbTypes.map(x => ({
                label: translateHerbType(x),
                value: x,
            }));
        },
        herbPricingTypes(): SelectItem<HerbPricingType>[] {
            return herbPricingTypes.map(x => ({
                label: translateHerbPricingType(x),
                value: x,
            }));
        }
    },
    actions: {
        async init() {
            const user = useUserStore();
            if (user.isLoggedIn) {
                await this.getUserGroups();
                eventEmitter.on('user-group.updated', async () => {
                    this._userGroups = await UserGroupService.getItems();
                });
                eventEmitter.on('clinics.updated', async () => {
                    this._clinics = await ClinicService.getItems();
                });
                eventEmitter.on('user:created', async () => {
                    this._users = await UserService.getItems();
                });
                eventEmitter.on('current-clinic:changed', async () => {
                    this._doctorsOfCurrentClinic = await ClinicService.listDoctorsOfCurrentClinic();
                });

                await Promise.all([
                    this.getClinics(),
                    this.getDoctors(),
                    this.getDoctorsOfClinic(),
                ])

                eventEmitter.on('global:clear-cache', async () => {
                    Object.keys(localStorage)
                        .filter(x => x.startsWith('SelectItemStore:'))
                        .forEach(x => localStorage.removeItem(x))
                    for (const k of Object.keys(defaultState)) {
                        this[k] = null;
                    }
                });
            }
        },
        async getUserGroups() {
            if (!this._userGroups) {
                const cache = useCache();
                this._userGroups = await cache.getCacheOrCallApi(`SelectItemStore:_userGroups`, async () => {
                    return await UserGroupService.getItems();
                }, {
                    expireInSecond: 3600 * 24
                });
            }
            return this._userGroups;
        },
        async getClinics() {
            if (!this._clinics) {
                const cache = useCache();
                this._clinics = await cache.getCacheOrCallApi(`SelectItemStore:_clinics`, async () => {
                    return await ClinicService.getItems();
                }, {
                    expireInSecond: 3600 * 24
                });
            }
            return this._clinics;
        },
        async getUsers() {
            if (!this._users) {
                this._users = await UserService.getItems();
            }
            return this._users;
        },
        async getDoctors() {
            if (!this._doctors) {
                const cache = useCache();
                this._doctors = await cache.getCacheOrCallApi(`SelectItemStore:_doctors`, async () => {
                    return await DoctorService.getItems();
                }, {
                    expireInSecond: 3600
                });
            }
            return this._doctors;
        },
        async getTags() {
            if (!this._tags) {
                const cache = useCache();
                this._tags = await cache.getCacheOrCallApi(`SelectItemStore:_tags`, async () => {
                    return await PatientService.getTags();
                }, {
                    expireInSecond: 600
                });
            }
            return this._tags;
        },
        async getHerbBrands() {
            if (!this._herbBrands) {
                const cache = useCache();
                this._herbBrands = await cache.getCacheOrCallApi(`SelectItemStore:_herbBrands`, async () => {
                    return await HerbBrandService.getItems();
                }, {
                    expireInSecond: 3600
                });
            }
            return this._herbBrands;
        },
        async getHerbUnits() {
            if (!this._herbUnits) {
                const cache = useCache();
                this._herbUnits = await cache.getCacheOrCallApi(`SelectItemStore:_herbUnits`, async () => {
                    return await HerbUnitService.getItems();
                }, {
                    expireInSecond: 3600
                });
            }
            return this._herbUnits;
        },
        async getHerbFunctions() {
            if (!this._herbFunctions) {
                const cache = useCache();
                this._herbFunctions = await cache.getCacheOrCallApi(`SelectItemStore:_herbFunctions`, async () => {
                    return await HerbFunctionService.getItems();
                }, {
                    expireInSecond: 3600
                });
            }
            return this._herbFunctions;
        },
        async getPaymentMethods() {
            if (!this._paymentMethods) {
                const cache = useCache();
                this._paymentMethods = await cache.getCacheOrCallApi(`SelectItemStore:_paymentMethods`, async () => {
                    return await PaymentService.getItems();
                }, {
                    expireInSecond: 3600
                });
            }
            return this._paymentMethods;
        },
        async getDoctorsOfClinic(options?: { flush?: boolean }) {
            const {
                flush = false,
            } = options ?? {};

            if (!this._doctorsOfCurrentClinic) {
                const userStore = useUserStore();
                const cache = useCache();
                this._doctorsOfCurrentClinic = await cache.getCacheOrCallApi(`SelectItemStore:_doctorsOfCurrentClinic:${userStore.currentClinicId}`, async () => {
                    return ClinicService.listDoctorsOfCurrentClinic();
                }, {
                    flush,
                });
            }
            const doctors = await this.getDoctors();
            return doctors.filter(x => this._doctorsOfCurrentClinic.map(x => x.id).includes(x.value));
        },
        async findDoctor(doctorId: number) {
            return (await this.getDoctors()).filter(x => x.value === doctorId)[0]?.label;
        },
        async findClinic(clinicId: number) {
            return (await this.getClinics()).filter(x => x.value === clinicId)[0]?.label;
        },
        async findHerbBrandOption(brand_name: string) {
            const match = (await this.getHerbBrands()).filter(x => x.label === brand_name);
            if (match.length === 0) {
                return null;
            }
            return match[0];
        },
        findUserGroupName(userGroupId: UserGroup['id']): SelectItem<UserGroup['id']> {
            return this.userGroups.filter(x => x.value === userGroupId)[0];
        },
        findClinicName(clinicId: Clinic['id']): string {
            return this.clinics.find(x => x.value === clinicId)?.label ?? null;
        }
    }
});

export const useSelectItemStoreWithOut = () => {
    return useSelectItemStore(store);
};
