export const tryTranslateConstraints = (constraintMessage) => {
    for(const key of Object.keys(map)) {
        const match = constraintMessage.match(map[key].regexp);
        if(match) {
            return buildErrorMessage(match[1], key);
        }
    }
    return constraintMessage;
}

const buildErrorMessage = (paramName: string, matchType: string) => {
    let name = paramName;
    if(paramName in paramNameMap) {
        name = paramNameMap[name];
    }
    return `${map[matchType].message.replace('###', name)}`;
}

const map = {
    dateString: {
        regexp: /(.*) must be a valid ISO 8601 date string/i,
        message: '日期無效：###',
    },
};

const paramNameMap = {
    dob: "出生日期",
};
