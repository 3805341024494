import { Locale } from "@model/constants";
import { AppointmentType } from "@model/models";

export const DEFAULT_AUTO_REMINDER_TEMPLATES = {
    reminder_consult_chi_v2: {
        lang: 'zh_HK',
        supportedLanguages: ['zh_HK'],
        appointmentType: ['new', 'old', 'long', 'massage', 'acu'] as AppointmentType[],
        params: ['patient', 'date', 'time', 'location', 'doctor', 'address'],
    },
    reminder_facetime_chi_v3: {
        lang: 'zh_HK',
        supportedLanguages: ['zh_HK'],
        appointmentType: ['vc'] as AppointmentType[],
        params: ['patient', 'date', 'time', 'doctor'],
    },
    reminder_moon_consultation_chi_v3: {
        lang: 'zh_HK',
        supportedLanguages: ['en', 'zh_HK'],
        appointmentType: ['confinement'] as AppointmentType[],
        params: ['patient', 'date', 'time', 'location', 'doctor', 'address'],
    },
    reminder_moon_facetime_chi_v2: {
        lang: 'zh_HK',
        supportedLanguages: ['en', 'zh_HK'],
        appointmentType: ['confinementvc'] as AppointmentType[],
        params: ['patient', 'date', 'time', 'doctor'],
    },

    //
    reminder_consult_eng_v2: {
        lang: 'en',
        supportedLanguages: ['en'],
        appointmentType: ['new', 'old', 'long', 'massage', 'acu'] as AppointmentType[],
        params: ['patient', 'date', 'time', 'location', 'doctor', 'address'],
    },
    reminder_facetime_eng_v2: {
        lang: 'en',
        supportedLanguages: ['en'],
        appointmentType: ['vc'] as AppointmentType[],
        params: ['patient', 'date', 'time', 'doctor'],
    },
} as const;

export const OTHER_AUTO_REMINDER_TEMPLATES = {
    '85246788904': {
        reminder_consult_chi_v1: {
            lang: 'zh_HK',
            supportedLanguages: ['zh_HK'],
            appointmentType: ['new', 'old', 'long', 'massage', 'acu'] as AppointmentType[],
            params: ['patient', 'date', 'time', 'location', 'doctor', 'address'],
        },
        reminder_facetime_chi_v1: {
            lang: 'zh_HK',
            supportedLanguages: ['zh_HK'],
            appointmentType: ['vc'] as AppointmentType[],
            params: ['patient', 'date', 'time', 'doctor'],
        },

        //
        reminder_consult_eng_v1: {
            lang: 'en',
            supportedLanguages: ['en'],
            appointmentType: ['new', 'old', 'long', 'massage', 'acu'] as AppointmentType[],
            params: ['patient', 'date', 'time', 'location', 'doctor', 'address'],
        },
        reminder_facetime_eng_v1: {
            lang: 'en',
            supportedLanguages: ['en'],
            appointmentType: ['vc'] as AppointmentType[],
            params: ['patient', 'date', 'time', 'doctor'],
        },
    }
};


export const autoReminderLanguageOptions = [
    {
        label: '中文',
        value: 'zh_HK',
    },
    {
        label: 'English',
        value: 'en',
    },
];

export const autoReminderTemplateOptions = Object.keys(DEFAULT_AUTO_REMINDER_TEMPLATES).map((key) => {
    return {
        label: key,
        value: key,
    }
});

export const getAutoReminderTemplateLanguage = (key: keyof typeof DEFAULT_AUTO_REMINDER_TEMPLATES, from?: string) => {
    if (from && OTHER_AUTO_REMINDER_TEMPLATES[from]) {
        return OTHER_AUTO_REMINDER_TEMPLATES[from][key]?.lang ?? 'zh_HK';
    }
    return DEFAULT_AUTO_REMINDER_TEMPLATES[key]?.lang ?? 'zh_HK';
}

export const getTemplateByAppointmentType = (type: AppointmentType, locale: Locale = 'zh', from?: string): [keyof typeof DEFAULT_AUTO_REMINDER_TEMPLATES | null, (typeof DEFAULT_AUTO_REMINDER_TEMPLATES)[keyof typeof DEFAULT_AUTO_REMINDER_TEMPLATES] | null] => {
    const lang = locale === 'en' ? 'en' : 'zh_HK';
    if (from && OTHER_AUTO_REMINDER_TEMPLATES[from]) {
        const res = Object.keys(OTHER_AUTO_REMINDER_TEMPLATES[from]).find((key) => OTHER_AUTO_REMINDER_TEMPLATES[from][key].appointmentType.includes(type) && OTHER_AUTO_REMINDER_TEMPLATES[from][key].supportedLanguages.includes(lang)) as any ?? null;
        return [res, OTHER_AUTO_REMINDER_TEMPLATES[from][res]];
    }
    const res = Object.keys(DEFAULT_AUTO_REMINDER_TEMPLATES).find((key) => DEFAULT_AUTO_REMINDER_TEMPLATES[key].appointmentType.includes(type) && DEFAULT_AUTO_REMINDER_TEMPLATES[key].supportedLanguages.includes(lang)) as any ?? null;
    return [res, DEFAULT_AUTO_REMINDER_TEMPLATES[res]];
}

export const mapTemplateParams = (template: (typeof DEFAULT_AUTO_REMINDER_TEMPLATES)[keyof typeof DEFAULT_AUTO_REMINDER_TEMPLATES], {
    appointment_date,
    appointment_timeslot,
    clinic_name,
    clinic_location,
    clinic_location_hint,
    doctor_name,
    patient_name,
}: {
    appointment_date: string | Date,
    appointment_timeslot: string,
    clinic_name: string,
    clinic_location: string,
    clinic_location_hint: string,
    doctor_name: string,
    patient_name: string,
}) => {
    return template.params.map((x: string) => {
        switch (x) {
            case 'date':
                return {
                    key: 'date',
                    value: appointment_date as string,
                };
            case 'time':
                return {
                    key: 'time',
                    value: appointment_timeslot,
                };
            case 'location':
                return {
                    key: 'location',
                    value: clinic_name,
                };
            case 'patient':
                return {
                    key: 'patient',
                    value: patient_name,
                };
            case 'address':
                return {
                    key: 'address',
                    value: `${clinic_location} ${clinic_location_hint}`,
                };
            case 'doctor':
                return {
                    key: 'doctor',
                    value: doctor_name
                };
        }
    })
}