import qs from "qs";
import { axDelete, axGet, axPost, axPut } from "./axios";
import { SelectItem } from '@model/models';
import { AdvancedResponse } from '@model/responses';
import { constructError, ErrorCode } from '@model/error';
import { ListQueryDTO, SortableItemsOrderUpdateDTO } from '@model/dtos';
import { downloadFile } from '@/utils/download-file';
import { notify } from "@/utils/notify";
import { dynamicEnv } from "@/utils/dynamic-env";
import { os } from "../utils/os";

export abstract class BaseService {
    constructor(public basePath: string) {

    }

    protected parseQuery(query: any, mustHaveKeys?: string[]) {
        if (!query) {
            return '';
        }
        for (const key of Object.keys(query)) {
            if (query[key] === null || query[key] === '') {
                delete query[key];
            }
        }
        if (mustHaveKeys) {
            for (const k of mustHaveKeys) {
                if (!(k in query)) {
                    throw Error(`Key \`${k}\` does not exist`);
                }
            }
        }
        return qs.stringify(query);
    }

    handleCreationError(e: ErrorCode) {
        return false;
    }

    async one<T>(id: string | number) {
        return await axGet<T>({
            url: `${this.basePath}/one/${id}`,
        });
    }

    async oneName(id: string | number) {
        return await axGet<string>({
            url: `${this.basePath}/one/name/${id}`,
        });
    }

    async oneDeep<T>(id: string | number) {
        return await axGet<T>({
            url: `${this.basePath}/one/deep/${id}`,
        });
    }

    async list<T>(query?: ListQueryDTO) {
        return await axGet<T[]>({
            url: `${this.basePath}?${this.parseQuery(query)}`,
        });
    }

    async listDeep<T>(query?: ListQueryDTO): Promise<T[]> {
        return await axGet<T[]>({
            url: `${this.basePath}/deep?${this.parseQuery(query)}`,
        });
    }

    async setActive<T>(id: string | number, active: boolean): Promise<T[]> {
        return await axPost<T[]>({
            url: `${this.basePath}/active/${id}`,
            data: { active },
        });
    }

    async beforeCreate<T>(body: any) {
        return body;
    }

    async create<T>(body: any) {
        try {
            body = await this.beforeCreate(body);
            return await axPut<T>({
                url: `${this.basePath}`,
                data: body,
            });
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }

    async update<T>(body: any) {
        try {
            return await axPost<T>({
                url: `${this.basePath}`,
                data: body,
            });
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }

    async updateOrder<T>(body: SortableItemsOrderUpdateDTO) {
        try {
            return await axPost<T>({
                url: `${this.basePath}/order`,
                data: body,
            });
        } catch (e) {
            if (!this.handleCreationError(e)) {
                throw e;
            }
        }
    }

    async deactivate(id: string | number): Promise<string | number> {
        return this.delete(`/${id}`);
    }

    async reactivate(herbId: string | number): Promise<string | number> {
        return this.post(`/reactivate/${herbId}`);
    }

    async autocomplete<T>(queryString: string, type?: string): Promise<T> {
        return this.get(`/autocomplete/${type ? `${type}/` : ''}${queryString}`);
    }

    itemLoading = null;
    async getItems() {
        try {
            if (!this.itemLoading) {
                this.itemLoading = this.get<SelectItem<any>[]>(`/items`);
            }
            return await this.itemLoading;
        } catch (e) {
            constructError(e);
        } finally {
            this.itemLoading = null;
        }
    }

    protected async get<T>(path: string, option?: { mutex?: boolean }) {
        return await axGet<T>({
            url: `${this.basePath}${path}`,
            mutex: option?.mutex,
        });
    }

    protected async getAdvanced<T>(path: string): Promise<T> {
        const res = await axGet<AdvancedResponse<T>>({
            url: `${this.basePath}${path}`,
        });
        if (!res.state.ok || res.state.errors.length > 0) {
            throw res.state.errors[0];
        }
        if (res.state.warns.length > 0) {
            res.state.warns.forEach(x => notify.error(x.message));
        }
        return res.data;
    }

    protected async postAdvanced<T>(path: string, body?: any): Promise<T> {
        const res = await axPost<AdvancedResponse<T>>({
            url: `${this.basePath}${path}`,
            data: body,
        });
        if (!res.state.ok || res.state.errors.length > 0) {
            throw res.state.errors[0];
        }
        if (res.state.warns.length > 0) {
            res.state.warns.forEach(x => notify.error(x.message));
        }
        return res.data;
    }

    protected async deleteAdvanced<T>(path: string, body?: any): Promise<T> {
        const res = await axDelete<AdvancedResponse<T>>({
            url: `${this.basePath}${path}`,
            data: body,
        });
        if (!res.state.ok || res.state.errors.length > 0) {
            throw res.state.errors[0];
        }
        if (res.state.warns.length > 0) {
            res.state.warns.forEach(x => notify.error(x.message));
        }
        return res.data;
    }

    protected async download(path: string, filename: string = 'untitiled') {
        const res = await axGet({
            url: `${this.basePath}${path}`,
            responseType: 'blob',
        });
        downloadFile(res, filename);
    }

    protected open(path: string) {
        window.open(`${dynamicEnv('BACKEND_ENDPOINT')}${this.basePath}${path}`, '_blank');
    }

    protected attemptOpenWithEdge(path: string) {
        if (os.isWindows()) {
            window.open(`microsoft-edge:${dynamicEnv('BACKEND_ENDPOINT')}${this.basePath}${path}`, '_blank');
        } else {
            this.open(path);
        }
    }

    protected async post<T, U>(path: string, body?: U) {
        return await axPost<T>({
            url: `${this.basePath}${path}`,
            data: body,
        });
    }

    protected async postForm<T, U>(path: string, body?: U) {
        return await axPost<T>({
            url: `${this.basePath}${path}`,
            headersType: 'multipart/form-data',
            data: body,
        });
    }

    protected async put<T, U>(path: string, body?: U) {
        return await axPut<T>({
            url: `${this.basePath}${path}`,
            data: body,
        });
    }

    protected async delete<T, U>(path: string, body?: U) {
        return await axDelete<T>({
            url: `${this.basePath}${path}`,
            data: body,
        });
    }
}