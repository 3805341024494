import { defineStore } from 'pinia';
import { initStore, setupStore, store } from '@/stores';
import { UserPermissionType, UserProfile } from '@model/models';
import { UserLoginDTO } from '@model/dtos';
import { UserService } from '@/services';
import { useCache } from '@/hooks/useCache';
import { Clinic, Doctor } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { SelectItem } from "@model/models";
import { useRouteStore } from './route';
import { Router } from "vue-router";
import { useNavigationStore } from './navigation.store';
import { LocalStorage } from '@/utils/local-storage';
import { rollbarInstance } from "@/utils/handleError";

const { wsCache } = useCache('localStorage');

export interface UserState {
    _user: UserProfile,
    _isLoggedIn: boolean,
    channel: BroadcastChannel,
}

const defaultState: UserState = {
    _user: null,
    _isLoggedIn: false,
    channel: new BroadcastChannel('soschinmed-nbf'),
}

export const useUserStore = defineStore({
    id: 'user',
    state: (): UserState => defaultState,
    persist: {
        enabled: true,
    },
    getters: {
        v2SelectClinicId() {
            return LocalStorage.selectedClinic.get() ?? null;
        },
        cacheKey() {
            return 'user';
        },
        user(): UserProfile | null {
            return this._user;
        },
        doctor(): Doctor | null {
            return this._user?.doctor ?? null;
        },
        doctorName(): string {
            return this._user?.doctor?.display_name ?? '';
        },
        currentClinic(): UserProfile['currentClinic'] | null {
            return this._user?.currentClinic ?? null;
        },
        currentClinicIsPharmacy(): boolean {
            return this._user?.currentClinic?.is_pharmacy ?? false;
        },
        availableClinics(): SelectItem<Clinic["id"]>[] | null {
            return this._user?.availableClinics ?? null;
        },
        currentClinicId(): number | null {
            return this._user?.currentClinic?.id ?? null;
        },
        currentClinicName(): string {
            return this._user?.currentClinic?.meta.name_zh ?? '';
        },
        isLoggedIn(): boolean {
            return this._isLoggedIn;
        },
    },
    actions: {
        async init() {
            this.channel.onmessage = (ev) => {
                if (ev.data === 'current-clinic:changed') {
                    if (sessionStorage.getItem('soschinmed-nbf:sub-window') !== '1') {
                        window.location.reload();
                    }
                }
            };

            this._isLoggedIn = wsCache.get('isLoggedIn');
            if (this._isLoggedIn) {
                try {
                    this._user = await UserService.getProfile();
                } catch (e) {
                    try {

                        await UserService.refresh();
                        this._user = await UserService.getProfile();
                    } catch (e) {
                        this.setIsLoggedIn(false);
                        window.location.href = '/login';
                        wsCache.delete('isLoggedIn');
                    }
                }
                if (rollbarInstance?.configure) {
                    rollbarInstance.configure({
                        payload: {
                            person: {
                                id: this._user.id,
                                username: this._user.name,
                            },
                            custom: {
                                clinicId: this._user.currentClinic.id,
                                clinicIdV2: LocalStorage.selectedClinic.get(),
                            }
                        },
                    });
                }
                wsCache.set(this.cacheKey, this._user);
            }
        },
        async login(body: UserLoginDTO, routerSetupParams?: { router: Router; redirect?: string }) {
            LocalStorage.selectedClinic.set(body.clinic);
            await UserService.login(body);
            this.setIsLoggedIn(true);
            await initStore();
            if (routerSetupParams) {
                LocalStorage.selectedClinic.set(body.clinic);
                const routeStore = useRouteStore();
                routeStore.setupRouter({ ...routerSetupParams });
            }
        },
        async logout() {
            await UserService.logout();
        },
        async setCurrentClinic(clinicId: number, options?: { noRefresh: boolean }) {
            const {
                noRefresh = false,
            } = options ?? {};
            const navigation = useNavigationStore();
            await navigation.withFullscreenLoading(async () => {
                LocalStorage.selectedClinic.set(clinicId);
                await UserService.changeClinic(clinicId);
                this._user = await UserService.getProfile();
                // TODO: remove all current-clinic:changed listeners
                eventEmitter.emit('current-clinic:changed', this._user.currentClinic);
                if (!noRefresh && !navigation.isSubWindow) {
                    this.channel.postMessage('current-clinic:changed');
                    window.location.reload();
                }
            })
        },
        setIsLoggedIn(value: boolean) {
            this._isLoggedIn = value;
            wsCache.set('isLoggedIn', value);
            if (value === false) {
                this._user = null;
                wsCache.delete(this.cacheKey);
            }
        },
        hasPermission(permission: UserPermissionType | string): boolean {
            const user = this.user ?? wsCache.get(this.cacheKey);

            if (user === null) {
                return false;
            }

            if (user.permissions === null) {
                return false;
            }

            if (user.permissions.includes('all')) {
                return true;
            }

            if (user.permissions.filter(x => x.includes(permission)).length > 0) {
                return true;
            }

            if (user.permissions.includes(permission)) {
                return true;
            }

            {
                // payment.all will apply to payment.*
                const x = permission.split('.');
                x.pop();
                x.push('all');
                if (user.permissions.includes(x.join('.'))) {
                    return true;
                }
            }

            return false;
        },
    }
});
export const useUserStoreWithOut = () => {
    return useUserStore(store);
};
