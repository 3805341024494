<script lang="ts">
import { AppointmentService } from "@/services";
import { eventEmitter } from "@/services/events";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { Appointment } from "@model/entities";
import { AppointmentAttachmentType, appointmentAttachmentTypeItems, SelectItem } from "@model/models";
import { AppointmentAttachmentCreateDTO } from "@model/dtos";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { notify } from "@/utils/notify";
import { Options, Vue } from "vue-property-decorator";

@Options({
    components: {},
})
export default class AppointmentAttachmentCreateDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    appointmentId: Appointment["id"] = null;
    appointmentAttachmentTypeItems: SelectItem<AppointmentAttachmentType>[] = appointmentAttachmentTypeItems;

    formData: AppointmentAttachmentCreateDTO = {
        id: null,
        description: "",
        type: 'none',
        file: null,
        _files: [],
    };

    @ListenEvent("appointment-attachment-create-dialog:open")
    async handleOpen(appointmentId: Appointment["id"]) {
        this.appointmentId = appointmentId;
        this.formData.id = appointmentId;
        this.formData.description = "";
        this.formData.type = "none";
        this.formData.file = null;
        this.formData._files = [];
        this.open = true;
    }

    async handleClickSubmit() {
        if (this.appointmentId) {
            try {
                if(this.formData._files.length === 0) {
                    return notify.error('未選取檔案');
                }
                if(!this.formData.description) {
                    return notify.error("請填寫備註");
                }
                this.loading = true;
                const res = await AppointmentService.createAttachment({
                    id: this.formData.id,
                    description: this.formData.description,
                    type: this.formData.type,
                    file: (this.formData._files[0] as any).raw,
                });
                eventEmitter.emit("appointment-attachment:updated", res.id);
                this.open = false;
                notify.saved();
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        }
    }
        
    handleClickAddType(item: SelectItem<AppointmentAttachmentType>) {
        this.formData.type = item.value;
        this.formData.description = this.formData.description + item.label;
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        div 加入預約附件
    .body
        el-form(label-position="top")
            //- el-form-item(label="附件類別")
            //-     SelectAppointmentAttachmentType(v-model="formData.type")
            el-form-item(label="附件備註")
                template(v-for="item in appointmentAttachmentTypeItems")
                    el-button(@click="handleClickAddType(item)") {{ item.label }}
                el-input(v-model="formData.description" type="textarea" :autosize="{minRows: 4}" required)
            el-form-item(label="附件")
                el-upload(v-model:fileList="formData._files" :limit="1" :auto-upload="false")    
                    el-button(type="primary") 選擇檔案
                    template(#tip)
                        .el-upload__tip 檔案大小上限:10MB

            Button(type="primary" @click="handleClickSubmit" :loading="loading") 儲存
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
