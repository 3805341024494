<script lang="ts">
import { eventEmitter } from "@/services/events";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { AppointmentAttachment } from "@model/entities";
import { Options, Vue, mixins } from "vue-property-decorator";
import { DialogMixin } from "@/mixins/dialog.mixin";
import { AppointmentAttachmentUpdateDTO } from '@model/dtos';
import { AppointmentService } from "@/services";

@Options({
    components: {},
})
export default class AppointmentAttachmentUpdateDialog extends mixins(Vue, DialogMixin) {
    user = useUserStore();
    open = false;
    loading = false;

    appointmentAttachment: AppointmentAttachment = null;

    formData: AppointmentAttachmentUpdateDTO = {
        id: null,
        description: null,
        type: null,
    };

    @ListenEvent("appointment-attachment-update-dialog:open")
    async handleOpen(appointmentAttachment: AppointmentAttachment) {
        if (!this.mx_isOpen) {
            this.appointmentAttachment = appointmentAttachment;
            this.formData = {
                id: this.appointmentAttachment.id,
                description: this.appointmentAttachment.description,
                type: this.appointmentAttachment.type,
            };
            this.mx_open();
        }
    }

    async handleSubmit() {
        try {
            this.mx_isLoading = true;
            const updatedAttachment = await AppointmentService.updateAttachment(this.formData);
            eventEmitter.emit("appointment-attachment:updated", this.appointmentAttachment.id);
            this.mx_success();
        } catch (e) {
            handleError(e);
        } finally {
            this.mx_isLoading = false;
        }
    }
}
</script>

<template lang="pug">
el-dialog(v-model="mx_isOpen" append-to-body width="600px" destroy-on-close)
    template(#header)
        div 修改附件
    .body
        el-form(label-position="top")
                el-form-item(label="附件類別")
                    SelectAppointmentAttachmentType(v-model="formData.type")
                el-form-item(label="附件備註")
                    el-input(v-model="formData.description" type="textarea" :autosize="{minRows: 4}" required)
                Button(type="primary" @click="handleSubmit" :loading="loading") 儲存
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
