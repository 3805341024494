<script lang="ts">
import { Options, Vue } from "vue-property-decorator";

@Options({
    components: {},
})
export default class Background extends Vue {}
</script>

<template lang="pug">
.background
    .background2
        main
            slot
</template>

<style scoped lang="scss">
.background {
    z-index: -9999;
    width: 100%;
    min-height: 100vh;
    background: rgb(255, 255, 255);
    background: linear-gradient(
            90deg,
            rgba(248, 255, 216, 0.8) 0%,
            rgba(248, 255, 216, 0.8) 340px,
            rgba(250, 255, 232, 0.8) 340px,
            rgba(255, 255, 255, 0.8) 100%
        );
}

.background2 {
    z-index: -9998;
    width: 100%;
    min-height: 100vh;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        55deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0.8) 100%
    );
}

main {
    z-index: 1;
}
</style>
