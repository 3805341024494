import { defaultLocale, Locale, Translation } from "@model/constants";
import { Appointment } from "@model/entities";
import { Labels } from "@model/models";
import date from "@model/utils/date";

// This is an ordered list of statuses
const _patientPackageItemStatus = [
    'pending',
    'ready',
    'held', 
    'consumed',
    'cancelled',
    'expired',
] as const;

export type PatientPackageItemStatus = typeof _patientPackageItemStatus[number];

export const patientPackageItemStatus: Labels<PatientPackageItemStatus, {
}> = {
    pending: {
        label: "未付款",
        color: "#fff",
    },
    ready: {
        label: "可以使用",
        color: "#fff",
    },
    held: {
        label: "保留中",
        color: "#fff",
    },
    consumed: {
        label: "已使用",
        color: "#fff",
    },
    cancelled: {
        label: "已取消",
        color: "#fff",
    },
    expired: {
        label: "已到期",
        color: "#fff",
    },
};

export const translatePatientPackageItemStatus = (type: PatientPackageItemStatus) => {
    return patientPackageItemStatus[type].label ?? type;
}

