import { createApp } from "vue";

import ElementPlus, { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";

import router, { setupRouter } from "./router";
import App from "@/App.vue";

import "./assets/main.scss";
import { setupStore } from "./stores";
import { setupDirectives } from "./directives";
import "./permission";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/zh-hk";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { initServices } from "./services";
import { initFilters } from "./utils/filters";
import { dynamicEnv } from "./utils/dynamic-env";

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(dynamicEnv("AG_GRID_LICENSE_KEY"));

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { notify } from "./utils/notify";
import ElTableInfiniteScroll from "el-table-infinite-scroll";
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
import { handleError, rollbarInstance } from "./utils/handleError";
import { eventEmitter } from "./services/events";

dayjs.extend(tz);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.locale(locale);

const app = createApp(App);

// Element UI
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

//
app.use(ContextMenu);
app.use(ElTableInfiniteScroll as never);

// Route
setupRouter(app);

// Directives
setupDirectives(app);

initFilters(app);

async function asyncLoader() {
    await initServices();
    await setupStore(app);

    app.config.globalProperties.$emitter = eventEmitter;
    app.config.globalProperties.$confirm = notify.confirm;

    app.config.globalProperties.$loading = (message = "讀取中...") => {
        return ElLoading.service({
            lock: true,
            text: message,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    };

    app.config.globalProperties.$prompt = async ({ message, title, inputPattern, inputErrorMessage }) => {
        return ElMessageBox.prompt(
            message,
            title,
            {
                inputPattern,
                inputErrorMessage,
                confirmButtonText: '確認',
                cancelButtonText: '取消',
            }
        ).then(({ value }) => value)
            .catch(() => null);
    };
    app.mount("#app");
}

if (process.env.NODE_ENV !== 'development' && dynamicEnv("BUILD_VERSION") !== '0.0.0') {
    console.log(`(production) Version: ${dynamicEnv("BUILD_VERSION")}`);
    (window as any).console = {
        log: () => { },
        warn: () => { },
        error: console.error,
    }
    app.config.globalProperties.$rollbar = rollbarInstance;
    app.config.errorHandler = function (err, vm, info) {
        notify.doNothing();
        handleError(err);
        vm.$rollbar.error(err as never);
    }
    app.config.warnHandler = function (err, vm, info) {
        //
    }
} else {
    (window as any).console = {
        log: console.log,
        warn: console.warn,
        error: console.error,
    };
    app.config.globalProperties.$rollbar = {};
    app.config.warnHandler = function (err, vm, info) {
        console.warn(err);
    }
    app.config.errorHandler = function (err, vm, info) {
        // vm.$rollbar.error(err as never);
        handleError(err);
        throw err;
    }
}

asyncLoader();