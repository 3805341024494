import { UIState } from "@/stores/modules/ui.store";
import { PatientTableSearchDTO } from "@model/dtos";
import { Clinic } from "@model/entities";
import { PatientBriefInfo } from "@model/models";

class BaseLocalStorage<T> {
    constructor(protected readonly key: string) { }

    get(): T | null {
        const stored = localStorage.getItem(this.key);
        if (!stored) {
            return null;
        }
        return JSON.parse(stored);
    }

    set(data?: T) {
        if (data) {
            localStorage.setItem(this.key, JSON.stringify(data));
        } else {
            localStorage.removeItem(this.key);
        }
    }

    clear() {
        localStorage.removeItem(this.key);
    }
}

export const LocalStorage = {
    lastPatientTableSearch: new BaseLocalStorage<PatientTableSearchDTO>('last-patient-table-search'),
    selectedPatient: new BaseLocalStorage<PatientBriefInfo>('selected-patient'),
    selectedClinic: new BaseLocalStorage<Clinic['id']>('selectedClinic'),
    uiState: new BaseLocalStorage<UIState>('uiState'),
};
