import { Clinic, Doctor } from "@model/entities";
import { BaseService } from "./base.service";
import { SelectItem } from '@model/models';

class Service extends BaseService {
    async list(): Promise<Clinic[]> {
        return this.get(``);
    }

    async getItems() {
        return this.get<SelectItem<Clinic['id']>[]>(`/items`);
    }

    async listDoctorsOfCurrentClinic() {
        return this.get<Doctor[]>(`/doctors`);
    }
}

export const ClinicService = new Service('/clinic');