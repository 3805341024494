import { defineStore } from 'pinia';
import { store } from '@/stores';
import { useCache } from '@/hooks/useCache';
import { useUserStore } from './user.store';
import { Clinic, Doctor } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { ConsultationDictionaryWordlist, ConsultationDictionaryWordlistItem } from '@model/models';
import { PricingDictionaryResponse, HerbDictionaryResponse } from "@model/responses";
import { PricingService, HerbService } from '@/services';

const { wsCache } = useCache('localStorage');

export interface ConsultationDictionaryState {
    _hash: string;
    _data: ConsultationDictionaryWordlist;
    _pricing: PricingDictionaryResponse;
}

const defaultState: ConsultationDictionaryState = {
    _hash: null,
    _data: null,
    _pricing: null,
}

export const useConsultationDictionaryStore = defineStore({
    id: 'consultation-dictionary',
    state: (): ConsultationDictionaryState => defaultState,
    persist: {
        enabled: true,
    },
    getters: {
        usage(): ConsultationDictionaryWordlistItem[] {
            return this._data?.usage ?? [];
        },
        massage(): ConsultationDictionaryWordlistItem[] {
            return this._data?.massage ?? [];
        },
        remark(): ConsultationDictionaryWordlistItem[] {
            return this._data?.remark ?? [];
        },
        caution(): ConsultationDictionaryWordlistItem[] {
            return this._data?.caution ?? [];
        },
        diagnosis_ill(): ConsultationDictionaryWordlistItem[] {
            return this._data?.diagnosis_ill ?? [];
        },
        diagnosis_ill_type(): ConsultationDictionaryWordlistItem[] {
            return this._data?.diagnosis_ill_type ?? [];
        },
        diagnosis_treatment(): ConsultationDictionaryWordlistItem[] {
            return this._data?.diagnosis_treatment ?? [];
        },
        diagnosis_pulse(): ConsultationDictionaryWordlistItem[] {
            return this._data?.diagnosis_pulse ?? [];
        },
        diagnosis_tongue(): ConsultationDictionaryWordlistItem[] {
            return this._data?.diagnosis_tongue ?? [];
        },
        content(): ConsultationDictionaryWordlistItem[] {
            return this._data?.content ?? [];
        },
    },
    actions: {
        async init() {
            // TODO: localstorage 
            const res = await fetch(`/data/consultation-dictionary.json`).then(x => x.json());
            this._data = res.data;

            eventEmitter.on('current-clinic:changed', async () => {
                this._pricing = await PricingService.dictionary();
            });

            eventEmitter.on('global:clear-cache', async () => {
                await this.loadHerbDictionary({ flush: true });
                await this.loadPricing({ flush: true });
            });
        },
        async loadPricing(options?: { flush: boolean }) {
            const {
                flush = false,
            } = options ?? {};
            if (this._pricing === null || flush) {
                this._pricing = await PricingService.dictionary();
            }
            return this._pricing;
        },
        async loadHerbDictionary(options?: { clinicId?: Clinic['id'], flush?: boolean }): Promise<HerbDictionaryResponse> {
            const user = useUserStore();
            const {
                clinicId = user.currentClinicId,
                flush = false,
            } = options ?? {};
            const cacheKey = `ConsultationDictionaryStore:_dictionary:${clinicId}`;
            let herb = flush ? null : wsCache.get(cacheKey);
            if(!herb) {
                herb = await HerbService.dictionary(clinicId);
                wsCache.set(cacheKey, herb, { exp: 360 });
            }
            return herb;
        },
        async loadHerbClinicSelectItemList(clinicId?: Clinic['id']) {
            const dict = await this.loadHerbDictionary({ clinicId });
            return dict.clinics;
        },
        async loadHerbOfClinic(clinicId: Clinic['id'], options?: { flush: boolean }) {
            const {
                flush = false,
            } = options ?? {};
            // TODO Optimize and merge
            const cacheKey = `ConsultationDictionaryStore:_clinic:${clinicId}`;
            const cached = wsCache.get(cacheKey);
            if (cached && !flush) {
                return cached;
            }
            const herbs = await HerbService.dictionaryOfClinic(clinicId);
            if (herbs && herbs.data.length > 0) {
                wsCache.set(cacheKey, herbs.data, { exp: 3600 });
            }
            return herbs.data;
        },
        async loadHerbsWithNonzeroStockOfClinic(clinicId: Clinic['id'], options?: { flush: boolean }) {
            const {
                flush = false,
            } = options ?? {};
            // TODO Optimize and merge
            const cacheKey = `ConsultationDictionaryStore:_clinic-non-zero-stock:${clinicId}`;
            const cached = wsCache.get(cacheKey);
            if (cached && !flush) {
                return cached;
            }
            const herbs = await HerbService.getHerbsWithNonzeroStockOfClinic(clinicId);
            if (herbs && herbs.length > 0) {
                wsCache.set(cacheKey, herbs, { exp: 3600 });
            }
            return herbs;
        },
    }
});

export const useConsultationDictionaryStoreWithOut = () => {
    return useConsultationDictionaryStore(store);
};
