import { Prescription } from "@model/entities";
import date from "@model/utils/date";
import { Labels } from "../common";

const _prescriptionStatuses = [
    "pending",
    "printed",
    "dispensing",
    "dispensed",
    "decocting",
    "deliverable",
    "ordered",
    "delivering",
    "delivered",
    "cancelled",
] as const;

export type PrescriptionStatus = typeof _prescriptionStatuses[number];

export const prescriptionStatusMap: Labels<PrescriptionStatus, {
    isDispensed?: boolean,
}> = {
    cancelled: {
        label: "已取消",
        color: "#4fe74a",
    },
    pending: {
        label: "待配藥",
        color: "#4fe74a",
    },
    printed: {
        label: "已列印",
        color: "#95fdff",
    },
    dispensing: {
        label: "配藥中",
        color: "#95fdff",
    },
    dispensed: {
        label: "已配藥",
        color: "#95fdff",
        meta: {
            isDispensed: true,
        }
    },
    decocting: {
        label: "煎藥中",
        color: "#95fdff",
        meta: {
            isDispensed: true,
        }
    },
    deliverable: {
        label: "待發貨",
        color: "#95fdff",
        meta: {
            isDispensed: true,
        }
    },
    ordered: {
        label: "待取件",
        color: "#95fdff",
        meta: {
            isDispensed: true,
        }
    },
    delivering: {
        label: "運送中",
        color: "#95fdff",
        meta: {
            isDispensed: true,
        }
    },
    delivered: {
        label: "已送達",
        color: "#95fdff",
        meta: {
            isDispensed: true,
        }
    },
};

export const translatePrescriptionStatus = (status: PrescriptionStatus) => {
    return prescriptionStatusMap[status].label;
}

export const isDispensed = (status: PrescriptionStatus): boolean => {
    return prescriptionStatusMap[status].meta?.isDispensed ?? false;
}

export const getPrescriptionStatusSortFunction = (
    priortized?: PrescriptionStatus,
    datetimeKey: keyof Prescription = 'dispense_at',
): ((
    a: Pick<Prescription, 'status' | 'dispense_at' | 'created_at'>,
    b: Pick<Prescription, 'status' | 'dispense_at' | 'created_at'>
) => number) => {
    const front = -1;
    const back = 1;

    return (
        a: Pick<Prescription, 'status' | 'dispense_at' | 'created_at'>,
        b: Pick<Prescription, 'status' | 'dispense_at' | 'created_at'>
    ) => {
        if (a.status === priortized && b.status !== priortized) {
            return front;
        }

        if (b.status === priortized && a.status !== priortized) {
            return back;
        }

        if (a.status !== b.status) {
            return _prescriptionStatuses.indexOf(a.status) - _prescriptionStatuses.indexOf(b.status);
        }

        if (datetimeKey !== 'dispense_at' && datetimeKey !== 'created_at') {
            return 0;
        }

        return date.isDayBefore(a[datetimeKey], b[datetimeKey]) ? front : back;
    }
}