import { DoctorConsultationFeeUpdateDto } from "@model/dtos";
import { Doctor, Clinic, ClinicDoctorAssignment } from "@model/entities";
import { TransformNumber } from "@model/utils/transform/transform-number";
import { plainToClass } from "class-transformer";

export class SettingDoctorConsultationFeeState {
    doctorId: Doctor['id'];

    data: State[];

    static fromEntities(doctorId: Doctor['id'], clinicDoctorAssignments: ClinicDoctorAssignment[]): SettingDoctorConsultationFeeState {
        return plainToClass(SettingDoctorConsultationFeeState, {
            doctorId,
            data: clinicDoctorAssignments.filter(x => x.doctor_id === doctorId).map(x => State.fromEntity(x)),
        });
    }

    static toDoctorConsultationFeeUpdateDto(state: SettingDoctorConsultationFeeState): DoctorConsultationFeeUpdateDto {
        return {
            data: state.data.map(x => ({
                doctorId: state.doctorId,
                clinicId: x.clinicId,
                consultationFee: x.consultationFee,
                vcConsultationFee: x.vcConsultationFee,
            })),
        }
    }
}

class State {
    clinicId: Clinic['id'];

    clinicName: string;

    @TransformNumber({ nullable: true })
    consultationFee: number = null;

    @TransformNumber({ nullable: true })
    vcConsultationFee: number = null;

    static fromEntity(clinic_doctor_assignment: ClinicDoctorAssignment) {
        return plainToClass(State, {
            clinicId: clinic_doctor_assignment.clinic_id,
            clinicName: clinic_doctor_assignment.clinic.display_name,
            consultationFee: clinic_doctor_assignment.consultation_fee,
            vcConsultationFee: clinic_doctor_assignment.vc_consultation_fee,
        });
    }
}
