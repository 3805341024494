import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'

export const AuthenticatedLayout = () => import('@/layouts/authenticated.layout.vue');
export const RegistrationLayout = () => import('@/views/registration/layouts/registration.layout.vue');

export const EmptyLayout = () => import('@/layouts/empty.layout.vue');
export const SettingLayout = () => import('@/views/setting/layout/setting.layout.vue');

export const AppointmentLayout = () => import('@/views/appointment/layouts/appointment.layout.vue');

export const ConsultationLayout = () => import('@/views/consultation/layouts/consultation.layout.vue');
export const ConsultationDetailLayout = () => import('@/views/consultation/layouts/consultation-detail.layout.vue');

export const TreatmentLayout = () => import('@/views/treatment/layouts/treatment.layout.vue');

export const PaymentLayout = () => import('@/views/payment/layouts/payment.layout.vue');
export const PaymentDetailLayout = () => import('@/views/payment/layouts/payment-detail.layout.vue');

export const StockLayout = () => import('@/views/stock/layouts/stock.layout.vue');

export const DispenseLayout = () => import('@/views/dispense/layouts/dispense.layout.vue');
export const DispenseDetailLayout = () => import('@/views/dispense/layouts/dispense-detail.layout.vue');
export const DispenseUpdatePaymentLayout = () => import('@/layouts/dispense/dispense-update.layout.vue');
export const DispenseUpdatePrescriptionLayout = () => import('@/layouts/dispense/dispense-update.layout.vue');
export const DispenseUpdateLayout = () => import('@/layouts/dispense/dispense-update.layout.vue');

export const ReportLayout = () => import('@/views/report/layouts/report.layout.vue');
export const SettlementLayout = () => import('@/views/settlement/settlement.layout.vue');
export const OtherLayout = () => import('@/views/other/layouts/other.layout.vue');

export const constantRouterMap: AppRouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/appointment',
        name: 'Index',
        meta: {
            hidden: true
        }
    },
    {
        path: '/login',
        component: () => import('@/views/auth/login.view.vue'),
        name: 'Login',
        meta: {
            hidden: true,
            title: 'router.login',
            noTagsView: true
        }
    },
    // To be require authentication
]

export const fallbackRouterMap = [
    {
        path: '/:path(.*)*',
        component: AuthenticatedLayout,
        children: [
            {
                path: '',
                component: () => import('@/views/error/not-found.view.vue'),
                name: 'NotFoundWildcard',
                meta: {
                    hidden: true,
                    noTagsView: true
                }
            },
        ],
    },
];

export const asyncRouterMap = [
    {
        path: '/not-found',
        component: AuthenticatedLayout,
        children: [
            {
                path: '',
                component: () => import('@/views/error/not-found.view.vue'),
                name: 'NotFound',
                meta: {
                    hidden: true,
                    noTagsView: true
                }
            },
        ],
    },
    {
        path: '/',
        component: AuthenticatedLayout,
        meta: {},
        name: '/',
        children: [
            {
                path: 'registration',
                component: RegistrationLayout,
                name: 'registration',
                meta: {
                    title: 'router.registration',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/registration/registration.view.vue'),
                        name: 'registration.index',
                        meta: {
                            title: 'router.registration.index',
                            affix: true,
                            permission: 'registration'
                        }
                    },
                ],
            },
            {
                path: 'appointment',
                component: AppointmentLayout,
                name: 'appointment',
                meta: {
                    title: 'router.appointment',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/appointment/appointment.view.vue'),
                        name: 'appointment.index',
                        meta: {
                            title: 'router.appointment.index',
                            permission: 'appointment'
                        }
                    },
                ],
            },
            {
                path: 'consultation',
                component: ConsultationLayout,
                name: 'consultation',
                meta: {
                    title: 'router.consultation',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/consultation/consultation-list.view.vue'),
                        name: 'consultation.index',
                        meta: {
                            title: 'router.consultation.index',
                            permission: 'consultation'
                        }
                    },
                ],
            },
            {
                path: 'treatment',
                component: TreatmentLayout,
                name: 'treatment',
                meta: {
                    title: 'router.treatment',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/treatment/treatment-list.view.vue'),
                        name: 'treatment.index',
                        meta: {
                            title: 'router.treatment.index',
                            permission: 'treatment'
                        }
                    },
                ],
            },
            {
                path: 'payment',
                component: PaymentLayout,
                name: 'payment',
                meta: {
                    title: 'router.payment',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/payment/payment-list.view.vue'),
                        name: 'payment.index',
                        meta: {
                            title: 'router.payment.index',
                            permission: 'payment'
                        }
                    },
                ],
            },
            {
                path: 'dispense',
                component: DispenseLayout,
                name: 'dispense',
                meta: {
                    title: 'router.dispense',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/dispense/dispense-list.view.vue'),
                        name: 'dispense.index',
                        meta: {
                            title: 'router.dispense.index',
                            affix: true
                        }
                    },
                ],
            },
            {
                path: 'stock',
                component: StockLayout,
                name: 'stock',
                meta: {
                    title: 'router.stock',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/stock/stock.view.vue'),
                        name: 'StockIndex',
                        meta: {
                            title: 'router.stock.index',
                            permission: 'stock',
                        }
                    },
                    {
                        path: 'consume-log',
                        component: () => import('@/views/stock/stock-consume-log.view.vue'),
                        name: 'stock.consume-log',
                        meta: {
                            title: 'router.stock.consume-log',
                            permission: 'stock',
                        }
                    },
                    {
                        path: 'in',
                        component: () => import('@/views/stock/stock-in.view.vue'),
                        name: 'stock.in',
                        meta: {
                            title: 'router.stock.in',
                            permission: 'stock.in',
                        }
                    },
                    {
                        path: 'in/create',
                        component: () => import('@/views/stock/stock-in-create.view.vue'),
                        name: 'stock.in.create',
                        meta: {
                            title: 'router.stock.in.create',
                            permission: 'stock.in',
                        }
                    },
                    {
                        path: 'in/complete/:id',
                        component: () => import('@/views/stock/stock-in-complete.view.vue'),
                        name: 'stock.in.complete',
                        meta: {
                            title: 'router.stock.in.complete',
                            permission: 'stock.in',
                        }
                    },
                    {
                        path: 'in/validate/:id',
                        component: () => import('@/views/stock/stock-in-validate.view.vue'),
                        name: 'stock.in.validate',
                        meta: {
                            title: 'router.stock.in.validate',
                            permission: 'stock.in.validate',
                        }
                    },
                    {
                        path: 'check',
                        component: () => import('@/views/stock/stock-check.view.vue'),
                        name: 'stock.check',
                        meta: {
                            title: 'router.stock.check',
                            permission: 'stock.check',
                        }
                    },
                    {
                        path: 'check/create',
                        component: () => import('@/views/stock/stock-check-create.view.vue'),
                        name: 'stock.check.create',
                        meta: {
                            title: 'router.stock.check.create',
                            permission: 'stock.check',
                        }
                    },
                    {
                        path: 'check/update/:id',
                        component: () => import('@/views/stock/stock-check-update.view.vue'),
                        name: 'stock.check.update',
                        meta: {
                            title: 'router.stock.check.update',
                            permission: 'stock.check',
                        }
                    },
                    {
                        path: 'check/validate/:id',
                        component: () => import('@/views/stock/stock-check-validate.view.vue'),
                        name: 'stock.check.validate',
                        meta: {
                            title: 'router.stock.check.validate',
                            permission: 'stock.check.validate',
                        }
                    },
                    {
                        path: 'adjust',
                        component: () => import('@/views/stock/stock-adjust.view.vue'),
                        name: 'stock.adjust',
                        meta: {
                            title: 'router.stock.adjust',
                            permission: 'stock.adjust',
                        }
                    },
                    {
                        path: 'adjust/create',
                        component: () => import('@/views/stock/stock-adjust-create.view.vue'),
                        name: 'stock.adjust.create',
                        meta: {
                            title: 'router.stock.adjust.create',
                            permission: 'stock.adjust',
                        }
                    },
                    {
                        path: 'adjust/update/:id',
                        component: () => import('@/views/stock/stock-adjust-update.view.vue'),
                        name: 'stock.adjust.update',
                        meta: {
                            title: 'router.stock.adjust.update',
                            permission: 'stock.adjust',
                        }
                    },
                    {
                        path: 'adjust/validate/:id',
                        component: () => import('@/views/stock/stock-adjust-validate.view.vue'),
                        name: 'stock.adjust.validate',
                        meta: {
                            title: 'router.stock.adjust.validate',
                            permission: 'stock.adjust.validate',
                        }
                    },
                    {
                        path: 'migrate',
                        component: () => import('@/views/stock/stock-migrate.view.vue'),
                        name: 'stock.migrate',
                        meta: {
                            title: 'router.stock.migrate',
                            permission: 'stock.migrate',
                        }
                    },
                    {
                        path: 'order',
                        component: () => import('@/views/stock/stock-order.view.vue'),
                        name: 'stock.order',
                        meta: {
                            title: 'router.stock.order',
                            permission: 'stock.order',
                        }
                    },
                    {
                        path: 'order/create',
                        component: () => import('@/views/stock/stock-order-create.view.vue'),
                        name: 'stock.order.create',
                        meta: {
                            title: 'router.stock.order.create',
                            permission: 'stock.order',
                        }
                    },
                ],
            },
            {
                path: 'report',
                component: ReportLayout,
                name: 'report',
                meta: {
                    title: 'router.report',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/report/report.view.vue'),
                        name: 'ReportIndex',
                        meta: {
                            title: 'router.report.index',
                        }
                    },
                    {
                        path: 'daily/all',
                        component: () => import('@/views/report/report-daily-all.view.vue'),
                        name: 'report.daily-all',
                        meta: {
                            title: 'router.report.daily-all',
                            permission: 'report.daily-all',
                        }
                    },
                    {
                        path: 'daily/summary',
                        component: () => import('@/views/report/report-daily-summary.view.vue'),
                        name: 'report.daily-summary',
                        meta: {
                            title: 'router.report.daily-summary',
                            permission: 'report.daily-summary',
                        }
                    },
                    {
                        path: 'daily/income',
                        component: () => import('@/views/report/report-daily-income-detail.view.vue'),
                        name: 'report.daily-income',
                        meta: {
                            title: 'router.report.daily-income',
                            permission: 'report.daily-income',
                        }
                    },
                    {
                        path: 'monthly/healthcare-voucher',
                        component: () => import('@/views/report/report-monthly-healthcare-voucher.view.vue'),
                        name: 'report.monthly-healthcare-voucher',
                        meta: {
                            title: 'router.report.monthly-healthcare-voucher',
                            permission: 'report.monthly-healthcare-voucher',
                        }
                    },
                    {
                        path: 'monthly/herb-usage',
                        component: () => import('@/views/report/report-monthly-herb-usage.view.vue'),
                        name: 'report.monthly-herb-usage',
                        meta: {
                            title: 'router.report.monthly-herb-usage',
                            permission: 'report.monthly-herb-usage',
                        }
                    }, 
                    {
                        path: 'monthly/summary',
                        component: () => import('@/views/report/report-monthly-summary.view.vue'),
                        name: 'report.monthly-summary',
                        meta: {
                            title: 'router.report.monthly-summary',
                            permission: 'report.monthly-summary',
                        }
                    },
                    {
                        path: 'monthly/package-consume',
                        component: () => import('@/views/report/report-monthly-package-consume.view.vue'),
                        name: 'report.monthly-package-consume',
                        meta: {
                            title: 'router.report.monthly-package-consume',
                            permission: 'report.monthly-package-consume',
                        }
                    },
                    {
                        path: 'monthly/package-purchase',
                        component: () => import('@/views/report/report-monthly-package-purchase.view.vue'),
                        name: 'report.monthly-package-purchase',
                        meta: {
                            title: 'router.report.monthly-package-purchase',
                            permission: 'report.monthly-package-purchase',
                        }
                    },
                    {
                        path: 'monthly/salary',
                        component: () => import('@/views/report/report-monthly-salary.view.vue'),
                        name: 'report.monthly-salary',
                        meta: {
                            title: 'router.report.monthly-salary',
                            permission: 'report.monthly-salary',
                        }
                    },
                    {
                        path: 'herb-usage',
                        component: () => import('@/views/report/report-herb-usage.view.vue'),
                        name: 'report.herb-usage',
                        meta: {
                            title: 'router.report.herb-usage',
                            permission: 'report.herb-usage',
                        }
                    }, 
                    {
                        path: 'stock',
                        component: () => import('@/views/report/report-herb-stock.view.vue'),
                        name: 'report.herb-stock',
                        meta: {
                            title: 'router.report.herb-stock',
                            permission: 'report.herb-stock',
                        }
                    },
                    {
                        path: 'patients',
                        component: () => import('@/views/report/report-patients.view.vue'),
                        name: 'report.patients',
                        meta: {
                            title: 'router.report.patients',
                            permission: 'report.patients',
                        }
                    },
                    {
                        path: 'patient-packages',
                        component: () => import('@/views/report/report-patient-packages.view.vue'),
                        name: 'report.patient-packages',
                        meta: {
                            title: 'router.report.patient-packages',
                            permission: 'report.patient-packages',
                        }
                    },
                ],
            },
            {
                path: 'other',
                component: OtherLayout,
                name: 'other',
                meta: {
                    title: 'router.other',
                    affix: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/other/other.view.vue'),
                        name: 'other.index',
                        meta: {
                            title: 'router.other.index',
                            affix: true
                        }
                    },
                    {
                        path: 'patient-merge',
                        component: () => import('@/views/other/patient-merge.view.vue'),
                        name: 'other.patient-merge',
                        meta: {
                            title: 'router.other.patient-merge',
                            affix: true
                        }
                    },
                    {
                        path: 'placeholder-tool',
                        component: () => import('@/views/other/placeholder-tool.view.vue'),
                        name: 'other.placeholder_tool',
                        meta: {
                            title: 'router.other.placeholder_tool',
                            affix: true
                        }
                    },
                    {
                        path: 'auto-reminder',
                        component: () => import('@/views/other/auto-reminder-history.view.vue'),
                        name: 'other.auto-reminder',
                        meta: {
                            title: 'router.other.auto-reminder',
                            affix: true
                        }
                    },
                ],
            },
            {
                path: '/settlement',
                component: SettlementLayout,
                meta: {},
                name: 'settlement',
                children: [
                    {
                        path: '',
                        component: () => import('@/views/settlement/settlement.view.vue'),
                        name: 'settlement.index',
                        meta: {
                            permission: 'other.settlement',
                        }
                    },
                ]
            },
            {
                path: '/setting',
                component: SettingLayout,
                meta: {},
                name: 'setting',
                children: [
                    {
                        path: '',
                        name: 'setting.index',
                        meta: {
                            permission: 'setting',
                        }
                    },
                    {
                        path: 'package',
                        components: {
                            default: () => import('@/views/setting/business/setting-package.view.vue'),
                        },
                        name: 'setting.business.package',
                        meta: {
                            title: 'router.setting.business.package',
                            permission: 'setting.business.package',
                        }
                    },
                    {
                        path: 'clinic/herb',
                        components: {
                            default: () => import('@/views/setting/clinic/herb/setting-herb.view.vue'),
                        },
                        name: 'setting.clinic.herb',
                        meta: {
                            title: 'router.setting.clinic.herb',
                            permission: 'setting.clinic.herb',
                        }
                    },
                    {
                        path: 'clinic/herb-combo/:herbId',
                        components: {
                            default: () => import('@/views/setting/clinic/herb/setting-herb-combo.view.vue'),
                        },
                        name: 'setting.clinic.herb-combo',
                        meta: {
                            title: 'router.setting.clinic.herb-combo',
                            permission: 'setting.clinic.herb-combo',
                        }
                    },
                    {
                        path: 'clinic/pricing',
                        components: {
                            default: () => import('@/views/setting/clinic/payment-pricing/setting-payment-pricing-item.view.vue'),
                        },
                        name: 'setting.clinic.payment-pricing-item',
                        meta: {
                            title: 'router.setting.clinic.payment-pricing-item',
                            permission: 'setting.clinic.payment-pricing-item',
                        }
                    },
                    {
                        path: 'clinic/discount',
                        components: {
                            default: () => import('@/views/setting/clinic/payment-pricing/setting-payment-pricing-discount.view.vue'),
                        },
                        name: 'setting.clinic.payment-pricing-discount',
                        meta: {
                            title: 'router.setting.clinic.payment-pricing-discount',
                            permission: 'setting.clinic.payment-pricing-discount',
                        }
                    },
                    {
                        path: 'clinic/offer',
                        components: {
                            default: () => import('@/views/setting/clinic/payment-pricing/setting-payment-pricing-offer.view.vue'),
                        },
                        name: 'setting.clinic.payment-pricing-offer',
                        meta: {
                            title: 'router.setting.clinic.payment-pricing-offer',
                            permission: 'setting.clinic.payment-pricing-offer',
                        }
                    },
                    {
                        path: 'clinic/general',
                        components: {
                            default: () => import('@/views/setting/clinic/general/setting-general.view.vue'),
                        },
                        name: 'setting.clinic.general',
                        meta: {
                            title: 'router.setting.clinic.general',
                            permission: 'setting.clinic.general',
                        }
                    },
                    {
                        path: 'company',
                        components: {
                            default: () => import('@/views/setting/business/setting-company.view.vue'),
                        },
                        name: 'setting.business.company',
                        meta: {
                            title: 'router.setting.business.company',
                            permission: 'setting.business.company',
                        }
                    },
                    {
                        path: 'clinic',
                        component: () => import('@/views/setting/business/setting-clinic-list.view.vue'),
                        name: 'setting.business.clinic',
                        meta: {
                            title: 'router.setting.business.clinic',
                            permission: 'setting.business.clinic',
                        }
                    },
                    {
                        path: 'clinic/create',
                        component: () => import('@/views/setting/business/setting-clinic-create.view.vue'),
                        name: 'setting.business.clinic.create',
                        meta: {
                            title: 'router.setting.business.clinic.new',
                            permission: 'setting.business.clinic',
                        }
                    }, {
                        path: 'clinic/edit/:clinicId',
                        component: () => import('@/views/setting/business/setting-clinic-edit.view.vue'),
                        name: 'setting.business.clinic.edit',
                        meta: {
                            title: 'router.setting.business.clinic.edit',
                            permission: 'setting.business.clinic',
                        }
                    },
                    {
                        path: 'usergroup',
                        components: {
                            default: () => import('@/views/setting/business/setting-usergroup-list.view.vue'),
                            // sidebar: () => import('@/views/setting/setting.view.vue'),
                        },
                        name: 'setting.business.usergroup',
                        meta: {
                            title: 'router.setting.business.usergroup',
                            permission: 'setting.business.userGroup',
                        }
                    },
                    {
                        path: 'usergroup/create',
                        components: {
                            default: () => import('@/views/setting/business/setting-usergroup-create.view.vue'),
                            // sidebar: () => import('@/views/setting/setting.view.vue'),
                        },
                        name: 'setting.business.usergroup.create',
                        meta: {
                            title: 'router.setting.business.usergroup.create',
                            permission: 'setting.business.userGroup',
                        }
                    },
                    {
                        path: 'usergroup/edit/:userGroupId',
                        components: {
                            default: () => import('@/views/setting/business/setting-usergroup-edit.view.vue'),
                            // sidebar: () => import('@/views/setting/setting.view.vue'),
                        },
                        name: 'setting.business.usergroup.edit',
                        meta: {
                            title: 'router.setting.business.usergroup.edit',
                            permission: 'setting.business.userGroup',
                        }
                    },
                    {
                        path: 'user',
                        component: () => import('@/views/setting/business/setting-user-list.view.vue'),
                        name: 'setting.business.user',
                        meta: {
                            title: 'router.setting.business.user',
                            permission: 'setting.business.user',
                        }
                    },
                    {
                        path: 'user/create',
                        component: () => import('@/views/setting/business/setting-user-create.view.vue'),
                        name: 'setting.business.user.create',
                        meta: {
                            title: 'router.setting.business.user.create',
                            permission: 'setting.business.user',
                        }
                    },
                    {
                        path: 'user/edit/:userId',
                        component: () => import('@/views/setting/business/setting-user-edit.view.vue'),
                        name: 'setting.business.user.edit',
                        meta: {
                            title: 'router.setting.business.user.edit',
                            permission: 'setting.business.user',
                        }
                    },
                    {
                        path: 'doctors',
                        component: () => import('@/views/setting/business/setting-doctors-list.view.vue'),
                        name: 'setting.business.doctors',
                        meta: {
                            title: 'router.setting.business.doctors',
                            permission: 'setting.business.doctors',
                        }
                    },
                    {
                        path: 'doctors/create',
                        component: () => import('@/views/setting/business/setting-doctors-create.view.vue'),
                        name: 'setting.business.doctors.create',
                        meta: {
                            title: 'router.setting.business.doctors.create',
                            permission: 'setting.business.doctors',
                        }
                    },
                    {
                        path: 'doctors/edit/:doctorId',
                        component: () => import('@/views/setting/business/setting-doctors-edit.view.vue'),
                        name: 'setting.business.doctors.edit',
                        meta: {
                            title: 'router.setting.business.doctors.edit',
                            permission: 'setting.business.doctors',
                        }
                    },
                    {
                        path: 'schedule',
                        component: () => import('@/views/setting/business/setting-schedule-list.view.vue'),
                        name: 'setting.business.schedule',
                        meta: {
                            title: 'router.setting.business.schedule',
                            permission: 'setting.business.schedule',
                        }
                    },
                    {
                        path: 'schedule/create',
                        component: () => import('@/views/setting/business/setting-schedule-create.view.vue'),
                        name: 'setting.business.schedule.create',
                        meta: {
                            title: 'router.setting.business.schedule.create',
                            permission: 'setting.business.schedule',
                        }
                    },
                    {
                        path: 'schedule/edit/:scheduleId',
                        component: () => import('@/views/setting/business/setting-schedule-edit.view.vue'),
                        name: 'setting.business.schedule.edit',
                        meta: {
                            title: 'router.setting.business.schedule.edit',
                            permission: 'setting.business.schedule',
                        }
                    },
                    {
                        path: 'doctor/prescription',
                        component: () => import('@/views/setting/doctor/doctor-prescription-list.view.vue'),
                        name: 'setting.doctor.prescription-view',
                        meta: {
                            title: 'router.setting.doctor.prescription',
                            permission: 'setting.doctor.prescription-view',
                        }
                    },
                    {
                        path: 'doctor/prescription/create',
                        component: () => import('@/views/setting/doctor/doctor-prescription.view.vue'),
                        name: 'setting.doctor.prescription.create',
                        meta: {
                            title: 'router.setting.doctor.prescription-create',
                            permission: 'setting.doctor.prescription-upsert',
                        }
                    },
                    {
                        path: 'doctor/prescription/update/:id',
                        component: () => import('@/views/setting/doctor/doctor-prescription.view.vue'),
                        name: 'setting.doctor.prescription.update',
                        meta: {
                            title: 'router.setting.doctor.prescription-update',
                            permission: 'setting.doctor.prescription-upsert',
                        }
                    },
                ],
            },
        ],
    },
    {
        path: '/demo',
        component: ConsultationDetailLayout,
        meta: {},
        name: 'demo',
        children: [

            {
                path: '',
                component: () => import('@/views/demo.view.vue'),
                name: 'demo',
                meta: {
                    title: 'router.demo',
                    affix: true
                }
            },
        ],
    },
    {
        path: '/consultation',
        component: ConsultationDetailLayout,
        name: 'consultation-detail-new',
        meta: {
            title: 'router.consultation.detail',
            affix: true
        },
        children: [
            {
                path: 'appointment/:appointmentId',
                component: () => import('@/views/consultation/consultation.view.vue'),
                name: 'consultation.consult',
                meta: {
                    title: 'router.consultation.detail.appointment',
                    affix: true
                }
            },
        ],
    },
    {
        path: '/payment',
        component: PaymentDetailLayout,
        name: 'payment-detail',
        meta: {
            title: 'router.payment.detail',
            affix: true
        },
        children: [
            {
                path: ':appointmentId',
                component: () => import('@/views/payment/payment.view.vue'),
                name: 'PaymentDetailAppointment',
                meta: {
                    title: 'router.payment.detail.appointment',
                    affix: true
                }
            },
            // {
            //     path: 'prepaid/:appointmentId',
            //     component: () => import('@/views/payment/payment-prepaid.view.vue'),
            //     name: 'PaymentPrepaid',
            //     meta: {
            //         title: 'router.payment.prepaid.appointment',
            //         affix: true
            //     }
            // },
        ],
    },
    {
        path: '/dispense',
        component: DispenseDetailLayout,
        name: 'dispense-detail',
        meta: {
            title: 'router.dispense.details',
            affix: true
        },
        children: [
            {
                path: ':prescriptionId',
                component: () => import('@/views/dispense/dispense.view.vue'),
                name: 'dispense.detail',
                meta: {
                    title: 'router.dispense.detail',
                    affix: true
                }
            },
        ],
    },
    {
        path: '/dispense',
        component: EmptyLayout,
        name: 'dispense-update',
        children: [
            {
                path: 'prescription',
                component: DispenseUpdatePrescriptionLayout,
                children: [
                    {
                        path: ':prescriptionId',
                        component: () => import('@/views/dispense/dispense-update-prescription.view.vue'),
                        name: 'dispense.update.prescription',
                        meta: {
                            title: 'router.dispense.update.prescription',
                            affix: true
                        }
                    },
                ]
            },
            {
                path: 'payment',
                component: PaymentDetailLayout,
                children: [
                    {
                        path: ':appointmentId',
                        component: () => import('@/views/dispense/dispense-update-payment.view.vue'),
                        name: 'dispense.update.payment',
                        meta: {
                            title: 'router.dispense.update.payment',
                            affix: true
                        }
                    },
                ]
            }
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    strict: true,
    routes: constantRouterMap as RouteRecordRaw[],
    scrollBehavior: () => ({ left: 0, top: 0 })
})

// export const resetRouter = (): void => {
//     const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root'];
//     router.getRoutes().forEach((route) => {
//         const { name } = route;
//         if (name && !resetWhiteNameList.includes(name as string)) {
//             router.hasRoute(name) && router.removeRoute(name);
//         }
//     })
// }

export const setupRouter = (app: App<Element>) => {
    app.use(router);
}

export default router
