import { Appointment, Consultation } from "@model/entities";
import { ConsultationTreatmentDataUpdateDTO } from "@model/dtos";
import { BaseService } from "./base.service";

class Service extends BaseService {
    async getHistory(id: Consultation['id']): Promise<Consultation> {
        return this.get(`/history/${id}`);
    }
    
    async exit(id: Appointment['id']) {
        return this.get(`/exit/${id}`);
    }

    async save(body: any) {
        return this.put('', body);
    }
}

export const ConsultationService = new Service('/consultation');