import { Appointment } from "@model/entities";
import { AuditPrintCreateDto } from '@model/dtos';
import { AuditAppointmentListResponse } from "@model/responses";
import { BaseService } from "./base.service";

class Service extends BaseService {
    async auditPrint(body: AuditPrintCreateDto) {
        return this.post(`/print`, body);
    }

    async listAppointment(appiontmentId: Appointment['id']): Promise<AuditAppointmentListResponse[]> {
        return this.get(`/appointment/${appiontmentId}`);
    }    
}

export const AuditService = new Service('/audit');