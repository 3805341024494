import { IsBoolean, IsDateString, IsEmail, IsObject, IsOptional, IsString, ValidateIf } from "class-validator";
import { Gender } from "../common";
import { PatientReferral } from "../patient/patient-referral";
import { PatientCaution } from "../patient/patient-caution";

export class PatientRegistrationFormSubmitDTO {
    @IsString()
    code: string;

    @IsString()
    name_zh: string;

    @IsString()
    name_en: string;

    @IsString()
    gender: Gender;

    @IsDateString()
    dob: Date;

    @IsString()
    hkid: string;
    
    @IsBoolean()
    @IsOptional()
    useOtherId?: boolean;
    
    @IsString()
    @IsOptional()
    otherId?: string;
    
    @IsString()
    @IsOptional()
    otherIdType?: string;
    
    @IsString()
    mobileAreaCode: string;

    @IsString()
    mobile: string;
    
    @IsString()
    @IsOptional()
    mobileAreaCode2?: string;

    @IsString()
    @IsOptional()
    mobile2?: string;

    @IsString()
    address: string;

    @IsString()
    @IsEmail()
    @ValidateIf((o) => o.email !== '')
    @IsOptional()
    email?: string;

    @IsObject()
    referral: PatientReferral;

    @IsString()
    @IsOptional()
    custodyName?: string;

    @IsString()
    @IsOptional()
    custodyMobileAreaCode?: string;

    @IsString()
    @IsOptional()
    custodyMobile?: string;

    @IsObject()
    caution: PatientCaution;

    @IsObject()
    medication: {
        value: boolean;
        description: string;
    };

    @IsObject()
    allergy: {
        asprin: false,
        penicillin: false,
        dairy: false,
        peanut: false,
        latex: false,
        pollen: false,
        insects: false,
        beans: false,
        others: false,
        otherText: '',
    };
    
    @IsObject()
    female: {
        numberOfKids: string,
        miscarriages: string,
        breastfeeding: boolean,
    }

    @IsString()
    @IsOptional()
    reason?: string;
}1