<script lang="ts">
import { eventEmitter } from "@/services/events";
import { Options, Vue, Prop, Watch } from "vue-property-decorator";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
@Options({
    components: {},
})
export default class PatientHistoryDataview extends Vue {
    store = usePatientHistoryDataviewStore();
    activeTab = null;

    @Prop({ default: false })
    redispense: boolean;
}
</script>

<template lang="pug">
.dataview#patient-history-dataview(v-loading="store.loading")
    template(v-if="store.currentConsultation")
        el-tabs(v-model="activeTab")
            PatientHistoryTabDiagnosis
            PatientHistoryTabPrescription(:redispense="redispense")
            PatientHistoryTabAcu
            PatientHistoryTabMassage
            PatientHistoryTabPayment
            PatientHistoryTabPrint
            PatientHistoryTabAttachment
    template(v-else)
        .center
            h3 沒有病歷記錄及診症資料
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.dataview {
    width: 100%;
}
</style>
