import { defaultLocale, Locale } from "@model/constants";
import { PrescriptionItem } from "@model/entities";
import { HerbComboData, PrescriptionPharmacyOption, PrescriptionUsage, PrescriptionUsageType } from "@model/models";


export const getUsageTextPrefix = (props: {
    is_required: boolean;
    is_multiple_per_day?: boolean;
    take?: PrescriptionUsageType;
    day?: number;
    times?: number;
    pack?: number;
    pack_unit?: string;
}, locale: Locale = defaultLocale): string => {
    const {
        is_required,
        is_multiple_per_day,
        take,
        day,
        times,
        pack,
        pack_unit,
    } = props;

    if (!is_required) {
        return '';
    }

    const translations: Record<Locale, any> = {
        zh: {
            internal: '內服 ',
            external: '外用 ',
            day: '天',
            timesPrefix: ' 每天',
            times: '次',
            packPrefix: '每次',
            total: '共',
            packs: {
                '包': '包',
                '碗': '碗',
                '劑': '劑',
            },
        },
        en: {
            internal: 'Internal Use ',
            external: 'External Use ',
            day: 'Day(s)',
            timesPrefix: '',
            times: 'Time(s) per day',
            packPrefix: 'Each time',
            total: 'Total',
            packs: {
                '包': 'Pack(s)',
                '碗': 'Bowl(s)',
                '劑': 'Dose(s)',
            },
        }
    };

    let text = '';
    text += take === 'internal' ? translations[locale].internal : translations[locale].external;
    text += `${day} ${translations[locale].day}`;
    text += `,${translations[locale].timesPrefix} ${times} ${translations[locale].times}`;

    if (is_multiple_per_day) {
        if (pack && pack_unit) {
            const total = day * times * pack;
            text += `, ${translations[locale].packPrefix} ${pack} ${translations[locale].packs[pack_unit]}, ${translations[locale].total} ${total} ${translations[locale].packs[pack_unit]}`;
        }
    } else {
        const total = day * times * 1;
        text += `, ${translations[locale].packPrefix} 1 ${translations[locale].packs["包"]}, ${translations[locale].total} ${total} ${translations[locale].packs["包"]}`;
    }
    return text;
}

export const getUsageText = (props: {
    is_required: boolean;
    is_multiple_per_day?: boolean;
    take?: PrescriptionUsageType;
    day?: number;
    times?: number;
    pack?: number;
    pack_unit?: string;
    remark?: string;
}, locale: Locale = defaultLocale): string => {
    const {
        is_required,
        remark,
    } = props;

    if (!is_required) {
        return remark;
    }

    let prefix = getUsageTextPrefix(props, locale);

    if (remark) {
        return prefix + `, ${remark}`;
    }
    
    return prefix;
}

export const getComboIngredientText = (data: HerbComboData): string => {
    if (!data?.content) {
        return null;
    }
    return data.content.map(x => `${x.name_zh}（${x.amount}${x.unit}）`).join('，');
}

/**
 * @param usage 
 * @param item 
 * @returns The final amount of (day * amount) of powder/pieces, or the amount of unit items
 */
export const getPrescriptionItemFinalAmount = (usage: PrescriptionUsage, item: Pick<PrescriptionItem, 'pricing_type' | 'amount'>): number => {
    if (!usage.is_required) {
        usage.day = 0;
    }

    switch (item.pricing_type) {
        case 'total_weight_expensive_daily':
        case 'total_weight':
        case 'total_weight_expensive_thershold':
        case 'pieces_cost':
            return Number(usage.day) * item.amount;
        case 'unit_cost':
            return item.amount;
        default:
            throw Error("未知的藥物收費模式");
    }
}

export const prescriptionUtils = {
    getUsageText,
    getComboIngredientText,
    getPrescriptionItemFinalAmount,
    getUsageTextPrefix,
}