import { Consultation, Patient } from "@model/entities";
import { PatientTableSearchDTO, PatientRemarkUpdateDTO, PatientUpdateDTO, PatientTagsUpdateDTO, PaginationQueryDTO } from "@model/dtos";
import { PatientQueryResponse, PatientTableSearchResponse } from "@model/responses";
import { BaseService } from "./base.service";
import dateFilter from "@/utils/filters/date.filter";
import { Locale } from "@model/constants";


class Service extends BaseService {
    async beforeCreate(body: any) {
        return {
            ...body,
            dob: body.dob ? dateFilter.formatDate(body.dob) : null,
        };
    }

    async getLocale(patientId: number): Promise<Locale> {
        return this.get(`/locale/${patientId}`);
    }

    async getTags(): Promise<string[]> {
        return this.get(`/tags`);
    }

    async tableSearch(body: PatientTableSearchDTO, pagination: PaginationQueryDTO = {}): Promise<PatientTableSearchResponse> {
        return this.post(`/table-search?${pagination ? this.parseQuery(pagination) : ''}`, body);
    }

    async edit(id: Patient['id'], body: PatientUpdateDTO): Promise<Patient> {
        body = {
            ...body,
            dob: body.dob ? dateFilter.formatDate(body.dob) : null,
        };
        return this.post(`/edit/${id}`, body);
    }

    async updateRemark(body: PatientRemarkUpdateDTO): Promise<Patient> {
        return this.post(`/remark`, body);
    }

    async updateTags(body: PatientTagsUpdateDTO): Promise<Patient> {
        return this.post(`/tags`, body);
    }

    async query(queryString: string): Promise<PatientQueryResponse> {
        return this.get(`/query/${queryString}`);
    }

    async listConsultations(patientId: number): Promise<Consultation[]> {
        return this.get(`/consultations/${patientId}`);
    }

    async hasHkidUsed(hkid: string): Promise<boolean> {
        return this.get(`/exist/hkid/${hkid}`);
    }

    async deactivate(patientId: number): Promise<Consultation[]> {
        return this.delete(`/${patientId}`);
    }

    async toggleTag(patientId: Patient['id'], key: string, value: boolean): Promise<Patient> {
        if (value) {
            return this.put(`/tag/${patientId}/${key}`);
        } else {
            return this.delete(`/tag/${patientId}/${key}`);
        }
    }

    async merge({ fromPatientId, toPatientId }: { fromPatientId: number, toPatientId: number }): Promise<void> {
        return this.post(`/merge`, {
            fromId: fromPatientId,
            toId: toPatientId,
        });
    }
}

export const PatientService = new Service('/patient');