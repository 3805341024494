<script lang="ts">
import { Emit, Options, Prop, Vue, Watch } from "vue-property-decorator";
import { useSelectItemStore } from "@/stores/modules/select-item";
import { Patient } from "@model/entities";
import { PatientService } from "@/services";
import { PatientQueryResponse } from "@model/responses";
import { useUserStore } from "@/stores/modules";

@Options({})
export default class SelectPatient extends Vue {
    user$ = useUserStore();

    state: string = "";

    @Prop()
    patientId = null;

    async searchQuery(queryString: string, cb) {
        if (queryString.length <= 2) {
            return cb([]);
        }
        const res = await PatientService.query(queryString);
        return cb(res.data);
    }

    @Watch("patientId", { immediate: true })
    async onPatientIdChanged(patientId: Patient["id"]) {
        if (patientId) {
            const patient = await PatientService.one<Patient>(patientId);
            this.state = `(${patient.code}) ${patient.name_zh}`;
        } else {
            this.state = "";
        }
    }

    @Emit("update:patientId")
    handleSelect(value: PatientQueryResponse["data"][0]) {
        return value.id;
    }
}
</script>

<template lang="pug">
el-autocomplete#select-patient(
    v-model="state"
    :fetch-suggestions="searchQuery"
    value-key="label"
    name="select-patient"
    placeholder="病人編號/名稱/電話/身份證號碼"
    @select="handleSelect"
)
    template(#default="{ item }")
        .body
            .top
                .p-name {{ item.name_zh }} 
                div
                    .p-code  {{ `#` }}{{ item.code }}
            .bottom
                .p-hkid 
                    icon(name="credit-card" :size="10" style="margin-right: 5px; color: #999999;")
                    | {{ $filters.hkid(item.hkid) }} 
                .p-mobile(v-if="user$.hasPermission('patient.contact')")
                    icon(name="mobile" :size="10" style="margin-right: 5px; color: #999999;")
                    | {{ item.mobile }}
                .p-tags
                    .mini(v-for="tag in item.tags") &sharp;{{ tag }}
</template> 

<style scoped lang="scss">
.body {
    line-height: initial;
    border-bottom: 1px solid #e1e1e1;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
}

.bottom {
    font-size: 12px;
    margin-bottom: 5px;
    gap: 5x;
    display: flex;
    flex-direction: column;
}

.p-tags {
    display:flex;
    flex-direction: row;
    gap: 2px;
    flex-wrap: wrap;
}

.p-name {
    display: block;
    white-space: initial;
    max-width: 100px;
    font-weight: bold;
}

.p-code {
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    display: inline-block;
    background-color: #888888;
    color: #fff;
    border-radius: 5px;
    padding: 1px 5px;
}
</style>
