<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";

@Options({
    components: {},
})
export default class PatientHistoryTabPayment extends Vue {
    store = usePatientHistoryDataviewStore();
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-payment(label="收費項目")
    .tab-container(v-loading="store.loading")
        PaymentTable(:payment="store.payment")
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
