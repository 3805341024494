import { Labels } from "../common";

export const prescriptionViolations = [
    "OVERDOSE_WARN",
    "PREG_BAN",
    "PREG_WARN",
    "G6PD_BAN",
    "G6PD_WARN",
    "BLOOD_THINNER_WARN",
    "CLASHES",
    "INCOMPATIBILITIES",
    "OTHER",
    "ALLERGY",
    "STOCK_WARN",
] as const;

export type PrescriptionViolation = typeof prescriptionViolations[number];

const colorBan = "#ff5e87";
const colorIncompatable = "#ffb536";
const colorWarn = "#fffb00";

export const prescriptionViolationsMap: Labels<PrescriptionViolation, {
    selectable: boolean
}> = {
    PREG_BAN: {
        label: "禁用藥",
        name: "妊娠禁用藥",
        color: colorBan,
        meta: {
            selectable: true,
        }
    },
    G6PD_BAN: {
        label: "禁用藥",
        name: "G6PD禁用藥",
        color: colorBan,
        show: false,
        meta: {
            selectable: true,
        }
    },
    CLASHES: {
        label: "十八反十九畏",
        name: "十八反",
        color: colorIncompatable,
        meta: {
            selectable: false,
        }
    },
    INCOMPATIBILITIES: {
        label: "十八反十九畏",
        name: "十九畏",
        color: colorIncompatable,
        show: false,
        meta: {
            selectable: false,
        }
    },
    OVERDOSE_WARN: {
        label: "用量超標",
        color: "#63c6ff",
        meta: {
            selectable: false,
        }
    },
    BLOOD_THINNER_WARN: {
        label: "影響華法林藥效",
        name: "影響華法林藥效",
        color: "#fff4d6",
        meta: {
            selectable: true,
        }
    },
    G6PD_WARN: {
        label: "慎用藥",
        name: "G6PD慎用藥",
        color: colorWarn,
        meta: {
            selectable: true,
        }
    },
    PREG_WARN: {
        label: "慎用藥",
        name: "妊娠慎用藥",
        color: colorWarn,
        show: false,
        meta: {
            selectable: true,
        }
    },
    ALLERGY: {
        label: "藥物敏感",
        color: "#7dff99",
        meta: {
            selectable: false,
        }
    },
    OTHER: {
        label: "其他",
        color: "#eeeeee",
        show: false,
        meta: {
            selectable: false,
        }
    },
    STOCK_WARN: {
        label: "庫存不足",
        color: "#cfa9fc",
        meta: {
            selectable: false,
        }
    },
};

export const translatePrescriptionViolation = (violation: PrescriptionViolation) => {
    return prescriptionViolationsMap[violation]?.name ?? prescriptionViolationsMap[violation]?.label ?? '-';
}
