import { defaultLocale, Locale } from "@model/constants";

export class PatientCaution {
    g6pd?: boolean;
    blood_thinner?: boolean;
    blood_thinner_warfarin?: boolean;
    pregnant?: boolean;
    pregnant_times?: number;
    pregnant_weeks?: number;
    no_age?: boolean;
    other?: boolean;
    other_text?: string;

    static getDefaultValue(): PatientCaution {
        return {
            g6pd: false,
            blood_thinner: false,
            blood_thinner_warfarin: false,
            pregnant: false,
            no_age: false,
            pregnant_times: 0,
            pregnant_weeks: 0,
            other: false,
            other_text: '',
        };
    }

    static sanitize(d: Partial<PatientCaution>): PatientCaution {
        return {
            ...PatientCaution.getDefaultValue(),
            ...d,
        };
    }

    static hasCaution(caution: PatientCaution): boolean {
        if (!caution) {
            return false;
        }
        return caution.g6pd || caution.blood_thinner_warfarin || caution.pregnant || caution.other;
    }
    
    static requireIndependentDispense(caution: PatientCaution, age: number): boolean {
        if (!caution) {
            return age <= 3;
        }
        return caution.g6pd || caution.pregnant || caution.other || age <= 3;
    }

    static getCautionText(caution: PatientCaution, locale: Locale = defaultLocale): string {
        if (!caution) {
            return '';
        }

        let arr = [];
        if (caution.g6pd) {
            arr.push('G6PD');
        }
        if (caution.blood_thinner) {
            arr.push('薄血藥');
        }
        if (caution.blood_thinner_warfarin) {
            arr.push('華法林');
        }
        if (caution.pregnant) {
            arr.push('懷孕');
        }
        if (caution.other) {
            arr.push(caution.other_text);
        }
        return arr.join(', ');
    }
}