import { BaseService } from "./base.service";
import { Prescription, Appointment } from "@model/entities";
import { PrescriptionStatus } from "@model/models";
import { DispensePrescriptionUpdateDto, DispenseInformationUpdateDto, AppointmentQueryDTO } from "@model/dtos";
import { DispenseClinicListResponse, DispensePharmacyListResponse } from "@model/responses";

class Service extends BaseService {
    async label(id: Prescription['id']) {
        return this.open(`/label/${id}`);
    }

    async manyByAppointmentId(appointmentId: Appointment['id']): Promise<DispensePharmacyListResponse | DispenseClinicListResponse> {
        return this.get(`/appointment/${appointmentId}`);
    }
    
    async listQuery(query: AppointmentQueryDTO): Promise<DispensePharmacyListResponse | DispenseClinicListResponse> {
        return this.get(`/?${this.parseQuery(query)}`);
    }
    
    async updateStatus(id: Prescription['id'], status: PrescriptionStatus) {
        return this.post(`/status/${id}`, { status });
    }

    async updatePrescription(id: Prescription['id'], body: DispensePrescriptionUpdateDto) {
        return this.post(`/prescription/${id}`, body);
    }

    async updateDispenseInfo(id: Prescription['id'], body: DispenseInformationUpdateDto) {
        return this.post(`/info/${id}`, body);
    }
}

export const DispenseService = new Service('/dispense');