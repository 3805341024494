export const herbPricingTypes = [
    // 淨重
    "total_weight",
    // 淨重 + 名貴藥材 每天收費
    "total_weight_expensive_daily",
    // 淨重 + 名貴藥材 多於某每單位收費
    "total_weight_expensive_thershold",
    // 每單位收費
    "unit_cost",
    // 飲片收費
    "pieces_cost",
] as const;

export type HerbPricingType = typeof herbPricingTypes[number];


export const herbPricingTypeIsExpensive = (herbType: HerbPricingType) => {
    return herbType === 'total_weight_expensive_daily' || herbType === 'total_weight_expensive_thershold';
}

export const translateHerbPricingType = (herbType: HerbPricingType) => {
    switch (herbType) {
        case "total_weight":
            return '總淨重';
        case "total_weight_expensive_daily":
            return '總淨重及額外每天收費 (名貴藥材)';
        case "total_weight_expensive_thershold":
            return '總淨重及額外上限收費 (名貴藥材)';
        case "unit_cost":
            return '每處方單位個別收費';
        case "pieces_cost":
            return '飲片每克收費';
    }
}


export const getHerbPricingTypePrefix = (herb: { pricing_type: HerbPricingType }) => {
    if (herb.pricing_type === 'total_weight_expensive_daily' || herb.pricing_type === 'total_weight_expensive_thershold') {
        return '*';
    }
    if (herb.pricing_type === 'pieces_cost') {
        return '#';
    }
    return '';
}