import { Clinic, Prescription } from "@model/entities";
import { IsArray, IsNumber } from "class-validator";

export class PaymentPrescriptionsPreviewDTO {
    /**
     * Appointment clinic id
     */
    @IsNumber()
    clinic_id: Clinic['id'];
    
    @IsArray()
    prescriptions: PaymentPrescriptionsPreviewRequiredParams[];
}


export type PaymentPrescriptionsPreviewRequiredParams = {
    clinic_id: Clinic['id'],
    index?: Prescription['index'],
    usage: Prescription['usage'],
    pharmacy_option?: Prescription['pharmacy_option'],
    items: {
        name: Prescription['items'][number]['name'];
        type: Prescription['items'][number]['type'];
        amount: Prescription['items'][number]['amount'];
        pricing_type: Prescription['items'][number]['pricing_type'];
        unit_cost: Prescription['items'][number]['unit_cost'];
        expensive_day_cost: Prescription['items'][number]['expensive_day_cost'];
        expensive_thershold: Prescription['items'][number]['expensive_thershold'];
        expensive_extra_unit_cost: Prescription['items'][number]['expensive_extra_unit_cost'];
        pieces_unit_cost: Prescription['items'][number]['pieces_unit_cost'];
    }[],
}
