import { BaseService } from "./base.service";
import { PackagePurchaseRequest, Package, Patient, PatientPackageItem, PackageConsumeRequest } from "@model/entities";
import { PackagePurchaseRequestDTO, PackageConsumeRequestDTO, PaginationQueryDTO } from "@model/dtos";
import { PaginatedResponse } from "@model/models";
import { PatientPackageItemListResponse, PatientPackageItemConsultationAvailableListResponse } from "@model/responses";

class Service extends BaseService {
    async query(queryParams: PaginationQueryDTO): Promise<PaginatedResponse<Package>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }

    async listPatientPackage(patientId: Patient['id']): Promise<PatientPackageItemListResponse> {
        return this.get(`/patient/${patientId}`);
    }

    async listPatientPackageForAppointment(appointmentId: Patient['id']): Promise<PatientPackageItemConsultationAvailableListResponse> {
        return this.get(`/appointment/${appointmentId}`);
    }

    async requestPurchase(body: PackagePurchaseRequestDTO): Promise<PackagePurchaseRequest> {
        return this.post(`/purchase`, body);
    }

    async requestConsume(body: PackageConsumeRequestDTO): Promise<PackageConsumeRequest> {
        return this.post(`/consume`, body);
    }

    async cancelPackage(packagePurchaseRequestId: PackagePurchaseRequest['id']): Promise<void> {
        return this.deleteAdvanced(`/cancel/package/${packagePurchaseRequestId}`);
    }

    async cancelPackageItem(patientPackageItemId: PatientPackageItem['id']): Promise<void> {
        return this.deleteAdvanced(`/cancel/package-item/${patientPackageItemId}`);
    }

    async cancelPackagePurchaseRequest(packagePurchaseRequestId: PackagePurchaseRequest['id']): Promise<void> {
        return this.deleteAdvanced(`/cancel/package-purchase-request/${packagePurchaseRequestId}`);
    }

    async skipPackageConsumeRequestItemGeneration(packageConsumeRequestId: PackageConsumeRequest['id']): Promise<void> {
        return this.deleteAdvanced(`/consume/skip-item/${packageConsumeRequestId}`);
    }

    async cancelPackageConsumeRequest(packageConsumeRequestId: PackageConsumeRequest['id']): Promise<void> {
        return this.deleteAdvanced(`/cancel/package-consume-request/${packageConsumeRequestId}`);
    }
}

export const PackageService = new Service('/package');