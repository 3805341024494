import { Patient, Consultation } from "@model/entities";

export class ConsultationDiagnosisFacadeStateVO {
    content: string;
    diagnosis_pulse: string;
    diagnosis_tongue: string;
    diagnosis_ill: string;
    diagnosis_ill_type: string;
    diagnosis_treatment: string;
    has_sick_leave: boolean;
    _sickLeaveDateRange: (Date | string)[];
    sick_leave_start: Date | string;
    sick_leave_end: Date | string;
    sick_leave_start_from_pm: boolean;
    sick_leave_end_at_am: boolean;
    override_sick_leave_date_count?: number;
    remark: string;

    static getDefaultValue(): ConsultationDiagnosisFacadeStateVO {
        return {
            content: '',
            diagnosis_pulse: '',
            diagnosis_tongue: '',
            diagnosis_ill: '',
            diagnosis_ill_type: '',
            diagnosis_treatment: '',
            has_sick_leave: false,
            _sickLeaveDateRange: null,
            sick_leave_start: null,
            sick_leave_end: null,
            sick_leave_start_from_pm: false,
            sick_leave_end_at_am: false,
            override_sick_leave_date_count: null,
            remark: '',
        };
    }

    static fromEntity(consultation: Consultation, options?: { excludeSickLeave?: boolean }): ConsultationDiagnosisFacadeStateVO {
        const {
            excludeSickLeave = false,
        } = options ?? {};

        return {
            content: consultation.content,
            diagnosis_pulse: consultation.diagnosis_pulse,
            diagnosis_tongue: consultation.diagnosis_tongue,
            diagnosis_ill: consultation.diagnosis_ill,
            diagnosis_ill_type: consultation.diagnosis_ill_type,
            diagnosis_treatment: consultation.diagnosis_treatment,
            has_sick_leave: excludeSickLeave ? false : consultation.has_sick_leave,
            _sickLeaveDateRange: excludeSickLeave ? null : (consultation.has_sick_leave ? [consultation.sick_leave_start, consultation.sick_leave_end] : null),
            sick_leave_start: excludeSickLeave ? null : consultation.sick_leave_start,
            sick_leave_end: excludeSickLeave ? null : consultation.sick_leave_end,
            sick_leave_start_from_pm: excludeSickLeave ? false : consultation.sick_leave_start_from_pm,
            sick_leave_end_at_am: excludeSickLeave ? false : consultation.sick_leave_end_at_am,
            override_sick_leave_date_count: excludeSickLeave ? null : consultation.override_sick_leave_date_count,
            remark: consultation.remark,
        };
    }
}