<script lang="ts">
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { PatientVitalService } from "@/services/patient-vital.service";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { notify } from "@/utils/notify";
import { PatientVitalCreateDTO } from "@model/dtos";
import { Patient, PatientVital } from "@model/entities";
import { FormInstance } from "element-plus";
import { Options, Vue } from "vue-property-decorator";

@Options({
    components: {},
})
export default class PatientVitalDialog extends Vue {
    user = useUserStore();
    open = false;
    btnLoading = false;

    patientId: Patient["id"] = null;

    vitals: PatientVital[] = [];
    formData: PatientVitalCreateDTO = null;

    @ListenEvent("patient-vital-dialog:open")
    async handleOpen(patientId: Patient["id"]) {
        this.patientId = patientId;
        this.vitals = await PatientVitalService.list(patientId);
        this.formData = {
            patient_id: patientId,
            temp: null,
            temp_unit: "C",
            height: null,
            weight: null,
            high_blood_pressure: null,
            low_blood_pressure: null,
            breathe_rate: null,
            pulse: null,
        };

        this.open = true;
    }

    async handleSubmit() {
        try {
            this.btnLoading = true;
            const form = this.$refs["form"] as FormInstance;
            const valid = await form.validate();

            if (valid) {
                await PatientVitalService.create(this.formData);
                notify.saved();
                this.open = false;
            }
        } catch (e) {
            handleError(e);
        } finally {
            this.btnLoading = false;
        }
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" top="1vh" destroy-on-close)
    template(#header)
        div 生命表徵
    .body
        fieldset(v-if="vitals.length > 0")
            legend 過往記錄
            el-table.scroll(:data="vitals")
                el-table-column(label="紀錄日期")
                    template(#default="scope")
                        div {{ $filters.date(scope.row.created_at) }}
                el-table-column(label="體溫")
                    template(#default="scope")
                        div {{ $filters.tempUnit(scope.row.temp, scope.row.temp_unit) }}
                el-table-column(label="身高(cm)")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.height) }}
                el-table-column(label="體重(kg)")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.weight) }}
                el-table-column(label="上壓(mmHg)")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.high_blood_pressure) }}
                el-table-column(label="下壓(mmHg)")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.low_blood_pressure) }}
                el-table-column(label="呼吸(次/分)")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.breathe_rate) }}
                el-table-column(label="脈搏(次/分)")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.pulse) }}

        fieldset
            legend 新記錄
            el-row
                el-col(:span="20")
                    el-form(ref="form" :model="formData" label-position="top")
                        el-row
                            el-col(:span="10")
                                el-form-item(label="體溫" prop="temp")
                                    el-input(v-model='formData.temp' type="decimal" placeholder="0.0")  
                            el-col.flex.center(:span="5")
                                el-form-item(label="單位" prop="temp")
                                    el-radio(v-model='formData.temp_unit' label="C" value="C") °C
                                    el-radio(v-model='formData.temp_unit' label="F" value="F") °F
                        el-row
                            el-col(:span="10")
                                el-form-item(label="身高 (cm)" prop="height")
                                    el-input(v-model='formData.height' type="decimal" placeholder="0.0 cm")  
                            el-col(:span="1")
                            el-col(:span="10")
                                el-form-item(label="體重 (kg)" prop="weight")
                                    el-input(v-model='formData.weight' type="decimal" placeholder="0.0 kg")  

                        el-row
                            el-col(:span="10")
                                el-form-item(label="上壓 (mmHg)" prop="height")
                                    el-input(v-model='formData.high_blood_pressure' type="decimal" placeholder="0.0 mmHg")  
                            el-col(:span="1")
                            el-col(:span="10")
                                el-form-item(label="上壓 (mmHg)" prop="low_blood_pressure")
                                    el-input(v-model='formData.low_blood_pressure' type="decimal" placeholder="0.0 mmHg")  

                        el-row
                            el-col(:span="10")
                                el-form-item(label="呼吸 (次/分)" prop="breathe_rate")
                                    el-input(v-model='formData.breathe_rate' type="decimal" placeholder="0.0 (次/分)")  
                            el-col(:span="1")
                            el-col(:span="10")
                                el-form-item(label="脈搏 (次/分)" prop="pulse")
                                    el-input(v-model='formData.pulse' type="decimal" placeholder="0.0 (次/分)")  
                el-col(:span="3")
                    div
                        Button(type="primary" @click="handleSubmit") 儲存
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
