import { useCache } from "@/hooks/useCache";
import { extend } from "lodash";
import { BaseService } from "../base.service";
import { UserLoginDTO, UserCreateDto } from "@model/dtos";
import { axPost } from "../axios";
import { User, UserGroup } from "@model/entities";
import { SelectItem } from '@model/models';


class Service extends BaseService {
    async list() {
        return this.get<UserGroup[]>(``);
    }

    async getItems() {
        return this.get<SelectItem<UserGroup['id']>[]>(`/items`);
    }
}

export const UserGroupService = new Service('/user-group');