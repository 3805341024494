import { useSelectItemStoreWithOut } from "@/stores/modules/select-item";
import { Clinic } from "@model/entities";

const translateClinic = (clinicId: Clinic['id']) => {
    const selectItems = useSelectItemStoreWithOut();
    return selectItems.clinics.find(x => x.value === clinicId)?.label ?? clinicId;
};

const formatClinics = (clinics: Clinic[]) => {
    return clinics.map(x => x.meta.name_zh).join(', ');
}

export default {
    translateClinic,
    formatClinics,
}