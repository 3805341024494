import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Clinic, PaymentItem, Prescription } from '@model/entities';
import { PatientAlertDTO } from '@model/dtos';
import { eventEmitter } from '@/services/events';
import { PaymentService, PrescriptionDeliveryService, PrescriptionService } from '@/services';
import { PrescriptionFacadeStateVO } from '@model/vos';
import { notify } from '@/utils/notify';
import { useSelectItemStore } from '../select-item';
import { useClinicStore, useUserStore } from '@/stores/modules';
import { PrescriptionSingleDosageListVO } from '@model/vos';
import { previewPrescriptionFacade, printPrescriptionFacade } from '@/utils/print';
import { PrescriptionUsage } from '@model/models';
import { translate } from '@model/utils';
import { ElMessageBox } from 'element-plus';

export interface PrescriptionFacadeState {
    index: number;
    selectedClinic: Clinic['id'],
    prescriptions: PrescriptionFacadeStateVO[];
}

const defaultState: PrescriptionFacadeState = {
    index: 1,
    selectedClinic: null,
    prescriptions: [
        PrescriptionFacadeStateVO.getDefaultValue(),
    ],
}

export const usePrescriptionFacadeStore = defineStore({
    id: 'prescription-facade',
    state: (): PrescriptionFacadeState => cloneDeep(defaultState),
    getters: {
        hasPrescription(): boolean {
            return !(this.prescriptions.length === 0 || (this.prescriptions.length === 1 && this.prescriptions[0].items.length === 0));
        },
        numPrescription(): number {
            return this.prescriptions.length;
        },
        currentPrescription(): PrescriptionFacadeState['prescriptions'][number] {
            return this.prescriptions[this.index - 1];
        },
        isCurrentClinic(): boolean {
            const user = useUserStore();
            return this.currentPrescription.clinic_id === user.currentClinicId;
        },
        isPharmacy(): boolean {
            const clinic$ = useClinicStore();
            return clinic$.pharmacyIds.includes(this.currentPrescription.clinic_id);
        },
        currentPrescriptionDosage(): any {
            return {
                herb: this.currentPrescription.items.filter(x => x.type === 'single' || x.type === 'combo').map(x => Number(x.amount)).reduce((x, y) => x + y, 0).toFixed(2),
                pieces: this.currentPrescription.items.filter(x => x.type === 'pieces').map(x => Number(x.amount)).reduce((x, y) => x + y, 0).toFixed(2),
                itemCount: this.currentPrescription.items.length,
            };
        },
        currentPrescriptionDosageList(): PrescriptionSingleDosageListVO {
            return PrescriptionSingleDosageListVO.fromItems(this.currentPrescription.items);
        },
    },
    actions: {
        async init(prescriptions?: Prescription[], clinicId?: Clinic['id']) {
            const user = useUserStore();
            this.index = 1;
            this.selectedClinic = clinicId ?? user.currentClinicId;
            if (prescriptions && prescriptions.length > 0) {
                const selectItemStore = useSelectItemStore();
                const herbBrandItems = await selectItemStore.getHerbBrands();
                this.prescriptions = PrescriptionFacadeStateVO.fromEntities(prescriptions, herbBrandItems);
            } else {
                this.prescriptions = [PrescriptionFacadeStateVO.getDefaultValue(this.selectedClinic)];
            }
            eventEmitter.emit("consultation-prescription:init");
        },
        async inject(prescriptions?: Prescription[]) {
            if (prescriptions && prescriptions.length > 0) {
                const selectItemStore = useSelectItemStore();
                const herbBrandItems = await selectItemStore.getHerbBrands();
                this.prescriptions = PrescriptionFacadeStateVO.fromEntities(prescriptions, herbBrandItems);
            }
            eventEmitter.emit("consultation-prescription:init");
        },
        async injectByVO(prescriptions?: PrescriptionFacadeStateVO[]) {
            if (this.prescriptions && this.prescriptions.length > 0 && this.prescriptions.some(x => x.items.length > 0) && await notify.confirm('是否要疊加處方？', '提示', '疊加', '取代')) {
                this.prescriptions = this.prescriptions.filter(x => x.items.length > 0);
                this.prescriptions.push(...prescriptions);
                this.index = this.prescriptions.length;
            } else {
                this.prescriptions = prescriptions;
            }
            this.selectedClinic = this.prescriptions[this.index - 1].clinic_id;
            eventEmitter.emit("consultation-prescription:init");
        },
        tryUpdatePrescriptionClinic() {
            if (this.currentPrescription.clinic_id !== this.selectedClinic) {
                if (this.currentPrescription.items.length > 0) {
                    notify.error('已有處方項目，請先新增處方或刪除來自原本診所的處方項目。');
                    return false;
                }
                this.currentPrescription.clinic_id = this.selectedClinic;
            }
            eventEmitter.emit("consultation-wordlist-herb:clinic-changed", this.currentPrescription.clinic_id);
            return true;
        },
        addPrescription() {
            const user = useUserStore();
            this.prescriptions.push(PrescriptionFacadeStateVO.getDefaultValue(this.selectedClinic));
            this.index = this.prescriptions.length;
        },
        deletePrescription() {
            const user = useUserStore();
            this.prescriptions.splice(this.index - 1, 1);
            if (this.prescriptions.length === 0) {
                this.prescriptions.push(PrescriptionFacadeStateVO.getDefaultValue(this.selectedClinic));
            }
            this.index = 1;
            this.selectedClinic = this.prescriptions[this.index - 1].clinic_id;
            eventEmitter.emit('consultation-prescription:deleted', this.index);
        },
        async fetchDeliveryTime() {
            const deliveryTime = await PrescriptionDeliveryService.getDeliveryTime({
                decocting: this.currentPrescription.pharmacy_option.decocting,
                delivery_option: this.currentPrescription.pharmacy_option.delivery_option,
            });
            this.currentPrescription.pharmacy_option.delivery_time = deliveryTime;
        },
        async validate(alert: PatientAlertDTO) {
            const pharms = this.prescriptions.filter(x => x.clinic_id !== useUserStore().currentClinicId);
            for (const p of pharms) {
                if (p.pharmacy_option.decocting === null) {
                    throw Error(`請選擇${translate('props.prescription.pharmacy_option.decocting')}`);
                }
            }

            const zeroOrNegativeAmount = this.prescriptions.map(x => x.items.filter(x => x.amount <= 0 || isNaN(x.amount))).reduce((x, y) => x.concat(y), []);
            if (zeroOrNegativeAmount.length > 0) {
                throw Error(`藥物 <${zeroOrNegativeAmount.map(x => x.name).join(', ')}> 的用量不正確`);
            }

            const res = await PrescriptionService.validate({
                alert,
                prescriptions: this.prescriptions.map(x => ({
                    usage: PrescriptionUsage.sanitize(x.usage),
                    items: x.items.map(y => ({
                        id: y.id,
                        name: y.name,
                        pricing_type: y.pricing_type,
                        amount: Number(y.amount),
                    }))
                }))
            });
            eventEmitter.emit('consultation-prescription:validated', res);
            return res;
        },
        async previewPrescriptionPrice(clinic_id: Clinic['id']): Promise<PaymentItem[]> {
            const payments = await PaymentService.previewPrescriptions({
                clinic_id,
                prescriptions: this.prescriptions,
            });
            return payments;
        },
        async previewPrescription({ consultationId, patient, caution, diagnosis, appointmentId }) {
            await previewPrescriptionFacade({
                appointmentId,
                consultationId,
                prescription: this.currentPrescription,
                patient,
                caution,
                prescriptionIndex: this.index,
                diagnosis,
            });
        },
        async printPrescription({ consultationId, consultationDate, patient, caution, diagnosis, appointmentId }) {
            await printPrescriptionFacade({
                appointmentId,
                consultationId,
                consultationDate,
                prescriptions: this.prescriptions,
                patient,
                caution,
                diagnosis,
            });
        }
    }
});
