import { Appointment, Clinic, Consultation, Patient, User } from "@model/entities";
import { print, renderTemplate } from ".";
import date from '@model/utils/date';
import { useUserStore } from "@/stores/modules";
import { AppointmentService, AuditService, ClinicService, ConsultationService, DoctorService, OrganizationService, PatientService, UserService } from "@/services";
import clinicFilter from "../filters/clinic.filter";
import hkidFilter from "../filters/hkid.filter";
import { notify } from "../notify";
import { defaultLocale, Locale } from "@model/constants";
import { getPrintTitle } from "@model/models";

interface PrintParam {
    company: {
        name_zh: string,
        name_en: string,
    },
    clinic: {
        meta: {
            address_zh: string,
            address_en: string,
            mobile: string,
            email: string,
        }
    },
    patient: {
        name_zh: string,
        name_en: string,
        hkid: string,
    },
    doctor: {
        display_name: string,
        reg_code: string,
    },
    consultation: {
        diagnosis_ill: string,
        consult_dt: string,
    },
    user: {
        name_zh: string,
    },
    sickLeave: {
        from: string,
        to: string,
        total: string,
    },
    remark: string;
    date: string;
}

export const printSickLeaveProof = async (options: {
    consultation: Consultation,
    remark: string,
    locale?: Locale,
}) => {
    const {
        locale = defaultLocale,
    } = options;

    await print({
        folder: "consultation",
        templateName: `sick-leave-proof_${locale}`,
        options,   
        title: getPrintTitle({
            clinicCode: options.consultation.appointment.clinic.code,
            patientId: options.consultation.appointment.patient.id,
            patientName: options.consultation.appointment.patient.name_zh,
            appointmentDate: options.consultation.appointment.date,
            documentType: 'sick-leave-proof',
        }),
        appointmentId: options.consultation.appointment_id,
        paramsGenerator: async ({ consultation, remark }): Promise<PrintParam> => {
            const appointment = consultation.appointment;
            const doctor = consultation.appointment.doctor;
            const userStore = useUserStore();
            const company = await OrganizationService.load();
            const user = await UserService.one<User>(userStore.user.id);
            const patient = await PatientService.one<Patient>(consultation.patient_id);
            const doctorEsign = await DoctorService.getEsign(doctor.id);

            const am = locale === 'zh' ? '上午' : 'AM';
            const pm = locale === 'zh' ? '下午' : 'PM';

            const sickLeave = {
                from: `${date.formatDate(consultation.sick_leave_start)} ${consultation.sick_leave_start_from_pm ? pm : am}`,
                to: `${date.formatDate(consultation.sick_leave_end)} ${consultation.sick_leave_end_at_am ? am : pm}`,
                total: consultation.override_sick_leave_date_count ?? Number(date.getSickLeaveDays(consultation.sick_leave_start, consultation.sick_leave_end, {
                    sick_leave_start_from_pm: consultation.sick_leave_start_from_pm,
                    sick_leave_end_at_am: consultation.sick_leave_end_at_am,
                }).toFixed(1)).toString(),
            }

            return {
                company: {
                    name_zh: appointment.clinic.meta.company_header_zh ?? company.name_zh,
                    name_en: appointment.clinic.meta.company_header_en ?? company.name_en,
                },
                clinic: {
                    meta: {
                        address_zh: appointment.clinic.meta.address_zh,
                        address_en: appointment.clinic.meta.address_en,
                        mobile: appointment.clinic.meta.mobile,
                        email: appointment.clinic.meta.email,
                    }
                },
                consultation: {
                    diagnosis_ill: consultation.diagnosis_ill,
                    consult_dt: date.formatTimeslotWithAMPM(appointment.date, appointment.timeslot, locale),
                },
                patient: {
                    name_zh: patient.name_zh,
                    name_en: patient.name_en,
                    hkid: hkidFilter(patient.hkid),
                },
                doctor: {
                    display_name: doctor.display_name,
                    display_name_en: doctor.display_name_en,
                    reg_code: doctor.reg_code,
                    esignUrl: doctorEsign ? doctorEsign.destination : null,
                },
                user: {
                    name_zh: user.name_zh,
                },
                sickLeave,
                remark,
                date: date.formatDate(),
            };
        }
    });
}