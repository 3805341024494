import { createRouter, createWebHashHistory } from 'vue-router'
import type { Router, RouteLocationNormalized, RouteRecordNormalized, RouteMeta } from 'vue-router'
import { isUrl } from '@/utils/is'
import { omit, cloneDeep } from 'lodash-es'


export const flatMultiLevelRoutes = (routes: AppRouteRecordRaw[]) => {
    const modules: AppRouteRecordRaw[] = cloneDeep(routes)
    for (let index = 0; index < modules.length; index++) {
        const route = modules[index]
        if (!isMultipleRoute(route)) {
            continue
        }
        promoteRouteLevel(route)
    }
    return modules
}

// 層級是否大於2
const isMultipleRoute = (route: AppRouteRecordRaw) => {
    if (!route || !Reflect.has(route, 'children') || !route.children?.length) {
        return false
    }

    const children = route.children

    let flag = false
    for (let index = 0; index < children.length; index++) {
        const child = children[index]
        if (child.children?.length) {
            flag = true
            break
        }
    }
    return flag
}

// 生成二級路由
const promoteRouteLevel = (route: AppRouteRecordRaw) => {
    let router: Router | null = createRouter({
        routes: [route as unknown as RouteRecordNormalized as any],
        history: createWebHashHistory()
    })

    const routes = router.getRoutes()
    addToChildren(routes, route.children || [], route)
    router = null

    route.children = route.children?.map((item) => omit(item, 'children'))
}

// 添加所有子菜單
const addToChildren = (
    routes: RouteRecordNormalized[],
    children: AppRouteRecordRaw[],
    routeModule: AppRouteRecordRaw
) => {
    for (let index = 0; index < children.length; index++) {
        const child = children[index]
        const route = routes.find((item) => item.name === child.name)
        if (!route) {
            continue
        }
        routeModule.children = routeModule.children || []
        if (!routeModule.children.find((item) => item.name === route.name)) {
            routeModule.children?.push(route as unknown as AppRouteRecordRaw)
        }
        if (child.children?.length) {
            addToChildren(routes, child.children, routeModule)
        }
    }
}
