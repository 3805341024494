import { DoctorPrescription, DoctorPrescriptionItem } from "@model/entities";
import { DoctorPrescriptShareConfig, PrescriptionUsage } from "@model/models";

export class DoctorPrescriptionStateVO {
    id?: number;
    doctor_id?: number;
    share_config: DoctorPrescriptShareConfig;
    display_name: string;
    description: string;
    usage?: PrescriptionUsage;
    caution!: string;
    remark: string = "";
    internal_remark: string = "";
    active!: boolean;

    createdByName?: string;
    createdAt?: Date;
    updatedByName?: string;
    updatedAt?: Date;

    items?: DoctorPrescriptionStateVOItem[];

    static getDefaultValue(): DoctorPrescriptionStateVO {
        return {
            id: undefined,
            doctor_id: null,
            share_config: 'all',
            display_name: "",
            description: "",
            usage: PrescriptionUsage.sanitize({
                is_required: true,
                is_multiple_per_day: true,
                take: 'internal',
                day: 3,
                times: 2,
                pack: 1,
                pack_unit: '包',
            }),
            caution: "",
            remark: "",
            internal_remark: "",
            active: true,
            items: [
            ],
        };
    }

    static fromEntity(entity: DoctorPrescription, options?: {}): DoctorPrescriptionStateVO {
        const {

        } = options ?? {};

        return {
            id: entity.id,
            doctor_id: entity.doctor_id,
            share_config: entity.share_config,
            display_name: entity.display_name ?? "",
            description: entity.description ?? "",
            usage: entity.usage,
            caution: entity.caution ?? "",
            remark: entity.remark ?? "",
            internal_remark: entity.internal_remark ?? "",
            active: entity.active ?? true,
            //
            items: entity.items?.map(item => DoctorPrescriptionStateVOItem.fromEntity(item)) ?? null,
            //
            createdByName: entity.createdBy?.name_zh,
            createdAt: entity.created_at,
            updatedByName: entity.updatedBy?.name_zh,
            updatedAt: entity.updated_at,
        };
    }
}

export class DoctorPrescriptionStateVOItem {
    id?: string;
    order!: number;
    herb_code!: string;
    herb_name!: string;
    stock!: number;
    amount!: number;
    unit!: string;
    method!: string;

    static getDefaultValue(): DoctorPrescriptionStateVOItem {
        return {
            id: undefined,
            herb_code: "",
            herb_name: "",
            order: 0,
            amount: 0,
            stock: 0,
            unit: "",
            method: "",
        };
    }

    static fromEntity(entity: DoctorPrescriptionItem, options?: {}): DoctorPrescriptionStateVOItem {
        const {

        } = options ?? {};

        return {
            id: entity.id,
            herb_code: entity.herb_code,
            herb_name: entity.herb_name,
            order: entity.order,
            stock: null,
            amount: entity.amount,
            unit: entity.unit,
            method: entity.method,
        };
    }

}