<script lang="ts">
import { Options, Vue,mixins } from "vue-property-decorator";
import Background from "@/components/background/Background.vue";
import { useLayoutStore } from "./stores/modules/layout";

@Options({
    components: {
        Background,
    },
})
export default class App extends Vue {
    readonly layout$ = useLayoutStore();

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }

    private onResize() {
        this.layout$.setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }
}
</script>
<template lang="pug">
Background
    router-view
    section#dialogs
        GlobalMessageDialog
        AppointmentDialog
        PatientHistoryDialog
        PatientVitalDialog
        PatientCreateDialog
        PatientUpdateDialog
        AppointmentCancelDialog
        AppointmentUpdateReasonDialog
        PrintConsultationProofDialog
        PrintSickLeaveProofDialog
        PrintPregnancyProofDialog
        PatientAppointmentDialog
        AppointmentAttachmentDialog
        AppointmentRemarkDialog
        AppointmentAuditDialog
        AppointmentPrescriptionTracingDialog
        PrintDialog
</template>

<style scoped lang="scss"></style>
