import { AdminHerbStockOrderDTO } from "@model/dtos/herb/admin-herb-stock-order.dto";
import { Clinic, Herb, HerbBrand, HerbStockOrder } from "@model/entities";
import { HerbType } from "@model/models";
import { convert2dp } from "@model/utils";

export class StockOrderHerbInfoVO {
    id: Herb['id'];
    code: string;
    name: string;
    brand: string;
    clinicId: Clinic['id'];
    brandId: HerbBrand['id'];
    type: HerbType;
    stockMin: string;
    stockWeight: string;
    stockSafeValue: string;
    currentStock: string;
    stockInCost: string;
    stockUnit: string;
    suggestedStock: string;
    shouldOrder: boolean;
    amount?: number;

    static fromEntity(herb: Herb): StockOrderHerbInfoVO {
        const stockMin = convert2dp(Math.ceil((herb.stock_min * 10) / herb.stock_weight) / 10);
        const stockSafeValue = convert2dp(Math.ceil((herb.stock_safe_value * 10) / herb.stock_weight) / 10);
        const currentStock = convert2dp(Math.ceil((herb.stock.stock * 10) / herb.stock_weight) / 10);

        // TODO move to utils
        const suggestedStock = convert2dp(Math.max(0, Math.ceil(Number(stockMin) - Number(currentStock))));
        const shouldOrder = Number(currentStock) <= Number(stockMin);

        return {
            id: herb.id,
            code: herb.code,
            name: herb.name_zh,
            brand: herb.brand.name_zh,
            clinicId: herb.clinic_id,
            brandId: herb.brand.id,
            type: herb.type,
            stockMin,
            stockWeight: convert2dp(herb.stock_weight),
            stockSafeValue,
            currentStock,
            stockInCost: convert2dp(herb.stock_in_cost),
            stockUnit: herb.stock_unit.name_zh,
            suggestedStock,
            shouldOrder,
        };
    }

    // TODO not the correct type
    static fromHerbStockOrder(order: HerbStockOrder): Partial<StockOrderHerbInfoVO>[] {
        if (!order?.items) {
            return [];
        }
        return order.items.sort((x, y) => x.herb_id - y.herb_id).map(x => ({
            id: x.herb_id,
            code: x.herb_code,
            name: x.herb_name,
            brand: order.brand_name,
            brandId: order.brand_id,
            amount: x.amount,
            stockUnit: x.unit,
            stockInCost: convert2dp(x.unit_cost),
            totalCost: convert2dp(x.total_cost),
        }));
    }

    static toAdminHerbStockOrderDTO(items: Partial<StockOrderHerbInfoVO>[], others: {
        clinicId: Clinic['id'],
        brandId: HerbBrand['id'],
        brandName: string,
        remark: string;
    }): AdminHerbStockOrderDTO {
        if (!items.every(x => !isNaN(Number(x.amount)))) {
            throw Error('訂購數量有誤');
        }

        if (new Set(items.map(x => x.brand)).size > 1) {
            throw Error('每張訂單只能存放同一品牌的藥物');
        }

        return {
            remark: others.remark,
            clinic_id: others.clinicId,
            brand_id: others.brandId,
            brand_name: others.brandName,
            items: items.map(x => {
                const total_cost = convert2dp(Number(x.amount) * Number(x.stockInCost));
                return {
                    herb_id: x.id,
                    herb_code: x.code,
                    herb_name: x.name,
                    brand: x.brand,
                    amount: convert2dp(x.amount),
                    unit_cost: x.stockInCost,
                    unit: x.stockUnit,
                    total_cost,
                };
            })
        }
    }
}