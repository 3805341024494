import { FileUploadResponse } from "@model/responses";
import { BaseService } from "./base.service";
import { FileUploadDTO } from "@model/dtos";

class Service extends BaseService {
    async getPublicUrl(destination: string): Promise<string> {
        return this.get(`${destination}`);
    }

    async uploadFile(body: FileUploadDTO): Promise<FileUploadResponse> {
        return this.postForm('', body);
    }

    async deleteFile(destination: string): Promise<void> {
        return this.delete('', { destination });
    }
}

export const FileService = new Service('/file');