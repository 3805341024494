import { convert2dp } from "@model/utils";
import { PricingDiscountType } from "@model/models";

const calculatePaymentItemPrice = (props: {
    amount: string | number,
    formula?: string | number,
    prepaid?: string | number,
}, options = { ignorePrepaid: false }): string => {
    const amount = Number(props.amount);
    const prepaid = Number(props.prepaid ?? 0);
    const formula = Number(props.formula ?? 100);

    if (isNaN(prepaid) || isNaN(formula) || isNaN(amount)) {
        throw Error('金額錯誤');
    }

    if (options.ignorePrepaid) {
        return convert2dp(amount * (formula / 100));
    }
    // Items should be in 2 decimal place, ceil in total
    return convert2dp(amount * (formula / 100) - prepaid);
}

const calculatePaymentItemTotalPrice = (props: {
    amount: string | number,
    formula?: string | number,
    prepaid?: string | number,
}[], options = { ignorePrepaid: false }): string => {
    return convert2dp(props.map(x => calculatePaymentItemPrice(x, options)).reduce((x, y) => Number(x) + Number(y), 0));
}

const calculatePaymentItemTotalPrepaidPrice = (props: {
    amount: string | number,
    formula?: string | number,
    prepaid?: string | number,
}[]): string => {
    return convert2dp(props.map(x => Number(x.prepaid)).reduce((x, y) => x + y, 0));
}

const calculatePercentageDiscount = (
    itemsTotal: string | number,
    constantDiscounts: string | number,
    discountItem: {
        amount: string | number,
        type: PricingDiscountType,
    }
) => {
    return (Number(itemsTotal) - Number(constantDiscounts)) * (1 - Number(discountItem.amount));
}

const calculateDiscount = (
    itemsTotal: string | number,
    discountItems: Array<{
        amount: string | number,
        type: PricingDiscountType,
    }>
): {
    constantDiscounts,
    percentageDiscount,
    total,
} => {
    const constantDiscounts = discountItems.filter((x) => x.type === "constant")
        .map((x) => x.amount)
        .reduce((x, y) => {
            return Number(x) + Number(y)
        }, 0) as number;

    let percentageDiscount = 0;
    // if (discountItems.filter((x) => x.type === "percentage").length > 1) {
    //     throw Error('只能存在一個折扣優惠');
    // }

    const percentage = discountItems.filter((x) => x.type === "percentage")
        .map((x) => x.amount)
        .reduce((x, y) => {
            return Number(x) * Number(y)
        }, 1) as number;

    if (percentage > 1) {
        throw Error('折扣不能多於 100%');
    }
    if (percentage >= 0) {
        percentageDiscount = (Number(itemsTotal) - constantDiscounts) * (1 - percentage);
    }
    return {
        constantDiscounts: convert2dp(constantDiscounts),
        percentageDiscount: convert2dp(percentageDiscount, false, true),
        total: convert2dp(constantDiscounts + percentageDiscount),
    }
}

const calculateGrandTotal = (props: {
    total: string,
    discount: string,
    hcv?: string,
}): string => {
    const {
        total,
        discount,
        hcv = 0,
    } = props;
    // Ceil back to 1 decimal place
    return convert2dp(Math.floor((Number(total) - Number(discount) - Number(hcv)) * 100) / 100, true);
}

const calculateGrandTotalForPrepaid = (props: {
    prepaid: string,
    discount: string,
    hcv?: string,
}): string => {
    const {
        prepaid,
        discount,
        hcv = 0,
    } = props;
    // Ceil back to 1 decimal place
    return convert2dp(Math.floor((Number(prepaid) - Number(discount) - Number(hcv)) * 100) / 100, true);
}

const validatePrepaid = (props: {
    total: string,
    prepaid: string,
    discount: string,
    paidAmount: string,
    grandTotal: string,
}) => {
    const {
        total,
        prepaid,
        discount,
        paidAmount,
        grandTotal,
    } = props;

    if (Number(prepaid) > Number(grandTotal)) {
        throw Error("預繳金額不能多於應付金額");
    }

    if (Number(paidAmount) > 0 || Number(prepaid) <= 0) {
        const returnAmount = Number(paidAmount) - Number(prepaid);
        if (returnAmount < 0) {
            throw Error("償付金額不能少於應付金額");
        }
        return convert2dp(returnAmount);
    }
    return '0.00';
}



export const paymentCalculator = {
    calculatePercentageDiscount,
    calculatePaymentItemTotalPrice,
    calculatePaymentItemTotalPrepaidPrice,
    calculatePaymentItemPrice,
    calculateDiscount,
    calculateGrandTotal,
    calculateGrandTotalForPrepaid,
    validatePrepaid,
}