import { handleError } from "@/utils/handleError";
import { notify } from "@/utils/notify";
import { FormInstance } from "element-plus";
import { Options, Vue } from "vue-property-decorator";

@Options({})
export class DialogMixin extends Vue {
    readonly mx_dialogName: string = "新增/修改";
    readonly mx_onlyBlockUpdated: boolean = false;
    mx_isOpen: boolean = false;
    mx_isSuccess: boolean = false;
    mx_hasUpdated: boolean = false;
    private p_mx_isLoading: boolean = false;

    get mx_isLoading() {
        return this.p_mx_isLoading;
    }

    set mx_isLoading(value) {
        if (value === false) {
            setTimeout(() => {
                this.p_mx_isLoading = value;
            }, 300);
        } else {
            this.p_mx_isLoading = value;
        }
    }

    /**
     * Default method to open the dialog
     */
    mx_open() {
        this.mx_isOpen = true;
        this.mx_isSuccess = false;
        this.mx_hasUpdated = false;
    }

    /**
     * Close without alert message
     */
    mx_forceClose() {
        this.mx_isSuccess = true;
        this.mx_isOpen = false;
        this.mx_hasUpdated = false;
    }

    /**
     * `@close` event to el-dialog to close with alert message
     */
    async mx_onClose() {
        if (this.mx_onlyBlockUpdated && !this.mx_hasUpdated) {
            return;
        }
        if (!this.mx_isSuccess && !await this.$confirm(`是否停止${this.mx_dialogName}？`)) {
            this.mx_isOpen = true;
        }
    }

    /**
     * Default method to close the dialog with success code
     */
    mx_success(message = '已儲存') {
        notify.success(message);
        this.mx_isSuccess = true;
        this.mx_isOpen = false;
        this.mx_hasUpdated = false;
    }

    /**
     * Default method to submit form with loading & error handling
     */
    async mx_formSubmit<T>(cb: () => Promise<T>, onSuccess?: (res: T) => void | Promise<void>) {
        this.mx_isLoading = true;
        const form = this.$refs["form"] as FormInstance;
        if (!form) {
            throw Error("Form does not exist");
        }
        const valid = await form.validate();
        if (valid) {
            try {
                const res = await cb();
                if (onSuccess) {
                    await onSuccess(res);
                }
                return res;
            } catch (e) {
                handleError(e)
            } finally {
                this.mx_isLoading = false;
            }
        } else {
            notify.error('請填寫所有資料');
        }
    }

    /**
     * Default method to running async callback with loading & error handling
     */
    async mx_callback<T>(cb: () => Promise<T>, onSuccess?: (res: T) => void | Promise<void>) {
        this.mx_isLoading = true;
        try {
            const res = await cb();
            if (onSuccess) {
                await onSuccess(res);
            }
            return res;
        } catch (e) {
            handleError(e)
        } finally {
            this.mx_isLoading = false;
        }
    }

    /**
     * If there's any change occur in the dialog
     */
    mx_onChange() {
        this.mx_hasUpdated = true;
    }

    /**
     * If reset clicked in the dialog
     */
    mx_onReset() {
        this.mx_hasUpdated = false;
    }

    /**
     * Open dialog but warn {message} under {condition}. Force close if the warn is cancelled
     * @param condition 
     * @param message 
     */
    async mx_openAndWarnIf(condition: boolean, message: string) {
        if (condition && !await this.$confirm(message)) {
            this.mx_forceClose();
        } else {
            this.mx_open();
        }
    }
}
