import { AdminHerbStockAdjustCreateDTO } from "@model/dtos";
import { Clinic, Herb, HerbBrand, HerbStockAdjust } from "@model/entities";
import { HerbType } from "@model/models";
import { convert2dp, NotNull } from "@model/utils";

export class HerbStockAdjustItemInfoVO {
    id: Herb['id'];
    code: string;
    name: string;
    brand: string;
    type: HerbType;
    dispenseUnit: string;
    originalStock?: number;
    currentStock?: number;
    delta?: number;
    finalAmount?: number;
    validatedDelta?: number;

    static fromEntity(herb: Herb): HerbStockAdjustItemInfoVO {
        const currentStock = Number(convert2dp(herb.stock?.stock ?? 0));

        return {
            id: herb.id,
            code: herb.code,
            name: herb.name_zh,
            brand: herb.brand.name_zh,
            type: herb.type,
            currentStock,
            originalStock: Number(currentStock),
            dispenseUnit: herb.dispense_unit.name_zh,
        };
    }

    // TODO not the correct type
    static fromHerbStockAdjust(order: HerbStockAdjust, options?: { isValidateMode: boolean }): HerbStockAdjustItemInfoVO[] {
        if (!order?.items) {
            return [];
        }

        const {
            isValidateMode = false,
        } = options ?? {};
        
        return order.items.sort((x, y) => x.herb_id - y.herb_id).map(x => ({
            id: x.herb_id,
            code: x.herb_code,
            name: x.herb_name,
            brand: x.brand_name,
            type: x.herb?.type,
            stockUnit: x.unit,
            originalStock: x.original_stock,
            currentStock: x.herb.stock?.stock ?? 0,
            delta: x.delta,
            validatedDelta: NotNull(x.validated_delta) ? x.validated_delta : (isValidateMode ? x.delta : null),
            dispenseUnit: x.unit,
            finalAmount: x.final_amount,
        }));
    }

    static toAdminHerbStockAdjustCreateDTO(items: HerbStockAdjustItemInfoVO[], others: {
        clinicId: Clinic['id'];
        remark: string;
    }): AdminHerbStockAdjustCreateDTO {
        if (!items.every(x => !isNaN(Number(x.delta)))) {
            throw Error('調整數量有誤');
        }

        return {
            remark: others.remark,
            clinic_id: others.clinicId,
            items: items.map(x => {
                return {
                    herb_id: x.id,
                    herb_code: x.code,
                    herb_name: x.name,
                    brand_name: x.brand,
                    delta: convert2dp(x.delta),
                    original_stock: convert2dp(x.currentStock),
                    final_amount: NotNull(x.finalAmount) ? convert2dp(x.finalAmount) : null,
                    validated_delta: NotNull(x.validatedDelta) ? convert2dp(x.validatedDelta) : null,
                    unit: x.dispenseUnit,
                };
            })
        }
    }
}