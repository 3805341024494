import { UserService } from "@/services";
import { useUIStore } from "@/stores/modules";
import { LocalStorage } from "@/utils/local-storage";
import { PatientBriefInfo } from "@model/models";
import { TinyEmitter } from "tiny-emitter";

export default {
    init: (eventEmitter: TinyEmitter) => {

        eventEmitter.on("patient-table:clear", async () => {
            const ui$ = useUIStore();
            ui$.registration.selectedPatient = null;
            LocalStorage.lastPatientTableSearch.clear();
        });
    },
};
