import { IsNumber } from "class-validator";

export type SortableItem = {
    id: number;
    order: number;
}

export class SortedItem {
    @IsNumber()
    id: number;

    @IsNumber()
    order: number;
}