import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Clinic, Herb, HerbStockIn } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { notify } from "@/utils/notify";
import { HerbStockInItemInfoVO } from '@model/vos';
import { AdminHerbStockOrderService, AdminHerbStockInService } from "@/services";
import { handleError } from '@/utils/handleError';
import { useUserStore } from '../../user.store';
import { useSelectItemStore } from '../../select-item';

interface State {
    _herbStockInId: HerbStockIn['id'],
    loading: boolean,
    mode: 'readonly' | 'create' | 'update' | 'complete' | 'validate',
    clinicId: Clinic['id'];
    remark: string;
    currentHerbInfo: HerbStockInItemInfoVO,
    // todo not the correct type
    items: Partial<HerbStockInItemInfoVO>[];
}

const defaultState: State = {
    _herbStockInId: null,
    loading: false,
    mode: 'create',
    clinicId: null,
    currentHerbInfo: null,
    remark: '',
    items: [],
}

export const useStockInDatagridStore = defineStore({
    id: 'stock-in',
    state: (): State => cloneDeep(defaultState),
    getters: {
        canTmp(): boolean {
            return this.mode === 'create';
        },
        title(): string {
            const selectItem$ = useSelectItemStore();
            const clinicName = selectItem$.findClinicName(this.clinicId);
            const clinic = clinicName ? ` - ${selectItem$.findClinicName(this.clinicId)}` : '';
            switch (this.mode) {
                case "create":
                    return `新增入庫${clinic}`;
                case "update":
                    return `修改入庫${clinic}`;
                case "validate":
                    return `覆核入庫${clinic}`;
                case "complete":
                    return `開始入庫${clinic}`;
                default:
                    return '';
            }
        },
        saveButtonText(): string {
            switch (this.mode) {
                case "create":
                case "update":
                case "complete":
                    return '儲存入庫';
                case "validate":
                    return '確認覆核';
                default:
                    return '';
            }
        }
    },
    actions: {
        async init(options?: { id?, mode?}) {
            const {
                id: herbStockInId = null,
                mode = 'readonly',
            } = options ?? {};

            this._herbStockInId = herbStockInId;
            this.mode = mode;

            if (herbStockInId) {
                const res = await AdminHerbStockInService.one<HerbStockIn>(herbStockInId);
                this.clinicId = res.clinic_id;
                this.remark = res.remark;
                this.items = HerbStockInItemInfoVO.fromHerbStockIn(res, {
                    isValidateMode: mode === 'validate',
                });
            } else {
                const user$ = useUserStore();
                this.clinicId = user$.currentClinicId;
                this.remark = defaultState.remark;
                this.items = cloneDeep(defaultState.items);
            }
            eventEmitter.emit('stock-in:init');
        },
        async selectHerb(herbId: Herb['id']) {
            this.currentHerbInfo = await AdminHerbStockOrderService.herbInfo(herbId);
        },
        async addHerb() {
            try {
                this.loading = true;
                if (this.currentHerbInfo) {
                    if (this.items.filter(x => x.id === this.currentHerbInfo.id).length > 0) {
                        return notify.error(`藥物 <${this.currentHerbInfo.name}> 重覆加入`);
                    }
                    this.currentHerbInfo.amount = '1' as unknown as number;
                    this.items.push(this.currentHerbInfo);
                    eventEmitter.emit('stock-in:updated');
                }
            } catch (e) {
                handleError(e);
            } finally {
                this.loading = false;
            }
        },
        async createTmp() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                const res = await AdminHerbStockInService.createTmp(HerbStockInItemInfoVO.toAdminHerbStockInCreateDTO(this.items, {
                    clinicId: this.clinicId,
                    remark: this.remark,
                }));
                eventEmitter.emit('stock-in:saved', res.id);
                return res;
            } catch (e) {
                handleError(e);
            }
        },
        async save() {
            try {
                if (this.items.length <= 0) {
                    notify.error("未加入藥物");
                    return null;
                }

                if (this.mode === 'create') {
                    const res = await AdminHerbStockInService.create<HerbStockIn>(HerbStockInItemInfoVO.toAdminHerbStockInCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-in:saved', res.id);
                    return res;
                }

                if (this.mode === 'update') {
                    const res = await AdminHerbStockInService.update(this._herbStockInId, HerbStockInItemInfoVO.toAdminHerbStockInCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-in:saved', res.id);
                    return res;
                }

                if (this.mode === 'complete') {
                    const res = await AdminHerbStockInService.complete(this._herbStockInId, HerbStockInItemInfoVO.toAdminHerbStockInCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-in:saved', res.id);
                    return res;
                }

                if (this.mode === 'validate') {
                    const res = await AdminHerbStockInService.validate(this._herbStockInId, HerbStockInItemInfoVO.toAdminHerbStockInCreateDTO(this.items, {
                        clinicId: this.clinicId,
                        remark: this.remark,
                    }));
                    eventEmitter.emit('stock-in:saved', res.id);
                    return res;
                }
            } catch (e) {
                handleError(e);
            }
        },
        async cancel(id: HerbStockIn['id']) {
            try {
                await AdminHerbStockInService.cancel(id);
                notify.success('入庫已取消');
            } catch (e) {
                handleError(e);
            }
        },
    }
});
