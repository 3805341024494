<script lang="ts">
import { Options, Vue } from "vue-property-decorator";
import { defaultLocale, Locale } from "@model/constants";
import { usePatientHistoryDataviewStore } from "@/stores/modules/dataview";
import { eventEmitter } from "@/services/events";
import { printDiagnosisReport, printPrescriptionsByAppointmentId } from "@/utils/print";
import { handleError } from "@/utils/handleError";
import { printPaymentReceiptFromAppointmentId } from "@/utils/print";

@Options({
    components: {},
})
export default class PatientHistoryTabPrint extends Vue {
    store = usePatientHistoryDataviewStore();
    locale: Locale = defaultLocale;

    get consultation() {
        return this.store.currentConsultation?.consultation;
    }

    async handleClickPrintConsultionProof() {
        eventEmitter.emit("print-consultation-proof-dialog:open", this.consultation.id, this.locale);
    }

    async handleClickPrintPrescription() {
        await printPrescriptionsByAppointmentId({
            appointmentId: this.consultation.appointment_id,
            locale: this.locale,
        });
    }

    async handleClickPrintSickLeaveProof() {
        eventEmitter.emit("print-sick-leave-proof-dialog:open", this.consultation.id, this.locale);
    }

    async handleClickPrintPregnancyProof() {
        eventEmitter.emit("print-pregnancy-proof-dialog:open", this.consultation.id, this.locale);
    }

    async handleClickPrintDiagnosisReport() {
        try {
            await printDiagnosisReport({
                appointmentId: this.consultation.appointment_id,
                locale: this.locale,
            });
        } catch (e) {
            handleError(e);
        }
    }

    async handleClickPaymentReceipt() {
        try {
            await printPaymentReceiptFromAppointmentId({
                appointmentId: this.consultation.appointment_id,
                locale: this.locale,
            });
        } catch (e) {
            handleError(e);
        }
    }
}
</script>

<template lang="pug">
el-tab-pane#patient-history-tab-print(label="列印報告")
    .tab-container
        el-row
            el-button(@click="handleClickPrintPrescription") 中醫處方
        el-row
            el-button(@click="handleClickPrintConsultionProof") 到診證明書
        el-row
            el-button(@click="handleClickPrintSickLeaveProof") 病假證明書
        el-row
            el-button(@click="handleClickPrintPregnancyProof") 懷孕證明書
        el-row
            el-button(@click="handleClickPrintDiagnosisReport") 病歷報告
        el-row
            el-button(@click="handleClickPaymentReceipt") 收據
        el-row(style="margin-top: 10px;")
            SelectLocale(v-model="locale")

</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
