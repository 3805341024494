import { defineStore } from 'pinia';
import { store } from '@/stores';
import { useCache } from '@/hooks/useCache';
import { Clinic } from '@model/entities';
import { ClinicService } from '@/services';

const { wsCache } = useCache('localStorage');

export interface ClinicState {
    _clinics: Clinic[];
}

const defaultState: ClinicState = {
    _clinics: null,
}

export const useClinicStore = defineStore({
    id: 'clinic',
    state: (): ClinicState => defaultState,
    persist: {
        enabled: true,
    },
    getters: {
        pharmacyIds(): Clinic['id'][] {
            return this._clinics.filter(x => x.is_pharmacy).map(x => x.id);
        },
    },
    actions: {
        async init() {
            this._clinics = await ClinicService.list();
            wsCache.set('clinic-store:clinics', this._clinics, { exp: 3600 });
        },
        async getClinics() {
            const cached = wsCache.get('clinic-store:clinics');
            if (cached) {
                this._clinics = cached;
            }
            if (!this._clinics) {
                this._clinics = await ClinicService.list();
                wsCache.set('clinic-store:clinics', this._clinics, { exp: 3600 });
            }
            return this._clinics;
        },
        async isPharmacy(clinicId: Clinic['id']) {
            const clinics = await this.getClinics();
            return clinics.find(x => x.id === clinicId)?.is_pharmacy;
        },
        async isSamePharmacyType(clinicId1: Clinic['id'], clinicId2: Clinic['id']) {
            const clinics = await this.getClinics();
            return clinics.find(x => x.id === clinicId1)?.is_pharmacy === clinics.find(x => x.id === clinicId2)?.is_pharmacy;
        }
    }
});

export const useClinicStoreWithOut = () => {
    return useClinicStore(store);
};
