<script lang="ts">
import { FormInstance } from "element-plus";
import { Options, Vue, mixins } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { PatientService } from "@/services";
import { eventEmitter } from "@/services/events";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { useUserStore } from "@/stores/modules";
import { handleError } from "@/utils/handleError";
import { Patient } from "@model/entities";
import { PatientUpdateDTO } from "@model/dtos";
import { PatientReferral, PatientCaution, PatientDeliveryInfo } from "@model/models";
import { hkidMask, dobMask, parseHtml } from "@model/utils";
import { DialogMixin } from "@/mixins/dialog.mixin";

@Options({
    directives: {
        mask,
    },
})
export default class PatientUpdateDialog extends mixins(Vue, DialogMixin) {
    readonly mx_dialogName = "修改病人";
    user = useUserStore();
    btnLoading = false;

    patient: Patient = null;

    get hkidMask() {
        return hkidMask;
    }

    get dobMask() {
        return dobMask;
    }

    formData: PatientUpdateDTO = {
        code: '',
        name_zh: '',
        name_en: '',
        mobile: '',
        mobile2: '',
        mobile_code: '852',
        mobile2_code: '852',
        address: '',
        email: '',
        dob: '',
        gender: '',
        hkid: '',
        remark: '',
        allergy: '',
        locale: 'zh',
        caution: PatientCaution.sanitize({}),
        referral: PatientReferral.sanitize({}),
        delivery_info: PatientDeliveryInfo.sanitize({}),
        tags: [],
    };

    rules = {
        name_zh: [{ required: true, message: "請輸入病人姓名", trigger: "blur" }],
        mobile: [{ required: true, message: "請輸入電話", trigger: "blur" }],
        // address: [{ required: true, message: "請輸入地址", trigger: "blur" }],
        gender: [{ required: true, message: "請選擇性別", trigger: "blur" }],
        hkid: [{ required: true, message: "請輸入身份證號碼 (如未能提供，請輸入`N`)", trigger: "blur" }],
        // dob: [{ required: true, message: "請輸入出生日期", trigger: "blur" }],
    };

    @ListenEvent("patient-update-dialog:open")
    async handleAppointmentCreateEvent(id: Patient["id"]) {
        if (!this.mx_isOpen) {
            this.patient = await PatientService.one(id);
            this.reset();
            this.mx_open();
        }
    }

    async handleSubmit() {
        try {
            this.btnLoading = true;
            const form = this.$refs["form"] as FormInstance;
            const valid = await form.validate();
            if (valid) {
                const res = await PatientService.edit(this.patient.id, this.formData);
                eventEmitter.emit("patient:updated", res);
                this.mx_success();
            }
        } catch (e) {
            handleError(e);
        } finally {
            this.btnLoading = false;
        }
    }

    reset() {
        console.log(this.patient.tags.map(x => x.tag));
        this.formData = {
            code: this.patient.code,
            name_zh: this.patient.name_zh,
            name_en: this.patient.name_en,
            mobile: this.patient.mobile,
            mobile2: this.patient.mobile2,
            mobile_code: this.patient.mobile_code,
            mobile2_code: this.patient.mobile2_code,
            address: this.patient.address,
            email: this.patient.email,
            dob: this.patient.dob as any,
            gender: this.patient.gender,
            hkid: this.patient.hkid,
            remark: parseHtml(this.patient.remark),
            locale: this.patient.locale,
            allergy: this.patient.allergy,
            caution: PatientCaution.sanitize(this.patient.caution),
            referral: PatientReferral.sanitize(this.patient.referral),
            delivery_info: PatientDeliveryInfo.sanitize(this.patient.delivery_info),
            tags: this.patient.tags.map(x => x.tag),
        };
    }
}
</script>

<template lang="pug">
el-dialog(v-model="mx_isOpen" append-to-body width="1200px" top="1vh" @close="mx_onClose")
    template(#header)
        .d 修改病人
    .body
        el-form(ref="form" :model="formData" :rules="rules" label-position="top")
            el-row(:gutter="20")
                el-col(:span="4")
                    el-form-item(label="病人編號" prop="code")
                        el-input(v-model='formData.code')
                el-col(:span="8")
                    el-form-item(label="姓名(中)" prop="name_zh")
                        el-input(v-model='formData.name_zh')
                el-col(:span="8")
                    el-form-item(label="姓名(英)" prop="name_en")
                        el-input(v-model='formData.name_en')
                el-col(:span="4")
                    el-form-item(label="偏好語言" prop="name_en")
                        SelectLocale(v-model='formData.locale')

            el-row(:gutter="20")
                el-col(:span="6")
                    el-form-item(label="性別" prop="gender")
                        SelectGender(v-model='formData.gender' type="radio")
                el-col(:span="6")
                    el-form-item(label="出生日期" prop="dob")
                        el-input(v-model='formData.dob' placeholder="YYYY/MM/DD" v-mask="dobMask")
                el-col(:span="4")
                    el-form-item(label="年齡" prop="age")
                        el-input(:value="$filters.age(formData.dob)" disabled)
                el-col(:span="8")
                    el-form-item(label="身份證號碼" prop="hkid")
                        el-input(v-model='formData.hkid' placeholder="如未能提供，請輸入`N`" v-mask="hkidMask")
            el-row(:gutter="20")
                el-col(:span="2")
                    el-form-item(label="區號 #1" prop="mobile_code")
                        el-input(v-model='formData.mobile_code')
                el-col(:span="8")
                    el-form-item(label="聯絡電話 #1" prop="mobile")
                        el-input(v-model='formData.mobile')
                el-col(:span="2")
                    el-form-item(label="區號 #2" prop="mobile2_code")
                        el-input(v-model='formData.mobile2_code')
                el-col(:span="8")
                    el-form-item(label="聯絡電話 #2" prop="mobile2")
                        el-input(v-model='formData.mobile2')
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="地址" prop="address")
                        el-input(v-model='formData.address' type="textarea" :autosize="{minRows:1}")
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="電郵地址" prop="email")
                        el-input(v-model='formData.email')
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="藥物敏感" prop="allergy")
                        el-input(v-model="formData.allergy")
            el-row(:gutter="20")
                el-col(:span="12")
                    el-form-item(label="注意事項" prop="caution")
                        PatientCautionSelectGroup(v-model="formData.caution" :gender="formData.gender")
                    el-form-item(label="介紹途徑" prop="referral")
                        PatientReferralSelectGroup(v-model="formData.referral")
                el-col(:span="12" style="border: 1px solid #000")
                    div 送貨資料
                    el-form-item(label="取貨人姓名")
                        el-input(v-model="formData.delivery_info.name")
                    el-form-item(label="取貨人電話")
                        el-input(v-model="formData.delivery_info.mobile")
                    el-form-item(label="送貨地址")
                        el-input(v-model="formData.delivery_info.address" type="textarea" :autosize="{minRows:1}")
            el-row(:gutter="20")
                el-col(:span="12")
                    el-form-item(label="標記" prop="tags")
                        SelectPatientTag(v-model="formData.tags")
            el-row(:gutter="20")
                el-col(:span="24")
                    el-form-item(label="病人備註" prop="remark")
                        el-input(v-model='formData.remark' type="textarea" :autosize="{minRows:1}")
            el-row
                el-col(:span="24")
                    Button(type="primary" @click="handleSubmit" :loading="btnLoading") 修改病人
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

.body {}
</style>
