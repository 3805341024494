import { ConsultationPatientParam, PatientAlertDTO } from "@model/dtos";
import { Consultation, Patient } from "@model/entities";
import { Gender, PatientCaution, PatientDeliveryInfo } from "@model/models";

export class PatientDataviewState implements ConsultationPatientParam {
    id: number;
    code: string;
    name: string;
    dob: Date;
    consultCount: number;
    gender: Gender;
    caution: PatientCaution;
    med_hist: string;
    allergy: string;
    remark: string;
    delivery_info: PatientDeliveryInfo;

    tags: string[];

    // Optional
    consultationId?: number;
    diagnosis?: string;

    static getDefaultValue(): PatientDataviewState {
        return {
            id: null,
            code: '',
            name: '',
            dob: null,
            gender: null,
            consultCount: 0,
            caution: PatientCaution.getDefaultValue(),
            med_hist: '',
            allergy: '',
            remark: '',
            consultationId: null,
            diagnosis: null,
            delivery_info: PatientDeliveryInfo.getDefaultValue(),
            tags: [],
        };
    }

    static toConsultationPatientParam(state: PatientDataviewState): ConsultationPatientParam {
        return {
            delivery_info: state.delivery_info,
            caution: PatientCaution.sanitize(state.caution),
            med_hist: state.med_hist,
            allergy: state.allergy,
        };
    }

    static toPatientAlertDto(state: PatientDataviewState): PatientAlertDTO {
        return {
            allergy: state.allergy,
            caution: PatientCaution.sanitize(state.caution),
        };
    }

    static fromEntity(patient: Patient, consultation?: Consultation): PatientDataviewState {
        return {
            id: patient.id,
            code: patient.code ?? '',
            name: patient.name_zh ?? '',
            consultCount: patient.consult_count ?? 0,
            dob: patient.dob ?? null,
            gender: patient.gender,
            caution: PatientCaution.sanitize(patient.caution),
            delivery_info: PatientDeliveryInfo.sanitize(patient.delivery_info),

            remark: patient.remark ?? '',
            allergy: patient.allergy ?? '',
            med_hist: patient.med_hist ?? '',

            consultationId: consultation?.id ?? null,
            diagnosis: consultation?.diagnosis_ill ?? null,
            tags: patient.tags?.map(x => x.tag) ?? [],
        };
    }

    static fromEntityOrDefault(patient?: Patient, consultation?: Consultation) {
        return patient ? this.fromEntity(patient, consultation) : this.getDefaultValue();
    }
}