import { Labels } from "../common";

export const _auditAppointmentActions =
    [
        "create",
        "update",
        "update-reason",
        "remark",
        "dispensed",
        "consulted",
        "consult-updated",
        "dispense-status-updated",
        "create-treatment",
        "update-treatment",
        "complete-treatment",
        "payment",
        "set-status",
        "attachment",
        "cancel",
    ] ;


export type AuditAppointmentAction = typeof _auditAppointmentActions[number];

export const auditAppointmentActionMap: Labels<AuditAppointmentAction, never> = {
    create: {
        label: "新增",
        color: "#4fe74a",
    },
    update: {
        label: "修改",
        color: "#4fe74a",
        show: false,
    },
    "update-reason": {
        label: "更新原因",
        color: "#4fe74a",
        show: false,
    },
    remark: {
        label: "修改備註",
        color: "#4fe74a",
    },
    "set-status": {
        label: "狀態更新",
        color: "rgb(241, 152, 0)",
    },
    consulted: {
        label: "完成診症",
        color: "#ff1904",
    },
    "consult-updated": {
        label: "修改診症",
        color: "#ff1904",
    },
    "dispense-status-updated": {
        label: "處方狀態",
        color: "#ff1904",
    },
    "attachment": {
        label: "附件",
        color: "#ff1904",
    },
    "create-treatment": {
        label: "治療室",
        color: "#ff1904",
    },
    "update-treatment": {
        label: "治療室",
        color: "#ff1904",
    },
    "complete-treatment": {
        label: "治療室",
        color: "#ff1904",
    },
    cancel: {
        label: "取消",
        color: "#ff1904",
    },
    dispensed: {
        label: "完成配藥",
        color: "#95fdff",
    },
    payment: {
        label: "收費",
        color: "#f1f1f1",
    },
};

export const translateAuditAppointmentAction = (action: AuditAppointmentAction) => {
    return auditAppointmentActionMap[action]?.label ?? action;
}
