import { isString, isArray } from "@/utils/is";
import type { Directive } from "vue";

const getPermissions = () => {
    //  TODO:  Get permissions from store
    return [];
}

const hasPermissions = (permissions: string[]) => {
    const userPermissions = getPermissions();
    if (isString(permissions)) {
        return userPermissions.includes(permissions);
    }

    if (isArray(permissions) && permissions.length > 0) {
        return permissions.every(p => userPermissions.includes(p));

    }
    throw Error('v-can Required Permission. Example: v-can="\"db::user.create\""');
}

const hasNoPermissions = (permissions: string[]) => {
    const userPermissions = getPermissions();
    if (isString(permissions)) {
        return !userPermissions.includes(permissions);
    }

    if (isArray(permissions) && permissions.length > 0) {
        return permissions.every(p => !userPermissions.includes(p));
    }
    throw Error('v-cannot Required Permission. Example: v-cannot="\"db::user.create\""');
}

export const permissionDirectives: { [key: string]: Directive<HTMLElement, Array<string>> } = {
    can: {
        beforeMount(el, binding) {
            const { value } = binding;
            if (!hasPermissions(value)) {
                el.style.display = 'none';
            }
        }
    },
    cannot: {
        beforeMount(el, binding) {
            const { value } = binding;
            if (!hasNoPermissions(value)) {
                el.style.display = 'none';
            }
        }
    }
};