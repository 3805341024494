import { ConsultationPatientParam, PatientAlertDTO } from "@model/dtos";
import { Consultation, Patient, Prescription, PrescriptionItem } from "@model/entities";
import { Gender, PrescriptionUsage, translatePrescriptionViolation } from "@model/models";
import { idGenerator, prescriptionUtils, dispenseUtils } from "@model/utils";

export class PrescriptionDispenseDataviewState {
    id: Prescription['id'];
    usage: PrescriptionUsage;
    items: (PrescriptionItem & {
        amountPerPack: string;
        totalAmount: string;
        done: boolean;
    })[];
    patient: {
        name: string;
        code: string;
    };
    computed: {
        consultationId: string;
        prescriptionId: string;
        prescriptionIndex: string;
        itemsCount: number;
        totalPacks: number;
        issueReason: string;
        issues: string;
    };

    static getDefaultValue(): PrescriptionDispenseDataviewState {
        return {
            id: null,
            items: [],
            usage: {
                is_required: false,
                is_multiple_per_day: false,
                day: null,
                pack: null,
                times: null,
            },
            patient: {
                name: '',
                code: '',
            },
            computed: {
                consultationId: null,
                prescriptionId: null,
                prescriptionIndex: null,
                itemsCount: null,
                totalPacks: null,
                issueReason: null,
                issues: null,
            },
        }
    }

    static fromEntity(prescription: Prescription): PrescriptionDispenseDataviewState {
        const totalPacks = dispenseUtils.calculatePackCount(prescription.usage);
        // if (typeof window !== 'undefined' && window.sessionStorage) {
        //     const cached = window.sessionStorage.getItem(`dispense_${prescription.items}`);
        // }
        return {
            id: prescription.id,
            usage: prescription.usage,
            items: prescription.items.map(x => ({
                ...x,
                amountPerPack: dispenseUtils.calculateAmountPerPack(x.amount, prescription.usage),
                totalAmount: dispenseUtils.calculateAmountTotal(x.amount, prescription.usage),
                done: false,
            })),
            patient: {
                name: prescription.consultation?.appointment?.patient?.name_zh,
                code: prescription.consultation?.appointment?.patient?.code,
            },
            computed: {
                consultationId: idGenerator.formatConsultationId(prescription.consultation_id),
                prescriptionId: idGenerator.formatPrescriptionId({
                    consultationId: prescription.consultation_id,
                    index: prescription.index
                }),
                prescriptionIndex: `${prescription.index + 1}`,
                itemsCount: prescription.items.length,
                totalPacks,
                issueReason: prescription.issue?.reason ?? '',
                issues: prescription?.issue?.items?.length > 0 ? [...new Set(prescription?.issue?.items.map(x => translatePrescriptionViolation(x.violation)))].join(", ") : '',
            }
        };
    }
}