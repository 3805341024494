import { Clinic } from "@model/entities";
import { PatientDeliveryInfo } from "../patient";
import { ScheduleTimeRange } from "../schedule";
import { DecoctingOption } from "./decocting-option";
import { DeliveryOption } from "./delivery-option";
import { cloneDeep } from 'lodash';
export class PrescriptionPharmacyOption {
    decocting?: DecoctingOption;
    delivery_option?: DeliveryOption;
    delivery_info?: PatientDeliveryInfo;
    delivery_time?: Date;
    machine_number?: number;
    water_in_gram?: number;
    time_range?: ScheduleTimeRange;

    static getDefaultValue(clinicId?: Clinic['id']): PrescriptionPharmacyOption {
        return {
            decocting: null,
            delivery_option: clinicId ?? null,
            delivery_time: null,
            delivery_info: {
                name: "",
                mobile: "",
                address: ""
            },
            // machine_number: null,
            // water_in_gram: null,
            time_range: {
                from: null,
                to: null,
            },
        };
    }

    static sanitize(d: Partial<PrescriptionPharmacyOption>): PrescriptionPharmacyOption {
        return cloneDeep({
            ...PrescriptionPharmacyOption.getDefaultValue(),
            ...d,
        });
    }
}