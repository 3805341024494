import { HerbComboData, PrescriptionUsage, PrescriptionUsageType, SelectItem } from "@model/models";
import { convert2dp } from "@model/utils";


const calculatePackCount = (usage: PrescriptionUsage) => {
    if (!usage || !usage.is_required) {
        return 1;
    }
    if (!usage.is_multiple_per_day) {
        return Number(usage.day) * Number(usage.times);
    }
    return Number(usage.day) * Number(usage.times) * Number(usage.pack);
}

const calculateTimes = (usage: PrescriptionUsage) => {
    if (!usage || !usage.is_required) {
        return 1;
    }
    return Number(usage.day) * Number(usage.times);
}

const calculateAmountPerPack = (amount: number | string, usage: PrescriptionUsage) => {
    let pack = 1;
    if (usage && usage.is_required && usage.is_multiple_per_day) {
        pack = Number(usage.pack);
    }
    return convert2dp(Number(amount) / pack, true);
}

const calculateAmountTotal = (amount: number | string, usage: PrescriptionUsage) => {
    return convert2dp(Number(amount) * calculateTimes(usage));
}

export const dispenseUtils = {
    calculatePackCount,
    calculateTimes,
    calculateAmountPerPack,
    calculateAmountTotal,
}