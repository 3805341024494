import { AdminHerbStockCheckCreateDTO } from "@model/dtos";
import { Clinic, Herb, HerbBrand, HerbStockCheck, HerbStockIn } from "@model/entities";
import { HerbType } from "@model/models";
import { convert2dp, NotNull } from "@model/utils";

export class HerbStockCheckItemInfoVO {
    id: Herb['id'];
    code: string;
    name: string;
    brand: string;
    type: HerbType;
    func: string;
    dispenseUnit: string;
    currentStock?: number;
    originalStock?: number;
    amount?: number;
    validatedAmount?: number;

    static fromHerb(herb: Herb): HerbStockCheckItemInfoVO {
        return {
            id: herb.id,
            code: herb.code,
            name: herb.name_zh,
            func: herb.function.name_zh,
            brand: herb.brand.name_zh,
            type: herb.type,
            dispenseUnit: herb.dispense_unit.name_zh,
            currentStock: herb.stock?.stock ?? 0,
            originalStock: herb.stock?.stock ?? 0,
            amount: herb.stock?.stock ?? 0,
        };
    }

    static fromHerbStockCheck(stockCheck: HerbStockCheck): HerbStockCheckItemInfoVO[] {
        if (!stockCheck?.items) {
            return [];
        }
        return stockCheck.items.sort((x, y) => x.herb_id - y.herb_id).map(x => ({
            id: x.herb_id,
            code: x.herb_code,
            name: x.herb_name,
            brand: x.brand_name,
            type: x.herb.type,
            func: x.func_name,
            amount: x.amount,
            dispenseUnit: x.unit,
            originalStock: x.original_stock,
            currentStock: x.herb.stock?.stock ?? 0,
            validatedAmount: NotNull(x.validated_amount) ? x.validated_amount : x.amount,
            delta: x.delta,
        }));
    }

    static toAdminHerbStockCheckCreateDTO(items: HerbStockCheckItemInfoVO[], others: {
        clinicId: Clinic['id'];
        remark: string;
    }): AdminHerbStockCheckCreateDTO {
        return {
            remark: others.remark,
            clinic_id: others.clinicId,
            items: items.map(x => {
                if (isNaN(Number(x.amount))) {
                    throw Error(`盤點數量有誤 <${x.name}>`);
                }

                if (Number(x.amount) < 0) {
                    x.amount = 0;
                }

                if (!x.validatedAmount === null && (isNaN(Number(x.validatedAmount)) || Number(x.validatedAmount) < 0)) {
                    throw Error(`覆核數量有誤 <${x.name}>`);
                }

                const delta = Number(x.currentStock) - Number(x.validatedAmount ?? x.amount);

                return {
                    herb_id: x.id,
                    herb_code: x.code,
                    herb_name: x.name,
                    brand_name: x.brand,
                    func_name: x.func,
                    amount: convert2dp(x.amount),
                    original_stock: NotNull(x.originalStock) ? convert2dp(x.originalStock) : null,
                    validated_amount: NotNull(x.validatedAmount) ? convert2dp(x.validatedAmount) : null,
                    delta: convert2dp(delta),
                    unit: x.dispenseUnit,
                };
            })
        }
    }
}