import { IsString } from "class-validator";

export class ClinicMeta {
    @IsString()
    name_zh: string;

    @IsString()
    name_en: string;

    @IsString()
    address_zh: string;
    
    @IsString()
    address_en: string;

    @IsString()
    company_header_zh: string;
    
    @IsString()
    company_header_en: string;

    @IsString()
    location_hint_zh: string;
    
    @IsString()
    location_hint_en: string;
    
    @IsString()
    fax: string;

    @IsString()
    mobile: string;

    @IsString()
    email: string;

    @IsString()
    website: string;

    @IsString()
    whatsapp_from: string;

    static get defaultValue(): ClinicMeta {
        return {
            name_zh: '',
            name_en: '',
            address_zh: '',
            address_en: '',
            location_hint_zh: '',
            location_hint_en: '',
            company_header_zh: '',
            company_header_en: '',
            fax: '',
            mobile: '',
            email: '',
            website: '',
            whatsapp_from: '',
        }
    }
}