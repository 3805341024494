export const matchAndSumObjectArray = <T>(arr1: T[], arr2: T[], keyToMatch: string, keyToSum: string): T[] => {
    arr1.forEach(x => {
        const matches = arr2.filter(y => x[keyToMatch] === y[keyToMatch]);
        matches.forEach(m => {
            x[keyToSum] += m[keyToSum];
            arr2 = arr2.filter(z => z[keyToMatch] !== m[keyToMatch]);
        });
    });
    return arr1.concat(arr2);
}

export const uniqueBy = (a, key) => {
    var seen = {};
    return a.filter(function (item) {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
}
