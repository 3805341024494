import { defineStore } from 'pinia';
import { cloneDeep, isNumber } from 'lodash';
import { Appointment, Herb, Patient, Payment, PaymentDiscountItem, PaymentItem } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { ConsultationPaymentFacadeStateVO, PaymentConsultationPreviewDTO, PaymentPackagePreviewDTO } from '@model/vos';
import { notify } from '@/utils/notify';
import { PaymentHealthcareVoucherData } from "@model/models";
import { paymentCalculator } from "@model/utils";
import { PaymentService } from '@/services';
import { printPaymentReceiptFromAppointmentId } from '@/utils/print';
import { PaymentMode } from '@model/models';
import { convert2dp } from '@model/utils';
import { handleError } from '@/utils/handleError';

export interface PaymentFacadeState {
    mode: PaymentMode,
    paymentOk: boolean,
    payment: ConsultationPaymentFacadeStateVO;
}

const defaultState: PaymentFacadeState = {
    mode: 'default',
    paymentOk: true,
    payment: ConsultationPaymentFacadeStateVO.getDefaultValue(),
}

export const usePaymentFacadeStore = defineStore({
    id: 'payment-facade',
    state: (): PaymentFacadeState => cloneDeep(defaultState),
    getters: {
        total(): number {
            return Number(this.payment.total);
        },
        grandTotalText(): string {
            return this.payment.grandTotal;
        },
        hcvAmountText(): string {
            const hcv = this.payment.healthcareVoucher;
            return this.payment.healthcareVoucher ? `-${Number(hcv.amount).toFixed(2)}` : '-';
        },
        hcvCodeText(): string {
            const hcv = this.payment.healthcareVoucher;
            return this.payment.healthcareVoucher ? hcv.code : '-';
        },
        hcvInfo(): string {
            const hcv = this.payment.healthcareVoucher;
            return this.payment.healthcareVoucher ? `-${Number(hcv.amount).toFixed(1)} (${hcv.code} - ${hcv.hkid})` : '-';
        },
    },
    actions: {
        async init({ payment, patient }: {
            payment?: Payment;
            patient?: Patient;
        }) {
            this.payment = ConsultationPaymentFacadeStateVO.fromEntityOrDefault(payment);
            eventEmitter.emit("consultation-payment:init", patient);
        },
        setMode(mode: PaymentMode) {
            this.mode = mode;
        },
        calculateGrandTotal() {
            this.paymentOk = false;
            if (isNaN(parseFloat(this.payment.paidAmount))) {
                this.payment.paidAmount = '0.00';
                this.payment.returnAmount = '0.00';
                return notify.error('請輸入正確數字');
            }

            if (this.mode === 'prepaid') {
                this._calculateGrandTotalForPrepaid();
                return;
            }

            const total = paymentCalculator.calculatePaymentItemTotalPrice(this.payment.items);
            this.payment.total = total;
            const discount = paymentCalculator.calculateDiscount(total, this.payment.discounts).total;
            this.payment.grandTotal = paymentCalculator.calculateGrandTotal({
                total,
                discount,
                hcv: this.payment.healthcareVoucher?.amount,
            });

            if (Number(this.payment.paidAmount) === 0) {
                this.payment.returnAmount = convert2dp(0);
                this.payment.paidAmount = convert2dp(this.payment.grandTotal);
                this.paymentOk = true;
                return;
            }

            // Has input paidAmount
            if (Number(this.payment.paidAmount) > 0 || Number(this.payment.grandTotal) <= 0) {
                const returnAmount = Number(this.payment.paidAmount) - Number(this.payment.grandTotal);
                if (returnAmount < 0) {
                    this.payment.returnAmount = convert2dp(0);
                    this.payment.paidAmount = convert2dp(this.payment.grandTotal);
                } else {
                    this.payment.returnAmount = convert2dp(returnAmount);
                    this.payment.paidAmount = convert2dp(this.payment.paidAmount);
                }
                this.paymentOk = true;
            }
        },
        _calculateGrandTotalForPrepaid() {
            try {
                this.paymentOk = false;
                const total = paymentCalculator.calculatePaymentItemTotalPrice(this.payment.items, { ignorePrepaid: true });
                this.payment.total = total;

                const prepaid = paymentCalculator.calculatePaymentItemTotalPrepaidPrice(this.payment.items);
                const discount = paymentCalculator.calculateDiscount(total, this.payment.discounts).total;
                this.payment.grandTotal = paymentCalculator.calculateGrandTotalForPrepaid({
                    prepaid,
                    discount,
                    hcv: this.payment.healthcareVoucher?.amount,
                });

                const returnAmount = paymentCalculator.validatePrepaid({
                    total,
                    prepaid,
                    grandTotal: this.payment.grandTotal,
                    discount,
                    paidAmount: this.payment.paidAmount,
                });

                this.payment.returnAmount = returnAmount;
                this.payment.paidAmount = Number(this.payment.paidAmount).toFixed(2);
                this.paymentOk = true;
                return;
            } catch (e) {
                this.payment.returnAmount = '-';
                handleError(e);
            }
        },
        setPrescriptionPrice(items: PaymentItem[]) {
            this.payment.items = this.payment.items.filter((x) => x.category !== "prescription");
            items.forEach((x: any) => this.payment.items.push(x));
            eventEmitter.emit("consultation-payment:updated");
        },
        setPackagesPrice({ items, discounts }: { items: PaymentItem[], discounts: PaymentDiscountItem[] }) {
            console.log(this.payment.items);
            this.payment.items = this.payment.items.filter((x) => x.is_settled || (x.category !== "package" && x.category !== 'package-induced'));
            items.forEach((x: any) => this.payment.items.push(x));
            if (discounts.length > 0) {
                this.payment.discounts = this.payment.discounts.filter((x) => x.is_settled || x.category !== "package");
                discounts.forEach((x: any) => this.payment.discounts.push(x));
            }
            eventEmitter.emit("consultation-payment:updated");
        },
        setConsultationPrice({ items, discounts }: { items: PaymentItem[], discounts: PaymentDiscountItem[] }) {
            this.payment.items = this.payment.items.filter((x) => x.category !== "consultation");
            items.forEach((x: any) => this.payment.items.push(x));

            if (discounts.length > 0) {
                this.payment.discounts = this.payment.discounts.filter((x) => x.category !== "consultation");
                discounts.forEach((x: any) => this.payment.discounts.push(x));
                eventEmitter.emit("consultation-payment:updated");
            }
            eventEmitter.emit("consultation-payment:updated");
        },
        addHealthcareVoucher(data: PaymentHealthcareVoucherData) {
            this.payment.healthcareVoucher = cloneDeep(data);
            this.calculateGrandTotal();
        },
        cancelHealthcareVoucher() {
            this.payment.healthcareVoucher = null;
            this.calculateGrandTotal();
        },
        async savePayment(appointment: Appointment): Promise<Payment> {
            return PaymentService.save({
                appointment_id: appointment.id,
                consultation_id: appointment.consultation.id,
                payment: this.payment,
                prescriptions: appointment?.consultation?.prescriptions.map(x => ({
                    id: x.id,
                    pharmacy_option: x.pharmacy_option,
                    internal_remark: x.internal_remark,
                })),
            });
        },
        async printPaymentReceipt(appointmentId: Appointment['id']) {
            await printPaymentReceiptFromAppointmentId({ appointmentId });
        },
        async refreshPayment(appointment: Appointment) {
            const payments = await PaymentService.previewPrescriptions({
                clinic_id: appointment.clinic_id,
                prescriptions: appointment.consultation.prescriptions
            });
            this.setPrescriptionPrice(payments);
            const consultationPayments = await PaymentService.previewConsultation(PaymentConsultationPreviewDTO.fromAppointment(appointment));
            this.setConsultationPrice(consultationPayments);
            const packagePayments = await PaymentService.previewPackage(PaymentPackagePreviewDTO.fromAppointment(appointment));
            this.setPackagesPrice(packagePayments);
        },
        async refreshPackagePayment(appointmentId: Appointment['id']) {
            const packagePayments = await PaymentService.previewPackage({ appointment_id: appointmentId });
            this.setPackagesPrice(packagePayments);
        },
    }
});
