import { axGet } from "./axios";
import { BaseService } from "./base.service";
import { PricingOffer } from "@model/entities";
import { PaginationQueryDTO } from "@model/dtos";
import { PaginatedResponse } from "@model/responses";

class Service extends BaseService {
    async query(queryParams: PaginationQueryDTO): Promise<PaginatedResponse<PricingOffer>> {
        return this.get(`/query?${this.parseQuery(queryParams)}`);
    }
}

export const PaymentPricingOfferService = new Service('/payment-pricing/offer');

