<script lang="ts">
import { Payment } from "@model/entities";
import { Options, Vue, Prop } from "vue-property-decorator";

@Options({
    components: {},
})
export default class PaymentTable extends Vue {
    @Prop({ required: true })
    payment: Payment;

    get items() {
        return this.payment?.items ?? [];
    }

    get discounts() {
        return this.payment?.discounts ?? [];
    }
    
    get hcv() {
        return this.payment?.healthcare_voucher ?? null;
    }    

    get grandTotal() {
        return this.payment?.grand_total ?? null;
    }
}
</script>

<template lang="pug">
div
    el-row
        el-col(:span="6")
            h4 收費項目
            el-table(:data="items")
                el-table-column(label="名稱" width="150")
                    template(#default="scope")
                        div {{ scope.row.name }}
                el-table-column(label="小計＄" width="150")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.grand_total) }}
        el-col(:span="6")
            h4 優惠項目
            el-table(:data="discounts")
                el-table-column(label="名稱" width="150")
                    template(#default="scope")
                        div {{ scope.row.name }}
                el-table-column(label="優惠形式" width="100")
                    template(#default="scope")
                        div {{ $filters.pricingDiscountType(scope.row.type) }}
                el-table-column(label="優惠" width="100")
                    template(#default="scope")
                        div {{ $filters.decimal2(scope.row.total) }}

        el-col(:span="6")
            h4 醫療券
            div(v-if="hcv") 
                el-row
                    el-col(:span="4") 編號：
                    el-col(:span="20") {{ hcv.code }}
                el-row
                    el-col(:span="4") 金額：
                    el-col(:span="20") ＄ {{ hcv.amount }}
    br
    el-row
        el-col(:span="4")
            h4 總金額：
        el-col(:span="20")
            div ＄ {{ $filters.decimal2(grandTotal) }}
    el-row
        el-col(:span="4")
            h4 付款方式：
        el-col(:span="20")
            div {{ $filters.paymentMethod(payment?.method) }}
    el-row
        el-col(:span="4")
            h4 備註：
        el-col(:span="20")
            div {{ payment?.remark }}
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
