import { BaseService } from "./base.service";
import {
    ScheduleCreateDto,
    ScheduleAdminQueryDto,
    ScheduleQueryDTO,
    ScheduleSetActiveDto,
    ScheduleQueryOnDutyDto,
} from "@model/dtos";
import { ScheduleQueryResponse } from "@model/responses";
import { Schedule } from "@model/entities";
import qs from "qs";

class Service extends BaseService {
    async adminQuery(query: ScheduleAdminQueryDto): Promise<Schedule[]> {
        return this.get(`?${qs.stringify(query)}`);
    }

    async create(body: ScheduleCreateDto): Promise<Schedule> {
        return this.put(``, body);
    }

    async setActive(body: ScheduleSetActiveDto): Promise<Schedule> {
        return this.post(`/active`, body);
    }

    async query(query: ScheduleQueryDTO): Promise<ScheduleQueryResponse> {
        try {
            return this.get(`/clinic?${this.parseQuery(query, ['date'])}`);
        } catch (e) {
            if (e.message === 'Key date does not exist') {
                return;
            }
            throw e;
        }
    }

    async isOnDuty(query: ScheduleQueryOnDutyDto): Promise<boolean> {
        return this.get(`/on-duty?${this.parseQuery(query)}`);
    }
}

export const ScheduleService = new Service('/schedule');