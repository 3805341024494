import * as lang from './en.json';

export const translate = (t: string, params: (string | number)[] = []) => {
    const paths = t.split('.');
    let res = lang;
    for (const p of paths) {
        if (!(p in res)) {
            return t;
        }
        res = res[p];
        if (typeof res === 'string') {
            if (params.length > 0) {
                return replaceParams(res, params);
            }
            return res;
        }
    }
    return t;
}

const replaceParams = (str: string, params: (string | number)[]) => {
    for (const index in params) {
        const r = new RegExp(`\\\{${index}\\\}`, 'g');
        str = str.replace(r, `${params[index]}`);
    }
    return str;
}