import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { Consultation, Herb, Patient, Payment, Prescription } from '@model/entities';
import { eventEmitter } from '@/services/events';
import { PrescriptionDispenseDataviewState } from '@model/vos';
import { notify } from "@/utils/notify";
import { PrescriptionService, DispenseService } from '@/services';

interface State {
    _prescriptionId: Prescription['id'],
    prescription: PrescriptionDispenseDataviewState,
}

const defaultState: State = {
    _prescriptionId: null,
    prescription: PrescriptionDispenseDataviewState.getDefaultValue(),
}

export const useDispenseDataviewStore = defineStore({
    id: 'dispense-dataview',
    state: (): State => cloneDeep(defaultState),
    getters: {
        readyToSave(): boolean {
            return this.prescription.items.every(x => x.done);
        }
    },
    actions: {
        async init(prescriptionId: Prescription['id']) {
            this._prescriptionId = prescriptionId;
            // const prescription = await PrescriptionService.startDispense(prescriptionId);
            this.prescription = PrescriptionDispenseDataviewState.fromEntity(prescription);
        },
        async quit() {
            if (this._prescriptionId) {
                // return PrescriptionService.quitDispense(this._prescriptionId);
            }
        },
        async complete() {
            if (this._prescriptionId) {
                return DispenseService.updateStatus(this._prescriptionId, 'dispensed');
            }
        }
    }
});
