<script lang="ts">
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { useUserStore } from "@/stores/modules";
import { PaymentPrescriptionBreakdownResponse } from "@model/responses";
import { Options, Vue } from "vue-property-decorator";

@Options({
    components: {},
})
export default class PaymentBreakdownViewDialog extends Vue {
    user = useUserStore();
    open = false;
    res: PaymentPrescriptionBreakdownResponse = null;

    @ListenEvent("payment-breakdown-view-dialog:open")
    async handleOpen(res: PaymentPrescriptionBreakdownResponse) {
        this.res = res;
        this.open = true;
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" top="1vh" destroy-on-close)
    template(#header)
        div 拆解藥費
    .body(v-if="res")
        div 日數: {{ res.days }}
        div 總金額: {{ res.total }} 
        .mini 或 最低消費金額: {{ $filters.decimal2(Number(res.minCost) * res.days) }}
        div 總金額/每日: {{ res.totalPerDay }} 
        .mini 或 最低消費金額: {{ res.minCost }}
        
        el-table(:data="res.items")
            el-table-column(label="名稱" width="200")
                template(#default="scope")
                    div {{ scope.row.name }}
            el-table-column(label="數量(克)" width="100")
                template(#default="scope")
                    div {{ scope.row.amount }} 
            el-table-column(label="總金額" width="100")
                template(#default="scope")
                    div {{ scope.row.price }}
            el-table-column(label="金額/每日" width="100")
                template(#default="scope")
                    div {{ scope.row.pricePerDay }}
            el-table-column(label="金額/每單位" width="100")
                template(#default="scope")
                    div {{ scope.row.pricePerUnit }}
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

</style>
