import { ElMessage } from "element-plus";
import { isString } from "./is";
import { tryTranslateConstraints } from "@model/error";
import Rollbar from 'rollbar';
import { dynamicEnv } from "./dynamic-env";
import { notify } from "./notify";

interface ErrorHandleOptions {
    shouldPersistMessageBox?: boolean;
    handlers?: {
        [code: string]: (err: any) => void;
    }
}

export const handleError = (e?: Error | string | unknown, options?: ErrorHandleOptions) => {
    try {

        const {
            shouldPersistMessageBox = false,
            handlers,
        } = options ?? {};

        const err = e as any;

        if (!err) {
            ElMessage({
                showClose: true,
                message: "系統錯誤，請拍照及What'sapp傳給系統管理員 95057661。",
                type: 'error',
                duration: shouldPersistMessageBox ? 0 : undefined,
            });

            return null;
        }

        if (isString(err)) {
            ElMessage({
                showClose: true,
                message: err,
                type: 'error',
                duration: shouldPersistMessageBox ? 0 : undefined,
            });
            return null;
        }

        if (err && 'code' in (err as any)) {
            if (handlers?.[err.code]) {
                handlers[err.code](err);
                return null;
            }
            //
            if (err.details?.message) {
                ElMessage({
                    showClose: true,
                    message: err.details?.message,
                    type: 'error',
                    duration: shouldPersistMessageBox ? 0 : undefined,
                });
                return null;
            }

            if (err.code === 'ERR_INVALID_PARAMS') {
                if (err.details?.constraints) {
                    const message = tryTranslateConstraints(err.details?.constraints[0]);
                    ElMessage({
                        showClose: true,
                        message: `系統錯誤，請拍照及What'sapp傳給系統管理員 95057661。Error Code: ${message}`,
                        type: 'error',
                        duration: shouldPersistMessageBox ? 0 : undefined,
                    });
                    return null;
                }
            }
        }

        if ((err as any).message) {
            console.error(err);
            ElMessage({
                showClose: true,
                message: err.message,
                type: 'error',
                duration: shouldPersistMessageBox ? 0 : undefined,
            });
        }

        return e;
    } catch (e) {
        console.error(e);
    }
}

export const handleUnexpectedError = ([message, url, lineNo, columnNo, error]: any) => {
    handleError(error);
}

export const initRollbar = () => {
    const rollbar = new Rollbar({
        accessToken: dynamicEnv("ROLLBAR_ACCESS_TOKEN"),
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: process.env.NODE_ENV,
            client: {
                javascript: {
                    code_version: dynamicEnv("BUILD_VERSION"),
                    source_map_enabled: true,
                    guess_uncaught_frames: true
                },
            },
        }
    });

    return rollbar;
};

export const rollbarInstance = (process.env.NODE_ENV !== 'development' && dynamicEnv("BUILD_VERSION") !== '0.0.0') ? initRollbar() : null;

// Unexpected Error
window.onerror = (...props) => {
    // HOTFIX: Ignore ResizeObserver loop limit exceeded on el-table
    if (props[0] === 'ResizeObserver loop limit exceeded') {
        console.warn('Ignored: ResizeObserver loop limit exceeded')
        return false;
    };
    if (props[0] === 'ResizeObserver loop completed with undelivered notifications.') {
        console.warn('Ignored: ResizeObserver loop completed with undelivered notifications.')
        return false;
    };
    console.error(props);
    handleUnexpectedError(props);
};

window.addEventListener('unhandledrejection', (evt) => {
    evt.stopImmediatePropagation();
    console.error(evt.reason);
});
