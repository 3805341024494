import { Labels } from "../common";

const _herbStockCheckStatuses = [
    "cancelled",
    "temporary",
    "pending",
    "completed",
] as const;

export type HerbStockCheckStatus = typeof _herbStockCheckStatuses[number];


export const herbStockCheckStatusMap: Labels<HerbStockCheckStatus, never> = {
    cancelled: {
        label: "取消盤點",
        color: "#ff1904",
        show: false,
    },
    temporary: {
        label: "暫存盤點",
        color: "#4fe74a",
    },
    pending: {
        label: "等侯覆核",
        color: "#4fe74a",
        show: false,
    },
    completed: {
        label: "已完成",
        color: "#f1f1f1",
    },
};

export const translateHerbStockCheckStatus = (status: HerbStockCheckStatus) => {
    return herbStockCheckStatusMap[status]?.label ?? '';
}