import { BaseService } from "./base.service";
import { PrescriptionsValidationDto, PrescriptionsRedispenseDTO, PrescriptionsRedispenseExecuteDTO } from "@model/dtos";
import { Appointment, Prescription } from "@model/entities";
import { AdvancedResponse, PrescriptionListPendingResponse, PrescriptionsRedispenseExecuteResponse } from "@model/responses";
import { PrescriptionItemParam } from "@model/dtos/prescription/params/prescription-item.param";

class Service extends BaseService {
    async listPending(): Promise<PrescriptionListPendingResponse> {
        return this.get(`/is/pending`);
    }

    async validate(body: PrescriptionsValidationDto) {
        return this.post('/validate', body);
    }

    async redispenseExecute(body: PrescriptionsRedispenseExecuteDTO): Promise<AdvancedResponse<PrescriptionsRedispenseExecuteResponse>> {
        return this.post('/redispense', body);
    }

    async redispenseDryRun(body: PrescriptionsRedispenseDTO): Promise<AdvancedResponse<PrescriptionItemParam[]>> {
        return this.post('/redispense/dry-run', body);
    }

    async label(id: Prescription['id']) {
        return this.open(`/label/${id}`);
    }

    async listByAppointment(id: Appointment['id']): Promise<Prescription[]> {
        return this.get(`/appointment/${id}`);
    }
}

export const PrescriptionService = new Service('/prescription');