<script lang="ts">
import { AuditService } from "@/services";
import { ListenEvent } from "@/services/events/listen-event.decorator";
import { Appointment } from "@model/entities";
import { useUserStore } from "@/stores/modules";
import { Options, Vue } from "vue-property-decorator";
import { AuditAppointmentListResponse } from "@model/responses";

@Options({
    components: {},
})
export default class AppointmentAuditDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    appointmentId: Appointment["id"] = null;
    list: AuditAppointmentListResponse[] = [];

    @ListenEvent("appointment-audit-dialog:open")
    async handleOpen(appointmentId: Appointment["id"]) {
        this.appointmentId = appointmentId;
        this.list = await AuditService.listAppointment(appointmentId);
        this.open = true;
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        div 預約進度記錄
    .body
        el-table(:data="list")
            el-table-column(label="時間" width="180")
                template(#default="scope")
                    div {{ $filters.datetime(scope.row.createdAt) }}
            el-table-column(label="負責人" width="100")
                template(#default="scope")
                    div {{ scope.row.createdBy }}
            el-table-column(label="行動" width="180")
                template(#default="scope")
                    div {{ $filters.auditAppointmentAction(scope.row.action) }}
            el-table-column(label="備註")
                template(#default="scope")
                    div {{ scope.row.message }}
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common.scss";

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
