export class PatientReferral {
    friend?: boolean;
    leaflet?: boolean;
    ads?: boolean;
    web?: boolean;
    email?: boolean;
    other?: boolean;
    other_text?: string;

    static getDefaultValue(): PatientReferral {
        return {
            friend: false,
            leaflet: false,
            ads: false,
            web: false,
            email: false,
            other: false,
            other_text: "",
        };
    }

    static sanitize(d: Partial<PatientReferral>): PatientReferral {
        return {
            ...PatientReferral.getDefaultValue(),
            ...d,
        };
    }

    static toString(referral: PatientReferral): string {
        return Object.keys(referral)
            .filter(x => x !== 'other_text')
            .filter(x => referral[x] !== false)
            .map(x => PatientReferral.translate(referral[x], referral))
            .join(', ');
    }

    static translate(key: keyof PatientReferral, d?: PatientReferral) {
        switch (key) {
            case 'friend': return '朋友';
            case 'email': return '電郵';
            case 'leaflet': return '傳單';
            case 'web': return '網上';
            case 'ads': return '廣告';
            case 'other':
                return d ? `其他：${d.other_text}` : '其他';
            default: return key;
        }
    }
}