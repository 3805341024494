export const convert2dp = (val: number | string, round1dp: boolean = false, floor1dp: boolean = false): string => {
    if (round1dp) {
        val = Math.ceil(Number(convert2dp(val)) * 10) / 10;
    } else if (floor1dp) {
        val = Math.floor(Number(convert2dp(val)) * 10) / 10;
    }

    if (typeof val === 'number') {
        return val.toFixed(2);
    }
    return Number(`${val}`).toFixed(2);
}

export const sum = (x, y) => {
    return x + y;
}

export const NumberOrNull = (value) => {
    if (value === null || value === undefined) {
        return null;
    }
    return Number(value);
}

export const IsNull = (value) => {
    return value === null || value === undefined;
}

export const NotNull = (value) => {
    return !IsNull(value);
}