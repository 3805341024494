import { DoctorService, HerbService, OrganizationService } from "@/services";
import { useUserStore } from "@/stores/modules";
import date from '@model/utils/date';
import { renderTemplate, textToBase64Barcode } from ".";
import dateFormatter from "@/utils/filters/date.filter";
import genderFormatter from "@/utils/filters/gender.filter";
import { notify } from "../notify";
import { prescriptionUtils, idGenerator } from "@model/utils";
import { omit } from "lodash";
import { eventEmitter } from "@/services/events";
import { herbPricingTypeIsExpensive, PatientCaution, PrescriptionRedispenseConfig } from "@model/models";
import { defaultLocale } from "@model/constants";
import { PrescriptionPrintParams } from "@model/models";

const preparePrescriptionInfo = async (prescription) => {
    const prescriptionItemList = [[], []];
    const prescriptionComboList = [];
    const prescriptionRegCodeList = [];

    if (prescription.items.length === 0) {
        return { prescriptionItemList, prescriptionComboList, prescriptionRegCodeList };
    }

    const herbs = await HerbService.many(prescription.items.map((x) => x.id));
    const items = [...prescription.items].sort((a, b) => {
        const herbA = herbs.findIndex((x) => x.id === a.id);
        const herbB = herbs.findIndex((x) => x.id === b.id);
        return herbA - herbB;
    });
    const indexToNextColumn = Math.ceil(prescription.items.length / 2);
    for (const index in items) {
        const item = items[index];
        if (item.valid === false) {
            notify.error(`請檢查<${item.name}>的品牌及用量`);
            return;
        }
        const herb = herbs.find((x) => x.id === item.id);
        if (!herb) {
            throw Error(`藥材<${item.name}>不存在`);
        }
        const itemData = {
            ...item,
            isExpensive: herbPricingTypeIsExpensive(item.pricing_type),
            amountText: Number(item.amount).toFixed(1),
        };
        if (herb.type === 'combo') {
            if (prescriptionComboList.filter((x) => x.key === herb.code).length === 0) {
                if (herb.combo_data) {
                    prescriptionComboList.push({
                        key: herb.code,
                        value: prescriptionUtils.getComboIngredientText(herb.combo_data),
                    });
                    itemData.star = true;
                }
            }
        }
        if (herb.regcode) {
            if (prescriptionRegCodeList.filter((x) => x.key === herb.code).length === 0) {
                prescriptionRegCodeList.push({
                    key: herb.code,
                    value: herb.regcode,
                });
                itemData.star = true;
            }
        }
        prescriptionItemList[(Number(index) >= indexToNextColumn) ? 1 : 0].push({
            star: itemData.star,
            isExpensive: itemData.isExpensive,
            code: itemData.code,
            name: itemData.name,
            amountText: itemData.amountText,
            method: itemData.method ?? itemData.dispense_method,
            unit: itemData.unit,
        });
    }

    return { prescriptionItemList, prescriptionComboList, prescriptionRegCodeList };
}

export const previewPrescriptionFacade = async ({ appointmentId, consultationId, prescription, prescriptionIndex, caution, patient, diagnosis, locale = defaultLocale }) => {
    const user = useUserStore();
    const company = await OrganizationService.load();
    const clinic = user.currentClinic;
    const doctor = user.doctor;
    const esign = await DoctorService.getEsign(doctor.id);

    const {
        prescriptionItemList,
        prescriptionComboList,
        prescriptionRegCodeList,
    } = await preparePrescriptionInfo(prescription);

    const age = PatientCaution.sanitize(caution).no_age ? '-' : `${date.formatAge(patient.dob)}`;

    const params: PrescriptionPrintParams = {
        company: {
            name_zh: clinic.meta.company_header_zh ?? company.name_zh,
            name_en: clinic.meta.company_header_en ?? company.name_en,
        },
        appointment: {
            id: appointmentId,
        },
        clinic: {
            meta: {
                address_zh: clinic.meta.address_zh,
                address_en: clinic.meta.address_en,
                mobile: clinic.meta.mobile,
                email: clinic.meta.email,
            }
        },
        doctor: {
            display_name: doctor.display_name,
            reg_code: doctor.reg_code,
            esignUrl: esign?.destination,
        },
        patient: {
            code: patient.code,
            name: patient.name_zh,
            gender: genderFormatter(patient.gender),
            age,
            hasCaution: PatientCaution.hasCaution(caution),
            requireIndependentDispense: PatientCaution.requireIndependentDispense(caution, date.dayjs().diff(patient.dob, 'year')),
            caution: PatientCaution.getCautionText(caution),
        },
        consultation: {
            id: idGenerator.formatConsultationId(consultationId),
            diagnosis_ill: diagnosis.diagnosis_ill,
            consult_dt: date.formatDate(),
        },
        prescription: {
            index: prescriptionIndex,
            caution: prescription.caution,
            remark: prescription.remark,
            computed: {
                usage: prescriptionUtils.getUsageText(prescription.usage),
                combos: prescriptionComboList,
                regcodes: prescriptionRegCodeList,
                items: prescriptionItemList,
                redispenseText: PrescriptionRedispenseConfig.getText(prescription, locale as any),
            },
        },
        barcode: textToBase64Barcode(prescription.id),
        date: date.formatDate(),
    };

    const html = await renderTemplate("prescription", `prescription-v2_${locale}`, params);
    console.log(params.prescription.computed.redispenseText);

    eventEmitter.emit('print-dialog:open', {
        size: 'A5',
        shouldPrint: false,
        html,
        title: idGenerator.formatConsultationId(consultationId),
    });
};

export const printPrescriptionFacade = async ({ appointmentId, consultationId, consultationDate, prescriptions, patient, caution, diagnosis, locale = defaultLocale }) => {
    const user = useUserStore();
    const company = await OrganizationService.load();
    const clinic = user.currentClinic;
    const doctor = user.doctor;

    const esign = await DoctorService.getEsign(doctor.id);

    const paramsArray = [];
    const res = [];
    for (const index in prescriptions) {
        const prescriptionId = idGenerator.formatPrescriptionId({ consultationId, index: Number(index) })
        const {
            prescriptionItemList,
            prescriptionComboList,
            prescriptionRegCodeList,
        } = await preparePrescriptionInfo(prescriptions[index]);

        const age = PatientCaution.sanitize(caution).no_age ? '-' : `${date.formatAge(patient.dob)}`;

        const params: PrescriptionPrintParams = {
            company: {
                name_zh: company.name_zh,
                name_en: company.name_en,
            },
            appointment: {
                id: appointmentId,
            },
            clinic: {
                meta: {
                    address_zh: clinic.meta.address_zh,
                    address_en: clinic.meta.address_en,
                    mobile: clinic.meta.mobile,
                    email: clinic.meta.email,
                }
            },
            doctor: {
                display_name: doctor?.display_name ?? user.user.name ?? '-',
                display_name_en: doctor?.display_name_en ?? user.user.name ?? '-',
                reg_code: doctor?.reg_code ?? '-',
                esignUrl: esign?.destination,
            },
            patient: {
                code: patient.code,
                name: patient.name_zh,
                gender: genderFormatter(patient.gender),
                age,
                hasCaution: PatientCaution.hasCaution(caution),
                requireIndependentDispense: PatientCaution.requireIndependentDispense(caution, date.dayjs().diff(patient.dob, 'year')),
                caution: PatientCaution.getCautionText(caution),
            },
            consultation: {
                id: idGenerator.formatConsultationId(consultationId),
                diagnosis_ill: diagnosis.diagnosis_ill,
                consult_dt: date.formatDate(consultationDate),
            },
            prescription: {
                index: parseInt(index) + 1,
                caution: prescriptions[index].caution,
                remark: prescriptions[index].remark,
                computed: {
                    usage: prescriptionUtils.getUsageText(prescriptions[index].usage),
                    combos: prescriptionComboList,
                    regcodes: prescriptionRegCodeList,
                    items: prescriptionItemList,
                    redispenseText: PrescriptionRedispenseConfig.getText(prescriptions[index], locale as any),
                },
            },
            barcode: textToBase64Barcode(prescriptionId),
            date: date.formatDate(),
        };

        const html = await renderTemplate("prescription", `prescription-v2_${locale}`, params);
        paramsArray.push(omit(params, 'barcode'));
        res.push(html);
    }

    eventEmitter.emit('print-dialog:open', {
        size: 'A5',
        shouldPrint: true,
        html: res.join(''),
        title: idGenerator.formatConsultationId(consultationId),
        audit: {
            appointment_id: appointmentId,
            type: 'prescriptions',
            params: paramsArray,
        },
    });
};

