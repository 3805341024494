import { defineStore } from 'pinia';
import { store } from '..';

export interface LayoutState {
    goBackUrl?: string;
    windowHeight?: number;
    windowWidth?: number;
}

const defaultAppModules = {
    goBackUrl: undefined,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
}

export const useLayoutStore = defineStore({
    id: 'layout',
    state: (): LayoutState => defaultAppModules,
    persist: {
        enabled: true,
    },
    getters: {
        getGoBackUrl(): string {
            return this.goBackUrl ?? '';
        },
        shouldShowGoBackUrl(): boolean {
            return !!this.goBackUrl;
        },
        isMobile(): boolean {
            return this.windowWidth < 768;
        }
    },
    actions: {
        setWindowSize({ height, width }: { height: number, width: number }) {
            this.windowHeight = height;
            this.windowWidth = width;
        },
        setGoBackUrl(goBackUrl?: string) {
            this.goBackUrl = goBackUrl;
        },
    }
})


export const useLayoutStoreWithOut = () => {
    return useLayoutStore(store);
}
