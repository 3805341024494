import { defineStore } from 'pinia';
import { asyncRouterMap, constantRouterMap, fallbackRouterMap } from '@/router';
import { flatMultiLevelRoutes } from '@/utils/routerHelper';
import { store } from '..';
import { cloneDeep } from 'lodash-es';
import { RouteLocationNormalized, Router } from 'vue-router';
import { useCache } from '@/hooks/useCache';
import { useUserStore } from './user.store';
import { UserPermissionType, UserProfile } from '@model/models';

interface State {
    routers: AppRouteRecordRaw[];
    addRouters: AppRouteRecordRaw[];
    isRouterAdded: boolean;
    menuTabRouters: AppRouteRecordRaw[];
    currentRoute?: RouteLocationNormalized;
}

type RouterSetupParams = {
    router: Router,
    redirect?: string,
}

const { wsCache } = useCache();

const filterRouter = (routers: { component: any, children?: any[], meta?: { permission: string } }[]) => {
    const user = useUserStore();
    const result = [];
    for (const r of routers) {
        if (r.children) {
            r.children = filterRouter(r.children);
        }

        if (!r.meta?.permission || user.hasPermission(r.meta?.permission)) {
            result.push(r);
        } else {
            // r.component = () => import('@/views/error/NotFound.vue');
            result.push(r);
        }
    }
    return result;
}

export const useRouteStore = defineStore({
    id: 'route',
    state: (): State => ({
        currentRoute: undefined,
        routers: [],
        addRouters: [],
        isRouterAdded: false,
        menuTabRouters: []
    }),
    persist: {
        enabled: true
    },
    getters: {
        getRouters(): AppRouteRecordRaw[] {
            return this.routers
        },
        getAdditionalRouters(): AppRouteRecordRaw[] {
            return flatMultiLevelRoutes(cloneDeep(this.addRouters))
        },
        getIsReady(): boolean {
            return this.isRouterAdded;
        },
        getMenuTabRouters(): AppRouteRecordRaw[] {
            return this.menuTabRouters
        },
        getCurrentRoute(): RouteLocationNormalized | undefined {
            return this.currentRoute;
        }
    },
    actions: {
        setupRouter({ redirect, router }: RouterSetupParams) {
            if (!redirect) {
                redirect = '/';
            }
            let routers = filterRouter(asyncRouterMap as any) || [];
            wsCache.set("roleRouters", routers);

            this.generateRoutes().catch((e) => {
                console.error("Routers getRoute Error", e);
            });

            this.getRouters.forEach((route) => {
                router.addRoute(route as any);
            });

            this.setIsReady(true);
            const path = redirect || this.addRouters[0].path;
            router.push({ path });
        },
        generateRoutes(): Promise<unknown> {
            return new Promise<void>((resolve) => {
                let routerMap: AppRouteRecordRaw[] = filterRouter(asyncRouterMap as any ?? []);

                // Fallback at the tail
                this.addRouters = routerMap.concat(fallbackRouterMap as any);

                // Render all routes
                this.routers = cloneDeep(constantRouterMap).concat(this.addRouters);

                resolve();
            })
        },
        setIsReady(state: boolean): void {
            this.isRouterAdded = state
        },
        setMenuTabRouters(routers: AppRouteRecordRaw[]): void {
            this.menuTabRouters = routers
        },
        setCurrentRoute(to: RouteLocationNormalized): void {
            this.currentRoute = to;
        }
    }
})

export const useRouteStoreWithOut = () => {
    return useRouteStore(store)
}
