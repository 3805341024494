import { PaymentDiscountItemParam } from "@model/dtos";
import { PricingOffer } from "@model/entities";
import { constructError } from "@model/error";
import { convert2dp } from "@model/utils";
import { Labels, SelectItem } from "../common";

export const paymentPricingOfferTypes = [
    "constant",
    "percentage",
    "consultation_fee",
    "basic_consultation_fee",
    "pricing_item"
] as const;

export type PricingOfferType = typeof paymentPricingOfferTypes[number];

export const paymentPricingOfferTypeMap: Labels<PricingOfferType, {
    requireValue: boolean;
}> = {
    constant: {
        label: "折扣（定額）",
        color: '#fff',
        show: false,
        meta: {
            requireValue: true,
        }
    },
    percentage: {
        label: "折扣（百分比）",
        color: '#fff',
        show: false,
        meta: {
            requireValue: true,
        }
    },
    consultation_fee: {
        label: "診金",
        color: '#fff',
        show: false,
        meta: {
            requireValue: false,
        }
    },
    basic_consultation_fee: {
        label: "基本診金",
        color: '#fff',
        show: false,
        meta: {
            requireValue: false,
        }
    },
    pricing_item: {
        label: "收費項目",
        color: '#fff',
        show: false,
        meta: {
            requireValue: false,
        }
    },
}

export const translatePaymentPricingOfferType = (opt: PricingOfferType) => {
    return paymentPricingOfferTypeMap[opt].label ?? null;
}

export const translatePaymentPricingOfferTypeValue = (opt: PricingOfferType, value?: string | number, pricingItemName?: string) => {
    switch (opt) {
        case 'constant':
            return `$ ${convert2dp(value)}`;
        case 'percentage':
            return `${convert2dp(value)}%`;
        case 'consultation_fee':
        case 'basic_consultation_fee':
            return `-`;
        case 'pricing_item':
            return pricingItemName;
        default:
            return value;
    }
}

export const paymentPricingOfferTypeItems: SelectItem<PricingOfferType>[] = paymentPricingOfferTypes.map(x => ({
    label: translatePaymentPricingOfferType(x),
    value: x,
}));

export const convertPricingOfferToDiscountParam: Record<PricingOfferType, (offer: PricingOffer, meta: {
    consultationFee: number,
    basicConsultationFee?: number,
}) => PaymentDiscountItemParam> = {
    constant: (offer) => {
        return {
            amount: convert2dp(offer.value),
            category: 'consultation',
            name: offer.name_zh,
            type: 'constant',
        }
    },
    percentage: (offer) => {
        return {
            amount: convert2dp(offer.value),
            category: 'consultation',
            name: offer.name_zh,
            type: 'percentage',
        }
    },
    consultation_fee: (offer, meta) => {
        return {
            amount: convert2dp(meta.consultationFee),
            category: 'consultation',
            name: offer.name_zh,
            type: 'constant',
        }
    },
    basic_consultation_fee: (offer, meta) => {
        return {
            amount: convert2dp(meta.basicConsultationFee),
            category: 'consultation',
            name: offer.name_zh,
            type: 'constant',
        }
    },
    pricing_item: (offer, meta) => {
        // TODO this is pending
        return {
            amount: convert2dp(meta.basicConsultationFee),
            category: 'consultation',
            name: offer.name_zh,
            type: 'constant',
        }
    }
}