import { Doctor, Clinic, Patient, Appointment } from "@model/entities";
import { AppointmentType } from "@model/models";
import { IsNumber, IsString } from "class-validator";

export abstract class PaymentConsultationPreviewDTO {
    @IsNumber()
    clinic_id: Clinic['id'];

    @IsNumber()
    doctor_id: Doctor['id'];

    @IsNumber()
    patient_id: Patient['id'];
    
    @IsString()
    type: AppointmentType;

    static fromAppointment(appointment: Appointment): PaymentConsultationPreviewDTO {
        return {
            patient_id: appointment.patient_id,
            doctor_id: appointment.doctor_id,
            clinic_id: appointment.clinic_id,
            type: appointment.type,
        };
    }
}
