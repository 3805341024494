import { Appointment, Prescription } from "@model/entities";
import { print, printAppend, textToBase64Barcode } from ".";
import { PrintingService } from "@/services";
import { PrescriptionPrintParams } from "@model/models";
import { Locale, defaultLocale } from "@model/constants";
import { dynamicEnv } from "../dynamic-env";

export const printPrescriptionById = async (options: {
    id: Prescription['id'],
    locale: Locale,
    onClose?: () => void | Promise<void>
}) => {
    const {
        id,
        locale = defaultLocale,
        onClose,
    } = options;

    const prescription = await PrintingService.prescriptionByPrescriptionId(id, locale);
    await print({
        folder: "prescription",
        templateName: `prescription-v2_${locale}`,
        options: prescription,
        title: prescription?.title,
        appointmentId: prescription.appointment.id,
        paramsGenerator: async (params): Promise<PrescriptionPrintParams> => {
            return {
                ...params,
                barcode: textToBase64Barcode(params.prescription.id),
            };
        },
        onClose,
    });
}

export const printPrescriptionsByAppointmentId = async (options: {
    appointmentId: Appointment['id'],
    locale: Locale,
    onClose?: () => void | Promise<void>
}) => {
    const {
        appointmentId,
        locale = defaultLocale,
        onClose,
    } = options;

    const prescriptions = await PrintingService.prescriptionsByAppointmentId(appointmentId, locale);
    await printAppend({
        folder: "prescription",
        templateName: `prescription-v2_${locale}`,
        options: prescriptions,
        appointmentId: prescriptions[0].appointment.id,
        title: prescriptions[0]?.title,
        paramsGenerator: async (params): Promise<PrescriptionPrintParams> => {
            return {
                ...params,
                barcode: textToBase64Barcode(params.prescription.id),
            };
        },
        onClose,
    });
}
