import router from './router'

import { useCache } from "./hooks/useCache";
import { useNProgress } from "./hooks/useNProgress";
import { useRouteStoreWithOut } from './stores/modules/route';
import { RouteRecordRaw as RawRouteRecord } from 'vue-router';
import { useLayoutStoreWithOut } from './stores/modules/layout';
import { useNavigationStore, useNavigationStoreWithOut } from './stores/modules/navigation.store';
import { useUserStore } from './stores/modules';

const routeStore = useRouteStoreWithOut();
const layoutStore = useLayoutStoreWithOut();
const { wsCache } = useCache();
const { start, done } = useNProgress();
const navigationStore = useNavigationStoreWithOut();

// Whitelist not to redirect
const whiteList = [
    '/login',
    '/create-account',
    '/create-account/success',
    '/verifed',
    '/demo',
    '/preview',
    '/forgot-password',
    '/not-found',
    '/terms',
    '/privacy',
];

function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}

function hasSearchQuery(route) {
    return !!(route.query.search);
}

router.beforeEach(async (to, from, next) => {
    start()
    if (wsCache.get('isLoggedIn')) {
        if (to.path === '/login') {
            next({ path: '/' });
        } else {
            if (routeStore.getIsReady) {
                next();
                return;
            }

            await routeStore.generateRoutes();

            routeStore.getRouters.forEach((route) => {
                router.addRoute(route as unknown as RawRouteRecord);
            });

            const redirectPath = from.query.redirect || to.path;
            const redirect = decodeURIComponent(redirectPath as string);
            const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect };
            routeStore.setIsReady(true);
            next(nextData);
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next(`/login?redirect=${to.path}`); // 否则全部重定向到登录页
        }
    }
});


router.beforeEach(async (to, from, next) => {
    const user = useUserStore();
    if (!to.meta.permission || user.hasPermission(to.meta.permission)) {
        next();
    } else {
        next(`/not-found`);
    }
});

router.afterEach((to) => {
    navigationStore.setNavigation(to.name as string);
    done();
});
