<script lang="ts">
import { PrescriptionService } from '@/services';
import { Appointment, Prescription } from '@model/entities';
import { ListenEvent } from '@/services/events/listen-event.decorator';
import { useUserStore } from '@/stores/modules';
import { Options, Vue } from 'vue-property-decorator';
import { printPrescriptionById } from '@/utils/print';

@Options({
    components: {},
})
export default class AppointmentPrescriptionTracingDialog extends Vue {
    user = useUserStore();
    open = false;
    loading = false;

    appointmentId: Appointment['id'] = null;
    list: Prescription[] = [];

    @ListenEvent('appointment-prescription-tracing-dialog:open')
    async handleOpen(appointmentId: Appointment['id']) {
        this.appointmentId = appointmentId;
        this.list = await PrescriptionService.listByAppointment(appointmentId);
        this.open = true;
    }

    async handleClickPrint(prescriptionId: Prescription['id']) {
        await printPrescriptionById({ id: prescriptionId, locale: 'zh' });
    }
}
</script>

<template lang="pug">
el-dialog(v-model="open" append-to-body width="70%" destroy-on-close)
    template(#header)
        div 處方追蹤
    .body
        el-table(:data="list")
            el-table-column(label="狀態" width="120")
                template(#default="scope")
                    div {{ $filters.prescriptionStatus(scope.row.status) }}
            el-table-column(label="編號" width="180")
                template(#default="scope")
                    div {{ $filters.prescriptionId(scope.row.consultation_id, scope.row.index) }}
            el-table-column(label="處方地點" width="180")
                template(#default="scope")
                    div {{ scope.row.clinic.display_name }}
            el-table-column(label="內部備注" width="180")
                template(#default="scope")
                    div {{ scope.row.internal_remark }}
            el-table-column(label="最後更新" width="180")
                template(#default="scope")
                    div {{ $filters.datetime(scope.row.updated_at) }}
            el-table-column(label="" width="180")
                template(#default="scope")
                    .button-group 
                        el-button(type="text" size="mini" @click="handleClickPrint(scope.row.id)") 查看處方
</template>

<style lang="scss" scoped>
@import '@/assets/styles/common.scss';

:deep(.el-dialog__body) {
    padding-top: 0;
}
</style>
