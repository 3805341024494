import { AutoReminderQueryDTO } from "@model/dtos";
import { BaseService } from "./base.service";

class Service extends BaseService {
    async search(query: AutoReminderQueryDTO): Promise<{ meta: { totalItems: number, itemCount: number, itemsPerPage: number, totalPages: number, currentPage: number }, items: any[] }> {
        return this.get(`?${this.parseQuery(query)}`);
    }

    async cancelById(id: string): Promise<{ total: number, items: any[] }> {
        return this.delete(`/${id}`,);
    }

    async cancelByAppointmentId(id: string): Promise<{ total: number, items: any[] }> {
        return this.delete(`/appointment/${id}`,);
    }
}

export const AutoReminderService = new Service('/auto-reminder');