import { Labels } from "../common";

export const appointmentTypeList = [
    "new",
    "old",
    "vc",
    "long",
    "massage",
    "tianjiu",
    "acu",
    "socacu",
    "redispense",
    "placeholder",
    "package",
    "purchase",
    "regform",
    "confinement",
    "confinementvc",
] as const;

export const appointmentTypeForSelection = [
    "new", "old", "long", "vc", "massage", "tianjiu", "socacu", "confinement", "confinementvc",
]


export type AppointmentType = typeof appointmentTypeList[number];

export const translateAppointmentType = (type: AppointmentType) => {
    return appointmentTypeMap[type]?.label ?? '-';
}

export const getDefaultAppointmentDurationFromType = (type: AppointmentType) => {
    return appointmentTypeMap[type].meta.duration;
}

export const getDefaultAbbreviationFromType = (type: AppointmentType) => {
    return appointmentTypeMap[type].meta.abbreviation;
}

export const appointmentTypeMap: Labels<AppointmentType, { abbreviation: string, duration: number }> = {
    new: {
        label: "新症",
        color: "#fff",
        meta: {
            abbreviation: '新',
            duration: 15,
        }
    },
    old: {
        label: "舊症",
        color: "#fff",
        meta: {
            abbreviation: '舊',
            duration: 5,
        }
    },
    long: {
        label: "長時間病人",
        color: "#fff",
        meta: {
            abbreviation: '長',
            duration: 30,
        }
    },
    vc: {
        label: "視像",
        color: "#fff",
        meta: {
            abbreviation: '視',
            duration: 10,
        }
    },
    massage: {
        label: "推拿",
        color: "#fff",
        meta: {
            abbreviation: '推',
            duration: 20,
        }
    },
    tianjiu: {
        label: "天灸",
        color: "#fff",
        meta: {
            abbreviation: '天',
            duration: 5,
        }
    },
    acu: {
        label: "針灸",
        color: "#fff",
        meta: {
            abbreviation: '針',
            duration: 20,
        },
        show: false,
    },
    socacu: {
        label: "社區針灸",
        color: "#fff",
        meta: {
            abbreviation: '社',
            duration: 20,
        }
    },
    redispense: {
        label: "重配",
        color: "#fff",
        meta: {
            abbreviation: '重',
            duration: 0,
        },
        show: false,
    },
    placeholder: {
        label: "預留",
        color: "#fff",
        meta: {
            abbreviation: '預',
            duration: 0,
        },
        show: false,
    },
    regform: {
        label: "登記表",
        color: "#fff",
        meta: {
            abbreviation: '登記',
            duration: 0,
        },
        show: false,
    },
    package: {
        label: "套票購買",
        color: "#fff",
        meta: {
            abbreviation: '套',
            duration: 0,
        },
        show: false,
    },
    purchase: {
        label: "購物/預付",
        color: "#fff",
        meta: {
            abbreviation: '購',
            duration: 0,
        },
        show: false,
    },
    confinement: {
        label: "月子餐 - 面診",
        color: "#fff",
        meta: {
            abbreviation: '月',
            duration: 10,
        },
    },
    confinementvc: {
        label: "月子餐 - 視像",
        color: "#fff",
        meta: {
            abbreviation: '視/月',
            duration: 10,
        },
    }
};

export const visibleAppointmentTypes = appointmentTypeList.map(x => appointmentTypeMap[x]).filter(x => x.show !== false);
