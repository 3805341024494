import { TinyEmitter } from 'tiny-emitter';
import { AuthEventType } from './@types/auth.event';
import { TimerEventType } from './@types/timer.event';
import { DialogEventType } from './@types/dialog.event';
import { PatientViewEventType } from './@types/patient.event';
import { UserEventType } from './@types/user.event';
import { KeyboardEventType } from './@types/keyboard.event';
import authEvent from './auth.event';
import patientEvent from './patient.event';
import { AppointmentEventType } from './@types/appointment.event';
import { ServerEventType } from './@types/server.event.';
import { notify } from '@/utils/notify';
import { ConsultationEventType } from './@types/consultation.event';
import { DispenseEventType } from './@types/dispense.event';
import { DatagridEventType } from './@types/datagrid.event';
import { PaymentEventType } from './@types/payment.event';
import { GlobalEventType } from './@types/global.event';
import { PackageEventType } from '@/components/features/packages/@types/package.event';
import { DoctorPrescriptionEventType } from '@/components/features/doctor-prescription/@types/doctor-prescription.event';
import { DoctorEsignEventType } from '@/components/features/doctor-esign/@types/doctor-esign.event';
import { DoctorSalaryConfigEventType } from '@/components/features/doctor-salary-config/@types/doctor-salary-config.event';
import { AutoReminderEventType } from '@/components/features/auto-reminder/@types/auto-reminder.event';

export let eventEmitter: TinyEmitter = new TinyEmitter();

export function setupEventEmitter() {
    if (!eventEmitter) {
        eventEmitter = new TinyEmitter();
    }
}

export type EventType =
    | GlobalEventType
    | AuthEventType
    | ConsultationEventType
    | DialogEventType
    | PatientViewEventType
    | UserEventType
    | AppointmentEventType
    | KeyboardEventType
    | ServerEventType
    | DispenseEventType
    | DatagridEventType
    | PaymentEventType
    | PackageEventType
    | DoctorSalaryConfigEventType
    | DoctorPrescriptionEventType
    | DoctorEsignEventType
    | AutoReminderEventType
    | TimerEventType;

export function initEventEmitter() {
    eventEmitter = new TinyEmitter();
    authEvent.init(eventEmitter);
    patientEvent.init(eventEmitter);
    eventEmitter.on('server:connection-failed', () => {
        notify.error('伺服器連接失敗');
    });

    addEventListener('keydown', (event) => {
        const pressedKeyCode = event.code;
        if (pressedKeyCode === 'Escape') {
            return eventEmitter.emit('keydown:esc');
        } else if (pressedKeyCode === 'Tab') {
            return eventEmitter.emit('keydown:tab');
        } else if (pressedKeyCode === 'Enter') {
            return eventEmitter.emit('keydown:enter');
        }
    });

    const timer = setInterval(() => {
        const date = new Date();
        const offset = Math.floor(Math.random() * 60);
        if (date.getSeconds() === offset) {
            eventEmitter.emit('timer:minute');
            if (date.getMinutes() % 5 === 0 && date.getSeconds() === offset) {
                eventEmitter.emit('timer:five-minute');
            }
        }
        // Probably we don't really need this
        // eventEmitter.emit('timer:second');
    }, 1000);
}
