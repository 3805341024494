
import { Appointment } from "@model/entities";
import { print } from ".";
import { PrintingService } from "@/services";
import { defaultLocale, Locale } from "@model/constants";
import { DiagnosisReportPrintParams } from "@model/models";

export const printDiagnosisReport = async (options: {
    appointmentId: Appointment['id'],
    locale?: Locale,
}) => {
    const {
        appointmentId,
        locale = defaultLocale,
    } = options;
    const params = await PrintingService.diagnosisReportByAppointmentId(appointmentId, locale);
    await print({
        folder: "consultation",
        templateName: `diagnosis-report_${options.locale}`,
        options,
        title: params?.title,
        appointmentId: params.appointment.id,
        paramsGenerator: async (): Promise<DiagnosisReportPrintParams> => {
            return params;
        },
    })
};