import { Appointment, Consultation, ConsultationTreatment } from "@model/entities";
import { ConsultationTreatmentDataUpdateDTO } from "@model/dtos";
import { BaseService } from "./base.service";
import { AppointmentStatus } from "@model/models";

class Service extends BaseService {
    async oneByAppointmentId(id: Appointment['id']): Promise<ConsultationTreatment> {
        return this.get(`/one/appointment/${id}`);
    }

    async oneByConsultationId(id: Consultation['id']) {
        return this.get(`/one/consultation/${id}`);
    }

    async createOrUpdate(body: ConsultationTreatmentDataUpdateDTO, status?: AppointmentStatus): Promise<ConsultationTreatment> {
        return this.post(`/${status ? status : ''}`, body);
    }
}

export const ConsultationTreatmentService = new Service('/consultation-treatment');