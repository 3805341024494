<script lang="ts">
import { Emit, Options, Prop, Vue } from "vue-property-decorator";
import { useSelectItemStore } from "@/stores/modules/select-item";
import { SelectItem } from "@model/models";
import { Doctor, User } from "@model/entities";

@Options({})
export default class SelectDoctor extends Vue {
    selectItemStore = useSelectItemStore();

    @Prop({ default: "選擇醫師" })
    placeholder!: string;

    value: string = "";

    items: SelectItem<Doctor["id"]>[] = [];

    async mounted() {
        this.items = await this.selectItemStore.getDoctors();
    }

    @Emit("change")
    onChange(value: any) {
        this.value = value;
    }
}
</script>

<template lang="pug">
el-select(v-model="value" @change="onChange" :placeholder="placeholder")
    el-option(v-for="item in items" :key="item.value" :label="item.label" :value="item.value" )
</template>

<style scoped lang="scss">

</style>
