import { axGet } from "./axios";
import { BaseService } from "./base.service";
import { PatientVital } from "@model/entities";


class Service extends BaseService {
    async list(patientId: number): Promise<PatientVital[]> {
        return this.get(`/${patientId}`);
    }
}

export const PatientVitalService = new Service('/patient-vital');