import { Labels, SelectItem } from "../common";

export const paymentPricingDiscountTypes = [
    "constant",
    "percentage",
] as const;

export type PricingDiscountType = typeof paymentPricingDiscountTypes[number];

export const paymentPricingDiscountTypeMap: Labels<PricingDiscountType, never> = {
    constant: {
        label: "定額",
        color: '#fff',
        show: false,
    },
    percentage: {
        label: "折扣",
        color: '#fff',
        show: false,
    },
}

export const translatePaymentPricingDiscountType = (opt: PricingDiscountType) => {
    return paymentPricingDiscountTypeMap[opt].label ?? null;
}

export const paymentPricingDiscountTypeItems: SelectItem<PricingDiscountType>[] = paymentPricingDiscountTypes.map(x => ({
    label: translatePaymentPricingDiscountType(x),
    value: x,
}));